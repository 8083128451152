import { useCallback } from 'react';

import { useMessage } from '@tmapy/intl';
import { SvgExternal, Input, TertiaryLink } from '@tmapy/style-guide';

import type { DataComponent } from '../../types';
import { ReadOnlyFormField } from '../../components/ReadOnlyFormField';
import { msg } from '../../messages';

import { InlineViewComponentMap } from './InlineViewComponentMap';
import { stringDataConverter } from './InputComponentMap';

export const URLInput: DataComponent = ({
  data,
  errors,
  path,
  loading,
  onChange,
  isRequired,
  isReadOnly,
  formType,
}) => {
  const formatMessage = useMessage();

  const handleChange = useCallback(
    (e: React.ChangeEvent, eventData: { value: any }) => {
      const value = stringDataConverter.fromText(eventData.value);
      onChange?.(value);
    },
    [onChange],
  );

  const value = stringDataConverter.toText(data);

  return isReadOnly ? (
    <ReadOnlyFormField>
      <InlineViewComponentMap.URL
        loading={loading}
        data={value}
        errors={errors}
        path={path}
        variables={null}
        isReadOnly
        isRequired={isRequired}
      />
    </ReadOnlyFormField>
  ) : (
    <Input
      type='url'
      value={value}
      isRequired={isRequired}
      isReadOnly={isReadOnly}
      isInvalid={!!errors.length}
      isDisabled={loading}
      onChange={handleChange}
    >
      {formType !== 'new' && value && (
        <TertiaryLink
          icon={{ element: <SvgExternal /> }}
          tooltip={formatMessage(msg.openUrl, { url: value })}
          href={value}
          target='_blank'
        />
      )}
    </Input>
  );
};
