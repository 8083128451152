import { useCallback } from 'react';

import { DatePicker } from '@tmapy/style-guide';

import type { DataComponent } from '../../types';
import { ReadOnlyFormField } from '../../components/ReadOnlyFormField';
import { DateTimeRangeValue } from '../../utils/DateTimeRangeValue';

import { InlineViewComponentMap } from './InlineViewComponentMap';

export const DateTimeRangeInput: DataComponent = ({
  data,
  onChange,
  validate,
  isRequired,
  isReadOnly,
  loading,
  errors,
  path,
}) => {
  const handleAfterChange = useCallback(
    (
      _,
      eventData: {
        name?: string;
        value: Date | null;
      },
    ) => {
      let after;
      if (eventData.value) {
        try {
          // Fix timezone mismatch
          const midday = new Date(eventData.value.getTime());
          midday.setHours(12);
          after = midday;
        } catch (e) {
          /* empty */
        }
      }
      const veryNewData = new DateTimeRangeValue({
        ...data,
        after,
      });
      onChange?.(veryNewData);
    },
    [data, onChange],
  );

  const handleBeforeChange = useCallback(
    (
      _,
      eventData: {
        name?: string;
        value: Date | null;
      },
    ) => {
      let strictly_before;
      if (eventData.value) {
        try {
          // Fix timezone mismatch
          const midday = new Date(eventData.value.getTime());
          midday.setHours(12);
          // TODO: zbavit se hnusneho odstranovani milisekund
          strictly_before = midday;
        } catch (e) {
          /* empty */
        }
      }
      const veryNewData = new DateTimeRangeValue({
        ...data,
        strictly_before,
      });
      onChange?.(veryNewData);
    },
    [data, onChange],
  );

  if (isReadOnly) {
    return (
      <ReadOnlyFormField>
        <InlineViewComponentMap.DateRange
          loading={loading}
          data={data}
          errors={errors}
          path={path}
          variables={null}
          isReadOnly
          isRequired={isRequired}
        />
      </ReadOnlyFormField>
    );
  }

  return (
    <div className='sg-a-d-f sg-a-g-1'>
      <DatePicker
        value={data ? data?.after : null}
        minDay={validate?.min ? new Date(validate.min) : undefined}
        maxDay={validate?.max ? new Date(validate.max) : undefined}
        isInvalid={!!errors.length}
        isDisabled={loading}
        onChange={handleAfterChange}
      />
      <DatePicker
        value={data ? data?.strictly_before : null}
        minDay={validate?.min ? new Date(validate.min) : undefined}
        maxDay={validate?.max ? new Date(validate.max) : undefined}
        isInvalid={!!errors.length}
        isDisabled={loading}
        onChange={handleBeforeChange}
      />
    </div>
  );
};
