import { DirectiveNode, OperationDefinitionNode, parse } from 'graphql';

/**
 * ```
 * /@(\w+)\s*
 * (
 *   \(
 *     (
 *       """.*?"""
 *       |
 *       (
 *         "(
 *           (?=\\)"
 *           |
 *           [^"]
 *         )*"
 *       )
 *       |
 *       [^)]
 *     )*
 *   \)
 * )/gm
 * ```
 */
const RE_DESCRIPTION_DIRECTIVE = /@(\w+)\s*(\((""".*?"""|("((?=\\)"|[^"])*")|[^)])*\))?/gm;

export const getDirectivesFromDescription = (desc?: string | null): readonly DirectiveNode[] => {
  if (!desc) {
    return [];
  }
  const fakeDocument = ['{x'];
  for (const match of desc.matchAll(RE_DESCRIPTION_DIRECTIVE)) {
    const [, directiveName, directiveArguments] = match;
    fakeDocument.push(`@${directiveName}${directiveArguments ?? ''}`);
  }
  fakeDocument.push('}');
  const document = parse(fakeDocument.join(' '));
  return (
    (document.definitions[0] as OperationDefinitionNode).selectionSet.selections[0].directives ?? []
  );
};
