import type { ValuesOf } from '@tmapy/types';

export const SORTING_METHOD = {
  UNSORTED: 'UNSORTED',
  ASC: 'ASC',
  DESC: 'DESC',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type SORTING_METHOD = ValuesOf<typeof SORTING_METHOD>;

export enum GRAPHQL_ERROR_CODE {
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  AUTH_ERROR = 'AUTH_ERROR',
  EWKT_INPUT_EMPTY = 'EWKT_INPUT_EMPTY',
  EWKT_INPUT_INVALID_TYPE = 'EWKT_INPUT_INVALID_TYPE',
  EWKT_INPUT_PATTERN_MISMATCH = 'EWKT_INPUT_PATTERN_MISMATCH',
  INVALID_EWKT = 'INVALID_EWKT',
  INVALID_EMAIL = 'INVALID_EMAIL',
  INVALID_INTEGER = 'INVALID_INTEGER',
  INVALID_VALUE = 'INVALID_VALUE',
  MUST_BE_DEFINED = 'MUST_BE_DEFINED',
  NOT_NULL = 'NOT_NULL',
  NULL_GIVEN = 'NULL_GIVEN',
}
