import { useMessage } from '@tmapy/intl';

import { msg } from './messages';
import './ErrorPage.scss';

export type ErrorPageProps = {
  title?: string;
  subtitle?: string;
  children?: React.ReactNode;
};

export const ErrorPage = ({ title, subtitle, children }: ErrorPageProps) => {
  const formatMessage = useMessage();

  return (
    <div className='tw-errorPage'>
      <main className='tw-errorPage--inner'>
        <h1>{title || formatMessage(msg.title)}</h1>
        <h2 className='sg-a-my-2'>{subtitle || formatMessage(msg.notFoundPageSubtitle)}</h2>
        {children}
      </main>
    </div>
  );
};
