import React from 'react';

import { useMessage } from '@tmapy/intl';
import { Link } from '@tmapy/router';

import './NavItem.scss';

import { FetchedIcon } from 'lib/graphql/components/FetchedIcon';

type NavItemProps = {
  to: string;
  icon: string;
};

export const NavItem: React.FC<NavItemProps> = ({ to, icon }) => {
  const formatMessage = useMessage();
  const label = formatMessage({ id: `nav.${to}` }) ?? to;
  return (
    <div className='tw-navItem' title={label}>
      <Link to={to} testId={`tw-navItem--${to}`}>
        <div className='tw-navItem--img'>
          <FetchedIcon name={icon} />
        </div>
        <span className='tw-navItem--label' data-label={label}>
          {label}
        </span>
      </Link>
    </div>
  );
};
