// keep this import at first line
import { loadPolyfills } from './polyfill-loader';

import ReactDOM from 'react-dom';
import { FormattedMessage } from 'react-intl';
import * as Sentry from '@sentry/react';

import { deriveAuthFromConfig, derivePermissionsFromConfig } from '@tmapy/config';
import { actionSetExtent } from '@tmapy/mapcore';
import { actionCobraLoginAsAnonymous, configureAuth, initCobraAuth } from '@tmapy/auth';
import {
  configureLocale,
  setLocale,
  actionUpdateNonTranslatableResourceStrings,
} from '@tmapy/intl';
import { configureRouter, getLocalizedPath, history } from '@tmapy/router';
import { actionSetPanel } from '@tmapy/ui-manager';
import { configureEditTools } from '@tmapy/edit-tools';
import { actionLoadCustomLayers } from '@tmapy/layer-switcher';
import { ErrorPage } from '@tmapy/style-guide';

import { createClient, showInstalledModules, TwcFeatureManager } from 'lib/graphql';

import { sentryInit } from './sentry';
import { configureStore } from './redux/configureStore';

import { App, BaseApp } from './app/App';
import { Router } from './app/Router';
import { msg as errorMsg } from './app/errors/messages';
import { getLocaleLoader } from './locale/loadLocale';
import {
  actionAppStartPopup,
  actionSetPackageVersion,
  actionSetLayerVisibilityByHashParam,
  deriveStatesFromConfig,
} from './app/actions';
import { VERSION } from './constants';
import { auth, authInitialState } from './auth/reducer';
import { actionSetPermission } from './auth/actions';
import { getAppBaseName } from './utils/getAppBaseName';
import { loadConfig } from './config/loadConfig';

console.info('version: ', VERSION);

async function main() {
  const rootElement = document.getElementById('root');
  try {
    await loadPolyfills();

    sentryInit();
    const appBaseName = getAppBaseName(window.location.pathname);
    Sentry.setTag('application', appBaseName);

    const store = configureStore();
    const config = await loadConfig(appBaseName);
    console.info('[main] config', config);

    const appBaseNameMask = new RegExp(/^[a-z0-9-]+$/);
    if (!appBaseNameMask.test(appBaseName) || !config?.appId) {
      const error = new Error(
        `App "${appBaseName}" not found or contains forbidden characters (only lowercase, digits or hyphens are allowed).`,
      );
      error.cause = { subtitle: <FormattedMessage {...errorMsg.notFoundAppSubtitle} /> };
      error.stack = undefined;
      throw error;
    }

    if (config?.graphql) {
      Sentry.setTag('graphql', new URL(config?.graphql, window.location.href).href);
    }

    if (config?.auth && config?.auth !== true) {
      // TODO - auth url from configuration is a potentional vulnerability
      configureAuth(deriveAuthFromConfig(config?.auth), authInitialState, auth);

      const isRefreshTokenValid = await initCobraAuth(store.dispatch, (replaceUrl: string) => {
        const url = new URL(replaceUrl);
        if (!url.search) {
          // HACK: history library will not replace query string parameters if search is empty
          url.search = '?_';
        }
        history.replace(url.href);
      });

      if (!isRefreshTokenValid) {
        await store.dispatch(actionCobraLoginAsAnonymous());
      }
    }

    const client = await createClient(store.dispatch, { uri: config?.graphql }, !!config?.auth);
    const installedModules = await showInstalledModules(client, VERSION, config.packageId);
    store.dispatch(actionSetPackageVersion(installedModules.packageVersion));
    if (installedModules.incompatibility || config.incompatibility) {
      const error = new Error('Version is not in the compatibility range.');
      error.cause = { subtitle: <FormattedMessage {...errorMsg.incompatibilitySubtitle} /> };
      error.stack = undefined;
      throw error;
    }

    const locale =
      (typeof config.basename === 'object' &&
        Object.entries(config.basename).find(
          ([, basename]) => basename === `/${appBaseName}`,
        )?.[0]) ||
      'cs';
    const loader = getLocaleLoader(
      config?.appId,
      config?.loadBeaConfig,
      installedModules.packageVersion,
    );
    store.dispatch(configureLocale({ default: locale, supported: ['cs', 'en'], loader }));
    await store.dispatch(setLocale(locale));

    store.dispatch(actionSetPermission(derivePermissionsFromConfig(config?.permissions)));

    const failedDeriveState = !store.dispatch(deriveStatesFromConfig(config, client));
    if (failedDeriveState) {
      const error = new Error('Failed to derive application settings from configuration.');
      error.cause = { subtitle: <FormattedMessage {...errorMsg.failedDeriveState} /> };
      error.stack = undefined;
      throw error;
    }

    if (config?.nonTranslatableResourceStrings) {
      store.dispatch(
        actionUpdateNonTranslatableResourceStrings(config?.nonTranslatableResourceStrings),
      );
    }

    const state = store.getState();

    const PUBLIC_URL = process.env.PUBLIC_URL ?? '';
    const pathPrefix = PUBLIC_URL.replace(/\/$/, '');

    await configureRouter(state.app.routes, {
      pathPrefix,
      basename: state.app.basename,

      /**
       * @see https://github.com/pillarjs/path-to-regexp#usage
       */
      strict: false,
    });

    const localizedBasenamePath = getLocalizedPath(pathPrefix, state.app.basename, locale)!;

    // When current path is web root or directly appId then replace location with basename path
    const currentPathnameWithoutSlashes = window.location.pathname.replace(/(^\/-)|(\/)/g, '');
    if (
      config &&
      (currentPathnameWithoutSlashes === '' || currentPathnameWithoutSlashes === appBaseName)
    ) {
      history.replace(localizedBasenamePath);
    }

    (store.dispatch(actionLoadCustomLayers()) as any as Promise<void>).then(() => {
      const l = store.getState().router.hashParams.l;
      if (l) {
        store.dispatch(actionSetLayerVisibilityByHashParam(l) as any);
      }
    });

    const panel = store.getState().router.hashParams.p;
    if (config.activeWidget && !panel) {
      store.dispatch(actionSetPanel(config.activeWidget));
    }

    configureEditTools({ featureManager: new TwcFeatureManager(store, client) });

    const app = (
      <App client={client}>
        <Router />
      </App>
    );

    ReactDOM.render(app, rootElement, () => {
      setTimeout(() => {
        const hashParams = store.getState().router.hashParams;
        if (hashParams.bbox) {
          const bbox = hashParams.bbox.split(',').map(parseFloat);
          store.dispatch(actionSetExtent(bbox));
        } else if (
          !!config?.map?.view?.defaultExtent &&
          !(!!hashParams.x && !!hashParams.y && !!hashParams.z)
        ) {
          store.dispatch(actionSetExtent(config.map?.view?.defaultExtent));
        }
        if (config?.popups) {
          store.dispatch(actionAppStartPopup(config?.appId, config.popups) as any);
        }
      }, 0);
    });
  } catch (err) {
    const cause = err instanceof Error ? err.cause : undefined;
    const subtitle =
      cause instanceof Object && Object.prototype.hasOwnProperty.call(cause, 'subtitle')
        ? (cause as { subtitle: unknown }).subtitle
        : undefined;
    const stack = err instanceof Error ? err.stack : undefined;
    const detailsTitle = err instanceof Error ? err.toString() : undefined;

    // TODO: Report error to Sentry or GA
    console.error('Error while rendering app.', err);
    ReactDOM.render(
      <BaseApp>
        <ErrorPage
          title={<FormattedMessage {...errorMsg.title} />}
          subtitle={subtitle ?? <FormattedMessage {...errorMsg.defaultSubtitle} />}
          detailsTitle={detailsTitle ?? <FormattedMessage {...errorMsg.detailsTitle} />}
          details={
            stack && (
              <code>
                <pre>{stack}</pre>
              </code>
            )
          }
        />
      </BaseApp>,
      rootElement,
    );
  }
}
main();
