import { useCallback, useMemo } from 'react';

import { useDispatch, useSelector } from '@tmapy/redux';
import type { LayerState } from '@tmapy/config';
import { LAYER_TYPE } from '@tmapy/config';
import { NestedMap, structuredClone, shallowCompare } from '@tmapy/utils';
import { Layer, MapProvider } from '@tmapy/mapcore';
import { authGlobals } from '@tmapy/auth';

const LAYERS_PARAM = ['LAYERS', 'layers', 'Layers'];

const mergeParams = (
  params: Record<string, any>,
  addParams: Record<string, any>,
  layerType: LAYER_TYPE,
) => {
  const newParams = { ...params };
  for (const [key, value] of Object.entries(addParams)) {
    if (!params[key]) {
      newParams[key] = value;
    } else if (params[key] !== value) {
      newParams[key] = params[key] + ',' + value;
    }
  }

  // ArcGIS layer param optimalization (show: only supported)
  if (layerType === LAYER_TYPE.IMAGE_ARCGIS_REST || layerType === LAYER_TYPE.TILE_ARCGIS_REST) {
    LAYERS_PARAM.forEach((key) => {
      if (newParams[key]) {
        newParams[key] = `show:${newParams[key].replaceAll('show:', '')}`;
      }
    });
  }

  return newParams;
};

const mergeCache = new NestedMap<[LayerState, LayerState], LayerState>([WeakMap, WeakMap]);

/**
 * Layer request optimalization
 * Create one request for equal mapServices
 * ImageWMS, VectorTile, ImageArcGISRest, TileArcGISRest
 * @param layers : LayerState[]
 * @returns: LayerState[]
 */
const mergeLayers = (layers: LayerState[]) => {
  const merged = [...layers];
  merged.sort((a, b) => a.layerOptions.zIndex - b.layerOptions.zIndex);

  let lastLayer: LayerState | null;
  const result = merged.reduce((acc, layer) => {
    if (
      (lastLayer?.type !== LAYER_TYPE.IMAGE_WMS &&
        lastLayer?.type !== LAYER_TYPE.TILE_VECTOR &&
        lastLayer?.type !== LAYER_TYPE.IMAGE_ARCGIS_REST &&
        lastLayer?.type !== LAYER_TYPE.TILE_ARCGIS_REST) ||
      lastLayer.type !== layer.type
    ) {
      lastLayer = layer;
      acc.push(lastLayer);
      return acc;
    }

    const isSameParams =
      layer.type === lastLayer.type &&
      shallowCompare(
        lastLayer.sourceOptions.params ?? {},
        layer.sourceOptions.params ?? {},
        LAYERS_PARAM,
      );
    if (
      lastLayer.layerOptions.visible &&
      (lastLayer.layerOptions.opacity ?? 1) === (layer.layerOptions.opacity ?? 1) &&
      lastLayer.sourceOptions.url === layer.sourceOptions.url &&
      isSameParams
    ) {
      if (!layer.layerOptions.visible || (layer.layerOptions.opacity ?? 1) === 0) {
        return acc;
      }

      let result = mergeCache.get([lastLayer, layer]);
      if (result) {
        lastLayer = result;
        acc[acc.length - 1] = result;
        return acc;
      }

      result = structuredClone(lastLayer);
      mergeCache.set([lastLayer, layer], result);
      lastLayer = result;

      result.id = `${lastLayer.id}&${layer.id}`;
      result.sourceOptions.params = mergeParams(
        lastLayer.sourceOptions.params ?? {},
        layer.sourceOptions.params ?? {},
        lastLayer.type,
      );

      acc[acc.length - 1] = result;
      return acc;
    } else {
      lastLayer = layer;
      acc.push(lastLayer);
      return acc;
    }
  }, [] as LayerState[]);

  return result;
};

export const MapWithStore: React.FC = ({ children }) => {
  const mapCore = useSelector((state) => state.mapCore);
  const params = useSelector((state) => state.router.params);
  const dispatch = useDispatch();

  const createGetUrlParams = useCallback(
    (layer: LayerState) => {
      const result: Record<string, string> = {};

      if (layer.routerParams) {
        for (const param in layer.routerParams) {
          if (Object.prototype.hasOwnProperty.call(layer.routerParams, param)) {
            const nameParam = layer.routerParams[param] as string;
            result[nameParam] = params[param] as string;
          }
        }
      }
      if (Object.keys(result).length || layer.auth) {
        return () => {
          if (layer.auth) {
            result.jwt = authGlobals.token as string;
          }
          return result;
        };
      } else {
        return undefined;
      }
    },
    [params],
  );

  const wrapper = useMemo(
    () => (layer: LayerState) => ({ ...layer, getUrlParams: createGetUrlParams(layer) }),
    [createGetUrlParams],
  );

  const layers = useMemo(() => {
    return mergeLayers(mapCore.layers).map(wrapper);
  }, [mapCore.layers, wrapper]);
  return (
    <MapProvider {...mapCore.view} dispatch={dispatch}>
      {layers.map((layer: LayerState) => {
        return <Layer key={layer.id} {...layer} dispatch={dispatch} />;
      })}
      {children}
    </MapProvider>
  );
};
