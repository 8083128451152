import classNames from 'classnames';

type PageTitleProps = {
  isSmall?: boolean;
};

export const PageTitle: React.FC<PageTitleProps> = ({ isSmall, children }) => {
  const Element = isSmall ? 'h3' : 'h2';
  return (
    <Element
      className={classNames({
        'sg-u-type-h1': !isSmall,
        'sg-u-type-h3': isSmall,
      })}
    >
      {children}
    </Element>
  );
};
