import type { GraphQLField, GraphQLSchema, OperationDefinitionNode } from 'graphql';
import { Kind, OperationTypeNode, getNamedType } from 'graphql';

import { createSelectionSetNode } from './nodeFactories/createSelectionSetNode';
import { createVariableDefinitionNodes } from './nodeFactories/createVariableDefinitionNode';
import { createArgumentNode } from './nodeFactories/createArgumentNode';
import { createNameNode } from './nodeFactories/createNameNode';

export const createMutationFromSchema = (
  mutationName: string,
  schema: GraphQLSchema,
  mutationType: GraphQLField<any, any>,
  variables: string[],
): OperationDefinitionNode => {
  const graphqlType = getNamedType(mutationType.type);
  const selectionSet = createSelectionSetNode(graphqlType);

  const argumentNodes = mutationType.args.map(createArgumentNode);
  const variableDefinitions = argumentNodes.reduce(createVariableDefinitionNodes, []);

  const variableNames = variableDefinitions.map(
    (variableDefinition) => variableDefinition.variable.name.value,
  );
  const bothVariables = variables.length && variableNames.length;
  const everyVariables = variables.every((variable) => variableNames.includes(variable));
  const everyInPath = variableNames.every((variable) => variables.includes(variable));
  const operation = everyVariables ? (everyInPath ? 'delete' : 'update') : 'create';

  const operationName = createNameNode(bothVariables ? operation : mutationName);
  const name = createNameNode(mutationName);

  return {
    kind: Kind.OPERATION_DEFINITION,
    operation: OperationTypeNode.MUTATION,
    name: operationName,
    variableDefinitions,
    selectionSet: {
      kind: Kind.SELECTION_SET,
      selections: [
        {
          kind: Kind.FIELD,
          arguments: argumentNodes,
          name,
          selectionSet,
        },
      ],
    },
  };
};
