import { FormattedMessage } from 'react-intl.macro';

import { useContext, useMemo } from 'react';
import type { DocumentNode, FieldNode, GraphQLField, GraphQLSchema } from 'graphql';
import { getNamedType, OperationDefinitionNode } from 'graphql';
import { gql } from '@apollo/client';

import type { AppState } from '@tmapy/redux';
import { useSelector } from '@tmapy/redux';
import { useLink, useLocation } from '@tmapy/router';
import { CtaBtn, CtaLink } from '@tmapy/style-guide';

import type { DecorationContext } from '../../visitors/decorateDocument';
import { useGraphQLDocument } from '../../useGraphQLDocument';
import { msg } from '../../messages';
import { getDirectives } from '../../utils/getDirectives';
import { modifyVariables } from '../../useQueryOrMutationData';
import { DataLayoutDialog, DataLayoutDialogContext } from '../../components/DataLayoutDialog';
import { DataLayoutSpacing } from '../../components/DataLayoutSpacing';

import type { DataConnectionProps } from '../tableComponents/createTableComponent';
import { createTableComponent } from '../tableComponents/createTableComponent';
import { getVariablesFromDocument } from '../tableComponents/createTableFilterComponent';

export function createMassSelectComponent(
  graphqlField: GraphQLField<any, any, any>,
  field: FieldNode,
  document: DocumentNode,
  schema: GraphQLSchema,
): React.FC<DataConnectionProps> {
  const directives = getDirectives(field?.directives);
  const selectDirective = directives.select;
  if (!selectDirective) {
    throw new Error('createSelectComponent: the select directive is missing.');
  }

  const selectQuery = document.definitions.find(
    (definition) =>
      definition.kind === 'OperationDefinition' && definition.name?.value === selectDirective.query,
  ) as OperationDefinitionNode;

  if (!selectQuery && !selectDirective.route) {
    throw new Error(`createSelectComponent: ${selectDirective.query} is not in document.`);
  }

  const TableComponent = createTableComponent(graphqlField, field, document, schema);
  const tableName = getNamedType(graphqlField.type).name;
  const massSelectDecorationContext: DecorationContext = {
    isMassSelect: true,
    isPage: false,
    operationName: selectDirective.query,
  };

  return ({ data, errors, path, variables, loading }) => {
    const dataLayoutDialogContext = useContext(DataLayoutDialogContext);
    const dialogId = `${dataLayoutDialogContext.dialogId}-${tableName}`;

    const routeConfig = useSelector((state: AppState) =>
      state.app.routes.find((route) => route.id === selectDirective.route),
    );
    const massSelectDocument =
      selectDirective.route && routeConfig?.query ? gql(routeConfig.query) : document;

    const modifiedVariables = useMemo(() => {
      const filterVariables = getVariablesFromDocument(massSelectDocument);
      return modifyVariables(massSelectDocument, variables ?? {}, filterVariables);
    }, [variables, massSelectDocument]);

    const { QueryComponent, data: dialogData } = useGraphQLDocument(
      massSelectDocument,
      modifiedVariables ?? {},
      massSelectDecorationContext,
    );

    const operationIndex =
      massSelectDocument.definitions.findIndex(
        (def) => (def as OperationDefinitionNode)?.name?.value === selectDirective.query,
      ) ?? 0;
    const name =
      (massSelectDocument.definitions[operationIndex] as OperationDefinitionNode)?.name?.value ??
      '';

    const subPath = [...path, name];
    const tools: React.ReactNode[] = [];

    const onClick = (e: React.MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();

      (window.document.getElementById(dialogId) as HTMLDialogElement).show();
    };
    const location = useLocation();
    const ctaLink = useLink(routeConfig?.id, location.params);
    if (routeConfig) {
      tools.push(
        <CtaLink {...ctaLink} onClick={onClick}>
          <FormattedMessage {...msg.formAdd} />
        </CtaLink>,
      );
    } else {
      tools.push(
        <CtaBtn onClick={onClick}>
          <FormattedMessage {...msg.formAdd} />
        </CtaBtn>,
      );
    }
    return (
      <>
        <TableComponent
          data={data}
          errors={errors}
          path={subPath}
          variables={variables}
          loading={loading}
          contextData={data}
          tools={tools}
        />
        <DataLayoutDialog dialogId={dialogId}>
          <DataLayoutSpacing>
            <QueryComponent
              data={dialogData}
              errors={errors}
              path={subPath}
              variables={variables}
              loading={loading}
            />
          </DataLayoutSpacing>
        </DataLayoutDialog>
      </>
    );
  };
}
