import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { getNamedType, GraphQLError } from 'graphql';
import type {
  DocumentNode,
  FieldNode,
  GraphQLField,
  GraphQLFieldMap,
  GraphQLObjectType,
  GraphQLSchema,
  SelectionNode,
} from 'graphql';

import { deepLooselyEquals } from '@tmapy/utils';
import { useMessage } from '@tmapy/intl';
import { useLink, useLocation } from '@tmapy/router';
import { SecondaryLink, Table } from '@tmapy/style-guide';

import type { GraphQLPath } from '../../types';
import type { DirectiveMap } from '../../utils/getDirectives';
import {
  getDirectives,
  auditFieldsFilter,
  AUDIT_CREATED_AT,
  AUDIT_CREATED_BY,
} from '../../utils/getDirectives';
import { getDirectivesFromDescription } from '../../utils/getDirectivesFromDescription';
import { getOperationNameFromDocument } from '../../utils/getOperationNameFromDocument';
import { getFieldAliasOrName } from '../../utils/getFieldAliasOrName';
import { nameComponent } from '../../utils/nameComponent';
import { filterErrors } from '../../utils/filterErrors';
import { msg } from '../../messages';
import { Diff } from '../../components/Diff';
import { DataLayoutSpacing } from '../../components/DataLayoutSpacing';
import { TableView } from '../../components/TableView';
import { DateTime } from '../../components/DateTime';

import { createInlineViewComponent } from '../inputComponents/createInlineViewComponent';

const createDiffRow = (
  queryColumns: readonly SelectionNode[],
  columnTypes: GraphQLFieldMap<any, any>,
  directives: DirectiveMap,
  document: DocumentNode,
  schema: GraphQLSchema,
  intlPrefix: string,
) => {
  const filteredQueryColumns = (queryColumns as readonly FieldNode[]).filter(auditFieldsFilter);
  const ColumnComponents = filteredQueryColumns.map((column) => {
    const description = columnTypes[column.name.value].description;
    const directivesFromSchema = getDirectivesFromDescription(description);

    return createInlineViewComponent(
      columnTypes[column.name.value],
      directivesFromSchema,
      column,
      document,
      schema,
      intlPrefix,
    );
  });

  const AttributesComponents = filteredQueryColumns.map((column) => {
    return () => {
      const formatMessage = useMessage();
      const columnId = getFieldAliasOrName(column);
      return <>{formatMessage.fallback([`${intlPrefix}.${columnId}`, columnId]) ?? columnId}</>;
    };
  });

  const detailRoute = directives?.detail?.route;

  return nameComponent(`DiffRow`, ({ current, older, variables, errors, path }: any) => {
    const navigateToRoute = useLink(detailRoute, current, undefined, 'push', true);
    const handleRowClick = detailRoute
      ? (e: React.MouseEvent) => {
          if (!(e.target as Element).closest('a')) {
            navigateToRoute.onClick(e);
          }
        }
      : undefined;

    const historyRows: React.ReactNode[] = [];
    let idx = 1;
    (filteredQueryColumns as readonly FieldNode[]).forEach((column, colIndex) => {
      const columnId = getFieldAliasOrName(column);
      const currentCol = current[columnId];
      const olderCol = older[columnId];
      const Component = ColumnComponents[colIndex];
      const AttributeComponent = AttributesComponents[colIndex];

      // lax comparison is better here
      // TODO: use structural comparison instead, like jest equal
      // eslint-disable-next-line eqeqeq
      if (!deepLooselyEquals(currentCol, olderCol)) {
        historyRows.push(
          <Diff
            key={idx++}
            attrName={<AttributeComponent />}
            newValue={
              currentCol && (
                <Component
                  data={currentCol}
                  path={path}
                  errors={errors}
                  variables={variables}
                  loading={false}
                />
              )
            }
            oldValue={
              olderCol && (
                <Component
                  data={olderCol}
                  path={path}
                  errors={[]}
                  variables={variables}
                  loading={false}
                />
              )
            }
          />,
        );
      }
    });

    const auditTimestamp = current[AUDIT_CREATED_AT];
    const auditUser = current[AUDIT_CREATED_BY];

    return (
      <tr onClick={handleRowClick}>
        <td>{auditTimestamp ? <DateTime timestamp={auditTimestamp} showSeconds /> : null}</td>
        <td>{auditUser ? `${auditUser.lastName} ${auditUser.firstName}` : ''}</td>
        <td className='sg-u-vs-1'>{historyRows}</td>
      </tr>
    );
  });
};

type EdgeType<T> = {
  cursor?: string;
  node: T;
};

type ConnectionType<T> = {
  edges: EdgeType<T>[];
  totalCount?: number;
  pageInfo?: {
    hasNextPage?: boolean;
    hasPreviousPage?: boolean;
  };
};

type DataConnectionProps<T = any> = {
  data: ConnectionType<T>;
  errors: readonly GraphQLError[];
  path: GraphQLPath;
  variables: Record<string, string | undefined>;
  loading: boolean;
};

function getFieldFromSelectionSet(field: FieldNode, name: string): FieldNode {
  return field.selectionSet?.selections.find(
    (selection) => (selection as FieldNode).name.value === name,
  ) as FieldNode;
}

export function createHistoryTableComponent(
  graphqlField: GraphQLField<any, any, any>,
  field: FieldNode,
  document: DocumentNode,
  schema: GraphQLSchema,
): any {
  const graphqlType = getNamedType(graphqlField.type) as GraphQLObjectType;
  const edgeType = getNamedType(graphqlType.getFields().edges.type) as GraphQLObjectType;
  const nodeType = getNamedType(edgeType.getFields().node.type) as GraphQLObjectType;
  const columnTypes = nodeType.getFields();

  const edgesField = getFieldFromSelectionSet(field, 'edges');
  const nodeField = getFieldFromSelectionSet(edgesField, 'node');
  const queryColumns = nodeField.selectionSet!.selections!;

  const tableDirectives = getDirectives(field.directives);
  const rowDirectives = getDirectives(nodeField.directives);

  const DiffRow = createDiffRow(
    queryColumns,
    columnTypes,
    rowDirectives,
    document,
    schema,
    nodeType.name,
  );

  let currentDetailRoute = '';
  if (tableDirectives.historyTable) {
    currentDetailRoute = tableDirectives.historyTable.currentDetailRoute;
  }

  const operationName = getOperationNameFromDocument(document);

  return nameComponent(
    `HistoryTable.${nodeType.name}`,
    ({ data, errors, path, variables, loading }: DataConnectionProps) => {
      const location = useLocation();
      const navigateToCurrentRecord = useLink(
        currentDetailRoute,
        location.params,
        undefined,
        'push',
        true,
      );
      const formatMessage = useMessage();
      const tableName =
        formatMessage.fallback([
          `${graphqlType.name}.table.title`,
          `${graphqlType.name}.${operationName}.table.title`,
          graphqlType.name,
        ]) ?? graphqlType.name;

      const edges = data?.edges;
      const pageSize = data?.edges?.length;
      const historyRows: React.ReactNode[] = useMemo(() => {
        if (!edges) return [];
        const rows: React.ReactNode[] = [];

        const rowCount = pageSize - 1;
        for (let rowId = 0; rowId < rowCount; rowId++) {
          const subPath = [...path, 'edges', rowId];
          rows.push(
            <DiffRow
              key={rowId}
              variables={variables}
              current={edges[rowId].node}
              older={edges[rowId + 1].node}
              errors={filterErrors(errors, subPath)}
              path={subPath}
            />,
          );
        }
        if (rowCount >= 0) {
          const subPath = [...path, 'edges', rowCount];
          rows.push(
            <DiffRow
              key='syntheticOldestRecord'
              variables={variables}
              current={edges[rowCount].node}
              older={{}}
              errors={filterErrors(errors, subPath)}
              path={subPath}
            />,
          );
        }

        return rows;
      }, [edges, variables, errors, path]);

      return (
        <DataLayoutSpacing>
          <TableView
            tableName={tableName}
            totalCount={data?.totalCount}
            isLoading={loading}
            variables={variables}
            tools={
              <SecondaryLink {...navigateToCurrentRecord}>
                <FormattedMessage {...msg.historyTableCurrentRecord} />
              </SecondaryLink>
            }
            pageSize={pageSize}
          >
            <Table isClickable={!!rowDirectives?.detail}>
              <thead>
                <tr>
                  <th>
                    <FormattedMessage {...msg.historyWhen} />
                  </th>
                  <th>
                    <FormattedMessage {...msg.historyWho} />
                  </th>
                  <th>
                    <Diff
                      inTableHead
                      attrName={<FormattedMessage {...msg.historyAttribute} />}
                      newValue={<FormattedMessage {...msg.historyNewValue} />}
                      oldValue={<FormattedMessage {...msg.historyOldValue} />}
                    />
                  </th>
                </tr>
              </thead>
              <tbody>{historyRows}</tbody>
            </Table>
          </TableView>
        </DataLayoutSpacing>
      );
    },
  );
}
