import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useMessage } from '@tmapy/intl';
import { useSelector } from '@tmapy/redux';
import { useLink, useLocation } from '@tmapy/router';
import { CtaLink } from '@tmapy/style-guide';

import { ErrorPage } from './ErrorPage';
import { msg } from './messages';

export const NotFoundPage: React.FC = () => {
  const formatMessage = useMessage();

  const defaultRouteId = useSelector((state) => state.app.defaultRouteId);
  const location = useLocation();
  const ctaLink = useLink(defaultRouteId, location.params, undefined, 'push', true);

  return (
    <ErrorPage title={formatMessage(msg.title)} subtitle={formatMessage(msg.notFoundPageSubtitle)}>
      {defaultRouteId && (
        <CtaLink {...ctaLink}>
          <FormattedMessage {...msg.homePageLink} />
        </CtaLink>
      )}
    </ErrorPage>
  );
};
