import type { DirectiveNode, SelectionNode, VariableDefinitionNode } from 'graphql';
import { valueFromASTUntyped } from 'graphql/utilities';

import type { Variables } from '../types';

export type _GeometryType =
  | 'POINT'
  | 'LINESTRING'
  | 'POLYGON'
  | 'MULTIPOINT'
  | 'MULTILINESTRING'
  | 'MULTIPOLYGON'
  | 'POINT_Z'
  | 'LINESTRING_Z'
  | 'POLYGON_Z'
  | 'MULTIPOINT_Z'
  | 'MULTILINESTRING_Z'
  | 'MULTIPOLYGON_Z'
  | 'POINT_M'
  | 'LINESTRING_M'
  | 'POLYGON_M'
  | 'MULTIPOINT_M'
  | 'MULTILINESTRING_M'
  | 'MULTIPOLYGON_M'
  | 'POINT_ZM'
  | 'LINESTRING_ZM'
  | 'POLYGON_ZM'
  | 'MULTIPOINT_ZM'
  | 'MULTILINESTRING_ZM'
  | 'MULTIPOLYGON_ZM';

export type _ActionType = 'DELETE' | 'COPY';

export type ActionDirective = {
  mutation: string;
  type?: _ActionType;
};

export type ComponentContext = 'PAGE' | 'MASS_SELECT' | 'DETAIL' | undefined;

export type DirectiveMap = {
  type?: {
    prefix: string;
  };
  decorate?: {
    component: string;
    props?: {
      context: ComponentContext;
    };
  };
  hidden?: {
    replaceHashParams?: any;
  };
  url?: { replaceHashParams?: any };
  text?: { multiline: boolean };
  export?: { as?: string };
  create?: {
    route: string;
    variables?: any;
  };
  detail?: {
    route: string;
  };
  loadFeature?: {
    query: string;
    pointZoomLevel?: number;
    replaceHashParams?: any;
  };
  history?: {
    route: string;
  };
  historyTable?: {
    currentDetailRoute: string;
  };
  redirect?: {
    route: string;
    history: 'PUSH' | 'REPLACE';
  };
  select?: {
    query?: string;
    route?: string;
  };
  mass?: {
    query?: string;
    id?: string;
  };
  value?: NonNullable<unknown>;
  ewkt?: {
    layer?: string;
    projection: string;
    geometryType?: _GeometryType;
    pointZoomLevel?: number;
  };
  validate?: {
    min?: any;
    max?: any;
  };
  order?: {
    name?: string;
  };
  group?: {
    id: string;
  };
  layer?: {
    id: string;
  };
  action?: ActionDirective[];
  action2?: {
    icon: string;
    type?: 'DANGER';
  };
  list?: {
    length: number;
  };
  selectFeature?: {
    layer: string;
    field: string;
  };
};

export const AUDIT_CREATED_AT = 'auditCreatedAt';
export const AUDIT_CREATED_BY = 'auditCreatedBy';

export const repeatableDirectives = ['action'];

export function getDirectives(
  directiveNodes?: readonly DirectiveNode[],
  variables?: Variables,
): DirectiveMap {
  const directives: Record<string, any> = {};
  if (directiveNodes) {
    for (const directive of directiveNodes) {
      const directiveName = directive.name.value;

      const directiveArguments = directive.arguments?.reduce((acc, argument) => {
        const value = valueFromASTUntyped(argument.value, variables);
        acc[argument.name.value] = value === 'NOW' ? new Date().toISOString() : value;
        return acc;
      }, {} as any);

      if (repeatableDirectives.includes(directiveName)) {
        if (directives[directiveName]) {
          directives[directiveName].push(directiveArguments);
        } else {
          directives[directiveName] = [directiveArguments];
        }
      } else {
        directives[directiveName] = directiveArguments;
      }
    }
  }
  return directives;
}

export const hiddenFieldsFilter = (field: SelectionNode | VariableDefinitionNode): boolean =>
  !getDirectives(field.directives).hidden;

export const auditFieldsFilter = (field: SelectionNode): boolean =>
  ![AUDIT_CREATED_AT, AUDIT_CREATED_BY].includes((field as any).name?.value) &&
  !getDirectives(field.directives).hidden;
