import type { OperationDefinitionNode, GraphQLSchema } from 'graphql';
import { parse, Kind } from 'graphql';

import type { HasPermission } from '@tmapy/config';

import { prepareClientDocument } from '../visitors/prepareClientDocument';

export const hasOperation = (
  query: string,
  schema: GraphQLSchema,
  hasPermission: HasPermission,
) => {
  const documentNode = parse(query);

  const clientDocument = prepareClientDocument(documentNode, schema, hasPermission);

  if (!clientDocument.definitions.length) return false;

  const firstOperationDefinition = clientDocument.definitions.find(
    (definition) => definition.kind === Kind.OPERATION_DEFINITION,
  ) as OperationDefinitionNode | undefined;

  if (!firstOperationDefinition || !firstOperationDefinition.selectionSet.selections.length) {
    return false;
  }

  return true;
};
