import React from 'react';

import './MapTool.scss';

type MapToolProps = {
  children: React.ReactNode;
};

export const MapTool: React.FC<MapToolProps> = ({ children }) => {
  return <div className='tw-mapTool'>{children}</div>;
};
