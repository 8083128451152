import type { SelectionNode } from 'graphql';

import { getFieldAliasOrName } from '../../utils/getFieldAliasOrName';
import { hiddenFieldsFilter } from '../../utils/getDirectives';

const createFieldsFilter = (selectHiddenStrings: boolean) => (field: SelectionNode) =>
  selectHiddenStrings !== hiddenFieldsFilter(field);

export const createStringFactory = (
  fieldNode: any,
  selectHiddenStrings = false,
): ((data: any) => string) => {
  if (fieldNode.selectionSet) {
    const fieldsFilter = createFieldsFilter(selectHiddenStrings);
    const selection = fieldNode.selectionSet.selections.filter(fieldsFilter);
    const stringifiers = selection?.map(createStringFactory);
    return (data) =>
      selection
        ?.map((field: any, idx: number) => stringifiers[idx](data?.[getFieldAliasOrName(field)]))
        .join(' ');
  }
  return (data: any) => data;
};
