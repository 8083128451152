import { ThunkAction } from '@tmapy/redux';
import { MAP_TOOL, PANEL } from '@tmapy/config';
import { actionDeactivateLayerSwitcher } from '@tmapy/layer-switcher';
import { actionDeactivateLegend } from '@tmapy/legend';
import { actionDeactivateMeasure } from '@tmapy/measure';
import { actionDeactivateNotes } from '@tmapy/notes';
import { actionDeactivateSearch } from '@tmapy/search';
import { actionDeactivateTools } from '@tmapy/edit-tools';

export const actionDeactivatePanel = (): ThunkAction => (dispatch, getState) => {
  const state = getState();
  const visiblePanel = state.uiManager.visiblePanel;

  switch (visiblePanel) {
    case PANEL.MEASURE: {
      dispatch(actionDeactivateMeasure());
      break;
    }
    case PANEL.SEARCH: {
      dispatch(actionDeactivateSearch());
      break;
    }
    case PANEL.LEGEND: {
      dispatch(actionDeactivateLegend());
      break;
    }
    case PANEL.LAYER_SWITCHER: {
      dispatch(actionDeactivateLayerSwitcher());
      break;
    }
    case PANEL.NOTES: {
      dispatch(actionDeactivateNotes());
      break;
    }
  }
};

export const deactiveMapTool = (): ThunkAction => (dispatch, getState) => {
  const state = getState();
  const activeMapTool = state.uiManager.activeMapTool;
  switch (activeMapTool) {
    case MAP_TOOL.NOTE_SELECT:
    case MAP_TOOL.NOTE_DRAW_LINE:
    case MAP_TOOL.NOTE_DRAW_POINT:
    case MAP_TOOL.NOTE_DRAW_POLYGON:
    case MAP_TOOL.NOTE_DRAW_TEXT:
      dispatch(actionDeactivateNotes());
      break;
    case MAP_TOOL.INTERSECTION_SPLIT_LINE:
    case MAP_TOOL.DELETE:
    case MAP_TOOL.JOIN_LINE:
    case MAP_TOOL.SPLIT_LINE:
    case MAP_TOOL.REVERSE_LINE:
    case MAP_TOOL.DRAW:
    case MAP_TOOL.INFOEDIT:
    case MAP_TOOL.SELECT_FEATURE:
      dispatch(actionDeactivateTools());
      break;
    case MAP_TOOL.MEASURE_LINE:
    case MAP_TOOL.MEASURE_POLYGON:
      dispatch(actionDeactivateMeasure);
      break;
    case MAP_TOOL.NONE:
      break;
    default:
      console.error('Deactivate MapTool without deactivate Action');
  }
};
