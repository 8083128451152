import type { DocumentNode, FragmentDefinitionNode, InlineFragmentNode } from 'graphql';
import { visit, Kind } from 'graphql';

/**
 * Given a document AST, convert named fragments to inline fragments.
 */
export const fragmentsToInlineFragments = (documentAST: DocumentNode): DocumentNode => {
  const fragmentDefinitions: {
    [key: string]: FragmentDefinitionNode | undefined;
  } = Object.create(null);

  for (const definition of documentAST.definitions) {
    if (definition.kind === Kind.FRAGMENT_DEFINITION) {
      fragmentDefinitions[definition.name.value] = definition;
    }
  }

  return visit(documentAST, {
    SelectionSet(selectionSet) {
      const selections = selectionSet.selections.map((selection) => {
        if (selection.kind === Kind.FRAGMENT_SPREAD) {
          const fragmentName = selection.name.value;
          const fragmentDefinition = fragmentDefinitions[fragmentName];
          if (!fragmentDefinition) {
            console.warn(`[fragmentsToInlineFragments] Cannot find fragment ${fragmentName}`);
            return selection;
          }

          const inlineFragment: InlineFragmentNode = {
            kind: Kind.INLINE_FRAGMENT,
            selectionSet: fragmentDefinition.selectionSet,
            directives: [...(fragmentDefinition.directives ?? []), ...(selection.directives ?? [])],
            typeCondition: fragmentDefinition.typeCondition,
            loc: fragmentDefinition.loc,
          };

          return inlineFragment;
        }

        return selection;
      });

      return {
        ...selectionSet,
        selections,
      };
    },

    FragmentDefinition() {
      return null;
    },
  });
};
