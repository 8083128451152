import { ASTNode, visit } from 'graphql';

export const getVariableNames = (node: ASTNode): Set<string> => {
  const names = new Set<string>();
  visit(node, {
    Variable(variable) {
      names.add(variable.name.value);
    },
    VariableDefinition(variableDefinition) {
      names.add(variableDefinition.variable.name.value);
    },
  });
  return names;
};
