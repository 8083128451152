import type { DefinitionNode, DocumentNode, OperationDefinitionNode } from 'graphql';

export const isMutationDefinition = (
  definitionNode: DefinitionNode,
): definitionNode is OperationDefinitionNode =>
  definitionNode.kind === 'OperationDefinition' && definitionNode.operation === 'mutation';

export const isQueryDefinition = (
  definitionNode: DefinitionNode,
): definitionNode is OperationDefinitionNode =>
  definitionNode.kind === 'OperationDefinition' && definitionNode.operation === 'query';

export const findMutation = (document: DocumentNode, mutationName: string) =>
  document.definitions.find(
    (definitionNode) =>
      isMutationDefinition(definitionNode) &&
      definitionNode.name?.value === mutationName &&
      definitionNode.selectionSet.selections.length,
  ) as OperationDefinitionNode | undefined;

export const findQuery = (document: DocumentNode, queryName: string) =>
  document.definitions.find(
    (definitionNode) =>
      isQueryDefinition(definitionNode) &&
      definitionNode.name?.value === queryName &&
      definitionNode.selectionSet.selections.length,
  ) as OperationDefinitionNode | undefined;
