import type {
  DirectiveNode,
  FieldNode,
  GraphQLField,
  GraphQLType,
  SelectionSetNode,
} from 'graphql';
import { Kind, getNamedType, isObjectType, isScalarType } from 'graphql';

import { isConnectionType } from '../../utils/getConnectionInfo';

import { createNameNode } from './createNameNode';
import { createDirectiveNode } from './createDirectiveNode';

export const createTableSelectionSetNode = (
  selectionSet: SelectionSetNode,
  directives: DirectiveNode[] = [],
): SelectionSetNode => ({
  kind: Kind.SELECTION_SET,
  selections: [
    {
      kind: Kind.FIELD,
      name: createNameNode('edges'),
      selectionSet: {
        kind: Kind.SELECTION_SET,
        selections: [
          {
            kind: Kind.FIELD,
            name: createNameNode('node'),
            selectionSet,
            directives,
          },
        ],
      },
    },
  ],
});

const createTotalCountSelectionSetNode = (): SelectionSetNode => ({
  kind: Kind.SELECTION_SET,
  selections: [
    {
      kind: Kind.FIELD,
      name: createNameNode('totalCount'),
    },
    {
      kind: Kind.FIELD,
      name: createNameNode('pageInfo'),
      selectionSet: {
        kind: Kind.SELECTION_SET,
        selections: [
          {
            kind: Kind.FIELD,
            name: createNameNode('hasNextPage'),
            directives: [createDirectiveNode('hidden')],
          },
          {
            kind: Kind.FIELD,
            name: createNameNode('hasPreviousPage'),
            directives: [createDirectiveNode('hidden')],
          },
          {
            kind: Kind.FIELD,
            name: createNameNode('startCursor'),
            directives: [createDirectiveNode('hidden')],
          },
          {
            kind: Kind.FIELD,
            name: createNameNode('endCursor'),
            directives: [createDirectiveNode('hidden')],
          },
        ],
      },
    },
  ],
});

export const createSelectionSetNode = (
  graphqlType: GraphQLType,
  crateFieldDirectives: (fieldType: GraphQLField<any, any>) => DirectiveNode[] = () => [],
): SelectionSetNode => {
  const selections: FieldNode[] = [];
  try {
    if (isObjectType(graphqlType)) {
      const fieldTypes = graphqlType.getFields();
      for (const graphqlTypeFieldName in fieldTypes) {
        const fieldType = fieldTypes[graphqlTypeFieldName];
        const namedType = getNamedType(fieldType.type);
        if (isScalarType(namedType)) {
          selections.push({
            kind: Kind.FIELD,
            name: createNameNode(fieldType.name),
            directives: crateFieldDirectives(fieldType),
          });
        } else if (isConnectionType(namedType)) {
          selections.push({
            kind: Kind.FIELD,
            name: createNameNode(fieldType.name),
            selectionSet: createTotalCountSelectionSetNode(),
          });
        } else if (isObjectType(namedType)) {
          const selectionSet = createSelectionSetNode(namedType, crateFieldDirectives);
          selections.push({
            kind: Kind.FIELD,
            name: createNameNode(fieldType.name),
            selectionSet,
          });
        }
      }
    }
  } catch (e) {
    console.error('Creation error SelectionSetNode!', e);
  }
  return {
    kind: Kind.SELECTION_SET,
    selections,
  };
};
