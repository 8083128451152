import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useApolloClient } from '@apollo/client';
import gql from 'graphql-tag';

import { useSelector } from '@tmapy/redux';
import { BtnBar, DangerAlert, SecondaryBtn } from '@tmapy/style-guide';
import { QUERY_STATE } from '@tmapy/utils';
import type { MessageDescriptor } from 'react-intl';

import { msg } from './messages';

const mutation = gql`
  mutation cmutation($appIdent: String!) {
    createCoreAppSync(input: { appIdent: $appIdent }) {
      coreAppSync {
        __typename
      }
    }
  }
`;

type RunSyncBtnProps = {
  labelMsgDescriptor: MessageDescriptor;
  onSyncStart(): void;
};

export const RunSyncBtn = ({ labelMsgDescriptor, onSyncStart }: RunSyncBtnProps) => {
  const appIdent = useSelector((state) => state.app.appId);
  const [mutationState, setMutationState] = useState(QUERY_STATE.EMPTY);

  const client = useApolloClient();

  const handleClick = async () => {
    setMutationState(QUERY_STATE.WAITING);

    try {
      const result = await client.mutate({
        mutation,
        variables: {
          appIdent,
        },
      });

      if (result.errors && result.errors.length) {
        throw new Error();
      }
      setMutationState(QUERY_STATE.SUCCESS);
      onSyncStart();
    } catch (err) {
      setMutationState(QUERY_STATE.FAIL);
    }
  };

  return (
    <>
      {mutationState === QUERY_STATE.FAIL && (
        <DangerAlert>
          <FormattedMessage {...msg.runQueryStateFail} />
        </DangerAlert>
      )}
      <BtnBar stretch>
        <SecondaryBtn
          queryState={mutationState === QUERY_STATE.WAITING ? 'waiting' : undefined}
          onClick={handleClick}
        >
          <FormattedMessage {...labelMsgDescriptor} />
        </SecondaryBtn>
      </BtnBar>
    </>
  );
};
