import { defineMessages, MessageDescriptor } from 'react-intl.macro';
import { FormattedMessage } from 'react-intl';
import type { GraphQLError } from 'graphql';
import type { ApolloError, ServerError } from '@apollo/client';

import * as Sentry from '@sentry/react';

import {
  HTTP_STATUS_ERROR,
  HTTP_STATUS_INTERNAL_SERVER_ERROR,
  HTTP_STATUS_UNAUTHORIZED,
  isHttpStatusError,
} from '@tmapy/config';
import { useMessage } from '@tmapy/intl';
import { DangerAlert } from '@tmapy/style-guide';

import { GRAPHQL_ERROR_CODE } from '../constants';

const msg = defineMessages({
  internalServerError: {
    id: 'sys.error.internalServerError',
    defaultMessage: 'Neočekávaná chyba serveru.',
  },
  authError: {
    id: 'sys.error.authError',
    defaultMessage: 'Chyba při ověření přihlášení do systému.',
  },
  unauthorized: {
    id: 'sys.error.unauthorized',
    defaultMessage: 'Přístup nepřihlášeného uživatele, přihlaste se do systému.',
  },
  variableWasNotProvided: {
    id: 'sys.input.error.missingVariable',
    defaultMessage: 'Položka nesmí být prázdná.',
  },
  invalidValue: {
    id: 'sys.input.error.invalidValue',
    defaultMessage: 'Vyplňte platnou hodnotu.',
  },
  invalidInteger: {
    id: 'sys.input.error.invalidInteger',
    defaultMessage: 'Vyplňte celé číslo v rozsahu od -2147483648 do 2147483647.',
  },
  invalidEmail: {
    id: 'sys.input.error.invalidEmail',
    defaultMessage: 'Vyplňte email ve správném formátu.',
  },
  ewktInputEmpty: {
    id: 'sys.input.error.emptyEwkt',
    defaultMessage: 'Zakreslete prvek do mapy.',
  },
  ewktInputInvalidType: {
    id: 'sys.input.error.ewktInputInvalidType',
    defaultMessage: 'Zadejte správný typ geometrie.',
  },
  ewktInputPatternMismatch: {
    id: 'sys.input.error.ewktInputPatternMismatch',
    defaultMessage: 'Zadejte validní geometrii.',
  },
  invalidGeometry: {
    id: 'sys.input.error.invalidGeometry',
    defaultMessage: 'Zakreslete do mapy platnou geometrii.',
  },
  unknownError: {
    id: 'sys.input.error.unknown',
    defaultMessage: 'Neznámá chyba. Kontaktujte správce aplikace.',
  },
});

const code_with_regular: Partial<Record<GRAPHQL_ERROR_CODE, RegExp>> = {
  INVALID_EWKT: /^EWKT/,
  INVALID_EMAIL: /^Email is not in valid format/,
  NOT_NULL: /^Variable "\$([^"]+)".*be null/,
  MUST_BE_DEFINED: /^Variable "\$([^"]+)".*was not provided/,
  INVALID_INTEGER:
    /^Variable "\$([^"]+)" got invalid value .* Int cannot represent non 32-bit signed integer value/,
  INVALID_VALUE: /^Variable "\$([^"]+)" got invalid value/,
  NULL_GIVEN: /^The type of the "([^"]+)".*"NULL" given/,
} as const;

const message_from_code: Record<GRAPHQL_ERROR_CODE, MessageDescriptor> = {
  [GRAPHQL_ERROR_CODE.INTERNAL_SERVER_ERROR]: msg.internalServerError,
  [GRAPHQL_ERROR_CODE.AUTH_ERROR]: msg.authError,
  [GRAPHQL_ERROR_CODE.EWKT_INPUT_EMPTY]: msg.ewktInputEmpty,
  [GRAPHQL_ERROR_CODE.EWKT_INPUT_INVALID_TYPE]: msg.ewktInputInvalidType,
  [GRAPHQL_ERROR_CODE.EWKT_INPUT_PATTERN_MISMATCH]: msg.ewktInputPatternMismatch,
  [GRAPHQL_ERROR_CODE.INVALID_EWKT]: msg.invalidGeometry,
  [GRAPHQL_ERROR_CODE.INVALID_EMAIL]: msg.invalidEmail,
  [GRAPHQL_ERROR_CODE.INVALID_INTEGER]: msg.invalidInteger,
  [GRAPHQL_ERROR_CODE.INVALID_VALUE]: msg.invalidValue,
  [GRAPHQL_ERROR_CODE.NOT_NULL]: msg.variableWasNotProvided,
  [GRAPHQL_ERROR_CODE.MUST_BE_DEFINED]: msg.variableWasNotProvided,
  [GRAPHQL_ERROR_CODE.NULL_GIVEN]: msg.variableWasNotProvided,
};

const message_from_status_code: Partial<Record<HTTP_STATUS_ERROR, MessageDescriptor>> = {
  [HTTP_STATUS_UNAUTHORIZED]: msg.unauthorized,
  [HTTP_STATUS_INTERNAL_SERVER_ERROR]: msg.internalServerError,
};

export const getVariableNameForError = (error: GraphQLError): string | null => {
  const message = error.message;
  for (const reg of Object.values(code_with_regular)) {
    const match = message.match(reg);
    if (match && match[1]) {
      return match[1];
    }
  }
  return null;
};

export const createErrorFilterForVariable =
  (variableName: string | null) => (error: GraphQLError) =>
    getVariableNameForError(error) === variableName;

/**
 * Chybova hlaska podle GraphQLError
 */
export const GraphQLQueryError: React.FC<{ error: GraphQLError }> = ({ error }) => {
  const formatMessage = useMessage();

  const code = (error.extensions?.code?.toString() ?? undefined) as GRAPHQL_ERROR_CODE | undefined;
  const errorMessage = error.message;

  if (code && message_from_code[code]) {
    return <FormattedMessage {...message_from_code[code]} />;
  }

  for (const [code, reg] of Object.entries(code_with_regular)) {
    const match = errorMessage.match(reg);
    if (match) {
      const fieldName = match[1];
      if (fieldName === 'geom') {
        return <FormattedMessage {...message_from_code[GRAPHQL_ERROR_CODE.EWKT_INPUT_EMPTY]} />;
      }

      const fieldLabel = formatMessage.fallback([fieldName]) ?? fieldName;
      return (
        <FormattedMessage
          {...message_from_code[code as GRAPHQL_ERROR_CODE]}
          values={{ fieldLabel }}
        />
      );
    }
  }

  const message = formatMessage.fallback([code]);
  if (message) {
    return <>{message}</>;
  }

  Sentry.captureMessage(`<GraphQLQueryError>: not implemented: '${errorMessage}'`, 'warning');

  console.error(`Unknown error: '${errorMessage}'`);

  return <FormattedMessage {...msg.unknownError} />;
};

/**
 * Nerozlisitelne chyby na cely formular
 */
export const QueryError: React.FC<{ error: ApolloError }> = ({ error }) => {
  if (error.graphQLErrors.length) {
    const graphQLErrors = error.graphQLErrors.reduce((errors, currentError) => {
      const currentCode = currentError.extensions?.code && currentError.extensions.code.toString();
      if (
        currentCode &&
        errors.some((error) => {
          const code = error.extensions.code && error.extensions.code.toString();
          return code && code === currentCode;
        })
      ) {
        return errors;
      }
      return errors.concat([currentError]);
    }, [] as GraphQLError[]);
    return (
      <>
        {graphQLErrors.filter(createErrorFilterForVariable(null)).map((error, idx) => (
          <DangerAlert key={idx}>
            <GraphQLQueryError error={error} />
          </DangerAlert>
        ))}
      </>
    );
  }

  if (error.networkError) {
    const serverError = error.networkError as ServerError;
    const statusCode = isHttpStatusError(serverError.statusCode)
      ? serverError.statusCode
      : HTTP_STATUS_INTERNAL_SERVER_ERROR;

    if (message_from_status_code[statusCode]) {
      return (
        <DangerAlert>
          <FormattedMessage {...message_from_status_code[statusCode]} />
        </DangerAlert>
      );
    }
  }

  return <DangerAlert>{error.toString()}</DangerAlert>;
};
