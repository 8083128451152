import {
  ASTNode,
  GraphQLSchema,
  Kind,
  OperationDefinitionNode,
  TypeInfo,
  visit,
  visitWithTypeInfo,
} from 'graphql';
import { isConnectionType } from '../utils';
import { getFieldSelectionFieldNode } from '../utils/getFieldSelectionFieldNode';
import { addFieldArgumentVariable } from '../utils/addFieldArgumentVariable';
import { addFieldSelection } from '../utils/addFieldSelection';
import { getFieldArgumentNode } from '../utils/getFieldArgumentNode';
import { getFieldAliasOrName } from '../utils/getFieldAliasOrName';

export const addPagination = <T extends ASTNode>(schema: GraphQLSchema, documentAST: T): T => {
  const typeInfo = new TypeInfo(schema);
  let currentOperation: OperationDefinitionNode | null = null;
  return visit(
    documentAST,
    visitWithTypeInfo(typeInfo, {
      OperationDefinition(operation) {
        currentOperation = operation;
      },
      Field(field) {
        const fieldType = typeInfo.getType();
        const aliasOrName = getFieldAliasOrName(field);
        if (fieldType && isConnectionType(fieldType)) {
          if (!getFieldSelectionFieldNode(field, 'pageInfo')) {
            addFieldSelection(field, {
              kind: Kind.FIELD,
              name: { kind: Kind.NAME, value: 'pageInfo' },
              selectionSet: {
                kind: Kind.SELECTION_SET,
                selections: ['startCursor', 'endCursor', 'hasPreviousPage', 'hasNextPage'].map(
                  (fieldName) => ({
                    kind: Kind.FIELD,
                    name: { kind: Kind.NAME, value: fieldName },
                  }),
                ),
              },
            });
          }
          if (!getFieldSelectionFieldNode(field, 'totalCount')) {
            addFieldSelection(field, {
              kind: Kind.FIELD,
              name: { kind: Kind.NAME, value: 'totalCount' },
            });
          }
          if (!getFieldArgumentNode(field, 'first')) {
            addFieldArgumentVariable(currentOperation!, field, 'first', `${aliasOrName}_first`);
          }
          if (!getFieldArgumentNode(field, 'last')) {
            addFieldArgumentVariable(currentOperation!, field, 'last', `${aliasOrName}_last`);
          }
          if (!getFieldArgumentNode(field, 'before')) {
            addFieldArgumentVariable(currentOperation!, field, 'before', `${aliasOrName}_before`);
          }
          if (!getFieldArgumentNode(field, 'after')) {
            addFieldArgumentVariable(currentOperation!, field, 'after', `${aliasOrName}_after`);
          }
        }
        return field;
      },
    }),
  );
};
