/**
 * WARNING: THIS FILE IS GENERATED FROM TEMPLATE
 * Edit scripts/codegen-templates/locale-loader-index.js instead
 */

/* eslint-disable */

import { LocaleCode } from '../constants';

export const localeLoaders: Record<LocaleCode, () => Promise<Record<string, string>>> = {
  cs: async () => (await import(/* webpackChunkName: "locale-cs" */ './cs')).messages,
  en: async () => (await import(/* webpackChunkName: "locale-en" */ './en')).messages,
};
