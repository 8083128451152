import { Kind } from 'graphql';
import type { OperationDefinitionNode } from 'graphql';

import { DocumentNode, FetchPolicy, gql } from '@apollo/client';
import type { Store } from 'redux';

import type { AppState } from '@tmapy/redux';
import type { FeatureManager } from '@tmapy/edit-tools';
import type { GeoJSONFeature, GeoJSONGeometry, GeoJsonProperties } from '@tmapy/types';
import { EWKTToGeoJSONObject, GeoJSONToEWKT } from '@tmapy/mapcore';

import type { ExtendedApolloClient } from './createClient';
import { prepareClientDocument } from './visitors/prepareClientDocument';
import { prepareServerDocument } from './visitors/prepareServerDocument';
import { processTypeMappings } from './visitors/processTypeMappings';
import { batchOperations, OperationWithArguments } from './utils/batchOperations';
import { findMutation, findQuery } from './utils/filters';
import { processRedirectDirective } from './astFactories/useMutationAction';
import { getDirectives } from './utils/getDirectives';
import { createHasPermission } from '../../auth/hooks/usePermission';

export type FeatureToSave = {
  layerId: string;
  featureId: string | number | null | undefined;
  feature: GeoJSONFeature<GeoJSONGeometry, GeoJsonProperties> | null;
};

export class TwcFeatureManager implements FeatureManager {
  constructor(
    public store: Store<AppState>,
    public client: ExtendedApolloClient,
  ) {}

  async loadFeature(
    layerId: string,
    featureId: string | number,
    fetchPolicy: FetchPolicy = 'cache-first',
  ): Promise<{
    layerId: string;
    featureId: string | number;
    data: GeoJSONFeature<GeoJSONGeometry, GeoJsonProperties> | null;
  } | null> {
    const state = this.store.getState();

    const mapping = state.app.featureRouteMappings?.find((mapping) => mapping.layer === layerId);
    if (!mapping) {
      console.error(`Feature route mapping for layer '${layerId}' not found!`);
      return null;
    }

    const routeId = mapping.routeId;
    const route = state.app.routes.find((route) => route.id === routeId);
    if (!route) {
      console.error(`Route '${routeId}' not found in config!`);
      return null;
    }

    const queryText = route.query;
    if (!queryText) {
      console.error(`Route '${routeId}' has no query!`);
      return null;
    }

    const hasPermission = createHasPermission(state.auth.permissions);
    const rawDocument = gql(queryText);

    const clientDocument = prepareClientDocument(
      rawDocument,
      this.client.clientSchema,
      hasPermission,
    );

    const serverDocument = prepareServerDocument(clientDocument, this.client.clientSchema);

    const definition = findQuery(serverDocument, mapping.queryDetail);
    if (!definition) {
      console.error(`Route '${routeId}' has no query '${mapping.queryDetail}'!`);
      return null;
    }

    const query: DocumentNode = {
      kind: Kind.DOCUMENT,
      definitions: [definition],
    };

    let variables: Record<string, any> = {
      _id: featureId,
    };

    variables = processTypeMappings(this.client, query, variables);
    if (!variables.id) {
      variables.id = featureId;
    }

    const result = await this.client.query({
      query,
      variables,
      fetchPolicy,
    });

    const dataProperties = Object.keys(result.data);
    console.assert(dataProperties.length === 1, `dataProperties.length !== 1`, dataProperties);
    const featureKey = dataProperties[0];
    let featureData = result.data[featureKey];
    if (!featureData) {
      return {
        layerId,
        featureId,
        data: null,
      };
    }

    if (featureData['edges']?.[0]) {
      featureData = featureData['edges'][0]['node'];
    }
    const { geom, ...properties } = featureData;
    const targetProjection = state.mapCore.view.projection;
    const feature = EWKTToGeoJSONObject(geom, targetProjection);
    feature.id = featureId;
    feature.properties = { ...feature.properties, ...properties };
    return {
      layerId,
      featureId,
      data: feature,
    };
  }

  /**
   * @deprecated
   */
  saveFeature(
    layerId: string,
    featureId: string | number | null | undefined,
    feature: GeoJSONFeature | null,
  ): Promise<any> {
    return this.saveFeatures([{ layerId, featureId, feature }]);
  }

  async saveFeatures(featuresToSave: FeatureToSave[]): Promise<any> {
    const operationsWithArguments: OperationWithArguments[] = [];

    const state = this.store.getState();
    const previousVariables = state.router.params;
    const hasPermission = createHasPermission(state.auth.permissions);

    let redirect: {
      clientOperation: OperationDefinitionNode | undefined;
      serverOperation: OperationWithArguments | undefined;
      variables: Record<string, any>;
    } = {
      clientOperation: undefined,
      serverOperation: undefined,
      variables: {},
    };

    featuresToSave.forEach((featureToSave) => {
      const { featureId, layerId, feature } = featureToSave;

      const mapping = state.app.featureRouteMappings.find((mapping) => mapping.layer === layerId);
      if (!mapping) {
        throw new Error(`featureRouteMapping for layer ${layerId} not found`);
      }

      const routeId = mapping.routeId;
      const route = state.app.routes.find((route) => route.id === routeId);
      if (!route) {
        console.error(`Route '${routeId}' not found in config!`);
        return null;
      }

      const queryText = route.query;
      if (!queryText) {
        console.error(`Route '${routeId}' has no query!`);
        return null;
      }

      const wholeDocument = gql(queryText);

      const clientDocument = prepareClientDocument(
        wholeDocument,
        this.client.clientSchema,
        hasPermission,
      );

      let isCreate = false;
      let mutationName = '';
      if (featureId) {
        if (feature) {
          mutationName = 'update';
        } else {
          mutationName = 'delete';
        }
      } else {
        mutationName = 'create';
        isCreate = true;
      }

      const mutation = findMutation(clientDocument, mutationName);
      if (!mutation) {
        console.error(`Route '${routeId}' has no mutation '${mutationName}'!`);
        return null;
      }

      const operationDocument: DocumentNode = {
        kind: Kind.DOCUMENT,
        definitions: [mutation],
      };

      const serverDocument = prepareServerDocument(operationDocument, this.client.clientSchema);

      const currentOperationDefinition = serverDocument.definitions[0] as OperationDefinitionNode;
      if (!currentOperationDefinition) {
        throw new Error(`[saveFeatures] Operation for layer ${layerId} not found`);
      }

      let variables: Record<string, any> = { layerId, id: featureId };

      if (feature) {
        const projection =
          feature?.crs?.properties.name ?? feature?.properties?.__targetCRS ?? 'EPSG:5514';
        const geom = GeoJSONToEWKT(feature!, projection);

        const typePrefix = this.client.typePrefixMappings?.[feature.properties!.__typename];
        const id = typePrefix ? `${typePrefix}${featureId}` : featureId;

        variables = {
          ...(isCreate ? feature.properties : {}),
          layerId,
          id,
          _id: featureId,
          geom,
          // TODO - je nutne pro cistokrevne TWC - proverit
          // data: {
          //   ...feature.properties,
          //   __typename: undefined,
          //   _originLayerId: undefined,
          //   layerId: undefined,
          //   id: featureId,
          //   geom,
          // },
        };
      }

      const operationWithArguments = {
        operation: currentOperationDefinition,
        variables,
      };
      operationsWithArguments.push(operationWithArguments);

      if (featureId && !feature && !redirect.clientOperation) {
        redirect = {
          clientOperation: mutation,
          serverOperation: operationWithArguments,
          variables,
        };
      }
    });

    const operationWithVars = batchOperations(operationsWithArguments);

    const operationsDocument: DocumentNode = {
      kind: Kind.DOCUMENT,
      definitions: [operationWithVars.operation],
    };

    const result = await this.client.mutate({
      mutation: operationsDocument,
      variables: operationWithVars.variables,
    });

    if (result.errors && result.errors.length) {
      console.error(`[TwcFeatureManager.saveFeatures] Errors when saving:`, result);
      throw new Error(`There are errors when saving feature changes`);
    }

    operationWithVars.processResult(result);

    if (redirect.clientOperation && redirect.serverOperation) {
      const mutationOperation = redirect.clientOperation;
      const mutationDirectives = getDirectives(mutationOperation?.directives);
      const mutationDirectiveRedirect = mutationDirectives.redirect;

      processRedirectDirective(
        redirect.variables,
        previousVariables,
        mutationOperation,
        redirect.serverOperation.result,
        mutationDirectiveRedirect!,
      );
    }

    return operationsWithArguments.map((operation) => operation.result);
  }
}
