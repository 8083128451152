import { FormattedDate, FormattedMessage } from 'react-intl';

import { Link, LINK_SEVERITY } from '@tmapy/style-guide';
import { parseEWKT } from '@tmapy/mapcore';
import { useMessage } from '@tmapy/intl';

import type { DataComponent } from '../../types';
import { DateTime } from '../../components/DateTime';
import { Date } from '../../components/Date';
import { msg } from '../../messages';

import { msg as geometryMsg } from '../geometryComponents/GeometryInput';

const formatFileSize = (fileSize: unknown) => {
  let size = Math.abs(Number(fileSize));
  let rank = 0;
  const rate = 1000;
  while (size >= rate && rank < 10) {
    size /= rate;
    rank++;
  }
  const precision = rank && size < 100 ? 1 : 0;
  return size.toFixed(precision) + ' ' + ' kMGTPEZYRQ'[rank] + 'B';
};

export const InlineViewComponentMap: Record<string, DataComponent> = {
  ID: ({ data }) => {
    if (!data) return <span data-empty />;

    return <>({data})</>;
  },
  GlobalID: ({ data }) => {
    if (!data) return <span data-empty />;

    return <>({data})</>;
  },
  Int: ({ data }) => {
    if (typeof data !== 'number') return <span data-empty />;

    return <>{data}</>;
  },
  String: ({ data }) => {
    if (!data) return <span data-empty />;

    return <>{data}</>;
  },
  L10nString: ({ data }) => {
    if (!data) return <span data-empty />;

    return <>{data}</>;
  },
  MultilineString: ({ data }) => {
    if (!data) return <span data-empty />;

    return <div className='sg-a-ws-pw'>{data}</div>;
  },
  Boolean: ({ data }) => {
    if (typeof data !== 'boolean') return <span data-empty />;

    return <FormattedMessage {...msg[data ? 'valueTrue' : 'valueFalse']} />;
  },
  Float: ({ data }) => {
    if (typeof data !== 'number') return <span data-empty />;

    return <>{data}</>;
  },
  URL: ({ data }) => {
    if (!data) return <span data-empty />;

    return (
      <Link href={data} target='_blank' severity={LINK_SEVERITY.COLORFUL} isExternal>
        {data}
      </Link>
    );
  },
  Email: ({ data }) => {
    if (!data) return <span data-empty />;

    return (
      <Link href={`mailto:${data}`} severity={LINK_SEVERITY.COLORFUL}>
        {data}
      </Link>
    );
  },
  Date: ({ data }) => {
    if (!data) return <span data-empty />;

    return <Date timestamp={data} />;
  },
  DateTime: ({ data }) => {
    if (!data) return <span data-empty />;

    return <DateTime timestamp={data} />;
  },
  DateRange: ({ data }) => {
    if (!data) return <span data-empty />;

    return (
      <>
        {data.after && (
          <>
            <FormattedMessage {...msg.from} />
            &nbsp;
            <FormattedDate year='numeric' month='short' day='numeric' value={data.after} />
          </>
        )}
        {data.after && (data.before || data.strictly_before) && <> </>}
        {(data.before || data.strictly_before) && (
          <>
            <FormattedMessage {...msg.to} />
            &nbsp;
            <FormattedDate
              year='numeric'
              month='short'
              day='numeric'
              value={data.before || data.strictly_before}
            />
          </>
        )}
      </>
    );
  },
  EWKT: ({ data }) => {
    if (!data) return <FormattedMessage {...geometryMsg.notDrawn} />;

    const { epsg, geometry } = parseEWKT(data);

    const formatMessage = useMessage();
    const projection = formatMessage.fallback([epsg]) ?? epsg;

    return (
      <>
        {projection}, {geometry}
      </>
    );
  },
  JSON: ({ data }) => {
    if (!data) return <span data-empty />;

    return <code>{JSON.stringify(data, null, 2)}</code>;
  },
  File: ({ data }) => {
    if (!data) return <span data-empty />;

    const fileSize = formatFileSize(data.fileSize);

    if (!data.downloadUrl) {
      return (
        <>
          {data.name} ({fileSize})
        </>
      );
    }

    return (
      <>
        <Link href={data.downloadUrl} severity={LINK_SEVERITY.COLORFUL} download>
          {data.name}
        </Link>{' '}
        ({fileSize})
      </>
    );
  },
};
