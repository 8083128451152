import { defineMessages } from 'react-intl.macro';

import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { VariableDefinitionNode } from 'graphql';

import type { GeoJSONFeature } from '@tmapy/types';
import {
  actionActivateSelectFeatureTool,
  actionDeactivateTools,
  actionUpdateSelectFeatureTool,
} from '@tmapy/edit-tools';
import { LayerFeature } from '@tmapy/mapcore';
import { useDispatch, useSelector } from '@tmapy/redux';
import { LINK_SEVERITY, Link } from '@tmapy/style-guide';

import { DataComponent } from 'lib/graphql/types';

const getLayerId = (layerFeature: LayerFeature) =>
  layerFeature.feature.get('_originLayerId') ?? layerFeature.layerId;

const msg = defineMessages({
  notSelected: {
    id: 'sys.input.selectFeature.notSelected',
    defaultMessage: 'Nevybrán',
  },
});

export const createSelectFeatureInputComponent = (
  variableDefinition: VariableDefinitionNode,
  selectFeatureDirective: Record<string, any>,
): DataComponent => {
  const SelectFeatureInputComponent: DataComponent = ({ onChange }) => {
    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(actionActivateSelectFeatureTool());
      return () => {
        dispatch(actionDeactivateTools());
      };
    }, [dispatch]);

    const { selectedFeature, selectedFeatureData } = useSelector(
      (state) => state.editTools.selectFeatureTool,
    );

    const loadedFeatures = useSelector((state) => state.editTools.loadedFeatures);

    useEffect(() => {
      if (!selectedFeature || !loadedFeatures.length) return;

      const data = loadedFeatures.find(
        (item) =>
          item.layerId === getLayerId(selectedFeature) &&
          item.featureId === selectedFeature.feature.getProperties()[selectFeatureDirective.field],
      );
      if (!data) return;

      if (!selectedFeatureData) {
        dispatch(
          actionUpdateSelectFeatureTool({
            selectedFeatureData: data.data as GeoJSONFeature,
            selectedFeature,
          }),
        );
      }
    }, [selectedFeature, loadedFeatures, selectedFeatureData, dispatch]);

    useEffect(() => {
      if (!selectedFeature) return;
      const id = selectedFeature.feature.getProperties()[selectFeatureDirective.field];
      onChange?.(id);
    }, [onChange, selectedFeature]);

    return (
      <>
        {selectedFeature && selectFeatureDirective.layer === getLayerId(selectedFeature) ? (
          selectedFeatureData?.properties?.url ? (
            <Link
              href={selectedFeatureData.properties.url}
              isExternal
              severity={LINK_SEVERITY.COLORFUL}
              target='_blank'
            >
              {selectedFeature.feature.getProperties()[selectFeatureDirective.field]}
            </Link>
          ) : (
            selectedFeature.feature.getProperties()[selectFeatureDirective.field]
          )
        ) : (
          <FormattedMessage {...msg.notSelected} />
        )}
      </>
    );
  };
  return SelectFeatureInputComponent;
};
