import { useMemo } from 'react';
import type { DocumentNode, OperationDefinitionNode } from 'graphql';
import { validate, print } from 'graphql';
import type { QueryResult, OperationVariables } from '@apollo/client';
import { gql, useApolloClient } from '@apollo/client';

import { useSelector } from '@tmapy/redux';

import type { DataComponent } from './types';
import type { ExtendedApolloClient } from './createClient';
import { decorateDocument, DecorationContext } from './visitors/decorateDocument';
import { prepareClientDocument } from './visitors/prepareClientDocument';
import { processTypeMappings } from './visitors/processTypeMappings';
import { createPageComponent } from './astFactories/createPageComponent';
import type { ResultTypeQueryOrMutation } from './useQueryOrMutationData';
import { useQueryOrMutationData } from './useQueryOrMutationData';

import { usePermission } from '../../auth/hooks/usePermission';
import { processExports } from './utils/processExports';

export type GraphQueryResult<
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
> = QueryResult<TData, TVariables> & {
  clientDocument: DocumentNode;
  serverDocument: DocumentNode;
  client: ExtendedApolloClient;
  QueryComponent: React.ComponentType<{ data: any }>;
};

type DocumentCache = {
  clientDocument: DocumentNode;
};

const graphDocumentCache = new Map<string, DocumentCache>();

type ResultType = ResultTypeQueryOrMutation & {
  combinedVariables: Record<string, any>;
  QueryComponent: DataComponent;
};

export function useGraphQLDocument(
  queryOrDocument: string | DocumentNode,
  variables: Record<string, any>,
  decorationContext: DecorationContext,
): ResultType {
  const { parsedAccessToken } = useSelector((state) => state.auth);
  const client = useApolloClient() as ExtendedApolloClient;

  const { isPage, isMassSelect, operationName } = decorationContext;

  let clientDocument: DocumentNode;

  let query: string;
  let rawDocument: DocumentNode | undefined;
  if (typeof queryOrDocument === 'string') {
    query = queryOrDocument;
  } else {
    query = print(queryOrDocument);
    rawDocument = queryOrDocument;
  }

  // TODO: Stabilni ID uzivatele
  const cacheKey = `${query}#${operationName ?? ''}#${
    parsedAccessToken?.payload.email
  } ${parsedAccessToken?.payload.name}`;
  const cached = graphDocumentCache.get(cacheKey);

  const hasPermission = usePermission();

  if (cached) {
    clientDocument = cached.clientDocument;
  } else {
    if (!rawDocument) {
      rawDocument = gql(query);
    }

    if (operationName) {
      const operationIndex = rawDocument.definitions.findIndex(
        (def) => (def as OperationDefinitionNode)?.name?.value === operationName,
      );

      if (operationIndex < 0) {
        throw new Error(`Operation '${operationName}' not found in document`);
      }

      // Prehodi query nebo mutaci na prvni misto tak, aby fungovaly create…Component factory funkce
      rawDocument = {
        ...rawDocument,
        definitions: [
          rawDocument.definitions[operationIndex],
          ...rawDocument.definitions.filter((_, idx) => idx !== operationIndex),
        ],
      };
    }

    clientDocument = prepareClientDocument(rawDocument, client.clientSchema, hasPermission);

    const errors = validate(client.clientSchema, clientDocument);

    if (errors.length) {
      throw new Error(`GraphQL Validation Error:\n${errors.join('\n')}`);
    }

    console.debug('CLIENT_DOCUMENT', print(clientDocument));

    graphDocumentCache.set(cacheKey, { clientDocument });
  }

  const QueryComponent = useMemo(() => {
    const decoratedDocument = decorateDocument(client.clientSchema, clientDocument, {
      isPage,
      isMassSelect,
      operationName,
    });
    return createPageComponent(decoratedDocument, client.clientSchema);
  }, [client.clientSchema, clientDocument, isPage, isMassSelect, operationName]);

  const mappedVariables = useMemo(
    () => processTypeMappings(client, clientDocument, variables),
    [client, clientDocument, variables],
  );
  const queryResult = useQueryOrMutationData(clientDocument, mappedVariables);

  const combinedVariables = useMemo(() => {
    if (!clientDocument) {
      return mappedVariables;
    }
    const exportedVariables = processExports(clientDocument, queryResult.data);
    return { ...exportedVariables, ...mappedVariables };
  }, [clientDocument, queryResult.data, mappedVariables]);

  return {
    ...queryResult,
    combinedVariables,
    QueryComponent,
  };
}
