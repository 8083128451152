import { VariableDefinitionNode } from 'graphql';

import type { Variables } from '../types';

import { getNamedTypeNode } from './getNamedTypeNode';

export const formatVariables = (
  variables: Variables,
  variableDefinitions: readonly VariableDefinitionNode[] | undefined,
) => {
  const formattedVariables = { ...variables };
  if (variables && variableDefinitions) {
    for (const [key, value] of Object.entries(variables)) {
      const variableDefinition = variableDefinitions.find(
        (variableDefinition) => variableDefinition.variable.name.value === key,
      );
      if (value && variableDefinition) {
        const typeName = getNamedTypeNode(variableDefinition.type).name.value;
        switch (typeName) {
          case 'Int': {
            formattedVariables[key] = typeof value === 'string' ? parseInt(value) : value;
            break;
          }
          case 'EWKT': {
            formattedVariables[key] = value ? value : null;
            break;
          }
          default:
            formattedVariables[key] = value;
        }
      }
    }
  }
  return formattedVariables;
};
