import type { ThunkAction } from '@tmapy/redux';
import type { GeoJSONFeature } from '@tmapy/types';
import type { LoadFeaturesPayload, LoadedFeature } from '@tmapy/edit-tools';
import {
  actionAfterLoadSuccess,
  actionAfterSaveSuccess,
  getFeatureManager,
} from '@tmapy/edit-tools';
import { actionSetSnapInteraction } from '@tmapy/mapcore';

export const loadFeatures = async (layerFeatureId: LoadFeaturesPayload) => {
  const loadedFeatures = await Promise.all(
    layerFeatureId.map(({ layerId, featureId }) =>
      getFeatureManager().loadFeature(layerId, featureId, 'network-only'),
    ),
  );
  return loadedFeatures.filter((feature) => !!feature) as LoadedFeature[];
};

export const actionLoadFeatures =
  (highlightedFeatures: LoadFeaturesPayload): ThunkAction =>
  async (dispatch) => {
    const all = await loadFeatures(highlightedFeatures);
    dispatch(actionAfterLoadSuccess(all));
  };

export const actionSaveFeatures =
  (
    dataToSave: {
      layerId: string;
      featureId: string | number | null;
      feature: any;
    }[],
  ): ThunkAction =>
  async (dispatch) => {
    const response = await getFeatureManager().saveFeatures(dataToSave);

    const layerIds = [...new Set(dataToSave.map((data) => data.layerId))];
    if (response.length > 0) {
      layerIds.forEach((layerId) => dispatch(actionAfterSaveSuccess(layerId)));
    }
    return response;
  };

export const actionLoadRealSnapFeatures =
  (layerFeatureIds: LoadFeaturesPayload): ThunkAction =>
  async (dispatch, getState) => {
    const loadedFeatures = await loadFeatures(layerFeatureIds);
    const snapState = getState().mapCore.interaction.snap;

    if (snapState.layerFeatureIds === layerFeatureIds) {
      dispatch(
        actionSetSnapInteraction({
          features: loadedFeatures
            .map((response) => response.data)
            .filter((feature) => feature) as GeoJSONFeature[],
        }),
      );
    }
  };
