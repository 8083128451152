import React from 'react';

import { useMessage } from '@tmapy/intl';
import type { ArcGISRestInfoState, InfoFeatureItem, WFSInfoState } from '@tmapy/config';

import { Section } from 'lib/graphql';

import { InfoFeature } from './InfoFeature';

type InfoItemFromJsonOutputProps = {
  items?: InfoFeatureItem[] | null;
  config: WFSInfoState | ArcGISRestInfoState;
};

export const InfoItemFromJsonOutput: React.FC<InfoItemFromJsonOutputProps> = ({
  items,
  config,
}) => {
  const formatMessage = useMessage();

  if (!items || !items.length || (items?.length === 1 && items[0].features?.length === 0)) {
    return null;
  }

  return (
    <Section title={formatMessage.fallback([`info.${config.id}`])}>
      <ul className='sg-u-box'>
        {items.map((item, idx) =>
          item.features.map((feature, idy) => (
            <InfoFeature
              key={`${idx}_${config.id}_${idy}`}
              feature={feature}
              geoJSONFeature={feature}
              id={config.id}
              properties={item.properties || {}}
            />
          )),
        )}
      </ul>
    </Section>
  );
};
