import type { DirectiveNode } from 'graphql';
import { Kind } from 'graphql';

import { astFromValueUntyped } from '../../utils/astFromValueUntyped';

export type DirectiveArguments = {
  name: string;
  value: string | boolean | undefined | Record<string, string | boolean | undefined>;
}[];
export const createDirectiveNode = (
  directiveName: string,
  directiveArguments: DirectiveArguments = [],
): DirectiveNode => {
  const directive: DirectiveNode = {
    kind: Kind.DIRECTIVE,
    name: {
      kind: Kind.NAME,
      value: directiveName,
    },
    arguments: directiveArguments.map((argument) => ({
      kind: Kind.ARGUMENT,
      name: {
        kind: Kind.NAME,
        value: argument.name,
      },
      value: astFromValueUntyped(argument.value),
    })),
  };
  return directive;
};

export const addDirective = <T extends { directives?: readonly DirectiveNode[] }>(
  node: T,
  ...directives: DirectiveNode[]
): T => ({
  ...node,
  directives: [...(node.directives ?? []), ...directives],
});
