import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import type {
  DirectiveNode,
  DocumentNode,
  GraphQLSchema,
  OperationDefinitionNode,
  VariableDefinitionNode,
} from 'graphql';
import { getNamedType, isEnumType } from 'graphql';

import { useLink, useLocation } from '@tmapy/router';
import { useMessage } from '@tmapy/intl';
import { useDispatch, useSelector } from '@tmapy/redux';
import { Collapsible, FormItem, FormRow } from '@tmapy/style-guide';

import { getNamedTypeNode } from '../../utils/getNamedTypeNode';
import { getDirectives, DirectiveMap, hiddenFieldsFilter } from '../../utils/getDirectives';
import { actionAppSetFilterGroupVisibility } from '../../../../app/actions';
import { TableFilter } from '../../components/TableFilter';

import type { DataComponent, Variables } from '../../types';
import { modifyVariables } from '../../useQueryOrMutationData';

import { createSelectComponent } from '../selectComponents/createSelectComponent';
import { createMultiSelectComponent } from '../selectComponents/createMultiSelectComponent';
import { InputComponentMap } from '../inputComponents/InputComponentMap';
import { createEWKTInputComponent } from '../geometryComponents/createEWKTInputComponent';
import { createEnumComponent } from '../selectComponents/createEnumComponent';
import { createMultiEnumComponent } from '../selectComponents/createMultiEnumComponent';

export type VariableDefinition = {
  name: string;
  type: string;
  isRequired: boolean;
  isArray: boolean;
  directives: DirectiveMap;
  directiveNodes?: readonly DirectiveNode[];
};

const getVariableDefinitionsFromDocument = (document: DocumentNode) => {
  const { variableDefinitions } = document.definitions[0] as OperationDefinitionNode;
  return variableDefinitions ?? [];
};

const getVariableFromDefinitionNode = (
  variableDefinition: VariableDefinitionNode,
): VariableDefinition => ({
  name: variableDefinition.variable.name.value,
  type: getNamedTypeNode(variableDefinition.type).name.value,
  isRequired: variableDefinition.type.kind === 'NonNullType',
  isArray: variableDefinition.type.kind === 'ListType',
  directives: getDirectives(variableDefinition.directives),
  directiveNodes: variableDefinition.directives,
});

export const getFilterVariables = (document: DocumentNode, filterParamsName: string[]) =>
  getVariableDefinitionsFromDocument(document)
    .filter(hiddenFieldsFilter)
    .map(getVariableFromDefinitionNode)
    .filter((variable) => filterParamsName.includes(variable.name));

export const getVariablesFromDocument = (
  document: DocumentNode,
): VariableDefinition[] | undefined =>
  getVariableDefinitionsFromDocument(document).map(getVariableFromDefinitionNode);

type FilterComponent = React.ComponentType<{
  value: any;
  onChange: (value: Record<string, any>) => void;
  variables: Variables;
}>;

const createFilterComponent = (
  { name, type, isArray, directives, directiveNodes }: VariableDefinition,
  document: DocumentNode,
  schema: GraphQLSchema,
): FilterComponent => {
  const graphqlTypes = schema.getTypeMap();
  const graphqlType = getNamedType(graphqlTypes[type]);
  let Component: DataComponent;
  if (directives.ewkt) {
    Component = createEWKTInputComponent(directives);
  } else if (isEnumType(graphqlType) && isArray) {
    Component = createMultiEnumComponent(graphqlType);
  } else if (isEnumType(graphqlType)) {
    Component = createEnumComponent(graphqlType);
  } else if (directives.select) {
    const SelectComponent = isArray
      ? createMultiSelectComponent(directiveNodes, document)
      : createSelectComponent(directiveNodes, document, schema);

    Component = (props) => {
      let value = props.data;
      if (isArray && !Array.isArray(value)) {
        value = value ? [value] : [];
      }
      return <SelectComponent {...props} data={value} errors={props.errors} path={props.path} />;
    };
  } else {
    Component = InputComponentMap[type];
  }

  return ({ value, onChange, variables }) => {
    const formatMessage = useMessage();
    const [errorMesssage, setErrorMessage] = useState('');

    const handleChange = useCallback(
      (newValue: any) => {
        onChange({
          [name]: newValue || newValue === false || newValue === 0 ? newValue : undefined,
        });
      },
      [onChange],
    );

    const handleInputError = useCallback((errorMessage) => {
      setErrorMessage(errorMessage);
    }, []);

    if (!Component) return null;

    return (
      <FormItem
        id={`aria-${name}`}
        label={formatMessage.fallback([`filter.${name}`, name]) ?? name}
        errorMessage={errorMesssage}
      >
        <Component
          data={value}
          errors={[]}
          path={[]}
          variables={variables}
          loading={false}
          validate={directives.validate}
          onChange={handleChange}
          onInputError={handleInputError}
        />
      </FormItem>
    );
  };
};

const FilterGroup: React.FC<{
  values: Record<string, any>;
  queryName: string;
  groupId: string;
  groupComponents: { Component: FilterComponent; name: string }[];
  variables: Variables;
  onChange: (value: Record<string, any>) => void;
}> = ({ values, queryName, groupId, groupComponents, variables, onChange }) => {
  const dispatch = useDispatch();
  const formatMessage = useMessage();
  const filtersVisibility = useSelector((state) => state.app.filtersVisibility);
  const visible = filtersVisibility[queryName]?.[groupId] ?? true;

  const handleClick = useCallback(() => {
    dispatch(
      actionAppSetFilterGroupVisibility({
        queryName,
        groupId,
        visible: !visible,
      }),
    );
  }, [visible, queryName, groupId, dispatch]);

  return (
    <Collapsible
      label={formatMessage.fallback([`form.group.${groupId}`]) ?? groupId}
      isClosed={!visible}
      onClick={handleClick}
    >
      <div className='sg-u-vs-1 sg-a-pt-1 sg-a-pb-2'>
        {groupComponents.map(({ Component, name }) => (
          <FormRow key={name}>
            <Component value={values[name]} variables={variables} onChange={onChange} />
          </FormRow>
        ))}
      </div>
    </Collapsible>
  );
};

type VariableDefinitionWithComponent = (VariableDefinition & { Component: FilterComponent })[];

export const createTableFilterComponent = (
  document: DocumentNode,
  schema: GraphQLSchema,
  filterParamsName: string[],
  paginationVariables: string[],
): React.FC<{ variables: Variables }> | null => {
  const filterParams = getFilterVariables(document, filterParamsName);

  if (!filterParams || !filterParams.length) return null;

  const groupComponents = new Map<string, VariableDefinitionWithComponent>();
  for (const variableDefinition of filterParams) {
    const groupId = variableDefinition.directives.group?.id ?? 'group.undefined';
    const filterComponent = {
      ...variableDefinition,
      Component: createFilterComponent(variableDefinition, document, schema),
    };

    if (groupComponents.has(groupId)) {
      groupComponents.get(groupId)!.push(filterComponent);
    } else {
      groupComponents.set(groupId, [filterComponent]);
    }
  }

  const queryName = (document.definitions[0] as OperationDefinitionNode).name?.value ?? 'anonymous';

  const clearedPaginationParams = Object.fromEntries(
    paginationVariables.map((variable) => {
      return [variable, undefined];
    }),
  );

  const initFilterValues = (data: Record<string, any>) => {
    const initialFilterValues = modifyVariables(document, data, filterParams) ?? {};
    return initialFilterValues;
  };

  return ({ variables }) => {
    const location = useLocation();

    const [filterValues, handleFilterValuesChange] = useReducer(
      (state: Record<string, any>, action: Record<string, any>) => {
        const newState = { ...state, ...action };
        return newState;
      },
      location.params,
      initFilterValues,
    );

    useEffect(() => {
      const filterValuesFromUrlParams = Object.fromEntries(
        Object.keys(filterValues).map((key) => {
          if (!Object.prototype.hasOwnProperty.call(location.params, key)) {
            return [key, undefined];
          }
          return [];
        }),
      );
      handleFilterValuesChange(filterValuesFromUrlParams);
    }, [location.params]);

    const submitLink = useLink(location.route?.id, {
      ...clearedPaginationParams,
      ...filterValues,
    });

    const clearedFilterParams = useMemo(() => {
      const newClearedFilterParams = Object.keys(filterValues ?? {}).reduce(
        (filterParams, key) => {
          filterParams[key] = undefined;
          return filterParams;
        },
        {} as Record<string, undefined>,
      );
      return newClearedFilterParams;
    }, [filterValues]);

    const clearLink = useLink(location.route?.id, {
      ...clearedPaginationParams,
      ...clearedFilterParams,
    });

    const groupComponentsKeys = [...groupComponents.keys()];

    return (
      <TableFilter onSubmit={submitLink.onClick} onClear={clearLink.onClick}>
        {groupComponentsKeys.length > 1 ? (
          groupComponentsKeys.map((groupId) => {
            return (
              <FilterGroup
                key={groupId}
                values={filterValues}
                queryName={queryName}
                groupId={groupId}
                groupComponents={groupComponents.get(groupId)!}
                variables={variables}
                onChange={handleFilterValuesChange}
              />
            );
          })
        ) : (
          <div className='sg-u-vs-1 sg-a-pt-1'>
            {groupComponents
              .get(groupComponentsKeys[0])!
              .map(({ Component, name }: { Component: FilterComponent; name: string }) => (
                <FormRow key={name}>
                  <Component
                    value={filterValues[name]}
                    variables={variables}
                    onChange={handleFilterValuesChange}
                  />
                </FormRow>
              ))}
          </div>
        )}
      </TableFilter>
    );
  };
};
