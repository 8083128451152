import type { Permission } from '@tmapy/config';

export const isWildcardMatch = (rule: string, tested: string) =>
  rule === '*' || (rule.startsWith('*') && tested.endsWith(rule.slice(1)));

export const isMatch = (rule: string, tested: string) =>
  tested === rule || isWildcardMatch(rule, tested);

export const evalPermission = (permissions: Permission[], type: string, field: string) => {
  if (!field || field === '') {
    console.error(`Empty field for type ${type}`);
    return false;
  }
  for (const permission of permissions) {
    if (isMatch(permission.type, type)) {
      if (permission.fields.some((permissionField) => isMatch(permissionField, field))) {
        // TODO: eval field argument
        return true;
      }
    }
  }
  console.debug(`PERMISSION ${type}.${field} DENIED`);
  return false;
};
