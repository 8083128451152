import type { ASTNode } from 'graphql';

import { visit, Kind } from 'graphql';

export const renameVariables = <N extends ASTNode>(
  documentAST: N,
  suffix: string,
  inputVariables: Record<string, any>,
  outputVariables: Record<string, any>,
): N => {
  return visit(documentAST, {
    Variable(variable) {
      const oldName = variable.name.value;
      const newName = `${oldName}${suffix}`;
      outputVariables[newName] = inputVariables[oldName];
      return {
        ...variable,
        name: {
          kind: Kind.NAME,
          value: newName,
        },
      };
    },
  });
};
