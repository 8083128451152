import { useCallback, useMemo, useState } from 'react';
import type {
  DocumentNode,
  FieldNode,
  GraphQLFieldMap,
  GraphQLSchema,
  OperationDefinitionNode,
} from 'graphql';
import { getVariableValues, Kind } from 'graphql';
import { ApolloError, MutationResult, useApolloClient, useMutation } from '@apollo/client';

import { useMessage } from '@tmapy/intl';
import { useDispatch } from '@tmapy/redux';
import { getRouteByIdLang, history, filterRouteParams, useLocation } from '@tmapy/router';
import { actionInvalidateVectorTileLayer } from '@tmapy/mapcore';
import { actionDeactivateTools } from '@tmapy/edit-tools';

import { findMutation } from '../utils/filters';
import { getDirectives } from '../utils/getDirectives';
import { removeDirectives } from '../visitors/removeDirectives';
import { processExports } from '../utils/processExports';
import { getDirectivesFromDescription } from '../utils/getDirectivesFromDescription';
import { getFieldsFromFragments } from '../utils/getFieldsFromFragments';

import type { Variables } from '../types';
import type { ExtendedApolloClient } from '../createClient';
import { msg } from '../messages';

export type UseMutationAction = [
  undefined | ((variables: Record<string, any>) => Promise<void>),
  MutationResult,
];

export const processRedirectDirective = (
  variables: Record<string, any>,
  previousVariables: Record<string, any>,
  mutationOperation: OperationDefinitionNode,
  result: any,
  mutationDirectiveRedirect: Record<string, any>,
) => {
  const newVariables = {
    ...previousVariables,
    ...variables,
    ...processExports(mutationOperation!, result.data),
  };

  const routeId = mutationDirectiveRedirect.route;
  const route = getRouteByIdLang(routeId, newVariables?.lang);
  if (route) {
    const routeParams = filterRouteParams(mutationDirectiveRedirect?.route, newVariables);
    history[mutationDirectiveRedirect.history === 'PUSH' ? 'push' : 'replace'](
      route.getHref({ ...mutationDirectiveRedirect.variables, ...routeParams }),
    );
  } else {
    console.warn(`[processRedirectDirective]: Invalid route name ${routeId}`);
  }
};

export const getDescriptionFromFields = (
  operation: OperationDefinitionNode | undefined,
  fields: GraphQLFieldMap<any, any> | undefined,
) => {
  const mutations = fields ?? {};
  const selections = operation ? getFieldsFromFragments(operation.selectionSet.selections) : [];
  const mutation = selections?.find((field) => field.kind === 'Field') as FieldNode | undefined;
  const name = mutation?.name.value ?? '';
  return mutations[name]?.description ?? '';
};

export const useMutationAction = (
  document: DocumentNode,
  schema: GraphQLSchema,
  operationName: string,
  mutationVariables: Record<string, any>,
  onSuccess?: () => void,
): UseMutationAction => {
  const dispatch = useDispatch();
  const formatMessage = useMessage();
  const mutationOperation = findMutation(document, operationName);
  const apolloClient = useApolloClient() as ExtendedApolloClient;
  const { params: previousVariables } = useLocation();

  const directives = getDirectives(mutationOperation?.directives);

  const description = getDescriptionFromFields(
    mutationOperation,
    schema.getMutationType()?.getFields(),
  );
  const directivesFromSchema = getDirectivesFromDescription(description);

  const layerId = directives.layer?.id;

  const mutationDocument: DocumentNode = {
    kind: Kind.DOCUMENT,
    definitions: mutationOperation
      ? [removeDirectives(mutationOperation)]
      : [
          {
            kind: 'OperationDefinition',
            operation: 'mutation',
          } as any,
        ],
  };
  const mutationDirectives = getDirectives([
    ...directivesFromSchema,
    ...(mutationOperation?.directives ?? []),
  ]);

  const mutationDirectiveRedirect = mutationDirectives.redirect;
  const mutationDirectiveAction = mutationDirectives.action2;
  const isDanger = mutationDirectiveAction?.type === 'DANGER';

  const [callMutation, mutationStatus] = useMutation(mutationDocument, { fetchPolicy: 'no-cache' });

  const [clientErrors, setClientErrors] = useState({} as Partial<MutationResult<any>>);

  const derivedMutationStatus = useMemo(
    () => ({
      ...mutationStatus,
      ...clientErrors,
    }),
    [mutationStatus, clientErrors],
  );

  const callAction = useCallback(
    async (actionVariables?: Variables) => {
      if (!isDanger || window.confirm(formatMessage(msg.actionConfirm))) {
        const variables = { ...previousVariables, ...mutationVariables, ...actionVariables };

        if (mutationOperation?.variableDefinitions && variables) {
          const { errors } = getVariableValues(
            apolloClient.clientSchema,
            mutationOperation?.variableDefinitions,
            variables,
          );
          if (errors) {
            console.error('Input cannot be parsed to match variable definitions.', errors);
            setClientErrors({
              error: new ApolloError({ graphQLErrors: errors }),
              loading: false,
              called: true,
              data: null,
            });

            return;
          } else {
            setClientErrors({});
          }
        }
        const result = await callMutation({ variables });
        if (!result.errors) {
          if (layerId) {
            dispatch(actionInvalidateVectorTileLayer(layerId));
            dispatch(actionDeactivateTools());
          }
          if (onSuccess) {
            onSuccess();
          } else if (mutationDirectiveRedirect) {
            processRedirectDirective(
              mutationVariables,
              previousVariables,
              mutationOperation!,
              result,
              mutationDirectiveRedirect,
            );
          }
          await apolloClient.reFetchObservableQueries();
        }
      }
    },
    [
      apolloClient,
      callMutation,
      mutationDirectiveRedirect,
      mutationOperation,
      onSuccess,
      mutationVariables,
      previousVariables,
      dispatch,
      layerId,
      operationName,
      formatMessage,
    ],
  );

  return [mutationOperation ? callAction : undefined, derivedMutationStatus];
};
