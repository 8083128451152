import { useCallback, useState } from 'react';
import type { FieldNode, DocumentNode, GraphQLSchema, DirectiveNode, GraphQLField } from 'graphql';
import { getNamedType, isNonNullType, isListType, isEnumType } from 'graphql';

import { useMessage } from '@tmapy/intl';
import { FormItem, FormRow } from '@tmapy/style-guide';

import type { DataComponent, DataProps } from '../../types';
import { nameComponent } from '../../utils/nameComponent';
import { findMutation } from '../../utils/filters';
import { getNamedTypeNode } from '../../utils/getNamedTypeNode';
import { getDirectives } from '../../utils/getDirectives';
import { getFieldAliasOrName } from '../../utils/getFieldAliasOrName';
import { isConnectionType } from '../../utils';
import { GraphQLQueryError } from '../../components/QueryError';
import { ReadOnlyFormField } from '../../components/ReadOnlyFormField';
import { msg } from '../../messages';

import { createEnumComponent } from '../selectComponents/createEnumComponent';
import { createEWKTInputComponent } from '../geometryComponents/createEWKTInputComponent';
import { createInlineViewComponent } from '../inputComponents/createInlineViewComponent';
import { createBadgeComponent } from '../inputComponents/createBadgeComponent';
import { createSelectComponent } from '../selectComponents/createSelectComponent';
import { InputComponentMap } from '../inputComponents/InputComponentMap';
import { createListComponent } from '../createListComponent';
import { createMultiSelectComponent } from '../selectComponents/createMultiSelectComponent';

import { isMultipleConnectionType } from './createDetailFormComponent';

export const createDetailInputComponent = (
  graphqlField: GraphQLField<any, any, any>,
  directivesFromSchema: readonly DirectiveNode[],
  field: FieldNode,
  document: DocumentNode,
  schema: GraphQLSchema,
  intlPrefix: string | null,
): DataComponent => {
  const graphqlType = graphqlField.type;
  const isTable = isConnectionType(graphqlType);
  const namedType = getNamedType(graphqlType);
  const typeName = namedType.name;
  const label = getFieldAliasOrName(field);

  const mutationOperation = findMutation(document, 'update');

  let variableDefinition;
  if (mutationOperation && mutationOperation.variableDefinitions) {
    variableDefinition = mutationOperation.variableDefinitions.find(
      (variableDefinition) => variableDefinition.variable.name.value === label,
    );
  }

  const isReadOnly = !variableDefinition;
  const isRequired =
    isNonNullType(graphqlType) ||
    (!!variableDefinition && variableDefinition.type.kind === 'NonNullType');

  let variableName: string;
  let variableTypeName = typeName;
  if (variableDefinition) {
    variableName = variableDefinition.variable.name.value;
    variableTypeName = getNamedTypeNode(variableDefinition.type).name.value;
  }

  let Component = InputComponentMap[variableTypeName];

  const directives = getDirectives([...directivesFromSchema, ...(field.directives ?? [])]);
  const variableDirectives = getDirectives([
    ...directivesFromSchema,
    ...(variableDefinition?.directives ?? []),
  ]);

  if (isTable) {
    const isMultiple = isMultipleConnectionType(graphqlField, field);
    if (!directives.select || isMultiple) {
      return () => null;
    }
  }

  if (directives.url) {
    Component = InputComponentMap.URL;
  } else if (directives.select && isTable) {
    Component = createMultiSelectComponent(field.directives, document);
  } else if (directives.select) {
    Component = createSelectComponent(field.directives, document, schema, false, label);
  } else if (directives.ewkt) {
    Component = createEWKTInputComponent(directives);
  } else if (directives.text) {
    Component = directives.text.multiline
      ? InputComponentMap.MultilineString
      : InputComponentMap.String;
  }

  if (!Component && isEnumType(namedType)) {
    if (isReadOnly) {
      Component = createBadgeComponent(namedType);
    } else {
      Component = createEnumComponent(namedType);
    }
  }

  if (!Component) {
    const InlineViewComponent = createInlineViewComponent(
      graphqlField,
      directivesFromSchema,
      field,
      document,
      schema,
      intlPrefix,
    );

    Component = (props) => (
      <ReadOnlyFormField>
        <InlineViewComponent {...props} />
      </ReadOnlyFormField>
    );
  }

  let unwrapNonNull = graphqlType;
  if (isNonNullType(unwrapNonNull)) {
    unwrapNonNull = unwrapNonNull.ofType;
  }

  if (isListType(unwrapNonNull)) {
    if (variableTypeName === 'JSON') {
      Component = InputComponentMap.ArrayJSON;
    } else {
      Component = createListComponent(Component, directives);
    }
  }

  return nameComponent(
    `LabelDecorator`,
    ({ data, errors, path, variables, onChange, loading, parentContext }: DataProps) => {
      const formatMessage = useMessage();
      const [errorMesssage, setErrorMessage] = useState('');
      const help = formatMessage.fallback([`${intlPrefix}.${label}.help`, `${label}.help`]);
      const hint = formatMessage.fallback([`${intlPrefix}.${label}.hint`, `${label}.hint`]);

      const handleChange = useCallback(
        (value) => {
          onChange?.({
            [variableName]: value,
          });
        },
        [onChange],
      );

      const handleInputError = useCallback((errorMessage) => {
        setErrorMessage(errorMessage);
      }, []);

      return (
        <FormRow>
          <FormItem
            id={isReadOnly ? undefined : `aria-${intlPrefix}-${label}`}
            label={formatMessage.fallback([intlPrefix && `${intlPrefix}.${label}`, label]) ?? label}
            isRequired={isRequired && !isReadOnly}
            errorMessage={
              errors.length || errorMesssage ? (
                <>
                  {errors.map((error, idx) => (
                    <GraphQLQueryError key={idx} error={error} />
                  ))}
                  {errorMesssage}
                </>
              ) : undefined
            }
            hint={hint && !isReadOnly ? hint : undefined}
            help={help && !isReadOnly ? help : undefined}
            helpLabel={formatMessage(msg.formItemHelp)}
            requiredLabel={formatMessage(msg.formItemRequired)}
          >
            <Component
              data={data}
              path={path}
              errors={errors}
              variables={variables}
              loading={loading}
              validate={variableDirectives.validate}
              isReadOnly={isReadOnly}
              isRequired={isRequired}
              parentContext={parentContext}
              onInputError={handleInputError}
              onChange={handleChange}
            />
          </FormItem>
        </FormRow>
      );
    },
  );
};
