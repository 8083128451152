import React from 'react';

import { useMessage } from '@tmapy/intl';
import { PanelIcon, SvgCross, TertiaryBtn } from '@tmapy/style-guide';

import { msg } from 'lib/graphql';

import './PanelHead.scss';

export type PanelHeadProps = {
  title: string;
  icon: JSX.Element;
  onHideBtnClick(): void;
};

export const PanelHead: React.FC<PanelHeadProps> = React.memo(({ title, icon, onHideBtnClick }) => {
  const formatMessage = useMessage();

  return (
    <div className='tw-panelHead sg-a-d-f sg-a-ai-c sg-a-pt-2 sg-a-pl-2 sg-a-pr-1 sg-a-cg-2'>
      <PanelIcon icon={icon} />
      <h2 className='tw-panelHead--title sg-a-f-1'>{title}</h2>
      <div className='sg-a-f-n'>
        <TertiaryBtn
          icon={{ element: <SvgCross /> }}
          isDanger
          tooltip={formatMessage(msg.close)}
          onClick={onHideBtnClick}
        />
      </div>
    </div>
  );
});
