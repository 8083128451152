import type React from 'react';
import { useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import type { DirectiveNode, DocumentNode, GraphQLSchema, OperationDefinitionNode } from 'graphql';
import { visit } from 'graphql';
import { ApolloError, gql } from '@apollo/client';

import { useLink } from '@tmapy/router';
import { CtaLink, SvgLink, SvgPlusCircle, TertiaryLink } from '@tmapy/style-guide';

import type { Variables } from '../../types';
import type { DirectiveMap } from '../../utils/getDirectives';
import { getDirectives } from '../../utils/getDirectives';
import { getNamedTypeNode } from '../../utils/getNamedTypeNode';
import { RenderQuery } from '../../components/RenderQuery';
import { DataLayoutDialog, DataLayoutDialogContext } from '../../components/DataLayoutDialog';

import { useUploadFile } from '../inputComponents/useUploadFile';
import { useMutationAction } from '../useMutationAction';

const getFileKeyNameFromDocument = (document: DocumentNode): string | void => {
  let fileKeyName;
  try {
    visit(document, {
      VariableDefinition: (node) => {
        const typeName = getNamedTypeNode(node.type).name.value;
        if (typeName === 'FileInput') {
          fileKeyName = node.variable.name.value;
        }
      },
    });
    document.definitions;
  } catch (e) {
    /* empty */
  }
  return fileKeyName;
};

type ToolComponent = React.FC<{ isDisabled?: boolean }>;

type CreateUseTool = ({ variables }: { variables: Variables }) => {
  Tool: ToolComponent;
  upload?: {
    handleUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
    error?: ApolloError;
    isUploading: boolean;
  };
};

export const createUseTool = (
  schema: GraphQLSchema,
  typeDirective: 'create' | 'detail',
  directive: DirectiveMap['create'] | DirectiveMap['detail'],
  directiveNodes: readonly DirectiveNode[] | undefined,
  dialogIdSuffix = '',
): CreateUseTool => {
  const getToolsVariables = (variables: Variables): Variables => {
    if (typeDirective === 'create') {
      return getDirectives(directiveNodes, variables).create?.variables ?? {};
    }
    return variables;
  };

  if (!directive?.route) {
    return () => ({ Tool: () => null, upload: undefined });
  }

  const icon = { element: typeDirective === 'create' ? <SvgPlusCircle /> : <SvgLink /> };

  const testId = `tw-tool--${typeDirective}`;

  return ({ variables }) => {
    const toolsVariables = getToolsVariables(variables) || {};

    const routeConfig = useSelector((state) =>
      state.app.routes.find((route) => route.id === directive.route),
    );

    if (!routeConfig || !routeConfig.query) {
      throw new Error(`The tool '${directive.route}' is created without a route.`);
    }

    // TODO the query should be a raw document
    const document = gql(routeConfig.query);
    const queryName = (document.definitions?.[0] as OperationDefinitionNode)?.name?.value;

    const [operation, status] = useMutationAction(document, schema, queryName ?? '', {});
    const fileKeyName = getFileKeyNameFromDocument(document);

    const onChange = fileKeyName
      ? (value: string | string[]) => {
          const fileKeys = Array.isArray(value) ? value : [value];
          fileKeys.forEach((fileKey) => operation?.({ ...toolsVariables, [fileKeyName]: fileKey }));
        }
      : undefined;

    const { handleChange, isUploading } = useUploadFile({ onChange });

    const [uploadError, setUploadError] = useState<ApolloError>();

    const handleUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
      try {
        await handleChange(event);
      } catch (error) {
        if (error instanceof ApolloError) {
          setUploadError(error);
        } else {
          throw error;
        }
      }
    };

    const Tool = useMemo(
      (): ToolComponent =>
        ({ isDisabled, children }) => {
          const dataLayoutDialogContext = useContext(DataLayoutDialogContext);
          const dialogId = `${dataLayoutDialogContext.dialogId}-${typeDirective}-${dialogIdSuffix}`;

          const routeConfig = useSelector((state) =>
            state.app.routes.find((route) => route.id === directive.route),
          );

          if (!queryName) {
            throw new Error('DialogComponent: Query name for is missing!');
          }

          const href = useLink(directive.route, toolsVariables, undefined, 'push', true).href;

          const onClick = (e: React.MouseEvent) => {
            if (isDisabled) {
              return;
            }
            e.stopPropagation();
            e.preventDefault();

            (window.document.getElementById(dialogId) as HTMLDialogElement).show();
          };

          const buttonProps = { icon, href, isDisabled, onClick, testId };

          if (!href) {
            console.error(
              `The tool "${typeDirective}-${dialogIdSuffix}" is not rendered because it is not validly configured.`,
            );
            return null;
          }

          if (!routeConfig?.hasBasePermission) {
            return null;
          }

          return (
            <>
              {children ? (
                <CtaLink {...buttonProps} icon={undefined}>
                  {children}
                </CtaLink>
              ) : (
                <TertiaryLink {...buttonProps} />
              )}
              <DataLayoutDialog dialogId={dialogId}>
                <RenderQuery
                  query={document}
                  variables={toolsVariables}
                  operationName={queryName}
                />
              </DataLayoutDialog>
            </>
          );
        },
      [variables, queryName],
    );

    return {
      Tool,
      upload: fileKeyName
        ? {
            handleUpload,
            error: status.error || uploadError,
            isUploading: isUploading || status.loading,
          }
        : undefined,
    };
  };
};
