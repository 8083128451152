export const DEFAULT_APP_BASE_NAME = 'manager';

export const createGetAppBaseName = (pulicUrl: string) => {
  const publicUrlPrefix = pulicUrl.replace(/^\//, '');
  const RE_APP_BASE_NAME = new RegExp(`^/?(?:${publicUrlPrefix})?/?([^/]+)?`);

  return (pathname: string): string => {
    const [, appBaseNameMatch] = pathname.match(RE_APP_BASE_NAME) ?? [];
    if (appBaseNameMatch && appBaseNameMatch !== publicUrlPrefix) return appBaseNameMatch;
    return DEFAULT_APP_BASE_NAME;
  };
};

export const getAppBaseName = createGetAppBaseName(process.env.PUBLIC_URL);
