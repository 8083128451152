import { addVersionToUrl } from '../utils/addVersionToUrl';

export const resolveImports = async (config: any, pathBase: string) => {
  const promises: Promise<any>[] = [];

  const loadGraphql = async (queryConfig: any) => {
    const url = addVersionToUrl(new URL(queryConfig.queryFile, pathBase)).toString();

    try {
      const graphqlFile = await fetch(url);
      const queryText = await graphqlFile.text();
      queryConfig.query = queryText;
    } catch (err) {
      console.error(`Error while trying to load GraphQL query from ${url}`);
    }
  };

  for (const route of config.routes) {
    if (typeof route.path === 'string') {
      route.path = route.path.replace(/^(http)s?:/, '');
    } else if (typeof route.path === 'object') {
      route.path = Object.fromEntries(
        Object.entries<string>(route.path).map((route) => {
          return [route[0], route[1].replace(/^(http)s?:/, '')];
        }),
      );
    }
    if (route.queryFile) {
      promises.push(loadGraphql(route));
    }
  }

  if (Array.isArray(config.info?.services)) {
    for (const info of config.info.services) {
      if (info.queryFile) {
        promises.push(loadGraphql(info));
      }
    }
  }

  await Promise.all(promises);

  return config;
};
