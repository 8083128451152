import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { useMessage } from '@tmapy/intl';

import './Chip.scss';

import { SvgCross, SvgFilter, Icon } from '@tmapy/style-guide';

import { msg } from '../messages';

type ChipProps = {
  label?: string;
  isDanger?: boolean;
  showRemoveBtn?: boolean;
  onRemoveBtnClick?: React.MouseEventHandler;
  children: ReactNode;
};

export const Chip: React.FC<ChipProps> = ({
  label,
  isDanger,
  showRemoveBtn = true,
  onRemoveBtnClick,
  children,
}) => {
  const formatMessage = useMessage();

  return (
    <span
      className={classNames('tw-chip', {
        'tw-chip-danger': isDanger,
      })}
    >
      <span className='tw-chip--icon'>
        <Icon element={<SvgFilter />} />
      </span>
      <span className='tw-chip--inner' onClick={onRemoveBtnClick}>
        {label && <span className='tw-chip--label'>{label}</span>}
        <b className='tw-chip--value'>{children}</b>
        {showRemoveBtn && (
          <button
            className='tw-chip--removeBtn'
            type='button'
            title={formatMessage.lookup(msg, 'filterChipsRemove') ?? ''}
          >
            <Icon element={<SvgCross />} />
          </button>
        )}
      </span>
    </span>
  );
};
