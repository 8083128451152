import React from 'react';
import classNames from 'classnames';
import DOMPurify from 'dompurify';

import { FormItem, FormRow } from '@tmapy/style-guide';
import { useMessage } from '@tmapy/intl';

import { Section } from 'lib/graphql';

const parser = new DOMParser();

// Add a hook to make all links open a new window
DOMPurify.addHook('afterSanitizeAttributes', function (node) {
  // set all elements owning target to target=_blank
  if ('target' in node) {
    node.setAttribute('target', '_blank');
  }
});

type InfoItemFromHtmlOutputProps = {
  id: string;
  html: string;
};

export const InfoItemFromHtmlOutput: React.FC<InfoItemFromHtmlOutputProps> = ({ id, html }) => {
  const formatMessage = useMessage();
  const document = parser.parseFromString(html, 'text/html');
  const bodyElement = DOMPurify.sanitize(document.body.innerHTML, {
    ALLOWED_ATTR: ['colspan', 'rowspan', 'href'],
    ALLOWED_TAGS: ['table', 'thead', 'tbody', 'tr', 'th', 'td', 'p', 'a'],
    RETURN_DOM: true,
  });
  if (!(bodyElement instanceof HTMLElement)) {
    return null;
  }

  const bodyInnerHTML = bodyElement.innerHTML.trim();

  if (!bodyInnerHTML) return null;

  const tables = Array.from(
    bodyElement.querySelectorAll('body > table') as NodeListOf<HTMLTableElement>,
  );

  if (tables.length && tables[0].rows[0]?.cells?.length === 2) {
    return (
      <Section title={formatMessage.fallback([`info.${id}`, `layer.${id}`])}>
        <ul className='sg-u-box'>
          {tables.map((table, tableIdx) => {
            return (
              <li
                key={tableIdx}
                className={classNames({
                  'sg-a-p-2 sg-u-vs-1': true,
                  'sg-u-bt': tableIdx > 0,
                })}
              >
                <ul className='sg-u-vs-1'>
                  {Array.from(table.rows).map((row, rowIdx) => {
                    return (
                      <li key={rowIdx}>
                        <FormRow>
                          <FormItem label={row.cells[0]?.innerText}>
                            <div
                              className='sg-u-userContent'
                              dangerouslySetInnerHTML={{ __html: row.cells[1]?.innerHTML }}
                            />
                          </FormItem>
                        </FormRow>
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          })}
        </ul>
      </Section>
    );
  } else {
    console.warn(`[InfoItemFromHtmlOutput] do not support service HTML response: ${bodyInnerHTML}`);
    return null;
  }
};
