import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';

import type { GeoJSONFeature, GeoJSONPoint } from '@tmapy/types';
import './Panorama.scss';
import { history } from '@tmapy/router';
import { actionSetCenter, actionSetLayerFeatures } from '@tmapy/mapcore';
import { useDispatch, useSelector } from '@tmapy/redux';
import { LAYER_IDS, WFSInfoState } from '@tmapy/config';

const viewerOpts = {
  controls: {
    mouseViewMode: 'drag',
  },
};

const getWFSData = async (serviceConfig: WFSInfoState, imgId: string) => {
  try {
    const panoServiceConfig = serviceConfig.properties.fields?.find(
      (field) => field.type === 'panorama',
    );
    if (!panoServiceConfig) return null;

    const fieldPath = panoServiceConfig.nameId.split('.');
    const field = fieldPath.pop();
    const url = `${serviceConfig.url}&cql_filter=${field} IN ('${imgId}')`;
    const wfsUrl = new URL(url, window.location.href);

    const response = await fetch(wfsUrl.href);
    const data = await response.json();
    return data.features?.[0];
  } catch (err) {
    console.error('[panorama/getWFSData] failed:', err);
  }
  return null;
};

const replaceHistory = (imgId: string) => {
  const currentUrl = new URL(window.location.href);
  const searchParams = currentUrl.searchParams;
  searchParams.set('imgId', imgId);
  currentUrl.search = searchParams.toString();
  history.replace(currentUrl.toString());
};

export type PanoramaImperatives = {
  resize(): void;
};

export const Panorama = forwardRef((_, ref: React.Ref<PanoramaImperatives>) => {
  const dispatch = useDispatch();

  const panoContainer = useRef<HTMLDivElement>(null);

  const { imgId, serviceId } = useSelector((state) => state.router.params);
  const serviceConfig = useSelector((state) => state.info.services).find(
    (service) => service.id === serviceId,
  ) as WFSInfoState;

  const [view, setView] = useState<any>(null);
  const [featureData, setFeatureData] = useState<GeoJSONFeature | null>(null);
  const [panoId, setPanoId] = useState(imgId);
  const [viewLook, setViewLook] = useState({
    yaw: 0,
    pitch: 0,
    roll: 0,
  });
  const [viewAngle, setViewAngle] = useState<number>(0);

  const [marzipano, setMarzipano] = useState<any>(null);

  const viewer = useMemo(
    () =>
      panoContainer.current && marzipano
        ? new marzipano.Viewer(panoContainer.current, viewerOpts)
        : null,
    [panoContainer.current, marzipano],
  );

  const panoImg = useMemo(() => {
    if (!serviceConfig || !panoId) return null;
    const panoServiceConfig = serviceConfig.properties.fields?.find(
      (field) => field.type === 'panorama',
    );

    return panoServiceConfig?.template?.replace('%FIELD%', panoId.toString());
  }, [panoId, serviceConfig]);

  useImperativeHandle(ref, () => ({
    resize: () => {
      viewer?.updateSize();
    },
  }));

  useEffect(() => {
    if (featureData && !marzipano) {
      // eslint-disable-next-line node/no-unsupported-features/es-syntax
      import(/* webpackChunkName: "marzipano" */ 'marzipano').then((marzipano) => {
        setMarzipano(marzipano);
      });
    }
  }, [featureData, marzipano]);

  useEffect(() => {
    if (serviceConfig && panoId) {
      const loadWFSData = async () => {
        const featureData = await getWFSData(serviceConfig, panoId.toString());
        setFeatureData(featureData);
      };
      loadWFSData();
    }
  }, [panoId, serviceConfig]);

  useEffect(() => {
    if (!marzipano || !featureData) return;

    const viewParams = {
      yaw: featureData.properties?.yaw || viewLook.yaw,
      pitch: featureData.properties?.pitch || viewLook.pitch,
      roll: featureData.properties?.roll || viewLook.roll,
      head: 0,
      fov: 0,
    };
    const limiter = marzipano.RectilinearView.limit.traditional(
      1024,
      (100 * Math.PI) / 180,
      (120 * Math.PI) / 180,
    );
    setView(new marzipano.RectilinearView(viewParams, limiter));

    viewer.addEventListener('viewChange', () => {
      const currentView = viewer.view();
      setViewAngle((180 * currentView.yaw()) / Math.PI);
    });

    return () => {
      viewer?.destroy();
    };
  }, [viewer]);

  useEffect(() => {
    if (!view) return;
    const source = marzipano.ImageUrlSource.fromString(panoImg);
    const geometry = new marzipano.EquirectGeometry([{ width: 4800 }]);

    // SCENE
    const newScene = viewer?.createScene({
      source,
      geometry,
      view,
      pinFirstLevel: true,
    });

    //STEPS
    featureData?.properties?.near.forEach((e: any) => {
      const step = document.createElement('div');
      step.classList.add('tw-panoram--step');
      step.classList.add('tw-panoram--step-size' + 5 * Math.ceil(e.dist / 5));
      const onClick = () => {
        setViewLook({
          yaw: view._yaw,
          pitch: view._pitch,
          roll: view._roll,
        });

        setPanoId(e.id);
        replaceHistory(e.id);
      };
      step.addEventListener('click', onClick);
      step.addEventListener('touchstart', onClick);

      newScene.hotspotContainer().createHotspot(
        step,
        {
          yaw: e.yaw,
          pitch: e.pitch,
          roll: e.roll,
        },
        {
          perspective: {
            radius: 500,
          },
        },
      );
    });

    // SHOW CURRENT SCENE
    newScene.switchTo({
      transitionDuration: 1000,
    });
    // set map center
    featureData && dispatch(actionSetCenter((featureData.geometry as GeoJSONPoint).coordinates));
  }, [featureData, view]);

  useEffect(() => {
    if (featureData) {
      // uhlova korekce natoceni pohledu v mape vuci severu
      const headAngle = (180 * (featureData.properties?.head || 0)) / Math.PI;

      featureData.properties = {
        ...featureData.properties,
        viewAngle: viewAngle + headAngle,
      };
    }
    featureData && dispatch(actionSetLayerFeatures(LAYER_IDS.PANORAMA, [featureData]));
    return () => {
      dispatch(actionSetLayerFeatures(LAYER_IDS.PANORAMA, []));
    };
  }, [featureData, viewAngle, dispatch]);

  return <div className='tw-panorama' ref={panoContainer} />;
});
