import type {
  DirectiveNode,
  GraphQLField,
  GraphQLNamedOutputType,
  GraphQLObjectType,
  GraphQLSchema,
  OperationDefinitionNode,
} from 'graphql';
import { Kind, OperationTypeNode, getNamedType } from 'graphql';

import { isConnectionType } from '../utils/getConnectionInfo';
import { isNamingID } from '../utils/isNamingID';

import { createNameNode } from './nodeFactories/createNameNode';
import { createArgumentNode } from './nodeFactories/createArgumentNode';
import { createVariableDefinitionNode } from './nodeFactories/createVariableDefinitionNode';
import {
  createSelectionSetNode,
  createTableSelectionSetNode,
} from './nodeFactories/createSelectionSetNode';
import { createDirectiveNode } from './nodeFactories/createDirectiveNode';

export const createDirective = () => {
  return {};
};

export const getTypeFromConnectionType = (queryType: GraphQLObjectType) => {
  const { edges } = queryType.getFields();
  const { node } = (getNamedType(edges.type) as GraphQLObjectType).getFields();
  return getNamedType(node.type) as GraphQLObjectType;
};

const createConnectionQuery = (
  queryName: string,
  schema: GraphQLSchema,
  queryType: GraphQLNamedOutputType,
): OperationDefinitionNode => {
  const name = createNameNode(queryName);

  const namedType = getTypeFromConnectionType(queryType as GraphQLObjectType);

  const createHiddenDirective = (fieldType: GraphQLField<any, any>) => {
    const namedType = getNamedType(fieldType.type);
    if (isNamingID(namedType.name)) {
      return [createDirectiveNode('hidden')];
    }
    return [];
  };

  const mutations = schema.getMutationType()?.getFields() ?? {};

  const selectionSet = createSelectionSetNode(namedType, createHiddenDirective);

  const peerMutations = Object.entries(mutations).filter(([, mutation]) => {
    const type = getNamedType(mutation.type);
    return type.name === namedType.name;
  });

  const tableDirectives: DirectiveNode[] = peerMutations
    .filter(([mutationName]) => {
      return mutationName.indexOf('create') !== -1;
    })
    .map(([mutationName]) => {
      return createDirectiveNode('create', [{ name: 'route', value: mutationName }]);
    });

  const directives: DirectiveNode[] = peerMutations
    .filter(([, mutation]) => {
      return mutation.description;
    })
    .map(([mutationName]) => {
      return createDirectiveNode('action', [{ name: 'mutation', value: mutationName }]);
    });

  directives.push(createDirectiveNode('detail', [{ name: 'route', value: 'link' }])); // TODO !!

  return {
    kind: Kind.OPERATION_DEFINITION,
    operation: OperationTypeNode.QUERY,
    name,
    variableDefinitions: [],
    selectionSet: {
      kind: Kind.SELECTION_SET,
      selections: [
        {
          kind: Kind.FIELD,
          arguments: [],
          name,
          selectionSet: createTableSelectionSetNode(selectionSet, directives),
          directives: tableDirectives,
        },
      ],
    },
  };
};

export const createQueryFromSchema = (
  queryName: string,
  schema: GraphQLSchema,
  queryType: GraphQLField<any, any>,
  variables: string[],
): OperationDefinitionNode => {
  const name = createNameNode(queryName);

  const graphqlType = getNamedType(queryType.type);

  if (isConnectionType(graphqlType)) {
    return createConnectionQuery(queryName, schema, graphqlType);
  }

  const createHiddenDirective = (fieldType: GraphQLField<any, any>) => {
    if (variables.includes(fieldType.name)) {
      return [createDirectiveNode('hidden')];
    }
    return [];
  };

  const selectionSet = createSelectionSetNode(graphqlType, createHiddenDirective);

  const variableDefinitions = variables.map(createVariableDefinitionNode);
  const argumentNodes = queryType.args.map(createArgumentNode);

  return {
    kind: Kind.OPERATION_DEFINITION,
    operation: OperationTypeNode.QUERY,
    name,
    variableDefinitions,
    selectionSet: {
      kind: Kind.SELECTION_SET,
      selections: [
        {
          kind: Kind.FIELD,
          arguments: argumentNodes,
          name,
          selectionSet,
        },
      ],
    },
  };
};
