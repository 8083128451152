import type { GraphQLObjectType, GraphQLSchema, OperationDefinitionNode } from 'graphql';
import { getNamedType, print } from 'graphql';

import type { RouteConfigTWC } from '@tmapy/types';
import { getTokensFromPath } from '@tmapy/router';

import { isConnectionType } from '../utils';

import { createMutationFromSchema } from './createMutationFromSchema';
import { createQueryFromSchema, getTypeFromConnectionType } from './createQueryFromSchema';
import { createDocumentNode } from './nodeFactories/createDocumentNode';

type CreateDocumentFromSchema = {
  route: RouteConfigTWC;
  schema: GraphQLSchema;
  path: string;
  basename: string;
};

export const createDocumentFromSchema = ({
  route,
  schema,
  path,
  basename,
}: CreateDocumentFromSchema) => {
  const name = route.id;
  const definitions: OperationDefinitionNode[] = [];

  try {
    const tokens = getTokensFromPath(path, {}, basename);

    const variables = tokens
      .map((token) => (typeof token === 'object' ? `${token.name}` : ''))
      .filter(Boolean);

    const queries = schema.getQueryType()?.getFields() ?? {};
    const mutations = schema.getMutationType()?.getFields() ?? {};

    if (queries[name]) {
      definitions.push(createQueryFromSchema(route.id, schema, queries[name], variables));
      let graphqlType = getNamedType(queries[name].type);

      if (isConnectionType(graphqlType)) {
        graphqlType = getTypeFromConnectionType(graphqlType as GraphQLObjectType);
      }

      definitions.push(
        ...Object.entries(mutations)
          .filter(([, mutation]) => {
            const type = getNamedType(mutation.type);
            return type.name === graphqlType.name;
          })
          .map(([mutationName, mutation]) => {
            return createMutationFromSchema(mutationName, schema, mutation, variables);
          }),
      );
    } else if (mutations[name]) {
      definitions.push(createMutationFromSchema(route.id, schema, mutations[name], variables));
    }
  } catch (e) {
    console.error('Creation error DocumentNode!', e);
  }

  const document = createDocumentNode(definitions);

  const query = definitions.length > 0 ? print(document) : undefined;
  return query;
};
