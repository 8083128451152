import { defineMessages } from 'react-intl.macro';

export const mapMsg = defineMessages({
  inTooltip: {
    id: 'sys.map.zoom.in',
    defaultMessage: 'Přiblížit',
  },
  outTooltip: {
    id: 'sys.map.zoom.out',
    defaultMessage: 'Oddálit',
  },
  exportMapTitle: {
    id: 'sys.map.export',
    defaultMessage: 'Export mapy',
  },
  tooltipTitle: {
    id: 'sys.map.layerSwitcher',
    defaultMessage: 'Obsah mapy',
  },
  legendTitle: {
    id: 'sys.map.legend',
    defaultMessage: 'Legenda',
  },
  measureTitle: {
    id: 'sys.map.measure',
    defaultMessage: 'Měření',
  },
  notesTitle: {
    id: 'sys.map.notes',
    defaultMessage: 'Poznámky',
  },
  searchTitle: {
    id: 'sys.map.search',
    defaultMessage: 'Hledat',
  },
  defaultExtent: {
    id: 'sys.map.defaultExtent',
    defaultMessage: 'Nastavit výchozí pozici mapy',
  },
});

export const mapExportMsg = defineMessages({
  portrait: {
    id: 'sys.map.export.orientation.portrait',
    defaultMessage: 'Na výšku',
  },
  landscape: {
    id: 'sys.map.export.orientation.landscape',
    defaultMessage: 'Na šířku',
  },
  allInOnePage: {
    id: 'sys.map.export.template.allInOnePage',
    defaultMessage: 'Vše na jedné straně',
  },
  footer: {
    id: 'sys.map.export.footer',
    defaultMessage: 'Vytištěno v mapovém řešení Spinbox společnosti T-MAPY',
  },
  title: {
    id: 'sys.map.export.title',
    defaultMessage: 'Nadpis',
  },
  mapScale: {
    id: 'sys.map.export.mapScale',
    defaultMessage: 'Měřítko',
  },
  copyright: {
    id: 'sys.map.export.copyright',
    defaultMessage: 'Copyright',
  },
});
