import React, { useState } from 'react';

import { useMessage } from '@tmapy/intl';
import {
  CloseDialogBtn,
  Dialog,
  SvgArrowLeft,
  SvgArrowRight,
  TertiaryBtn,
} from '@tmapy/style-guide';

import { msg } from 'lib/graphql';

import './Slideshow.scss';

export type SlideshowProps = {
  imageIndex: number;
  images: {
    src: string;
    tooltip?: string;
  }[];
  onClose(): void;
};

export const Slideshow: React.FC<SlideshowProps> = React.memo(({ imageIndex, images, onClose }) => {
  const [index, setIndex] = useState(imageIndex);
  const formatMessage = useMessage();

  const handlePrevImage = () => {
    setIndex(index - 1);
  };

  const handleNextImage = () => {
    setIndex(index + 1);
  };

  return (
    <Dialog width='100%' height='100%' onEscKeyDown={onClose} onOverlayClick={onClose}>
      <CloseDialogBtn tooltip={formatMessage(msg.close)} onClick={onClose} />
      <div className='inf-slideshow'>
        <figure>
          <a href={images[index].src} target='_blank' rel='noopener noreferrer'>
            <img src={images[index].src} title={images[index].tooltip} alt='' draggable='false' />
          </a>
        </figure>
        {images.length > 1 && (
          <nav>
            <TertiaryBtn
              icon={{ element: <SvgArrowLeft /> }}
              isDisabled={index <= 0}
              onClick={handlePrevImage}
            />
            <TertiaryBtn
              icon={{ element: <SvgArrowRight /> }}
              isDisabled={index >= images.length - 1}
              onClick={handleNextImage}
            />
          </nav>
        )}
      </div>
    </Dialog>
  );
});
