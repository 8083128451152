import {
  ASTNode,
  FieldNode,
  getNamedType,
  GraphQLSchema,
  InlineFragmentNode,
  Kind,
  OperationDefinitionNode,
  SelectionNode,
  SelectionSetNode,
  TypeInfo,
  visit,
  visitWithTypeInfo,
} from 'graphql';

import { isConnectionType } from '../utils';
import { getDirectives } from '../utils/getDirectives';
import { getDirectivesFromDescription } from '../utils/getDirectivesFromDescription';

type SelectionSetStackItem = Map<FieldNode, string>;

export const mergeFragmentsFromDirective = <T extends ASTNode>(
  schema: GraphQLSchema,
  documentAST: T,
): T => {
  const typeInfo = new TypeInfo(schema);
  let firstOperation: OperationDefinitionNode | null = null;

  const selectionSetStack: SelectionSetStackItem[] = [];
  let currentSelectionSetMap: SelectionSetStackItem;

  const groupFieldsRemoved = visit(
    documentAST,
    visitWithTypeInfo(typeInfo, {
      OperationDefinition(operation) {
        if (!firstOperation) {
          firstOperation = operation;
        } else {
          // Preskoci vsechny krom prvni operace v dokumentu
          // see https://graphql.org/graphql-js/language/#visit
          return false;
        }
      },
      InlineFragment() {
        return false;
      },
      SelectionSet: {
        enter() {
          currentSelectionSetMap = new Map<FieldNode, string>();
          selectionSetStack.push(currentSelectionSetMap);
        },
        leave(selectionSet) {
          const currentSelectionSetMap = selectionSetStack.pop()!;
          const type = typeInfo.getType();
          const namedType = getNamedType(type);

          const groupFragments = new Map<string, InlineFragmentNode>();
          const selections: SelectionNode[] = [];
          for (const selection of selectionSet.selections) {
            switch (selection.kind) {
              case Kind.FIELD: {
                if (!currentSelectionSetMap.has(selection)) {
                  selections.push(selection);
                }
                break;
              }
              case Kind.FRAGMENT_SPREAD: {
                throw new Error('Unsupported');
              }
              case Kind.INLINE_FRAGMENT: {
                const fragmentDirectives = getDirectives(selection.directives);
                if (fragmentDirectives.group) {
                  groupFragments.set(fragmentDirectives.group?.id, selection);
                }
                selections.push(selection);
                break;
              }
            }
          }

          for (const [field, groupId] of currentSelectionSetMap!.entries()) {
            let groupFragment = groupFragments.get(groupId);
            if (!groupFragment) {
              groupFragment = {
                kind: Kind.INLINE_FRAGMENT,
                selectionSet: {
                  kind: Kind.SELECTION_SET,
                  selections: [],
                },
                typeCondition: {
                  kind: Kind.NAMED_TYPE,
                  name: { kind: Kind.NAME, value: namedType!.name },
                },
                directives: [
                  {
                    kind: Kind.DIRECTIVE,
                    name: { kind: Kind.NAME, value: 'group' },
                    arguments: [
                      {
                        kind: Kind.ARGUMENT,
                        name: { kind: Kind.NAME, value: 'id' },
                        value: {
                          kind: Kind.STRING,
                          value: groupId,
                        },
                      },
                    ],
                  },
                ],
              };
              groupFragments.set(groupId, groupFragment);
              selections.push(groupFragment);
            }
            (groupFragment.selectionSet.selections as any).push(field);
          }

          return {
            ...selectionSet,
            selections,
          } as SelectionSetNode;
        },
      },
      Field(field) {
        const parentType = typeInfo.getParentType();
        if (parentType?.name === 'Query') {
          return;
        }

        if (parentType && isConnectionType(parentType)) {
          return false;
        }

        const type = typeInfo.getType();
        if (type && isConnectionType(type)) {
          return false;
        }

        const fieldDef = typeInfo.getFieldDef();
        if (!fieldDef) return null;

        if (getDirectives(field.directives).hidden) {
          return false;
        }

        let groupId = '';
        if (fieldDef.description) {
          const directiveNodes = getDirectivesFromDescription(fieldDef.description);
          const directives = getDirectives(directiveNodes);
          if (directives.hidden) {
            return false;
          }

          if (directives.group?.id) {
            groupId = directives.group?.id;
          }
        }

        currentSelectionSetMap?.set(field, groupId);
        return null;
      },
    }),
  );

  return groupFieldsRemoved;
};
