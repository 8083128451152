import { defineMessages } from 'react-intl.macro';

import React from 'react';
import { useIntl } from 'react-intl';

import { SecondaryBtn, SvgCompass } from '@tmapy/style-guide';

const msg = defineMessages({
  btnLabel: { id: 'sys.map.resetRotation', defaultMessage: 'Zrušit natočení mapy' },
});

export type ResetRotationProps = {
  rotation: number;
  isActive?: boolean;
  onClick(): void;
};

export const ResetRotation: React.FC<ResetRotationProps> = ({ rotation, isActive, onClick }) => {
  const intl = useIntl();
  const transform = `rotate(${(rotation * 180) / Math.PI}deg)`;

  return (
    <SecondaryBtn
      icon={{ element: <SvgCompass style={{ transform }} /> }}
      isActive={isActive}
      tooltip={intl.formatMessage(msg.btnLabel)}
      onClick={onClick}
    />
  );
};
