import type { ThunkAction } from '@tmapy/redux';
import { batch } from '@tmapy/redux';
import {
  type PopupConfig,
  type extendedTWC,
  deriveMapStateFromConfig,
  deriveInfoStateFromConfig,
  deriveUIManagerStateFromConfig,
  deriveLayerSwitcherStateFromConfig,
  deriveLegendStateFromConfig,
  deriveSearchStateFromConfig,
  MAP_TOOL,
  DEFAULT_DISTANCE_IN_PIXELS,
  HASH_PARAM,
} from '@tmapy/config';
import type { LayerFeature } from '@tmapy/mapcore';
import { actionSetLayerOpacity, actionSetLayerVisibility, actionSetMapState } from '@tmapy/mapcore';
import { actionSetLegendState } from '@tmapy/legend';
import {
  deriveStateFromConfig as deriveStateFromMapSwitcherConfig,
  actionSetMapSwitcherState,
} from '@tmapy/map-switcher';
import { actionSetLayerSwitcherState } from '@tmapy/layer-switcher';
import { actionSetSearchState } from '@tmapy/search';
import { actionUpdateHashParams, getRouteByIdLang, history } from '@tmapy/router';
import { actionSetUIManagerState } from '@tmapy/ui-manager';
import { actionActivateEditTool } from '@tmapy/edit-tools';
import { actionActivateNotes } from '@tmapy/notes';
import { actionSetMeasureLine, actionSetMeasurePolygon } from '@tmapy/measure';

import type { ExtendedApolloClient } from '../lib/graphql';
import { actionSetInfoState } from '../lib/info';

import { parseHashParamLayerFragment } from '../utils/parseHashParamLayerFragment';

import {
  SET_APP_STATE,
  APP_POPUP_ADD,
  APP_POPUP_DISMISS_ALL,
  APP_POPUP_UPDATE,
  APP_SET_FILTER_GROUP_VISIBILITY,
  APP_SET_PACKAGE_VERSION,
  LAYER_HASH_FRAGMENT_DELIMITER,
} from '../constants';

import type { _AppState, PopupState } from './reducer';
import { deriveAppStateFromConfig } from '../config';

export type ActionSetAppState =
  | ReturnType<typeof actionSetAppState>
  | ReturnType<typeof actionAppPopupAdd>
  | ReturnType<typeof actionAppPopupUpdate>
  | ReturnType<typeof actionAppPopupDismissAll>
  | ReturnType<typeof actionSetPackageVersion>
  | ReturnType<typeof actionAppSetFilterGroupVisibility>;

const actionSetAppState = (json: _AppState) => ({ type: SET_APP_STATE, payload: json }) as const;

export const deriveStatesFromConfig =
  (config: extendedTWC, client: ExtendedApolloClient): ThunkAction =>
  (dispatch, getState): boolean => {
    try {
      const { intl, auth } = getState();
      const app = deriveAppStateFromConfig(config, client.clientSchema, intl.messages, auth);
      const mapState = deriveMapStateFromConfig(config);
      const mapSwitcher = deriveStateFromMapSwitcherConfig(config.mapSwitcher);
      const legend = deriveLegendStateFromConfig(config);
      const layerSwitcher = deriveLayerSwitcherStateFromConfig(config.layerSwitcher);
      const search = deriveSearchStateFromConfig(config.search);
      const info = deriveInfoStateFromConfig(config.info);
      const uiManager = deriveUIManagerStateFromConfig();

      batch('SET STATES FROM CONFIG', () => {
        dispatch(actionSetAppState(app));
        mapState && dispatch(actionSetMapState(mapState));
        dispatch(actionSetMapSwitcherState(mapSwitcher));
        dispatch(actionSetLegendState(legend));
        dispatch(actionSetLayerSwitcherState(layerSwitcher));
        dispatch(actionSetSearchState(search));
        info && dispatch(actionSetInfoState(info));
        dispatch(actionSetUIManagerState(uiManager));
      });
    } catch (err) {
      const message = err instanceof Error ? err.message : String(err);
      console.error(`Cannot derive states from config from: ${message}`);
      return false;
    }

    return true;
  };

export const actionNavigateToInfo =
  (point: [number, number]): ThunkAction =>
  (dispatch, getState) => {
    const state = getState();
    const projection = state.mapCore.view.projection.replace('EPSG:', 'SRID=');
    const routeId = state.app.routes.find((route) => route.component === 'MapInfo')?.id;
    const route = routeId ? getRouteByIdLang(routeId, state.router.route!.lang) : null;
    const params = state.router.route?.params ?? {};

    if (route) {
      const variables: Record<string, string> = {};
      const geom = `${projection};POINT(${point[0]} ${point[1]})`;
      route.tokens.forEach((token) => {
        if (typeof token === 'object') {
          const { name } = token;
          switch (name) {
            case 'geom': {
              variables[name] = geom;

              const resolution = state.mapCore.view.resolution;
              const distanceInMeters = resolution ? resolution * DEFAULT_DISTANCE_IN_PIXELS : 0;

              dispatch(
                actionUpdateHashParams({
                  [HASH_PARAM.DISTANCE_IN_METERS]: distanceInMeters.toFixed(2),
                }),
              );

              break;
            }
            default: {
              variables[name] = params[name];
            }
          }
        }
      });

      const href = route.getHref(variables);
      // setImmediate() -> nejprve dokonci ulozeni do state a nasledne provede history
      setImmediate(() => {
        history.push(href);
      });
    }
  };

export const actionNavigateToFeatureDetail =
  (layerFeature: LayerFeature): ThunkAction =>
  (_, getState) => {
    const state = getState();
    const feature = layerFeature.feature;
    const layerName = feature.get('_originLayerId') || layerFeature.layerId;
    const featureRoute = state.app.featureRouteMappings.find(
      (featureRoute) => featureRoute.layer === layerName,
    );
    if (!featureRoute || !state.router.route) {
      return;
    }
    const route = getRouteByIdLang(featureRoute.routeId, state.router.route.lang);
    if (route) {
      const variables: Record<string, string> = {};
      route.tokens.forEach((token) => {
        if (typeof token === 'object') {
          const { name } = token;
          switch (name) {
            case '_id': {
              variables[name] = feature.getId() ?? feature.get('_id') ?? feature.get('id');
              break;
            }
            case 'id': {
              variables[name] = feature.get('id') ?? feature.get('_id') ?? feature.getId();
              break;
            }
            default: {
              variables[name] = feature.get(name as string) ?? state.router.params[name];
            }
          }
        }
      });

      const href = route.getHref(variables);
      // setImmediate() -> nejprve dokonci ulozeni do state a nasledne provede history
      setImmediate(() => {
        history.push(href);
      });
    }
  };

export const actionAppPopupAdd = (contents: PopupState[]) =>
  ({
    type: APP_POPUP_ADD,
    payload: contents,
  }) as const;

export const actionAppPopupDismissAll = () => ({ type: APP_POPUP_DISMISS_ALL }) as const;

export const actionSetPackageVersion = (packageVersion?: string) =>
  ({
    type: APP_SET_PACKAGE_VERSION,
    payload: packageVersion,
  }) as const;

const getPopupLocalStorageKey = (appId: string, id: string): string => `${appId}.popup.${id}`;

export const actionAppPopupUpdate = (appId: string, payload: Partial<PopupState>) => {
  const localStorageKey = getPopupLocalStorageKey(appId, payload.id!);
  if (payload.notShowAgain) {
    window.localStorage.setItem(localStorageKey, '1');
  } else {
    window.localStorage.removeItem(localStorageKey);
  }

  return {
    type: APP_POPUP_UPDATE,
    payload,
  } as const;
};

export const actionAppStartPopup =
  (appId: string, popupConfigs: PopupConfig[]): ThunkAction =>
  (dispatch) => {
    const contents: PopupState[] = [];

    for (const popupConfig of popupConfigs) {
      const now = Date.now();
      if (popupConfig.dateFrom && Date.parse(popupConfig.dateFrom) < now) {
        continue;
      }
      if (popupConfig.dateTo && Date.parse(popupConfig.dateTo) >= now) {
        continue;
      }
      if (
        popupConfig.rememberNotShow &&
        window.localStorage.getItem(getPopupLocalStorageKey(appId, popupConfig.id))
      ) {
        continue;
      }

      contents.push({
        id: popupConfig.id,
        rememberNotShow: !!popupConfig.rememberNotShow,
        notShowAgain: false,
      });
    }

    if (!contents.length) {
      return;
    }

    dispatch(actionAppPopupAdd(contents));
  };

export const actionAppSetFilterGroupVisibility = (payload: {
  queryName: string;
  groupId: string;
  visible: boolean;
}) =>
  ({
    type: APP_SET_FILTER_GROUP_VISIBILITY,
    payload,
  }) as const;

export const actionActivateMapTool =
  (mapTool: MAP_TOOL): ThunkAction =>
  (dispatch, getState) => {
    const state = getState();
    const isEditor = state.app.isEditor;
    batch('actionActivateMapTool', () => {
      switch (mapTool) {
        case MAP_TOOL.INFOEDIT: {
          return dispatch(actionActivateEditTool(MAP_TOOL.INFOEDIT, 'Point', isEditor) as any);
        }
        case MAP_TOOL.NOTE_SELECT: {
          return dispatch(actionActivateNotes());
        }
        case MAP_TOOL.MEASURE_LINE: {
          return dispatch(actionSetMeasureLine());
        }
        case MAP_TOOL.MEASURE_POLYGON: {
          return dispatch(actionSetMeasurePolygon());
        }
        default: {
          throw new Error(`Unsupported mapTool: ${mapTool}`);
        }
      }
    });
  };

export const actionSetLayerVisibilityByHashParam =
  (l: string): ThunkAction =>
  (dispatch) => {
    const layersVisibility: Record<string, boolean> = {};
    for (const hashParamLayerFragment of l.split(LAYER_HASH_FRAGMENT_DELIMITER)) {
      const layerParamFragment = parseHashParamLayerFragment(hashParamLayerFragment);
      if (layerParamFragment) {
        const { visible, layerId, opacity } = layerParamFragment;
        layersVisibility[layerId] = visible;

        if (opacity) {
          dispatch(actionSetLayerOpacity({ [layerId]: opacity }));
        }
      }
    }
    dispatch(actionSetLayerVisibility(layersVisibility));
  };
