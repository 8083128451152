import { useCallback, useEffect, useState } from 'react';

import type { InputProps } from '@tmapy/style-guide';
import { Input, Textarea } from '@tmapy/style-guide';
import { identity } from '@tmapy/utils';

import type { DataComponent } from '../../types';
import { ReadOnlyFormField } from '../../components/ReadOnlyFormField';
import { nameComponent } from '../../utils/nameComponent';

import { InlineViewComponentMap } from './InlineViewComponentMap';
import { PasswordInput } from './PasswordInput';

export const identityDataConverter: DataConverter<any, any> = {
  toText: identity,
  fromText: identity,
};

export type DataConverter<Internal, Serialized> = {
  toText(internal: Internal | null | undefined): Serialized;
  fromText(serialized: Serialized): Internal | null | undefined;
};

export const createTextInputComponent = (
  componentName: string,
  dataConverter: DataConverter<any, string> = identityDataConverter,
  inputType: InputProps['type'] | 'textarea' | 'code' = 'text',
): DataComponent =>
  nameComponent(
    componentName,
    ({ data, loading, validate, isRequired, isReadOnly, errors, path, onChange, onInputError }) => {
      const [value, setValue] = useState(dataConverter.toText(data));

      useEffect(() => {
        try {
          const parsedText = dataConverter.toText(dataConverter.fromText(value));
          const parsedData = dataConverter.toText(data);
          if (parsedText !== parsedData) {
            setValue(parsedData);
          }
        } catch (e) {
          /* empty */
        }
      }, [data]);

      const testId = path.length ? `tw-textInput--${path.join('-')}` : undefined;

      const handleChange = useCallback(
        (e: React.ChangeEvent, eventData: { value: string }) => {
          try {
            setValue(eventData.value);
            onChange?.(dataConverter.fromText(eventData.value));
            onInputError?.('');
          } catch (e) {
            onInputError?.(e?.toString() ?? '\u2049');
          }
        },
        [onChange, onInputError],
      );

      if (isReadOnly) {
        let Component = InlineViewComponentMap.String;
        if (['textarea', 'code'].includes(inputType)) {
          Component = InlineViewComponentMap.MultilineString;
        }
        return (
          <ReadOnlyFormField>
            <Component
              data={dataConverter.toText(data)}
              errors={errors}
              path={path}
              variables={null}
              loading={false}
              isReadOnly
            />
          </ReadOnlyFormField>
        );
      }

      if (inputType === 'textarea') {
        return (
          <Textarea
            value={value}
            isRequired={isRequired}
            isInvalid={!!errors.length}
            isDisabled={loading}
            onChange={handleChange}
            testId={testId}
          />
        );
      }

      if (inputType === 'password') {
        return (
          <PasswordInput
            value={value}
            isRequired={isRequired}
            isInvalid={!!errors.length}
            isDisabled={loading}
            onChange={handleChange}
            testId={testId}
          />
        );
      }

      if (inputType === 'code') {
        return (
          <code className='sg-u-type-sml'>
            <Textarea
              value={value}
              isRequired={isRequired}
              isInvalid={!!errors.length}
              isDisabled={loading}
              onChange={handleChange}
              testId={testId}
            />
          </code>
        );
      }

      return (
        <Input
          type={inputType}
          value={value}
          min={validate?.min}
          max={validate?.max}
          isRequired={isRequired}
          isReadOnly={isReadOnly}
          isInvalid={!!errors.length}
          isDisabled={loading}
          onChange={handleChange}
          testId={testId}
        />
      );
    },
  );
