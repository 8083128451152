import React from 'react';

import { useDispatch, useSelector } from '@tmapy/redux';
import { useMessage } from '@tmapy/intl';
import type { LayerSwitcherDispatch } from '@tmapy/layer-switcher';
import { LayerSwitcher } from '@tmapy/layer-switcher';
import { SvgLayers } from '@tmapy/style-guide';

import type { PanelProps } from './types';
import { mapMsg } from './messages';
import { PanelHead } from './PanelHead';

export const LayerSwitcherPanel: React.FC<PanelProps> = ({ onHideBtnClick }) => {
  const formatMessage = useMessage();
  const dispatch: LayerSwitcherDispatch = useDispatch();

  const layerSwitcher = useSelector((state) => state.layerSwitcher);
  const mapRouteId = useSelector((state) => state.app.mapRouteId);
  const defaultRouteId = useSelector((state) => state.app.defaultRouteId);
  const currentRouteId =
    useSelector((state) => state.router.route?.id) ?? mapRouteId ?? defaultRouteId;

  const {
    layers,
    interaction,
    view: { resolution },
  } = useSelector((state) => state.mapCore);
  const routes = {
    mapRouteId,
    currentRouteId,
  };

  return (
    <div className='sg-a-d-f sg-a-fd-c sg-a-o-a'>
      <PanelHead
        title={formatMessage(mapMsg.tooltipTitle)}
        icon={<SvgLayers />}
        onHideBtnClick={onHideBtnClick}
      />
      <LayerSwitcher
        layerSwitcher={layerSwitcher}
        layers={layers}
        resolution={resolution}
        routes={routes}
        dispatch={dispatch}
        interaction={interaction}
      />
    </div>
  );
};
