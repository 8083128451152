import { useCallback, useReducer, useRef } from 'react';
import type { ApolloError } from '@apollo/client';

import { useMessage } from '@tmapy/intl';
import { TertiaryBtn, SvgWarning, Popup, SvgReload, SecondaryBtn } from '@tmapy/style-guide';

import type { ActionDirectiveButtonProps } from '../types';
import { msg } from '../messages';

import { QueryError } from './QueryError';

const popupPosition = ['bottom-left' as const, 'top-left' as const];

type ActionDirectiveProps = {
  error?: ApolloError;
  buttonProps: ActionDirectiveButtonProps;
  isDisabled?: boolean;
  isInHeader?: boolean;
};

export const ActionDirective = ({
  error,
  buttonProps,
  isDisabled,
  isInHeader,
}: ActionDirectiveProps) => {
  const formatMessage = useMessage();
  const msgRepeat = formatMessage(msg.formRepeat);
  const msgShowError = formatMessage(msg.showError);
  const msgHideError = formatMessage(msg.hideError);

  const [isMenuVisible, toggleMenuVisible] = useReducer((state) => !state, false);

  const stopPropagation = useCallback((e) => {
    e.stopPropagation();
  }, []);

  const handleBtnClick = useCallback((e) => {
    e.stopPropagation();
    toggleMenuVisible();
  }, []);

  const handleReloadClick = useCallback(
    (e) => {
      buttonProps.onClick(e);
      toggleMenuVisible();
    },
    [buttonProps.onClick],
  );

  const popupTriggerRef = useRef<HTMLDivElement>(null);

  const Button = isInHeader ? SecondaryBtn : TertiaryBtn;

  return (
    <div ref={popupTriggerRef}>
      {error ? (
        <Button
          icon={{
            element: isMenuVisible && buttonProps.icon ? buttonProps.icon : <SvgWarning />,
          }}
          onClick={handleBtnClick}
          isActive={isMenuVisible}
          tooltip={isMenuVisible ? msgHideError : msgShowError}
        />
      ) : (
        <Button
          {...buttonProps}
          icon={{ element: buttonProps.icon ?? <>?</> }}
          isDisabled={isDisabled && !buttonProps.queryState}
        />
      )}
      {isMenuVisible && (
        <Popup position={popupPosition} triggerRef={popupTriggerRef}>
          <div className='sg-a-p-2 sg-a-d-f sg-a-fw-w sg-a-g-2 sg-a-va-b' onClick={stopPropagation}>
            {error && <QueryError error={error} />}
            <TertiaryBtn
              icon={{ element: <SvgReload /> }}
              onClick={handleReloadClick}
              isDanger={buttonProps.isDanger}
              isDisabled={isDisabled && !buttonProps.queryState}
              tooltip={msgRepeat}
            />
          </div>
        </Popup>
      )}
    </div>
  );
};
