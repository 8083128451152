import { defineMessages } from 'react-intl.macro';

import React, { useCallback, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { useMessage } from '@tmapy/intl';
import { useSelector } from '@tmapy/redux';
import { actionCobraLogin, actionCobraLogout } from '@tmapy/auth';
import { useLink } from '@tmapy/router';
import {
  usePopup,
  CtaBtn,
  Popup,
  SecondaryBtn,
  BtnBar,
  SecondaryLink,
  Avatar,
  SvgEntry,
} from '@tmapy/style-guide';

import './User.scss';

const msg = defineMessages({
  login: {
    id: 'sys.user.button.login',
    defaultMessage: 'Přihlásit se',
  },
  logout: {
    id: 'sys.user.button.logout',
    defaultMessage: 'Odhlásit se',
  },
  profile: {
    id: 'sys.user.button.profile',
    defaultMessage: 'Profil uživatele',
  },
});

export const User: React.FC = React.memo(() => {
  const formatMessage = useMessage();
  const { isLoggedIn, hasExpiredLogin, parsedAccessToken } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const userProfileLink = useLink('user-profile', undefined, undefined, 'push', true);

  const popupTriggerRef = useRef<HTMLButtonElement>(null);
  const popupContentRef = useRef<HTMLDivElement>(null);
  const [isPopupShown, setPopupShown] = usePopup(popupTriggerRef, popupContentRef);
  const popupPosition = ['bottom-left' as const];

  const handleAvatarClick: React.MouseEventHandler = useCallback(() => {
    setPopupShown(!isPopupShown);
  }, [isPopupShown, setPopupShown]);

  const handleUserProfileClick = useCallback(
    (e: React.MouseEvent) => {
      userProfileLink.onClick(e);
      setPopupShown(false);
    },
    [setPopupShown],
  );

  const handleLogin = () => {
    dispatch(actionCobraLogin);
  };
  const handleLogout = () => {
    dispatch(actionCobraLogout());
  };

  if (isLoggedIn && parsedAccessToken && !hasExpiredLogin) {
    const name = parsedAccessToken?.payload.name;
    const avatar = (
      <>
        {parsedAccessToken?.payload.given_name?.[0]}
        {parsedAccessToken?.payload.family_name?.[0]}
      </>
    );

    return (
      <>
        <button
          className='twc-user--btn'
          ref={popupTriggerRef}
          title={name}
          onClick={handleAvatarClick}
        >
          <Avatar type='dark'>{avatar}</Avatar>
        </button>
        {isPopupShown && (
          <Popup triggerRef={popupTriggerRef} position={popupPosition}>
            <div ref={popupContentRef} className='sg-a-p-2 sg-u-vs-2'>
              <div className='sg-a-d-f sg-a-cg-1 sg-a-ai-c'>
                <Avatar type='light'>{avatar}</Avatar>
                <span className='sg-a-ws-nw sg-u-type-sml'>{name}</span>
              </div>
              <BtnBar stretch align='left'>
                {userProfileLink && (
                  <SecondaryLink {...userProfileLink} onClick={handleUserProfileClick}>
                    <FormattedMessage {...msg.profile} />
                  </SecondaryLink>
                )}
                <SecondaryBtn isDanger onClick={handleLogout}>
                  {formatMessage(msg.logout)}
                </SecondaryBtn>
              </BtnBar>
            </div>
          </Popup>
        )}
      </>
    );
  } else {
    return (
      <div className='twc-user--login'>
        <CtaBtn onClick={handleLogin} icon={{ element: <SvgEntry /> }}>
          {formatMessage(msg.login)}
        </CtaBtn>
      </div>
    );
  }
});
