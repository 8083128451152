import React from 'react';

export type SectionProps = {
  title?: string;
};

export const Section: React.FC<SectionProps> = React.memo(({ title, children }) => {
  return (
    <section className='sg-a-p-s sg-a-l-0 sg-u-vs-1 sg-a-mt-2'>
      {title && <h3 className='sg-u-type-h3 tw-a-mbs-3'>{title}</h3>}
      {children}
    </section>
  );
});
