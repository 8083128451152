import React from 'react';

import { useMessage } from '@tmapy/intl';
import { useLink } from '@tmapy/router';
import { useDispatch, useSelector } from '@tmapy/redux';
import type { VectorLayerState } from '@tmapy/config';
import { INTERACTION, LAYER_IDS, PANEL } from '@tmapy/config';
import { Notes } from '@tmapy/notes';
import { SvgPin } from '@tmapy/style-guide';

import type { PanelProps } from './types';
import { mapMsg } from './messages';
import { PanelHead } from './PanelHead';

export const NotesPanel: React.FC<PanelProps> = React.memo(({ onHideBtnClick }) => {
  const agendaName = useSelector((state) => state.app.appId);
  const isActive = useSelector((state) => state.uiManager.visiblePanel) === PANEL.NOTES;
  const formatMessage = useMessage();
  const dispatch = useDispatch();
  const interaction = useSelector((state) => state.mapCore.interaction);
  const notes = useSelector((state) => state.notes);
  const noteListLink = useLink('noteList', { agendaName });

  const noteFeatures = useSelector(
    (state) =>
      (state.mapCore.layers.find((layer) => layer.id === LAYER_IDS.NOTES) as VectorLayerState)
        .features,
  );
  const layerFeatures = useSelector((state) => {
    const selectedFeatureIds = state.mapCore.interaction.selectedFeatureIds;
    if (state.mapCore.interaction.type === INTERACTION.SELECT_MODIFY) {
      return (
        noteFeatures.filter((feature) => selectedFeatureIds.includes(feature?.properties?.id)) ?? []
      );
    } else {
      return (
        (
          state.mapCore.layers.find(
            (layer) => layer.id === LAYER_IDS.NOTES_MODIFY,
          ) as VectorLayerState
        )?.features ?? []
      );
    }
  });

  return (
    <div className='sg-a-d-f sg-a-fd-c sg-u-vs-2 sg-a-o-a'>
      <PanelHead
        title={formatMessage(mapMsg.notesTitle)}
        icon={<SvgPin />}
        onHideBtnClick={onHideBtnClick}
      />
      <div className='sg-a-px-2 sg-a-pb-2'>
        <Notes
          agendaName={agendaName}
          editedFeatures={layerFeatures}
          dispatch={dispatch}
          notes={notes}
          noteFeatures={noteFeatures}
          interaction={interaction}
          isActive={isActive}
          noteListLink={noteListLink}
        />
      </div>
    </div>
  );
});
