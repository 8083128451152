import type { ASTNode, FieldNode, OperationDefinitionNode } from 'graphql';

import { createStringFactory } from '../inputComponents/createStringFactory';

const getFirstFieldFromSelectionSet = (field: ASTNode) =>
  (field as FieldNode)?.selectionSet?.selections[0] as FieldNode;

export type Item = { value: string; label: string };
type TableData = { items: Item[]; totalCount: number };

export const getTableData = (data: any, selectQuery: OperationDefinitionNode): TableData => {
  const firstField = getFirstFieldFromSelectionSet(selectQuery);
  const firstFieldName = firstField?.name.value;
  if (!firstFieldName) {
    return { totalCount: 0, items: [] };
  }
  const edgesField = getFirstFieldFromSelectionSet(firstField);
  const nodeField = getFirstFieldFromSelectionSet(edgesField);
  const stringify = createStringFactory(nodeField);
  const stringifyHidden = createStringFactory(nodeField, true);

  return {
    totalCount: data?.[firstFieldName]?.totalCount ?? NaN,
    items:
      data?.[firstFieldName]?.edges?.map((edge: any) => ({
        value: stringifyHidden(edge.node),
        label: stringify(edge.node),
      })) ?? [],
  };
};

export const renderSelectItem = (item: Item) => item.label;
export const renderSelectValue = (item: Item) => item.label;
export const compareItems = (a: Item, b: Item) => a.value === b.value;
export const mergeItems = (a: Item[], b: Item[], compare = compareItems) => {
  const c = [...a]; // copy to avoid side effects
  // add all items from B to copy C if they're not already present
  b.forEach((bItem) => (c.some((cItem) => compare(bItem, cItem)) ? null : c.push(bItem)));
  return c;
};
