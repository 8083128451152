import type { DataComponent } from '../../types';

import { createTextInputComponent, DataConverter } from './createTextInputComponent';
import { BooleanInput } from './BooleanInput';
import { DateInput } from './DateInput';
import { DateRangeInput } from './DateRangeInput';
import { DateTimeRangeInput } from './DateTimeRangeInput';
import { EmailInput } from './EmailInput';
import { FileInput } from './FileInput';
import { URLInput } from './URLInput';

const parseArray = (array: unknown[]) => {
  try {
    return [...array];
  } catch (e) {
    throw new Error('It is not an array of items.');
  }
};

export const stringDataConverter: DataConverter<string, string> = {
  toText: (value) => (value === null || value === undefined ? '' : value.toString()),
  fromText: (value) => (value !== '' ? value : null),
};

const intDataConverter: DataConverter<number, string> = {
  toText: (value) => (value === null || value === undefined ? '' : value.toFixed(0)),
  fromText: (value) => (value !== '' ? parseInt(value, 10) : null),
};

const floatDataConverter: DataConverter<number, string> = {
  toText: (value) => (value === null || value === undefined ? '' : value.toString()),
  fromText: (value) => (value !== '' ? parseFloat(value) : null),
};

const jsonDataConverter: DataConverter<unknown, string> = {
  toText: (value) => (value === null || value === undefined ? '' : JSON.stringify(value, null, 2)),
  fromText: (value) => (value !== '' ? JSON.parse(value) : null),
};

const arrayJsonDataConverter: DataConverter<unknown[], string> = {
  toText: (value) => (value === null || value === undefined ? '' : JSON.stringify(value, null, 2)),
  fromText: (value) => (value !== '' ? parseArray(JSON.parse(value)) : null),
};

export const InputComponentMap: Record<string, DataComponent> = {
  FileInput,
  ID: createTextInputComponent('IDInput'),
  Int: createTextInputComponent('IntInput', intDataConverter, 'number'),
  Float: createTextInputComponent('FloatInput', floatDataConverter, 'number'),
  String: createTextInputComponent('StringInput', stringDataConverter),
  Password: createTextInputComponent('PasswordInput', stringDataConverter, 'password'),
  L10nString: createTextInputComponent('L10nStringInput'),
  MultilineString: createTextInputComponent(
    'MultilineStringInput',
    stringDataConverter,
    'textarea',
  ),
  JSON: createTextInputComponent('JSONInput', jsonDataConverter, 'code'),
  ArrayJSON: createTextInputComponent('JSONInput', arrayJsonDataConverter, 'code'),
  Boolean: BooleanInput,
  Email: EmailInput,
  URL: URLInput,
  Date: DateInput,
  DateRange: DateRangeInput,
  DateTimeRange: DateTimeRangeInput,
  EWKT: () => {
    return <>EWKT without @ewkt directive</>;
  },
};
