import {
  ASTNode,
  GraphQLSchema,
  OperationDefinitionNode,
  TypeInfo,
  visit,
  visitWithTypeInfo,
} from 'graphql';
import { isConnectionType } from '../utils';
import { getDirectives } from '../utils/getDirectives';

import { getDirectivesFromDescription } from '../utils/getDirectivesFromDescription';
import { getFieldArgumentNode } from '../utils/getFieldArgumentNode';
import { addFieldArgumentVariable } from '../utils/addFieldArgumentVariable';

export const addFilters = <T extends ASTNode>(schema: GraphQLSchema, documentAST: T): T => {
  const typeInfo = new TypeInfo(schema);
  let firstOperation: OperationDefinitionNode | null = null;
  return visit(
    documentAST,
    visitWithTypeInfo(typeInfo, {
      OperationDefinition(operation) {
        if (!firstOperation) {
          firstOperation = operation;
        } else {
          // Preskoci vsechny krom prvni operace v dokumentu
          // see https://graphql.org/graphql-js/language/#visit
          return false;
        }
      },
      Field(field) {
        const fieldType = typeInfo.getType();
        if (fieldType && isConnectionType(fieldType)) {
          const fieldDef = typeInfo.getFieldDef();
          if (fieldDef?.args) {
            for (const filterArgument of fieldDef.args) {
              if (!filterArgument.description) continue;

              const directiveDefinitions = getDirectivesFromDescription(filterArgument.description);
              const directives = getDirectives(directiveDefinitions);
              if (directives.group && !getFieldArgumentNode(field, filterArgument.name)) {
                const { newVariableDefinition } = addFieldArgumentVariable(
                  firstOperation!,
                  field,
                  filterArgument.name,
                  filterArgument.name,
                );

                (newVariableDefinition.directives as any) = directiveDefinitions;
              }
            }
          }
        }
        return field;
      },
    }),
  );
};
