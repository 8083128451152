import React from 'react';
import classNames from 'classnames';

import './TabBtn.scss';

export type TabBtnProps = {
  isActive: boolean;
  children: string;
  onClick?(e: React.MouseEvent): void;
};

export const TabBtn: React.FC<TabBtnProps> = ({ isActive, children, onClick }) => {
  return (
    <button
      className={classNames({
        'tw-tabBtn': true,
        'tw-tabBtn-isActive': isActive,
      })}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
