import { defineMessages } from 'react-intl.macro';

import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import throttle from 'lodash/throttle';

import Feature from 'ol/Feature';
import { Point, Polygon } from 'ol/geom';
import Geolocation from 'ol/Geolocation';

import type { MapViewState } from '@tmapy/config';
import { batch, Dispatch } from '@tmapy/redux';
import { SecondaryBtn, SvgLocation } from '@tmapy/style-guide';
import {
  actionSetCenter,
  actionSetRotation,
  actionSetLayerFeatures,
  featureToGeoJSONObject,
} from '@tmapy/mapcore';

import type { UserPositionMode } from '../types';

import { ResetRotation } from './ResetRotation';
import { actionToggleUserPositionMode } from '../actions';
import { Coordinate } from '@tmapy/types';

const ACTIVE_MODES: readonly UserPositionMode[] = [
  'LOCATION_AND_ORIENTATION_BY_DEVICE',
  'LOCATION_BY_DEVICE',
];

const RESET_ROTATON_MODES: readonly UserPositionMode[] = [
  'LOCATION_AND_ORIENTATION_BY_DEVICE',
  'LOCATION_AND_ORIENTATION_BY_USER',
  'LOCATION_AND_ORIENTATION_BY_USER_WITHOUT_DEVICE_LOCATION',
];

const msg = defineMessages({
  btnLabel: { id: 'sys.map.userLocation', defaultMessage: 'Moje poloha' },
});

const positionFeature = new Feature();
const accuracyFeature = new Feature();

export type UserLocationProps = {
  userPositionMode: UserPositionMode;
  layerId: string;
  projection: MapViewState['projection'];
  rotation: number;
  dispatch: Dispatch;
};

export const UserLocation: React.FC<UserLocationProps> = ({
  userPositionMode,
  layerId,
  projection,
  rotation,
  dispatch,
}) => {
  const intl = useIntl();
  //@ts-ignore
  const isWebKit = !!window.DeviceOrientationEvent.requestPermission;
  const [hasPermission, setPermission] = useState<boolean>(!isWebKit);

  const geolocation = useMemo(
    () =>
      new Geolocation({
        trackingOptions: {
          enableHighAccuracy: true,
        },
        projection,
      }),
    [projection],
  );

  const throttleSetRotation = throttle((rotation: number) => {
    dispatch(actionSetRotation(rotation));
  }, 500);

  const handleSetRotation = (e: DeviceOrientationEvent & { webkitCompassHeading?: number }) => {
    if (!e.alpha) {
      return setTimeout(() => {
        dispatch(actionToggleUserPositionMode());
      }, 500);
    }
    const degree = e.webkitCompassHeading ?? 360 - e.alpha;
    throttleSetRotation(((360 - degree) * Math.PI) / 180);
  };

  const throttleSetLocation = throttle((coordinates: Coordinate, accuracyGeometry: Polygon) => {
    positionFeature.setGeometry(new Point(coordinates));
    accuracyFeature.setGeometry(accuracyGeometry);
    batch(() => {
      dispatch(
        actionSetLayerFeatures(layerId, [
          featureToGeoJSONObject(positionFeature),
          featureToGeoJSONObject(accuracyFeature),
        ]),
      );

      dispatch(actionSetCenter(coordinates));
    });
  }, 500);

  const handleSetLocation = () => {
    const coordinates = geolocation.getPosition();
    const accuracyGeometry = geolocation.getAccuracyGeometry();
    if (coordinates && accuracyGeometry) {
      throttleSetLocation(coordinates, accuracyGeometry);
    }
  };

  const throttleGeolocationChange = throttle(
    (coordinates: Coordinate, accuracyGeometry: Polygon) => {
      positionFeature.setGeometry(new Point(coordinates));
      accuracyFeature.setGeometry(accuracyGeometry);

      dispatch(
        actionSetLayerFeatures(layerId, [
          featureToGeoJSONObject(positionFeature),
          featureToGeoJSONObject(accuracyFeature),
        ]),
      );
    },
    500,
  );

  const handleGeolocationChange = () => {
    const coordinates = geolocation.getPosition();
    const accuracyGeometry = geolocation.getAccuracyGeometry();
    if (coordinates && accuracyGeometry) {
      throttleGeolocationChange(coordinates, accuracyGeometry);
    }
  };

  const handleGeolocationError = (error: any) => {
    console.info('[UserLocation error]:', error.message);
  };

  const activateRotation = async () => {
    if (isWebKit) {
      if (!hasPermission) {
        return dispatch(actionToggleUserPositionMode());
      }
      window.addEventListener('deviceorientation', handleSetRotation);
    } else {
      window.addEventListener('deviceorientationabsolute', handleSetRotation);
    }
  };

  const deactivateRotation = () => {
    if (isWebKit) {
      window.removeEventListener('deviceorientation', handleSetRotation);
    } else {
      window.removeEventListener('deviceorientationabsolute', handleSetRotation);
    }
  };

  useEffect(() => {
    switch (userPositionMode) {
      case 'LOCATION_BY_DEVICE':
      case 'LOCATION_AND_ORIENTATION_BY_DEVICE': {
        geolocation.setTracking(true);
        handleGeolocationChange();
        geolocation.on('change', handleSetLocation);
        geolocation.on('error', handleGeolocationError);

        if (userPositionMode === 'LOCATION_AND_ORIENTATION_BY_DEVICE') {
          activateRotation();
        }

        return () => {
          geolocation.setTracking(false);
          geolocation.un('change', handleSetLocation);
          geolocation.un('error', handleGeolocationError);

          if (userPositionMode === 'LOCATION_AND_ORIENTATION_BY_DEVICE') {
            deactivateRotation();
          }

          dispatch(actionSetLayerFeatures(layerId, []));
        };
      }

      case 'LOCATION_BY_USER':
      case 'LOCATION_AND_ORIENTATION_BY_USER': {
        geolocation.setTracking(true);
        handleGeolocationChange();
        geolocation.on('change', handleGeolocationChange);
        geolocation.on('error', handleGeolocationError);

        if (userPositionMode === 'LOCATION_BY_USER') {
          dispatch(actionSetRotation(0));
        }

        return () => {
          geolocation.setTracking(false);
          geolocation.un('change', handleGeolocationChange);
          geolocation.un('error', handleGeolocationError);

          dispatch(actionSetLayerFeatures(layerId, []));
        };
      }

      case 'LOCATION_BY_USER_WITHOUT_DEVICE_LOCATION': {
        dispatch(actionSetRotation(0));
        return () => null;
      }

      case 'LOCATION_AND_ORIENTATION_BY_USER_WITHOUT_DEVICE_LOCATION': {
        return () => null;
      }
    }
  }, [userPositionMode]);

  const handleBtnClick = async () => {
    if (isWebKit) {
      //@ts-ignore
      setPermission(await window.DeviceOrientationEvent.requestPermission());
    }
    dispatch(actionToggleUserPositionMode());
  };

  const isActive = ACTIVE_MODES.includes(userPositionMode);
  if (RESET_ROTATON_MODES.includes(userPositionMode)) {
    return <ResetRotation rotation={rotation ?? 0} isActive={isActive} onClick={handleBtnClick} />;
  }
  return (
    <SecondaryBtn
      icon={{ element: <SvgLocation /> }}
      isActive={isActive}
      tooltip={intl.formatMessage(msg.btnLabel)}
      onClick={handleBtnClick}
    />
  );
};
