import React, { createContext, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { useMessage } from '@tmapy/intl';
import { CloseDialogBtn } from '@tmapy/style-guide';

import { msg } from '../messages';
import { DATA_LAYOUT_DIALOG_PORTAL_HTML_ID } from './DataLayout';

import './DataLayoutDialog.scss';

export const DATA_LAYOUT_DIALOG_HTML_ID_PREFIX = 'dataLayoutDialog';

type DataLayoutDialogContextValue = {
  isInsideDialog: boolean;
  dialogId: string;
};

export const DataLayoutDialogContext = createContext<DataLayoutDialogContextValue>({
  isInsideDialog: false,
  dialogId: DATA_LAYOUT_DIALOG_HTML_ID_PREFIX,
});

export type DataLayoutDialogProps = {
  children: React.ReactNode;
  dialogId: string;
};

export const DataLayoutDialog: React.FC<DataLayoutDialogProps> = ({ children, dialogId }) => {
  const formatMessage = useMessage();
  const dialogRef = useRef<HTMLDialogElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const context = {
    isInsideDialog: true,
    dialogId,
  };

  const observer = new MutationObserver((mutationList) => {
    for (const mutation of mutationList) {
      if (mutation.type === 'attributes' && mutation.attributeName === 'open') {
        setIsOpen(!isOpen);
      }
    }
  });

  const dialogPortalElement = window.document.getElementById(DATA_LAYOUT_DIALOG_PORTAL_HTML_ID);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.keyCode === 27) {
      // Esc
      handleDialogClose();
    }
  };

  const handleDialogClose = () => {
    (window.document.getElementById(dialogId) as HTMLDialogElement).close();
  };

  useEffect(() => {
    if (dialogRef.current) {
      observer.observe(dialogRef.current, { attributes: true });
    }
    return () => {
      observer.disconnect();
    };
  }, [dialogRef.current, observer]);

  return (
    dialogPortalElement &&
    createPortal(
      <dialog
        id={dialogId}
        ref={dialogRef}
        className='tw-dataLayoutDialog'
        onKeyDown={handleKeyDown}
      >
        {isOpen && (
          <div className='tw-dataLayoutDialog--inner'>
            <CloseDialogBtn tooltip={formatMessage(msg.close)} onClick={handleDialogClose} />
            <DataLayoutDialogContext.Provider value={context}>
              {children}
            </DataLayoutDialogContext.Provider>
          </div>
        )}
      </dialog>,
      dialogPortalElement,
    )
  );
};
