import { useLayoutEffect, useState, useCallback } from 'react';

export type SetLocalStorage = (value: string) => void;

export function useSashPositionLocalStorage(
  routeKey: string,
  defaultKey: string,
): [string | null, SetLocalStorage] {
  const getLocalStorageValue = () => {
    return window.localStorage.getItem(routeKey) ?? window.localStorage.getItem(defaultKey);
  };

  const [value, setValue] = useState(getLocalStorageValue);

  const set: SetLocalStorage = useCallback(
    (newValue) => {
      setValue(newValue);
      window.localStorage.setItem(routeKey, newValue);
      window.localStorage.setItem(defaultKey, newValue);
    },
    [routeKey, defaultKey],
  );

  // Use `useLayoutEffect` instead of `useEffect` has two effects:
  // 1. "SplitLayout" component does not flash between DEFAULT sash position and ROUTE sash position
  // 2. Map in "SplitLayout" component does not twitch when route change
  useLayoutEffect(() => {
    setValue(getLocalStorageValue());
  }, [routeKey, defaultKey]);

  return [value, set];
}
