import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useMessage } from '@tmapy/intl';
import { useLink, useLocation } from '@tmapy/router';
import { SvgCross, TertiaryLink } from '@tmapy/style-guide';

import { msg } from '../messages';

import './DataLayout.scss';

export const DATA_LAYOUT_DIALOG_PORTAL_HTML_ID = 'dataLayoutDialogPortal';

export type DataLayoutProps = {
  closeRouteId?: string;
  onExpandBtnClick?(): void;
  children: React.ReactNode;
};

export const DataLayout: React.FC<DataLayoutProps> = ({
  closeRouteId,
  onExpandBtnClick,
  children,
}) => {
  const location = useLocation();
  const formatMessage = useMessage();
  const closeLink = useLink(closeRouteId ?? '', location.params, undefined, 'push', true);
  const dialogPortalRef = useRef<HTMLDivElement>(null);
  const [isDialogOpen, setDialogOpen] = useState(false);

  const observer = useMemo(() => {
    const callback: MutationCallback = (mutationList) => {
      for (const mutation of mutationList) {
        if (mutation.type === 'attributes') {
          setDialogOpen(!isDialogOpen);
        }
      }
    };
    return new MutationObserver(callback);
  }, [isDialogOpen]);

  useEffect(() => {
    if (dialogPortalRef.current) {
      observer.observe(dialogPortalRef.current, {
        attributes: true,
        subtree: true,
        attributeFilter: ['open'],
      });
    }
    return () => {
      observer.disconnect();
    };
  }, [observer]);

  return (
    <div className='tw-dataLayout'>
      <div
        className='tw-dataLayout--content'
        // @ts-ignore
        inert={isDialogOpen ? 'true' : undefined}
      >
        {closeRouteId && (
          <div className='tw-dataLayout--closeBtn'>
            <div className='tw-dataLayout--closeBtnInner'>
              <TertiaryLink
                isDanger
                icon={{ element: <SvgCross /> }}
                tooltip={formatMessage(msg.close)}
                {...closeLink}
              />
            </div>
          </div>
        )}
        {children}
      </div>
      <div id={DATA_LAYOUT_DIALOG_PORTAL_HTML_ID} className='tw-dataLayout--dialog' />
      {onExpandBtnClick && (
        <button className='tw-dataLayout--expandBtn' type='button' onClick={onExpandBtnClick} />
      )}
    </div>
  );
};
