import { defineMessages } from 'react-intl.macro';

export const msg = defineMessages({
  waiting: { id: 'sys.alert.waiting', defaultMessage: 'Čekejte prosím...' },
  success: { id: 'sys.alert.success', defaultMessage: 'Záznam uložen.' },
  dataError: { id: 'sys.alert.dataError', defaultMessage: 'Chyba dat' },
  moreItems: {
    id: 'sys.alert.moreItems',
    defaultMessage: 'Seznam obsahuje {totalCount} položek, použijte filtr pro upřesnění.',
  },
  noItems: {
    id: 'sys.alert.noItems',
    defaultMessage: 'Seznam neobsahuje žádné položky.',
  },
  loginRequired: {
    id: 'sys.alert.loginRequired',
    defaultMessage: 'Pro práci v aplikaci se prosím přihlašte.',
  },
  accessDenied: {
    id: 'sys.alert.accessDenied',
    defaultMessage: 'Nemáte oprávnění pro zobrazení.',
  },
  actionConfirm: {
    id: 'sys.alert.confirm',
    defaultMessage: 'Opravdu si přejete provést akci?',
  },
  close: { id: 'sys.button.close', defaultMessage: 'Zavřít' },
  formDestroy: { id: 'sys.button.destroy', defaultMessage: 'Zničit' },
  formRun: { id: 'sys.button.run', defaultMessage: 'Spustit' },
  formAdd: { id: 'sys.button.add', defaultMessage: 'Přidat' },
  formUpdate: { id: 'sys.button.save', defaultMessage: 'Uložit' },
  formDelete: { id: 'sys.button.delete', defaultMessage: 'Smazat' },
  formCancel: { id: 'sys.button.cancel', defaultMessage: 'Zrušit' },
  formHistory: { id: 'sys.button.showHistory', defaultMessage: 'Zobrazit historii' },
  formCopy: { id: 'sys.button.copy', defaultMessage: 'Vytvořit kopii' },
  formRepeat: { id: 'sys.button.repeat', defaultMessage: 'Zkusit zopakovat' },
  filterSubmit: { id: 'sys.button.filter', defaultMessage: 'Filtrovat' },
  filterClear: { id: 'sys.button.clearFilter', defaultMessage: 'Smazat filtr' },
  formItemHelp: { id: 'sys.form.item.help', defaultMessage: 'Zobrazit nápovědu' },
  formItemRequired: { id: 'sys.form.item.required', defaultMessage: 'Povinná položka' },
  historyTableCurrentRecord: {
    id: 'sys.button.showCurrentRecord',
    defaultMessage: 'Zobrazit aktuální stav',
  },
  historyWho: { id: 'sys.history.attr.who', defaultMessage: 'Kdo' },
  historyWhen: { id: 'sys.history.attr.when', defaultMessage: 'Kdy' },
  historyAttribute: { id: 'sys.history.attr.attribute', defaultMessage: 'Změna v atributu' },
  historyNewValue: { id: 'sys.history.attr.newValue', defaultMessage: 'Nová hodnota' },
  historyOldValue: { id: 'sys.history.attr.oldValue', defaultMessage: 'Původní hodnota' },
  sendEmail: { id: 'sys.button.sendEmail', defaultMessage: 'Poslat e-mail\n{email}' },
  openUrl: { id: 'sys.button.openUrl', defaultMessage: `Otevřít odkaz do nové záložky\n{url}` },
  showInMap: { id: 'sys.button.showInMap', defaultMessage: 'Zobrazit v mapě' },
  showPassword: { id: 'sys.button.showPassword', defaultMessage: 'Odkrýt heslo' },
  hidePassword: { id: 'sys.button.hidePassword', defaultMessage: 'Skrýt heslo' },
  from: { id: 'sys.attr.from', defaultMessage: 'od' },
  to: { id: 'sys.attr.to', defaultMessage: 'do' },
  valueTrue: { id: 'sys.value.true', defaultMessage: 'Ano' },
  valueFalse: { id: 'sys.value.false', defaultMessage: 'Ne' },
  filterChipsRemove: { id: 'sys.table.chips.removeFilter', defaultMessage: 'Zrušit filtr' },
  filterChipsRemoveAll: { id: 'sys.table.chips.removeAll', defaultMessage: 'Zrušit vše' },
  geometryFilterValue: {
    id: 'sys.table.chips.geometryFilter',
    defaultMessage: 'Geometrický filtr',
  },
  showError: { id: 'sys.table.error.showError', defaultMessage: 'Zobrazit chybu' },
  hideError: { id: 'sys.table.error.hideError', defaultMessage: 'Skrýt chybu' },
});
