import type { GraphQLArgument, ObjectFieldNode } from 'graphql';
import { Kind, getNamedType, isScalarType } from 'graphql';

import { createNameNode } from './createNameNode';

export const createObjectFieldNode = (argument: GraphQLArgument): ObjectFieldNode => {
  const type = getNamedType(argument.type);
  if (!isScalarType(type)) {
    console.debug('Creation error ObjectFieldDefinition.', type);

    return {
      kind: Kind.OBJECT_FIELD,
      name: createNameNode(argument.name),
      value: {
        kind: Kind.VARIABLE,
        name: createNameNode(argument.name),
      },
    };
  }
  return {
    kind: Kind.OBJECT_FIELD,
    name: createNameNode(argument.name),
    value: {
      kind: Kind.VARIABLE,
      name: createNameNode(argument.name),
    },
  };
};
