import { DocumentNode, GraphQLSchema } from 'graphql';

import type { DataComponent } from '../types';

import { createSelectionSetComponent } from './createSelectionSetComponent';
import { createNewFormComponent } from './detailComponents/createNewFormComponent';
import { createQueryComponent } from './createQueryComponent';

/**
 * Create Component for entire page
 */
export function createPageComponent(document: DocumentNode, schema: GraphQLSchema): DataComponent {
  const firstDefinition = document.definitions[0];
  if (!firstDefinition) return () => null;

  switch (firstDefinition.kind) {
    case 'OperationDefinition': {
      switch (firstDefinition.operation) {
        case 'query': {
          return createSelectionSetComponent(
            firstDefinition.selectionSet.selections,
            schema.getQueryType()!.getFields(),
            document,
            schema,
            null,
            createQueryComponent,
          );
        }
        case 'mutation': {
          return createNewFormComponent(
            firstDefinition,
            schema.getMutationType()!.getFields(),
            document,
            schema,
          );
        }
        default: {
          console.error('UNKNOWN OPERATION');
          return () => null;
        }
      }
    }
    default: {
      return () => null;
    }
  }
}
