import { Icon, SIZE, SvgSpinner, useLoadingIndicatorDelay } from '@tmapy/style-guide';

import './ReadOnlyFormField.scss';

type ReadOnlyFormFieldProps = {
  isLoading?: boolean;
};

export const ReadOnlyFormField: React.FC<ReadOnlyFormFieldProps> = ({ isLoading, children }) => {
  const showSpinner = useLoadingIndicatorDelay();
  return (
    <div className='tw-readOnlyFormField'>
      {isLoading ? (
        showSpinner ? (
          <Icon element={<SvgSpinner />} size={SIZE.SML} />
        ) : null
      ) : (
        children
      )}
    </div>
  );
};
