import { useCallback } from 'react';

import { DatePicker } from '@tmapy/style-guide';

import type { DataComponent } from '../../types';
import { ReadOnlyFormField } from '../../components/ReadOnlyFormField';
import { DateRangeValue } from '../../utils/DateRangeValue';

import { InlineViewComponentMap } from './InlineViewComponentMap';

export const DateRangeInput: DataComponent = ({
  data,
  onChange,
  validate,
  isRequired,
  isReadOnly,
  loading,
  errors,
  path,
}) => {
  const handleAfterChange = useCallback(
    (
      _,
      eventData: {
        name?: string;
        value: Date | null;
      },
    ) => {
      let after;
      if (eventData.value) {
        try {
          const midday = new Date(eventData.value.getTime());
          midday.setHours(12);
          after = midday.toISOString().substring(0, 10);
        } catch (e) {
          /* empty */
        }
      }
      const veryNewData = new DateRangeValue({
        ...data,
        after,
      });
      onChange?.(veryNewData);
    },
    [data, onChange],
  );

  const handleBeforeChange = useCallback(
    (
      _,
      eventData: {
        name?: string;
        value: Date | null;
      },
    ) => {
      let before;
      if (eventData.value) {
        try {
          const midday = new Date(eventData.value.getTime());
          midday.setHours(12);
          before = midday.toISOString().substring(0, 10);
        } catch (e) {
          /* empty */
        }
      }
      const veryNewData = new DateRangeValue({
        ...data,
        before,
      });
      onChange?.(veryNewData);
    },
    [data, onChange],
  );

  if (isReadOnly) {
    return (
      <ReadOnlyFormField>
        <InlineViewComponentMap.DateRange
          loading={loading}
          data={data}
          errors={errors}
          path={path}
          variables={null}
          isReadOnly
          isRequired={isRequired}
        />
      </ReadOnlyFormField>
    );
  }

  return (
    <div className='sg-a-d-f sg-a-g-1'>
      <DatePicker
        value={data ? new Date(data?.after) : null}
        minDay={validate?.min ? new Date(validate.min) : undefined}
        maxDay={validate?.max ? new Date(validate.max) : undefined}
        isInvalid={!!errors.length}
        isDisabled={loading}
        onChange={handleAfterChange}
      />
      <DatePicker
        value={data ? new Date(data?.before) : null}
        minDay={validate?.min ? new Date(validate.min) : undefined}
        maxDay={validate?.max ? new Date(validate.max) : undefined}
        isInvalid={!!errors.length}
        isDisabled={loading}
        onChange={handleBeforeChange}
      />
    </div>
  );
};
