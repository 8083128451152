import { DocumentNode, TypeInfo, visit, visitWithTypeInfo, BREAK, getNamedType } from 'graphql';
import type { ExtendedApolloClient } from '../createClient';

export const processTypeMappings = (
  client: ExtendedApolloClient,
  clientDocument: DocumentNode,
  variables: Record<string, any>,
) => {
  if (!variables['_id'] || variables['id']) return variables;

  const result = { ...variables };
  const typeInfo = new TypeInfo(client.clientSchema);
  visit(
    clientDocument,
    visitWithTypeInfo(typeInfo, {
      Field() {
        const typeName = getNamedType(typeInfo.getType())?.name;
        if (typeName) {
          const prefix = client.typePrefixMappings[typeName];

          if (prefix) {
            result['id'] = `${prefix}${result['_id']}`;
          }
        }
        return BREAK;
      },
    }),
  );
  return result;
};
