import type { ArgumentNode, GraphQLArgument, ObjectFieldNode } from 'graphql';
import { Kind, getNamedType, isInputObjectType } from 'graphql';

import { createNameNode } from './createNameNode';
import { createObjectFieldNode } from './createObjectFieldNode';

export const createArgumentNode = (argument: GraphQLArgument): ArgumentNode => {
  const type = getNamedType(argument.type);

  if (isInputObjectType(type)) {
    const fields: ObjectFieldNode[] = [];
    const fieldTypes = type.getFields();
    for (const graphqlTypeFieldName in fieldTypes) {
      const fieldDefinition = createObjectFieldNode(fieldTypes[graphqlTypeFieldName]);
      fields.push(fieldDefinition);
    }
    return {
      kind: Kind.ARGUMENT,
      name: createNameNode(argument.name),
      value: {
        kind: Kind.OBJECT,
        fields,
      },
    };
  }

  return {
    kind: Kind.ARGUMENT,
    name: createNameNode(argument.name),
    value: {
      kind: Kind.VARIABLE,
      name: createNameNode(argument.name),
    },
  };
};
