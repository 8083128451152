import React from 'react';
import { useDispatch, useSelector } from '@tmapy/redux';

import type { MapSwitcherActions } from '@tmapy/map-switcher';
import { MapSwitcher } from '@tmapy/map-switcher';

type Dispatch = (action: MapSwitcherActions) => void;

export const MapSwitcherWithStore: React.FC = () => {
  const dispatch: Dispatch = useDispatch();

  const mapSwitcher = useSelector((state) => state.mapSwitcher);
  const layers = useSelector((state) => state.mapCore.layers);
  const appId = useSelector((state) => state.app.appId);

  return (
    <MapSwitcher appId={appId} mapSwitcher={mapSwitcher} layers={layers} dispatch={dispatch} />
  );
};
