import classNames from 'classnames';

import './LoadingIndicator.scss';

import { SvgSpinner, Icon, SIZE } from '@tmapy/style-guide';

type LoadingIndicatorProps = {
  isLoading: boolean;
};

export const LoadingIndicator: React.FC<LoadingIndicatorProps> = (props: LoadingIndicatorProps) => {
  return (
    <span
      className={classNames({
        'tw-loadingIndicator': true,
        'tw-loadingIndicator-isLoading': props.isLoading,
      })}
    >
      <Icon element={<SvgSpinner />} size={SIZE.SML} />
    </span>
  );
};
