import type { ThunkAction } from '@tmapy/redux';

export const TOOGLE_USER_POSITION_MODE = '@user-position/TOOGLE_USER_POSITION_MODE';

export type ActionToggleUserPositionMode = {
  readonly type: typeof TOOGLE_USER_POSITION_MODE;
  readonly payload: { readonly rotation: number };
};

export type UserPositionActions = ActionToggleUserPositionMode;

export const actionToggleUserPositionMode =
  (): ThunkAction<Promise<ActionToggleUserPositionMode>> => async (dispatch, getState) => {
    const state = getState();
    const rotation = state.mapCore.view.rotation;
    return dispatch({
      type: TOOGLE_USER_POSITION_MODE,
      payload: { rotation },
    } as const);
  };
