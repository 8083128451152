import type { DocumentNode, GraphQLSchema } from 'graphql';

import { HasPermission } from '@tmapy/config';

import { addPagination } from './addPagination';
import { addFilters } from './addFilters';
import { fragmentsToInlineFragments } from './fragmentsToInlineFragments';
import { replaceAutoTypes } from './replaceAutoTypes';
import { mergeFragmentsFromDirective } from './mergeFragmentsFromDirective';
import { scrubDocument } from './scrubDocument';

/**
 * Filter fields by ACL
 */
export function prepareClientDocument(
  documentNode: DocumentNode,
  clientSchema: GraphQLSchema,
  hasPermission: HasPermission,
): DocumentNode {
  const withoutFragments = fragmentsToInlineFragments(documentNode);
  const mergeFragmets = mergeFragmentsFromDirective(clientSchema, withoutFragments);
  const withFilters = addFilters(clientSchema, mergeFragmets);
  const withPagination = addPagination(clientSchema, withFilters);
  const visitedDocument = scrubDocument(withPagination, clientSchema, hasPermission);
  return replaceAutoTypes(clientSchema, visitedDocument);
}
