import { InfoState } from '@tmapy/config';

import type { InfoActions } from '../actions';
import { getInitialState } from './initialState';
import { SET_INFO_SERVICE_DATA, SET_INFO_STATE } from '../constants';

export const info = (state: InfoState = getInitialState(), action: InfoActions): InfoState => {
  switch (action.type) {
    case SET_INFO_STATE: {
      return {
        ...action.payload,
      };
    }
    case SET_INFO_SERVICE_DATA: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.serviceId]: {
            ...state.data[action.payload.serviceId],
            ...action.payload.data,
          },
        },
      };
    }
    default: {
      return state;
    }
  }
};
