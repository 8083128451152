import { FormattedMessage } from 'react-intl';

import { BtnBar, Form, CtaBtn, SecondaryBtn, StickyFooter } from '@tmapy/style-guide';

import { msg } from '../messages';

type TableFilterProps = {
  onSubmit: React.FormEventHandler;
  onClear: React.FormEventHandler;
};

export const TableFilter: React.FC<TableFilterProps> = ({ onSubmit, onClear, children }) => {
  return (
    <Form onSubmit={onSubmit}>
      {children}
      <StickyFooter>
        <BtnBar stretch>
          <CtaBtn type='submit' testId='tw-tableView--submitFilter'>
            <FormattedMessage {...msg.filterSubmit} />
          </CtaBtn>
          <SecondaryBtn onClick={onClear} isDanger testId='tw-tableView--clearFilter'>
            <FormattedMessage {...msg.filterClear} />
          </SecondaryBtn>
        </BtnBar>
      </StickyFooter>
    </Form>
  );
};
