import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { SecondaryBtn, SIZE } from '@tmapy/style-guide';

import type { DataComponent } from '../../types';
import { ReadOnlyFormField } from '../../components/ReadOnlyFormField';
import { msg } from '../../messages';

import { InlineViewComponentMap } from './InlineViewComponentMap';

export const BooleanInput: DataComponent = ({
  data,
  onChange,
  isReadOnly,
  isRequired,
  loading,
  errors,
  path,
}) => {
  const testId = path.length ? `tw-booleanInput--${path.join('-')}` : undefined;

  const handleTrueClick = useCallback(() => {
    const newValue = data === true && !isRequired ? null : true;
    onChange?.(newValue);
  }, [data, onChange]);

  const handleFalseClick = useCallback(() => {
    const newValue = data === false && !isRequired ? null : false;
    onChange?.(newValue);
  }, [data, onChange]);

  if (isReadOnly) {
    return (
      <ReadOnlyFormField>
        <InlineViewComponentMap.Boolean
          loading={loading}
          data={data}
          errors={errors}
          path={path}
          variables={null}
          isReadOnly
          isRequired={isRequired}
        />
      </ReadOnlyFormField>
    );
  }

  return (
    <div className='sg-a-d-f sg-a-cg-1/2'>
      <SecondaryBtn
        isActive={data === true}
        size={SIZE.SML}
        onClick={handleTrueClick}
        isDisabled={loading}
        testId={testId && `${testId}--true`}
      >
        <FormattedMessage {...msg.valueTrue} />
      </SecondaryBtn>
      <SecondaryBtn
        isActive={data === false}
        size={SIZE.SML}
        onClick={handleFalseClick}
        isDisabled={loading}
        testId={testId && `${testId}--false`}
      >
        <FormattedMessage {...msg.valueFalse} />
      </SecondaryBtn>
    </div>
  );
};
