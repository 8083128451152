import {
  getHeightPaddings,
  getHeightPanel,
  getWidthPaddings,
  getWidthPanel,
  isMobileDevice,
} from './getSizePanel';

export const getMapPadding = (): [number, number, number, number] => {
  const widthButton = 48;
  const widthPanel = getWidthPanel();
  const heightPanel = getHeightPanel();
  const widthPadding = getWidthPaddings() / 2;
  const heightPaddings = getHeightPaddings();
  const heightHeader = widthPadding;
  const heightFooter = heightPaddings - heightHeader;

  return isMobileDevice()
    ? [
        heightHeader * 2 + heightPanel,
        widthPadding * 2 + widthButton,
        heightFooter + 32 + widthButton + heightHeader,
        widthPadding,
      ]
    : [
        heightHeader * 2 + widthButton,
        widthPadding * 2 + widthButton,
        heightFooter + 2 * heightHeader,
        widthPadding * 2 + widthPanel,
      ];
};
