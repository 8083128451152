import { defineMessages } from 'react-intl.macro';

import React from 'react';
import { useIntl } from 'react-intl';

import { useDispatch, useSelector } from '@tmapy/redux';
import { MAP_TOOL } from '@tmapy/config';
import {
  ReverseLineToolBtn,
  EditToolBtn,
  JoinLineToolBtn,
  IntersectionSplitLineToolBtn,
  SplitLineToolBtn,
  DeleteToolBtn,
} from '@tmapy/edit-tools';
import { MapTool } from './MapTool';

const msg = defineMessages({
  editToolBtn: { id: 'sys.map.editTool.select', defaultMessage: 'Vybrat geometrii' },
  reverseLineToolBtn: { id: 'sys.map.editTool.reverseLine', defaultMessage: 'Změnit směr linie' },
  joinLineToolBtn: { id: 'sys.map.editTool.joinLine', defaultMessage: 'Propojit linie' },
  intersectionSplitLineToolBtn: {
    id: 'sys.map.editTool.intersectionSplitLine',
    defaultMessage: 'Rozdělit linie v průsečíku',
  },
  splitLineToolBtn: { id: 'sys.map.editTool.splitLine', defaultMessage: 'Rozdělit linii' },
  deleteToolBtn: { id: 'sys.map.editTool.delete', defaultMessage: 'Smazat geometrii' },
});

export const EditTools: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const uiManager = useSelector((state) => state.uiManager);

  return (
    <MapTool>
      <div className='sg-a-d-f sg-a-g-1/2'>
        <EditToolBtn isActive={uiManager.activeMapTool === MAP_TOOL.INFOEDIT} dispatch={dispatch}>
          {intl.formatMessage(msg.editToolBtn)}
        </EditToolBtn>
        <ReverseLineToolBtn
          isActive={uiManager.activeMapTool === MAP_TOOL.REVERSE_LINE}
          dispatch={dispatch}
        >
          {intl.formatMessage(msg.reverseLineToolBtn)}
        </ReverseLineToolBtn>
        <JoinLineToolBtn
          isActive={uiManager.activeMapTool === MAP_TOOL.JOIN_LINE}
          dispatch={dispatch}
        >
          {intl.formatMessage(msg.joinLineToolBtn)}
        </JoinLineToolBtn>
        <IntersectionSplitLineToolBtn
          isActive={uiManager.activeMapTool === MAP_TOOL.INTERSECTION_SPLIT_LINE}
          dispatch={dispatch}
        >
          {intl.formatMessage(msg.intersectionSplitLineToolBtn)}
        </IntersectionSplitLineToolBtn>
        <SplitLineToolBtn
          isActive={uiManager.activeMapTool === MAP_TOOL.SPLIT_LINE}
          dispatch={dispatch}
        >
          {intl.formatMessage(msg.splitLineToolBtn)}
        </SplitLineToolBtn>
        <DeleteToolBtn isActive={uiManager.activeMapTool === MAP_TOOL.DELETE} dispatch={dispatch}>
          {intl.formatMessage(msg.deleteToolBtn)}
        </DeleteToolBtn>
      </div>
    </MapTool>
  );
};
