import type { NavItem, FeatureUrlMapping } from '@tmapy/config';
import type { OtherLink, RouteStateTWC, LocalizedPath } from '@tmapy/types';

import { applyInheritanceOfRoutes } from '../utils/applyInheritanceOfRoutes';

import {
  SET_APP_STATE,
  APP_POPUP_ADD,
  APP_POPUP_DISMISS_ALL,
  APP_POPUP_UPDATE,
  APP_SET_FILTER_GROUP_VISIBILITY,
  APP_SET_PACKAGE_VERSION,
} from '../constants';
import type { ActionSetAppState } from './actions';
import { HeaderToolProps } from './HeaderTool';

export type PopupState = {
  id: string;
  /** konfigurace zda zobrazit checkbox "Priste nezobrazovat" */
  rememberNotShow: boolean;
  /** state checkboxu "Priste nezobrazovat" */
  notShowAgain: boolean;
};

export type _AppState = {
  appId: string;
  basename: LocalizedPath;
  graphql?: string;
  isEditor: boolean;
  packageId?: string;
  packageVersion?: string;
  menu: NavItem[] | null;
  routes: RouteStateTWC[];
  useAccessToken: boolean;
  featureRouteMappings: FeatureUrlMapping[];
  otherLinks: OtherLink[];
  homeLink?: string;
  headerTools: HeaderToolProps[];
  defaultRouteId: string;
  mapRouteId?: string;
  popups: PopupState[];
  isSyncEnabled: boolean;
  isExportMapEnabled: boolean;
  isMeasureEnabled: boolean;
  isNotesEnabled: boolean;
  isEditToolsEnabled: boolean;
  isUserLocationEnabled: boolean;
  filtersVisibility: Record<string, Record<string, boolean>>;
};

const appState: _AppState = {
  appId: '',
  basename: {},
  graphql: null as any,
  isEditor: false,
  menu: [],
  routes: [],
  useAccessToken: false,
  featureRouteMappings: [],
  otherLinks: [],
  homeLink: '',
  headerTools: [],
  defaultRouteId: '',
  mapRouteId: '',
  popups: [],
  isSyncEnabled: false,
  isExportMapEnabled: false,
  isMeasureEnabled: false,
  isNotesEnabled: false,
  isEditToolsEnabled: false,
  isUserLocationEnabled: false,
  filtersVisibility: {},
};

declare module '@tmapy/redux' {
  interface AppState {
    app: _AppState;
  }
}

export const app = (state = appState, action: ActionSetAppState): typeof appState => {
  switch (action.type) {
    case SET_APP_STATE: {
      return {
        ...state,
        ...action.payload,
        routes: applyInheritanceOfRoutes(action.payload.routes),
      };
    }

    case APP_POPUP_ADD: {
      return {
        ...state,
        popups: [...state.popups, ...action.payload],
      };
    }

    case APP_POPUP_DISMISS_ALL: {
      return {
        ...state,
        popups: [],
      };
    }

    case APP_POPUP_UPDATE: {
      return {
        ...state,
        popups: [...state.popups].map((popup) =>
          action.payload.id === popup.id ? { ...popup, ...action.payload } : popup,
        ),
      };
    }

    case APP_SET_PACKAGE_VERSION: {
      return {
        ...state,
        packageVersion: action.payload,
      };
    }

    case APP_SET_FILTER_GROUP_VISIBILITY: {
      const { queryName, groupId, visible } = action.payload;
      return {
        ...state,
        filtersVisibility: {
          ...state.filtersVisibility,
          [queryName]: {
            ...state.filtersVisibility?.[queryName],
            [groupId]: visible,
          },
        },
      };
    }

    default: {
      return state;
    }
  }
};
