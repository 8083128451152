import React, { useEffect, useState } from 'react';

import { Icon, SIZE } from '@tmapy/style-guide';

type FetchedIconProps = {
  name: string;
};

const ICON_CACHE: Record<string, string | Promise<string>> = {};

const getSvg = (name: string) => {
  if (!ICON_CACHE[name]) {
    const url = `${process.env.PUBLIC_URL}img/icons/${name}.svg`;
    ICON_CACHE[name] = new Promise((setSvg: (value: string) => void) => {
      fetch(url)
        .then((response: Response) => {
          return response.text().then((text) => {
            ICON_CACHE[name] = text;
            setSvg(text);
          });
        })
        .catch(() => {
          console.warn(`Icon "${name}" is missing.`);
        });
    });
  }
  return ICON_CACHE[name];
};

const RE_NAME_SVG = /^[a-z-]+$/;

export const FetchedIcon: React.FC<FetchedIconProps> = ({ name }) => {
  const [svg, setSvg] = useState('');

  useEffect(() => {
    if (RE_NAME_SVG.test(name)) {
      const svg = getSvg(name);
      typeof svg === 'string' ? setSvg(svg) : svg.then(setSvg);
    }
  }, [name]);

  return <Icon element={<span dangerouslySetInnerHTML={{ __html: svg }} />} size={SIZE.MED} />;
};
