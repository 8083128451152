import { defineMessages } from 'react-intl.macro';

import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';

import { useMessage } from '@tmapy/intl';
import { AppState, useSelector } from '@tmapy/redux';
import { useLink, useLocation } from '@tmapy/router';
import { useLocalStorage } from '@tmapy/utils';
import { useOLMap } from '@tmapy/mapcore';

import '@tmapy/style-guide/dist/sass/_global.scss';
import './style/variables.scss';
import './style/atomics.scss';
import './Layout.scss';

import {
  SvgCaretFastLeft,
  SvgShare,
  SvgLink,
  SvgMenu2,
  TertiaryBtn,
  SvgHome,
  TertiaryLink,
} from '@tmapy/style-guide';

import { Sync } from './sync/Sync';
import { VERSION } from '../constants';
import { User } from './User';
import { NavItem } from './NavItem';
import { HeaderTool } from './HeaderTool';
import { HeaderTools } from './HeaderTools';

const msg = defineMessages({
  collapseMenu: {
    id: 'sys.menu.button.collapse',
    defaultMessage: 'Zmenšit postranní panel',
  },
  expandMenu: {
    id: 'sys.menu.button.expand',
    defaultMessage: 'Zvětšit postranní panel',
  },
  openMenu: {
    id: 'sys.menu.button.open',
    defaultMessage: 'Otevřít postranní panel',
  },
  share: {
    id: 'sys.tool.share',
    defaultMessage: 'Sdílet aplikaci',
  },
  copyUrl: {
    id: 'sys.tool.copyUrl',
    defaultMessage: 'Kopírovat URL aplikace do schránky',
  },
  homeLink: {
    id: 'sys.tool.homeLink',
    defaultMessage: 'Domovská stránka',
  },
  copyUrlAlert: {
    id: 'sys.tool.copyUrl.alert',
    defaultMessage:
      'URL aplikace byla uložena do schránky. Pro vyjmutí ze schránky stisknete klávesy Ctrl + V nebo ⌘ + V.',
  },
});

export const Layout: React.FC = ({ children }) => {
  const appId = useSelector((state) => state.app.appId);
  const packageVersion = useSelector((state: AppState) => state.app.packageVersion);
  const lang = useSelector((state) => state.intl.currentLocale);
  const headerTools = useSelector((state) => state.app.headerTools);
  const defaultRouteId = useSelector((state) => state.app.defaultRouteId);
  const useAccessToken = useSelector((state) => state.app.useAccessToken);
  const homeLink = useSelector((state) => state.app.homeLink);
  const otherLinks = useSelector((state) => state.app.otherLinks);
  const routeId = useSelector((state) => state.router.route?.id);
  const formatMessage = useMessage();
  const title = formatMessage.fallback(['app.title'], { appId: appId || 'empty' }) ?? appId;
  const description = formatMessage.fallback(['app.description']);
  const location = useLocation();
  const menu = useSelector((state) => state.app.menu);
  const [isMenuCollapsed, setMenuCollapsed] = useLocalStorage(`${appId}.Menu.isCollapsed`);
  const [isMenuOpened, setMenuOpened] = useState(false);
  const olMap = useOLMap();

  useEffect(() => {
    document.title = title;
    document.documentElement.lang = lang;

    if (!description) return;
    const meta = document.createElement('meta');
    meta.setAttribute('name', 'description');
    meta.setAttribute('content', description);
    document.head.appendChild(meta);
    return () => {
      document.head.removeChild(meta);
    };
  }, [title, description, lang]);

  useEffect(() => {
    const meta = document.createElement('meta');
    meta.setAttribute('name', 'theme-color');
    meta.setAttribute('content', '#36464e');
    document.head.appendChild(meta);
    return () => {
      document.head.removeChild(meta);
    };
  }, []);

  const homepageLink = useLink(defaultRouteId, location.params, undefined, 'push', true);

  const handleHomepageLinkClick = useCallback(
    (event: React.MouseEvent) => {
      if (!homepageLink.href) {
        event.stopPropagation();
        return;
      }
      window.location.href = homepageLink.href;
    },
    [homepageLink],
  );

  const handleCollapseBtnClick = useCallback(() => {
    setMenuCollapsed(!isMenuCollapsed ? 'true' : null);
  }, [isMenuCollapsed, setMenuCollapsed]);

  const handleMenuToggle = useCallback(() => {
    setMenuOpened(!isMenuOpened);
  }, [isMenuOpened]);

  const handleShareBtnClick = useCallback(() => {
    navigator.share({
      title: title,
      text: description,
      url: window.location.href,
    });
  }, [title, description]);

  const handleCopyUrlBtnClick = useCallback(async () => {
    await navigator.clipboard.writeText(window.location.href);
    alert(formatMessage(msg.copyUrlAlert));
  }, [formatMessage]);

  useEffect(() => {
    olMap.updateSize();
  }, [isMenuCollapsed]);

  const version = packageVersion ? `${packageVersion} (client: ${VERSION})` : `v${VERSION}`;
  const titleAndVersion = `${title}\n${version}`;

  return (
    <div
      className={classNames({
        'tw-layout': true,
        'tw-layout-isMenuOpen': isMenuOpened,
      })}
    >
      <header className='tw-layout--header'>
        {menu && (
          <div className='tw-layout--menuBtn'>
            <TertiaryBtn
              icon={{ element: <SvgMenu2 /> }}
              isInverse
              tooltip={formatMessage(msg.openMenu)}
              onClick={handleMenuToggle}
            />
          </div>
        )}
        <a href={homepageLink.href} onClick={handleHomepageLinkClick} title={titleAndVersion}>
          <img src='/assets/logo.svg' alt='' />
          <h1 className='tw-layout--title'>{title}</h1>
        </a>
        <HeaderTools links={otherLinks}>
          <Sync />
          {routeId && (
            <>
              {!!navigator.share && (
                <TertiaryBtn
                  icon={{ element: <SvgShare /> }}
                  isInverse
                  tooltip={formatMessage(msg.share)}
                  onClick={handleShareBtnClick}
                />
              )}
              {!navigator.share && !!navigator.clipboard && (
                <TertiaryBtn
                  icon={{ element: <SvgLink /> }}
                  isInverse
                  tooltip={formatMessage(msg.copyUrl)}
                  onClick={handleCopyUrlBtnClick}
                />
              )}
            </>
          )}
          {headerTools.map((item) => (
            <HeaderTool key={item.icon} {...item} />
          ))}
          {homeLink && (
            <TertiaryLink
              icon={{ element: <SvgHome /> }}
              isInverse
              href={homeLink}
              tooltip={formatMessage(msg.homeLink)}
            />
          )}
        </HeaderTools>
        {useAccessToken && <User />}
      </header>
      {menu && (
        <div
          className={classNames({
            'tw-layout--menu': true,
            'tw-layout--menu-isCollapsed': isMenuCollapsed,
          })}
        >
          <nav onClickCapture={handleMenuToggle}>
            <ul>
              {menu.map((item) => (
                <li key={item.to}>
                  <NavItem {...item} />
                </li>
              ))}
            </ul>
          </nav>
          <div className='tw-layout--collapseMenuBtn'>
            <TertiaryBtn
              icon={{ element: <SvgCaretFastLeft /> }}
              tooltip={
                isMenuCollapsed ? formatMessage(msg.expandMenu) : formatMessage(msg.collapseMenu)
              }
              onClick={handleCollapseBtnClick}
            />
          </div>
        </div>
      )}
      <div className='tw-layout--main'>{children}</div>
      {menu && (
        <button className='tw-layout--closeMenuBtn' type='button' onClick={handleMenuToggle} />
      )}
    </div>
  );
};
