import { defineMessages } from 'react-intl.macro';

import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { GraphQLSchema } from 'graphql';
import gql from 'graphql-tag';
import { useApolloClient } from '@apollo/client';

import {
  BtnBar,
  CtaBtn,
  DangerAlert,
  Form,
  FormItem,
  FormRow,
  Input,
  SuccessAlert,
} from '@tmapy/style-guide';
import { useMessage } from '@tmapy/intl';
import { useSelector } from '@tmapy/redux';
import { QUERY_STATE } from '@tmapy/utils';

import { PageTitle, Section, msg as globalMsg, DataLayoutSpacing } from 'lib/graphql';

const msg = defineMessages({
  pageTitle: {
    id: 'sys.page.userProfile.title',
    defaultMessage: 'Profil uživatele',
  },
  changePassword: {
    id: 'sys.page.userProfile.changePassword',
    defaultMessage: 'Změna hesla',
  },
  name: {
    id: 'sys.page.userProfile.name',
    defaultMessage: 'Jméno',
  },
  password: {
    id: 'sys.page.userProfile.password',
    defaultMessage: 'Nové heslo',
  },
  passwordAgain: {
    id: 'sys.page.userProfile.passwordAgain',
    defaultMessage: 'Nové heslo znovu',
  },
  invalidPassword: {
    id: 'sys.page.userProfile.invalidPassword',
    defaultMessage: 'Vyplňte shodné heslo.',
  },
  submitQueryStateFail: {
    id: 'sys.page.userProfile.submit.queryState.fail',
    defaultMessage: 'Heslo se nepovedlo změnit. Zkuste to prosím později.',
  },
  submitQueryStateSuccess: {
    id: 'sys.page.userProfile.submit.queryState.success',
    defaultMessage: 'Heslo bylo úspěšně změněno.',
  },
});

const createMutation = gql`
  mutation createMutation($changePassword: createChangePasswordInput!) {
    createChangePassword(input: $changePassword) {
      changePassword {
        password
      }
    }
  }
`;

export const UserProfile = () => {
  const formatMessage = useMessage();
  const { parsedAccessToken } = useSelector((state) => state.auth);

  const [password, setPassword] = useState('');
  const [passwordAgain, setPasswordAgain] = useState('');
  const [isPasswordInvalid, setPasswordInvalid] = useState(false);
  const [submitQueryState, setSubmitQueryState] = useState(QUERY_STATE.EMPTY);

  const client = useApolloClient();
  const schema = (client as any).serverSchema as GraphQLSchema;
  const isMutationInSchema = !!schema.getMutationType()?.getFields()['createChangePassword'];

  const handlePasswordChange = (e: React.ChangeEvent, eventData: { value: string }) => {
    setPasswordInvalid(false);
    setSubmitQueryState(QUERY_STATE.EMPTY);
    setPassword(eventData.value);
  };

  const handlePasswordChangeAgain = (e: React.ChangeEvent, eventData: { value: string }) => {
    setPasswordInvalid(false);
    setSubmitQueryState(QUERY_STATE.EMPTY);
    setPasswordAgain(eventData.value);
  };

  const handleSubmit = async () => {
    if (password !== passwordAgain) {
      setPasswordInvalid(true);
    } else {
      setSubmitQueryState(QUERY_STATE.WAITING);

      try {
        const result = await client.mutate({
          mutation: createMutation,
          variables: {
            changePassword: {
              password: password,
            },
          },
        });

        if (result.errors && result.errors.length) {
          throw new Error();
        }

        setSubmitQueryState(QUERY_STATE.SUCCESS);
        setPassword('');
        setPasswordAgain('');
      } catch (err) {
        setSubmitQueryState(QUERY_STATE.FAIL);
      }
    }
  };

  return (
    <DataLayoutSpacing>
      <PageTitle>
        <FormattedMessage {...msg.pageTitle} />
      </PageTitle>
      <Form onSubmit={handleSubmit}>
        <main className='sg-u-vs-2'>
          <Section>
            <div className='sg-u-box sg-a-p-2 tw-u-vs-3/2'>
              <FormRow>
                <FormItem label={formatMessage(msg.name)}>
                  {parsedAccessToken?.payload.name}
                </FormItem>
              </FormRow>
            </div>
          </Section>

          {isMutationInSchema && (
            <>
              <Section title={formatMessage(msg.changePassword)}>
                <div className='sg-u-box sg-a-p-2 tw-u-vs-3/2'>
                  <FormRow>
                    <FormItem
                      label={formatMessage(msg.password)}
                      isRequired={!!password.length || !!passwordAgain.length}
                      errorMessage={
                        isPasswordInvalid ? formatMessage(msg.invalidPassword) : undefined
                      }
                    >
                      <Input
                        type='password'
                        value={password}
                        isInvalid={isPasswordInvalid}
                        onChange={handlePasswordChange}
                      />
                    </FormItem>
                  </FormRow>
                  <FormRow>
                    <FormItem
                      label={formatMessage(msg.passwordAgain)}
                      isRequired={!!password.length || !!passwordAgain.length}
                      errorMessage={
                        isPasswordInvalid ? formatMessage(msg.invalidPassword) : undefined
                      }
                    >
                      <Input
                        type='password'
                        value={passwordAgain}
                        isInvalid={isPasswordInvalid}
                        onChange={handlePasswordChangeAgain}
                      />
                    </FormItem>
                  </FormRow>
                </div>
              </Section>

              {submitQueryState === QUERY_STATE.FAIL && (
                <DangerAlert>
                  <FormattedMessage {...msg.submitQueryStateFail} />
                </DangerAlert>
              )}

              {submitQueryState === QUERY_STATE.SUCCESS && (
                <SuccessAlert>
                  <FormattedMessage {...msg.submitQueryStateSuccess} />
                </SuccessAlert>
              )}

              <BtnBar>
                <CtaBtn
                  type='submit'
                  queryState={submitQueryState === QUERY_STATE.WAITING ? 'waiting' : undefined}
                  isDisabled={!password}
                >
                  <FormattedMessage {...globalMsg.formUpdate} />
                </CtaBtn>
              </BtnBar>
            </>
          )}
        </main>
      </Form>
    </DataLayoutSpacing>
  );
};
