import {
  ArgumentNode,
  FieldNode,
  Kind,
  OperationDefinitionNode,
  VariableDefinitionNode,
} from 'graphql';
import { getNewVariableName } from './getNewVariableName';

export const addFieldArgumentVariable = (
  operation: OperationDefinitionNode,
  field: FieldNode,
  argumentName: string,
  prefferedName: string,
) => {
  const variableName = getNewVariableName(operation, prefferedName);

  const newVariableDefinition: VariableDefinitionNode = {
    kind: Kind.VARIABLE_DEFINITION,
    type: {
      kind: Kind.NAMED_TYPE,
      name: {
        kind: Kind.NAME,
        value: 'auto',
      },
    },
    variable: {
      kind: Kind.VARIABLE,
      name: { kind: Kind.NAME, value: variableName },
    },
  };

  (operation.variableDefinitions as VariableDefinitionNode[]) = [
    ...(operation.variableDefinitions ?? []),
    newVariableDefinition,
  ];

  const newArgument: ArgumentNode = {
    kind: Kind.ARGUMENT,
    name: { kind: Kind.NAME, value: argumentName },
    value: {
      kind: Kind.VARIABLE,
      name: {
        kind: Kind.NAME,
        value: variableName,
      },
    },
  };

  (field.arguments as ArgumentNode[]) = [...(field.arguments ?? []), newArgument];

  return {
    newVariableDefinition,
    newArgument,
  };
};
