import { Kind, NamedTypeNode, TypeNode } from 'graphql';

export const getNamedTypeNode = (type: TypeNode): NamedTypeNode => {
  switch (type.kind) {
    case Kind.NON_NULL_TYPE:
    case Kind.LIST_TYPE: {
      return getNamedTypeNode(type.type);
    }
    case Kind.NAMED_TYPE: {
      return type;
    }
  }
};
