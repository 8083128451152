import React, { FunctionComponentElement } from 'react';

import type { TabProps } from './Tab';
import { TabBtn } from './TabBtn';

export type TabsProps = {
  /**
   * Selected Children Tab ID
   */
  value?: string;
  children: FunctionComponentElement<TabProps>[];
  onChange(e: React.MouseEvent, eventData: { value: string }): void;
};

export const Tabs: React.FC<TabsProps> = ({ children, value, onChange }) => {
  const handleClick = (e: React.MouseEvent, tabId: string) => {
    onChange(e, { value: tabId });
  };

  return (
    <>
      {children.length > 1 && (
        <div className='sg-a-p-s sg-a-l-0 sg-a-mb-2 tw-a-mbs-3'>
          <div className='sg-a-d-f sg-a-fw-w sg-a-rg-1 sg-u-bb sg-a-mx--1'>
            {children.map((tab, index) => (
              <TabBtn
                key={tab.props.id}
                isActive={value ? tab.props.id === value : index === 0}
                onClick={(e) => handleClick(e, tab.props.id)}
              >
                {tab.props.label}
              </TabBtn>
            ))}
          </div>
        </div>
      )}
      {value ? children.filter((tab) => tab.props.id === value) : children[0]}
    </>
  );
};
