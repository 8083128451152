export class DateRangeValue {
  public after?: string;
  public before?: string;
  constructor(config?: { after?: string; before?: string }) {
    this.after = config?.after;
    this.before = config?.before;
  }

  public static parse(strValue: string): DateRangeValue | undefined {
    const [afterStr, beforeStr] = strValue.split('!');
    const after = afterStr ? afterStr.substring(0, 10) : undefined;
    const before = beforeStr ? beforeStr.substring(0, 10) : undefined;
    if (!after && !before) return undefined;
    return new DateRangeValue({ after, before });
  }

  public toString() {
    if (!this.after && !this.before) return '';
    return `${this.after ? this.after : ''}!${this.before ? this.before : ''}`;
  }
}
