import React from 'react';

import './ToolBar.scss';

type ToolBarProps = {
  children: React.ReactNode;
};

export const ToolBar: React.FC<ToolBarProps> = ({ children }) => {
  return <nav className='tw-toolBar'>{children}</nav>;
};
