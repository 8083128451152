import { useEffect, useState } from 'react';

import type { GeoJSONFeature } from '@tmapy/types';
import { useDispatch, useSelector } from '@tmapy/redux';
import { geometryTypeAndLayoutByWKT, LAYER_IDS, MAP_TOOL, isVectorLayerState } from '@tmapy/config';
import {
  actionSetCenter,
  getCenter,
  getFeaturesExtent,
  useOLMap,
  geoJSONToFeature,
  GeoJSONToEWKT,
  unifyEWKT,
} from '@tmapy/mapcore';
import {
  actionActivateEditTools,
  actionActivateEditTool,
  actionAfterLoadSuccess,
  actionSelectFeature,
  actionDeactivateTools,
} from '@tmapy/edit-tools';

import { _GeometryType } from '../../utils/getDirectives';

type EWKTProps = {
  projection: string;
  geometryType?: _GeometryType;
  layerId?: string;
  featureId?: string | number;
  geoJSONFeature: GeoJSONFeature | null;

  data: any;
  isReadOnly?: boolean;
  onChange?: (newValue: any) => void;
};

export const EWKTComponent = ({
  projection,
  geometryType,
  featureId,
  layerId,
  geoJSONFeature,
  data,
  isReadOnly,
  onChange,
}: EWKTProps) => {
  const dispatch = useDispatch();

  const map = useOLMap();
  const mapProjection = map.getView().getProjection();
  const activeMapTool = useSelector((state) => state.uiManager.activeMapTool);
  const modifyLayerId = useSelector((state) => state.mapCore.interaction.layerId);
  const modifyLayer = useSelector((state) =>
    state.mapCore.layers.find((layer) => layer.id === modifyLayerId),
  );
  const existsModifyLayer = isVectorLayerState(modifyLayer);
  const [lastEwktfeatures, setLastEwktfeatures] = useState<string>();
  const [mapMoveToFeature, setMapMoveToFeature] = useState(true);

  useEffect(() => {
    if (!geoJSONFeature) return;
    const extent = getFeaturesExtent([geoJSONFeature]);
    if (extent && mapMoveToFeature) {
      dispatch(actionSetCenter(getCenter(extent)));
      setMapMoveToFeature(false);
    }
    dispatch(actionActivateEditTools());
  }, [dispatch, geoJSONFeature, featureId]);

  useEffect(() => {
    if (!existsModifyLayer) {
      return;
    }

    if (activeMapTool === MAP_TOOL.INFOEDIT) {
      // activate edit tool
      if (!geoJSONFeature) {
        if (geometryType) {
          dispatch(
            actionActivateEditTool(
              MAP_TOOL.DRAW,
              geometryTypeAndLayoutByWKT[geometryType].geometryType,
            ),
          );
        } else {
          console.error(`[createEWKTInputComponent]: Undefined draw geometry type`);
        }
      } else {
        dispatch(actionActivateEditTool());
        if (layerId && featureId) {
          dispatch(actionAfterLoadSuccess([{ data: geoJSONFeature, featureId, layerId }]));
        }

        setLastEwktfeatures(
          GeoJSONToEWKT(geoJSONFeature, mapProjection.getCode(), projection, true),
        );
        const feature = geoJSONToFeature(geoJSONFeature);
        dispatch(
          actionSelectFeature({
            features: [{ feature, layerId: LAYER_IDS.EDIT_TOOLS_HIGHLIGHT }],
            edit: !isReadOnly,
          }),
        );
        return () => {
          dispatch(actionSelectFeature({ features: [], edit: !isReadOnly }));
        };
      }
    } else if (activeMapTool === MAP_TOOL.DRAW && geoJSONFeature) {
      dispatch(actionActivateEditTool());
      return () => {
        dispatch(actionSelectFeature({ features: [], edit: !isReadOnly }));
      };
    }
  }, [dispatch, geoJSONFeature, featureId, activeMapTool, isReadOnly]);

  useEffect(() => {
    if (!existsModifyLayer) {
      return;
    }

    const parseValue = unifyEWKT(data, true);
    const ewktfeatures = modifyLayer.features.map((geoJSONFeature) =>
      GeoJSONToEWKT(geoJSONFeature, mapProjection.getCode(), projection, true),
    );
    if (lastEwktfeatures === ewktfeatures[0]) {
      return;
    }
    if (ewktfeatures.length === 1) {
      if (parseValue !== ewktfeatures[0]) {
        onChange?.(ewktfeatures[0]);
      }
    } else if (ewktfeatures.length === 0) {
      // onChange?.(null); // nevim, k cemu to tu bylo, ale odkomentovane to rozbiji geom. filter
    } else {
      // TODO - multigeometry
      // handleChange(`GEOMETRYCOLLECTION(${ewktfeatures.map(stripSRID).join(',')})`);
      if (parseValue !== ewktfeatures[0]) {
        onChange?.(ewktfeatures[0]);
      }
      console.error('[EWKTInput] Cannot assign multiple geometries', ewktfeatures);
    }
  }, [modifyLayer]);

  useEffect(
    () => () => {
      dispatch(actionDeactivateTools());
    },
    [dispatch],
  );

  return null;
};
