import { fetchJSON } from '@tmapy/config';

import { getBeaBasePath } from '../config';
import { addVersionToUrlString } from '../utils/addVersionToUrl';

import type { LocaleCode, LocaleMessages } from './constants';
import { localeLoaders } from './loader';

const loadCustomL10nJSON = async (url: string): Promise<LocaleMessages> => {
  try {
    const json = await fetchJSON(addVersionToUrlString(url), {
      ignoreErrors: true,
    });

    if (!json) {
      throw new Error(`No data`);
    }
    if (typeof json !== 'object') {
      throw new Error(`Data must be an object`);
    }
    if (Array.isArray(json)) {
      throw new Error(`Data must be an object, array given`);
    }

    return json;
  } catch (err) {
    console.info(`[loadCustomL10nJSON] Cannot load custom dictionary from ${url}`, err);
    return {};
  }
};

const getAdditionalPaths = (
  locale: string,
  appId: string,
  loadBeaConfig?: boolean,
  packageVersion?: string,
) => {
  const paths = [`${process.env.PUBLIC_URL}l10n/${locale}.json`];
  if (loadBeaConfig) {
    const agendaPathBase = getBeaBasePath(appId);
    paths.push(`${agendaPathBase}l10n/${locale}.json?package=${packageVersion}`);
  } else {
    paths.push(`${process.env.PUBLIC_URL}config/${appId}/l10n/${locale}.json`);
  }
  return paths;
};

export const getLocaleLoader =
  (appId?: string, loadBeaConfig?: boolean, packageVersion?: string) => async (locale: string) => {
    const additionalPaths = appId
      ? getAdditionalPaths(locale, appId, loadBeaConfig, packageVersion)
      : [];

    const dictionaries = await Promise.all([
      ...additionalPaths.map(loadCustomL10nJSON),
      localeLoaders[locale as LocaleCode](),
    ]);

    return dictionaries.reduce((acc, dict) => ({ ...acc, ...dict }), {} as LocaleMessages);
  };
