import { defineMessages } from 'react-intl.macro';

import { useMessage } from '@tmapy/intl';

import { SvgHelp, TertiaryLink } from '@tmapy/style-guide';
import { useLink, useLocation } from '@tmapy/router';

import { HEADER_TOOL } from '../config';

const HEADER_TOOL_ICON_MAP: Record<HEADER_TOOL, JSX.Element> = {
  About: <SvgHelp />,
};

const msg = defineMessages<HEADER_TOOL>({
  About: {
    id: 'sys.tool.about',
    defaultMessage: 'O aplikaci',
  },
});

export type HeaderToolProps = { to: string; icon: HEADER_TOOL; type: HEADER_TOOL };

export const HeaderTool: React.FC<HeaderToolProps> = ({ to, icon, type }) => {
  const formatMessage = useMessage();
  const location = useLocation();
  const navigateToCurrentRecord = useLink(to, location.params, undefined, 'push', true);

  if (type === 'About') {
    return (
      <TertiaryLink
        {...navigateToCurrentRecord}
        icon={{ element: HEADER_TOOL_ICON_MAP[icon] }}
        isInverse
        tooltip={formatMessage(msg[icon])}
      />
    );
  }
  return null;
};
