import type { GeoJSONFeature } from '@tmapy/types';
import type { AGSResponseFeature } from '@tmapy/config';

export const createFeatureFromAGSResponse = (
  feature: AGSResponseFeature,
): GeoJSONFeature | undefined => {
  let geometry = undefined;
  // POLYGON
  if (feature.geometry.rings) {
    if (Array.isArray(feature.geometry.rings[0][0][0])) {
      geometry = {
        type: 'MultiPolygon' as const,
        coordinates: feature.geometry.rings as number[][][][],
      };
    } else {
      geometry = {
        type: 'Polygon' as const,
        coordinates: feature.geometry.rings as number[][][],
      };
    }
  }
  // LINE
  if (feature.geometry.paths) {
    if (Array.isArray(feature.geometry.paths[0][0])) {
      geometry = {
        type: 'MultiLineString' as const,
        coordinates: feature.geometry.paths as number[][][],
      };
    } else {
      geometry = {
        type: 'LineString' as const,
        coordinates: feature.geometry.paths as number[][],
      };
    }
  }
  // POINT
  if (feature.geometry.x && feature.geometry.y) {
    geometry = {
      type: 'Point' as const,
      coordinates: [feature.geometry.x, feature.geometry.y],
    };
  }

  if (!geometry) {
    return undefined;
  }

  const attributeId = feature.attributes?.id ?? feature.attributes.objectid;

  return {
    type: 'Feature' as const,
    id: `${attributeId}`,
    properties: feature.attributes,
    geometry,
  };
};
