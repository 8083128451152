import React from 'react';

import type { GeoJSONFeature } from '@tmapy/types';
import { useMessage } from '@tmapy/intl';
import { useDispatch, useSelector } from '@tmapy/redux';
import type { VectorLayerState } from '@tmapy/config';
import { LAYER_IDS } from '@tmapy/config';
import { Measure } from '@tmapy/measure';
import { SvgRuler } from '@tmapy/style-guide';

import type { PanelProps } from './types';
import { mapMsg } from './messages';
import { PanelHead } from './PanelHead';

export const MeasurePanel: React.FC<PanelProps> = React.memo(({ onHideBtnClick }) => {
  const activeMapTool = useSelector((state) => state.uiManager.activeMapTool);
  const formatMessage = useMessage();
  const dispatch = useDispatch();
  const features =
    (
      useSelector((state) =>
        state.mapCore.layers.find((layer) => layer.id === LAYER_IDS.MEASURE),
      ) as VectorLayerState
    ).features ?? ([] as GeoJSONFeature[]);

  return (
    <div className='sg-a-d-f sg-a-fd-c sg-u-vs-2 sg-a-o-a'>
      <PanelHead
        title={formatMessage(mapMsg.measureTitle)}
        icon={<SvgRuler />}
        onHideBtnClick={onHideBtnClick}
      />
      <div className='sg-a-px-2 sg-a-pb-2 sg-u-vs-2'>
        <Measure dispatch={dispatch} features={features} activeMapTool={activeMapTool} />
      </div>
    </div>
  );
});
