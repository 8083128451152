import { useRef, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useApolloClient } from '@apollo/client';
import gql from 'graphql-tag';

import {
  DangerAlert,
  InfoAlert,
  Popup,
  SvgSpinner,
  SvgSync,
  TertiaryBtn,
  WaitingAlert,
  usePopup,
} from '@tmapy/style-guide';
import { QUERY_STATE } from '@tmapy/utils';
import { useMessage } from '@tmapy/intl';
import { useSelector } from '@tmapy/redux';

import { DateTime } from 'lib/graphql/components/DateTime';
import { msg as globalMsg } from 'lib/graphql/messages';

import { useInterval } from '../../hooks/useInterval';
import { RunSyncBtn } from './RunSyncBtn';
import { msg } from './messages';

const query = gql`
  query synchronizations($appIdent: String!) {
    doneSynchronization: coreAppSyncs(
      appIdent: $appIdent
      order: { finishedAt: "DESC" }
      state_list: [DONE]
      first: 1
    ) {
      edges {
        node {
          id
          _id
          appIdent
          state
          finishedAt
          startedAt
          createdAt
        }
      }
    }

    runningSynchronizations: coreAppSyncs(appIdent: $appIdent, state_list: [RUNNING]) {
      edges {
        node {
          id
          _id
          appIdent
          state
          finishedAt
          startedAt
          createdAt
        }
      }
    }

    waitingSynchronizations: coreAppSyncs(appIdent: $appIdent, state_list: [WAITING]) {
      edges {
        node {
          id
          _id
          appIdent
          state
          finishedAt
          startedAt
          createdAt
        }
      }
    }

    lastSynchronization: coreAppSyncs(
      appIdent: $appIdent
      order: { finishedAt: "DESC" }
      state_list: [DONE, FAILED, STALLED]
      first: 1
    ) {
      edges {
        node {
          id
          _id
          appIdent
          state
          finishedAt
          startedAt
          createdAt
        }
      }
    }
  }
`;

type Synchronization = {
  id: number;
  appIdent: string;
  createdAt: string;
  startedAt: string;
  finishedAt: string;
  state: 'WAITING' | 'SCHEDULED' | 'RUNNING' | 'STALLED' | 'FAILED' | 'DONE' | 'CANCELLED';
};

const failedStates = ['FAILED', 'STALLED'];

export const Sync = () => {
  const formatMessage = useMessage();
  const isSyncEnabled = useSelector((state) => state.app.isSyncEnabled);
  const appIdent = useSelector((state) => state.app.appId);

  const popupTriggerRef = useRef<HTMLDivElement>(null);
  const popupContentRef = useRef<HTMLDivElement>(null);
  const [isPopupShown, setPopupShown] = usePopup(popupTriggerRef, popupContentRef);
  const popupPosition = ['bottom-left' as const];

  const [queryState, setQueryState] = useState(QUERY_STATE.WAITING);
  const [waitingSync, setWaitingSync] = useState<Synchronization | undefined>();
  const [runningSync, setRunningSync] = useState<Synchronization | undefined>();
  const [lastSuccessfulSync, setLastSuccessfulSync] = useState<Synchronization | undefined>();
  const [lastSync, setLastSync] = useState<Synchronization | undefined>();

  const client = useApolloClient();

  if (!isSyncEnabled) {
    return null;
  }

  const fetchData = async () => {
    try {
      const result = await client.query({
        query,
        variables: { appIdent },
        fetchPolicy: 'network-only',
      });

      if (result.errors && result.errors.length) {
        throw new Error();
      }
      setWaitingSync(result.data?.waitingSynchronizations?.edges?.[0]?.node);
      setRunningSync(result.data?.runningSynchronizations?.edges?.[0]?.node);
      setLastSuccessfulSync(result.data?.doneSynchronization?.edges?.[0]?.node);
      setLastSync(result.data?.lastSynchronization?.edges?.[0]?.node);
      setQueryState(QUERY_STATE.SUCCESS);
    } catch (err) {
      setWaitingSync(undefined);
      setRunningSync(undefined);
      setLastSuccessfulSync(undefined);
      setLastSync(undefined);
      setQueryState(QUERY_STATE.FAIL);
    }
  };

  useEffect(() => {
    setQueryState(QUERY_STATE.WAITING);
    fetchData();
  }, []);

  const handleClick = () => {
    setPopupShown(!isPopupShown);
  };

  const handleSyncStart = () => {
    fetchData();
  };

  useInterval(fetchData, 1000 * 15);

  const waiting = !!waitingSync?.state;
  const running = !!runningSync?.state;
  const isDone = !!lastSuccessfulSync?.state;
  const lastSynchronization = !!lastSync?.state;

  return (
    <div ref={popupTriggerRef}>
      <TertiaryBtn
        isInverse
        icon={{
          element: <SvgSync />,
          badge: running || waiting ? 'warning' : undefined,
        }}
        tooltip={formatMessage(msg.tooltip)}
        onClick={handleClick}
        testId='tw-sync--button'
      />
      {isPopupShown && (
        <Popup triggerRef={popupTriggerRef} position={popupPosition}>
          <div ref={popupContentRef} className='sg-a-p-2 sg-u-vs-2'>
            {queryState === QUERY_STATE.SUCCESS && (
              <>
                {lastSynchronization && failedStates.includes(lastSync.state) && (
                  <DangerAlert>
                    <FormattedMessage {...msg.statusFailed} />{' '}
                    <b className='sg-u-type-bold'>
                      <DateTime timestamp={lastSync.finishedAt ?? lastSync.startedAt} />
                    </b>
                    .
                  </DangerAlert>
                )}
                {isDone && (
                  <p className='sg-u-type-sml'>
                    <FormattedMessage {...msg.statusSuccess} />{' '}
                    <b className='sg-u-type-bold'>
                      <DateTime timestamp={lastSuccessfulSync.finishedAt} />
                    </b>
                    .
                  </p>
                )}
                {running ? (
                  <InfoAlert icon={<SvgSpinner />}>
                    <FormattedMessage {...msg.statusRunning} />
                  </InfoAlert>
                ) : waiting ? (
                  <InfoAlert icon={<SvgSpinner />}>
                    <FormattedMessage {...msg.statusWaiting} />
                  </InfoAlert>
                ) : null}
                {!waiting && (
                  <RunSyncBtn
                    labelMsgDescriptor={running ? msg.runBtnAgain : msg.runBtn}
                    onSyncStart={handleSyncStart}
                  />
                )}
              </>
            )}
            {queryState === QUERY_STATE.WAITING && (
              <WaitingAlert>
                <FormattedMessage {...globalMsg.waiting} />
              </WaitingAlert>
            )}
            {queryState === QUERY_STATE.FAIL && (
              <DangerAlert>
                <FormattedMessage {...msg.queryStateFail} />
              </DangerAlert>
            )}
          </div>
        </Popup>
      )}
    </div>
  );
};
