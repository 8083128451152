import { DocumentNode } from 'graphql';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useApolloClient } from '@apollo/client';

import type { RouteStateTWC, SplitLayoutDefaultSize, RouteComponent } from '@tmapy/types';
import { useDispatch } from '@tmapy/redux';
import type { RouterState } from '@tmapy/router';
import { useLocation } from '@tmapy/router';
import { actionLoadNote, NOTE_HASH_PARAM_NAME } from '@tmapy/notes';

import type { ExtendedApolloClient } from 'lib/graphql';
import { RenderQuery, QueryError, SplitLayout, DataLayoutSpacing } from 'lib/graphql';
import { MapContainer, MapInfo, Panorama, PanoramaImperatives } from 'lib/map';
import { UserProfile } from 'lib/user-profile';

import { AccessDenied } from './errors/AccessDenied';
import { NotFoundPage } from './errors/NotFoundPage';
import { ErrorBoundary } from './errors/ErrorBoundary';

import { AboutPage } from './AboutPage';
import { Layout } from './Layout';

declare module '@tmapy/router' {
  interface ResolvedRouteConfig {
    query?: string;
    component?: RouteComponent;
    defaultSize?: SplitLayoutDefaultSize;
  }
}

type RouterQueryProps = {
  route: RouteStateTWC;
  query: string | DocumentNode;
  location: RouterState;
};

const RouterQuery: React.FC<RouterQueryProps> = ({ location, query, route }) => {
  return (
    <ErrorBoundary>
      <RenderQuery
        key={route.id}
        query={query}
        variables={location.params}
        isPage
        isMassSelect={route.context?.isMassSelect}
      />
    </ErrorBoundary>
  );
};

const componentMap: Record<RouteComponent, React.FC<any>> = {
  RouterQuery,
  Map: MapContainer,
  MapInfo: MapInfo,
  About: AboutPage,
  Panorama: Panorama,
  UserProfile: UserProfile,
  NotFound: NotFoundPage,
  IDE: () => {
    const [ide, setIde] = useState<React.ReactElement<any, any> | null>(null);
    const graphql = useSelector((state) => state.app.graphql);

    useEffect(() => {
      if (ide) return;
      // eslint-disable-next-line node/no-unsupported-features/es-syntax
      import(/* webpackChunkName: "ide" */ '../lib/graphql-ide').then(({ IDE }) =>
        setIde(graphql ? <IDE graphql={graphql} /> : <div>GraphQL not configured</div>),
      );
    }, [ide, setIde, graphql]);

    return ide;
  },
};

export const Router: React.FC = () => {
  const dispatch = useDispatch();
  const client = useApolloClient() as ExtendedApolloClient;
  const location = useLocation();
  const auth = useSelector((state) => state.auth);
  const appId = useSelector((state) => state.app.appId);
  const route = location.route as RouteStateTWC | null;
  const query = route?.query;
  const noteId = location.hashParams[NOTE_HASH_PARAM_NAME];
  const componentRef = useRef<PanoramaImperatives>(null);

  const handleLayoutChange = () => {
    componentRef.current?.resize();
  };

  useEffect(() => {
    if (noteId) {
      dispatch(actionLoadNote(client, noteId));
    }
  }, [client, dispatch, noteId]);

  const Component =
    componentMap[route?.component ?? (route?.query ? 'RouterQuery' : 'NotFound')] ??
    componentMap['NotFound'];

  const accessDenied = (route?.auth && !auth.isLoggedIn) || !route?.hasBasePermission;
  const isMap = route?.component === 'Map';

  return (
    <Layout>
      <SplitLayout
        routeId={route?.id}
        show={accessDenied ? 'data' : isMap ? 'map' : route?.showMap ? 'both' : 'data'}
        defaultSize={route?.defaultSize ?? 'medium'}
        onLayoutChange={handleLayoutChange}
      >
        {client.error && Component === componentMap['RouterQuery'] ? (
          <DataLayoutSpacing>
            <div className='sg-u-vs-1'>
              <QueryError error={client.error} />
            </div>
          </DataLayoutSpacing>
        ) : accessDenied ? (
          <AccessDenied isLoggedIn={auth.isLoggedIn} />
        ) : !isMap ? (
          <Component
            ref={route?.component === 'Panorama' ? componentRef : undefined}
            location={location}
            route={route}
            query={query}
            appId={appId}
          />
        ) : null}
      </SplitLayout>
    </Layout>
  );
};
