import type { Middleware } from 'redux';
import { AppState, batch } from '@tmapy/redux';
import {
  MAP_CLICK,
  MAP_MOVE_END,
  SET_LAYER_VISIBILITY,
  SET_LAYER_OPACITY,
  actionSetCenter,
  actionSetZoom,
  SET_MAP_CENTER,
} from '@tmapy/mapcore';
import { SET_ROUTE, actionUpdateHashParams } from '@tmapy/router';
import { PREPARE_LOAD_FEATURES, PREPARE_SAVE_FEATURES } from '@tmapy/edit-tools';
import {
  actionSetPanel,
  PANELS_WITH_DEFAULT_MAP_TOOL,
  PANEL_DEFAULT_MAP_TOOL,
  SET_MAP_TOOL,
  SET_PANEL,
  SET_UI_MANAGER_STATE,
} from '@tmapy/ui-manager';
import { PANEL, MAP_TOOL } from '@tmapy/config';

import { actionLoadFeatures, actionSaveFeatures } from '../edit-tools/actions';
import {
  actionActivateMapTool,
  actionNavigateToFeatureDetail,
  actionNavigateToInfo,
  actionSetLayerVisibilityByHashParam,
} from '../app/actions';
import { HASH_PARAM_PANEL, LAYER_HASH_FRAGMENT_DELIMITER } from '../constants';
import {
  parseHashParamLayerFragment,
  serializeHashParamLayerFragment,
} from '../utils/parseHashParamLayerFragment';

export const mapMiddleware: Middleware = (store) => (next) => (action) => {
  return batch(() => {
    const oldState = store.getState() as AppState;
    const originalAction = next(action);
    switch (action.type) {
      case MAP_CLICK: {
        const activeMapTool = oldState.uiManager.activeMapTool;
        let openDetail = false;
        let layerFeature;

        if (action.payload.features.length !== 0 && activeMapTool === MAP_TOOL.INFOEDIT) {
          layerFeature = action.payload.features[0];
          const layerName = layerFeature.feature.get('_originLayerId') || layerFeature.layerId;
          const featureRoute = oldState.app.featureRouteMappings.find(
            (featureRoute) => featureRoute.layer === layerName,
          );
          openDetail = !!featureRoute && !!oldState.router.route;
        }

        const visibleLayerIds = oldState.mapCore.layers
          .filter((layer) => layer.layerOptions.visible)
          .map((layer) => layer.id);

        if (openDetail) {
          store.dispatch(actionNavigateToFeatureDetail(layerFeature) as any);
        } else if (
          activeMapTool === MAP_TOOL.INFOEDIT &&
          oldState.info.services.some(
            (service) =>
              service.onlyIfLayersVisible.length === 0 ||
              service.onlyIfLayersVisible.some((layer) => visibleLayerIds.includes(layer)),
          )
        ) {
          store.dispatch(actionNavigateToInfo(action.payload.point) as any);
        }
        return originalAction;
      }

      case SET_MAP_CENTER: {
        const center = action.payload;
        const newHashParams = {
          x: center[0].toString(),
          y: center[1].toString(),
        };
        store.dispatch(actionUpdateHashParams(newHashParams));
        break;
      }

      case MAP_MOVE_END: {
        const {
          zoom,
          center: [x, y],
        } = action.payload;
        if (isNaN(x) || isNaN(y) || isNaN(zoom)) {
          console.error(`[MAP_MOVE_END] Invalid coordinates: [${x}, ${y}] zoom ${zoom}`);
        } else {
          const newHashParams = {
            z: zoom.toString(),
            x: x.toString(),
            y: y.toString(),
            bbox: undefined,
          };
          store.dispatch(actionUpdateHashParams(newHashParams));
        }
        break;
      }

      case SET_LAYER_VISIBILITY: {
        const newVisibilities = new Map(Object.entries(action.payload as Record<string, boolean>));
        const state = store.getState() as AppState;
        const { hashParams } = state.router;
        const newLayersHashParamFragments: string[] = [];
        if (hashParams.l) {
          for (const hashParamLayerFragment of hashParams.l.split(LAYER_HASH_FRAGMENT_DELIMITER)) {
            const hashParamLayer = parseHashParamLayerFragment(hashParamLayerFragment);
            if (hashParamLayer && newVisibilities.has(hashParamLayer.layerId)) {
              hashParamLayer.visible = newVisibilities.get(hashParamLayer.layerId)!;
              newVisibilities.delete(hashParamLayer.layerId);
              newLayersHashParamFragments.push(serializeHashParamLayerFragment(hashParamLayer));
            } else {
              newLayersHashParamFragments.push(hashParamLayerFragment);
            }
          }
        }
        for (const [layerId, newVisibility] of newVisibilities.entries()) {
          const layer = oldState.mapCore.layers.find((layer) => layer.id === layerId);
          if (!layer) {
            continue;
          }
          const oldVisibility = layer.layerOptions.visible;

          if (oldVisibility !== newVisibility) {
            newLayersHashParamFragments.push(
              serializeHashParamLayerFragment({
                layerId,
                visible: newVisibility,
                opacity: state.mapCore.layers.find((layer) => layer.id === layerId)!.layerOptions
                  .opacity,
              }),
            );
          }
        }

        store.dispatch(
          actionUpdateHashParams({
            l: newLayersHashParamFragments.join(LAYER_HASH_FRAGMENT_DELIMITER),
          }),
        );

        break;
      }

      case SET_LAYER_OPACITY: {
        const newOpacity = action.payload as Record<string, number>;
        const state = store.getState() as AppState;
        const { hashParams } = state.router;
        const newLayersHashParamFragments: string[] = [];
        if (hashParams.l) {
          for (const hashParamLayerFragment of hashParams.l.split(LAYER_HASH_FRAGMENT_DELIMITER)) {
            const hashParamLayer = parseHashParamLayerFragment(hashParamLayerFragment);
            if (hashParamLayer && newOpacity[hashParamLayer.layerId] !== undefined) {
              hashParamLayer.opacity = newOpacity[hashParamLayer.layerId];
              newLayersHashParamFragments.push(serializeHashParamLayerFragment(hashParamLayer));
            } else {
              newLayersHashParamFragments.push(hashParamLayerFragment);
            }
          }
        }

        // resi vrstvy, ktere jsou defaultne v konfiguraci nastaveny s visible: true
        for (const [layerId, opacity] of Object.entries(newOpacity)) {
          const layer = oldState.mapCore.layers.find((layer) => layer.id === layerId);
          const layerHashParamAlreadyExists = newLayersHashParamFragments.some(
            (item) => parseHashParamLayerFragment(item)?.layerId === layerId,
          );
          if (!layer || layerHashParamAlreadyExists) {
            continue;
          }
          const oldOpacity = layer.layerOptions.opacity;

          if (oldOpacity !== opacity) {
            newLayersHashParamFragments.push(
              serializeHashParamLayerFragment({
                layerId,
                visible: state.mapCore.layers.find((layer) => layer.id === layerId)!.layerOptions
                  .visible,
                opacity,
              }),
            );
          }
        }

        store.dispatch(
          actionUpdateHashParams({
            l: newLayersHashParamFragments.join(LAYER_HASH_FRAGMENT_DELIMITER),
          }),
        );
        break;
      }

      case SET_ROUTE: {
        const { x, y, z, l, p } = action.payload.hashParams as Record<string, string>;

        if (z) {
          store.dispatch(actionSetZoom(parseFloat(z)));
        }
        if (x && y) {
          store.dispatch(actionSetCenter([parseFloat(x), parseFloat(y)]));
        }
        if (l) {
          store.dispatch(actionSetLayerVisibilityByHashParam(l) as any);
        }
        if (p) {
          for (const [panel, shortcut] of Object.entries(HASH_PARAM_PANEL)) {
            if (shortcut === p) {
              store.dispatch(actionSetPanel(panel as PANEL));
              break;
            }
          }
        }
        break;
      }
      case PREPARE_LOAD_FEATURES: {
        store.dispatch(actionLoadFeatures(action.payload) as any);
        return originalAction;
      }
      case PREPARE_SAVE_FEATURES: {
        originalAction.meta.responsePromise = store.dispatch(
          actionSaveFeatures(action.payload) as any,
        );

        return originalAction;
      }
      case SET_UI_MANAGER_STATE: {
        const defaultMapTool = action.payload.defaultMapTool;
        store.dispatch(actionActivateMapTool(defaultMapTool) as any);
        return originalAction;
      }
      case SET_MAP_TOOL: {
        if (action.payload === MAP_TOOL.NONE) {
          const defaultMapTool = oldState.uiManager.defaultMapTool;
          return store.dispatch(actionActivateMapTool(defaultMapTool) as any);
        }
        return originalAction;
      }
      case SET_PANEL: {
        const visiblePanel = action.payload as PANEL;
        if (PANELS_WITH_DEFAULT_MAP_TOOL.includes(visiblePanel)) {
          const activeMapTool =
            PANEL_DEFAULT_MAP_TOOL[visiblePanel] || oldState.uiManager.defaultMapTool;

          store.dispatch(actionActivateMapTool(activeMapTool) as any);
        }
        store.dispatch(
          actionUpdateHashParams({
            p: HASH_PARAM_PANEL[visiblePanel] ?? '',
          }),
        );
        return originalAction;
      }
      default: {
        return originalAction;
      }
    }
  });
};
