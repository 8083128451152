import { defineMessages } from 'react-intl.macro';

import { FormattedMessage } from 'react-intl';
import { useEffect, useMemo, useReducer, useState } from 'react';

import type { EWKT } from '@tmapy/config';
import { useMessage } from '@tmapy/intl';
import { useSelector } from '@tmapy/redux';
import { epsgToSridString, isValidEWKT, parseEWKT } from '@tmapy/mapcore';
import {
  FormItem,
  Select,
  SIZE,
  SvgEye,
  SvgMap,
  SvgPen,
  TertiaryBtn,
  Textarea,
} from '@tmapy/style-guide';

import { ReadOnlyFormField } from '../../components/ReadOnlyFormField';
import { msg as globalMsg } from '../../messages';

import { InlineViewComponentMap } from '../inputComponents/InlineViewComponentMap';

export const msg = defineMessages({
  drawn: {
    id: 'sys.input.geometry.drawn',
    defaultMessage: 'Geometrie vyplněna',
  },
  notDrawn: {
    id: 'sys.input.geometry.notDrawn',
    defaultMessage: 'Geometrie nevyplněna',
  },
  editInForm: {
    id: 'sys.input.geometry.editInForm',
    defaultMessage: 'Zadat geometrii ve formuláři',
  },
  showInForm: {
    id: 'sys.input.geometry.showInForm',
    defaultMessage: 'Zobrazit geometrii ve formuláři',
  },
  hideInForm: {
    id: 'sys.input.geometry.hideInForm',
    defaultMessage: 'Skrýt geometrii ve formuláři',
  },
  invalid: {
    id: 'sys.input.geometry.invalid',
    defaultMessage: 'Nevalidní geometrie',
  },
});

type GeometryInputProps = {
  data: unknown;
  isReadOnly?: boolean;
  onChange?(ewkt: EWKT | null): void;
  onInputError?(error: string): void;
  onZoomBtnClick?(): void;
};

export const GeometryInput = ({
  data,
  isReadOnly,
  onChange,
  onInputError,
  onZoomBtnClick,
}: GeometryInputProps) => {
  const formatMessage = useMessage();
  const projection = useSelector((state) => state.mapCore.view.projection);

  const [isEditVisible, toggleEdit] = useReducer((state) => !state, false);
  const { epsg, geometry } = isValidEWKT(data)
    ? parseEWKT(data)
    : { epsg: projection, geometry: '' };

  const [wkt, setWKT] = useState(geometry);

  useEffect(() => {
    setWKT(geometry);
  }, [geometry]);

  const epsgItems = useMemo(() => {
    return [{ value: epsg, label: formatMessage.fallback([epsg]) ?? epsg }];
  }, [epsg]);

  const handleChangeWKT = (e: React.ChangeEvent, { value }: { value: string }) => {
    setWKT(value);
    const ewkt = epsgToSridString(`${epsg};${value}`);
    const isValid = isValidEWKT(ewkt);
    if (isValid) {
      onChange?.(ewkt);
      onInputError?.('');
    } else if (value === '') {
      onChange?.(null);
      onInputError?.('');
    } else {
      onInputError?.(formatMessage(msg.invalid));
    }
  };

  return (
    <>
      <ReadOnlyFormField>
        <div className='sg-a-d-f sg-a-ai-c sg-a-cg-1/2'>
          <FormattedMessage {...msg[data ? 'drawn' : 'notDrawn']} />
          {onZoomBtnClick && (
            <TertiaryBtn
              icon={{ element: <SvgMap /> }}
              size={SIZE.SML}
              tooltip={formatMessage(globalMsg.showInMap)}
              onClick={onZoomBtnClick}
            />
          )}
          {!!onChange && (!isReadOnly || !!data) && (
            <TertiaryBtn
              icon={{ element: isReadOnly ? <SvgEye /> : <SvgPen /> }}
              size={SIZE.SML}
              isActive={isEditVisible}
              tooltip={formatMessage(
                isEditVisible ? msg.hideInForm : isReadOnly ? msg.showInForm : msg.editInForm,
              )}
              onClick={toggleEdit}
            />
          )}
        </div>
      </ReadOnlyFormField>
      {isEditVisible && (
        <div className='sg-a-mt-1 sg-u-vs-1'>
          {isReadOnly ? (
            <ReadOnlyFormField>
              <InlineViewComponentMap.EWKT
                data={data}
                loading={false}
                errors={[]}
                path={[]}
                variables={{}}
              />
            </ReadOnlyFormField>
          ) : (
            <>
              <FormItem>
                <Select value={epsg} items={epsgItems} onChange={() => null} />
              </FormItem>
              <Textarea value={wkt} onChange={handleChangeWKT} />
            </>
          )}
        </div>
      )}
    </>
  );
};
