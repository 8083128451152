import type { MutationResult } from '@apollo/client';
import { useState, useMemo, useEffect } from 'react';

export const useQueryState = (mutationResult: MutationResult) => {
  type QueryState = 'waiting' | 'success' | undefined;
  const [queryState, setQueryState] = useState<QueryState>(undefined);

  const queryStatus = useMemo(() => {
    if (!mutationResult.called) {
      return undefined;
    } else if (mutationResult.loading) {
      return 'waiting';
    } else if (!mutationResult.error) {
      return 'success';
    } else {
      return 'error';
    }
  }, [mutationResult]);

  useEffect(() => {
    switch (queryStatus) {
      case 'success':
        setQueryState('success');

        const timeout = window.setTimeout(() => {
          setQueryState(undefined);
        }, 2500);

        return () => {
          clearTimeout(timeout);
        };
      case 'waiting':
        setQueryState('waiting');
        break;
      default:
        setQueryState(undefined);
    }
  }, [queryStatus]);

  return queryState;
};
