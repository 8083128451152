import { useEffect } from 'react';

import { useDispatch, useSelector } from '@tmapy/redux';
import {
  INTERACTION,
  LAYER_IDS,
  MAP_TOOL,
  VectorLayerState,
  geometryTypeAndLayoutByWKT,
} from '@tmapy/config';
import { actionActivateEditTool, actionDeactivateTools } from '@tmapy/edit-tools';
import {
  GeoJSONToEWKT,
  actionInteractionSelectFeatures,
  actionSetInteraction,
  geoJSONToFeature,
} from '@tmapy/mapcore';

import type { DataComponent } from '../../types';
import type { DirectiveMap } from '../../utils/getDirectives';

import { GeometryInput } from './GeometryInput';

export const createDrawEWKTInputComponent = (
  ewktDirective: DirectiveMap['ewkt'],
): DataComponent => {
  const { layer: layerId, geometryType, projection } = ewktDirective ?? {};

  return ({ onChange, onInputError }) => {
    const dispatch = useDispatch();

    useEffect(() => {
      const { geometryType: drawGeometryType, geometryLayout } = geometryType
        ? geometryTypeAndLayoutByWKT[geometryType]
        : { geometryType: undefined, geometryLayout: undefined };
      dispatch(
        actionSetInteraction(
          {
            type: INTERACTION.DRAW,
            originLayerId: layerId,
            drawGeometryType,
            geometryLayout,
          },
          { activateDrawTool: true },
        ),
      );
      return () => {
        dispatch(actionDeactivateTools());
      };
    }, [dispatch]);

    const handleChange = (value: unknown) => {
      if (value === null) {
        const { geometryType: drawGeometryType, geometryLayout } = geometryType
          ? geometryTypeAndLayoutByWKT[geometryType]
          : { geometryType: undefined, geometryLayout: undefined };
        dispatch(
          actionSetInteraction(
            {
              type: INTERACTION.DRAW,
              originLayerId: layerId,
              drawGeometryType,
              geometryLayout,
            },
            { activateDrawTool: true },
          ),
        );
      }
      onChange?.(value);
    };

    const mapTool = useSelector((state) => state.uiManager.activeMapTool);
    const feature = useSelector(
      (state) =>
        (
          state.mapCore.layers.find(
            (layer) => layer.id === LAYER_IDS.EDIT_TOOLS_MODIFY,
          ) as VectorLayerState
        )?.features[0],
    );

    const mapProjection = useSelector((state) => state.mapCore.view.projection);

    const ewkt = feature ? GeoJSONToEWKT(feature, mapProjection, projection, true) : null;

    useEffect(() => {
      handleChange?.(ewkt);

      if (feature && mapTool === MAP_TOOL.DRAW) {
        dispatch(actionActivateEditTool(MAP_TOOL.SELECT_FEATURE));
        const olFeature = geoJSONToFeature(feature);
        dispatch(actionInteractionSelectFeatures(LAYER_IDS.EDIT_TOOLS_MODIFY, [olFeature]));
      }
    }, [dispatch, mapTool, feature, mapProjection]);

    return <GeometryInput data={ewkt} onChange={handleChange} onInputError={onInputError} />;
  };
};
