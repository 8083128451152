import React, { useMemo } from 'react';

import { useSelector } from '@tmapy/redux';
import { useOLMap } from '@tmapy/mapcore';
import { Repex } from '@tmapy/repex';
import { FormatMessage, useMessage } from '@tmapy/intl';
import { getAttributions } from '@tmapy/attributions';

import { Dialog } from '@tmapy/style-guide';

import { mapExportMsg } from './messages';

const getConfig = (formatMessage: FormatMessage) => ({
  defaultTemplate: 'allInOnePage',
  filename: 'export-{date}-{time}',
  formats: {
    pdf: {
      service: 'https://services7.tmapserver.cz/print/repex.pdf',
      label: 'PDF',
    },
    image: {
      service: 'https://services7.tmapserver.cz/print/repex.jpeg?quality=80&dpi=300',
      label: 'JPG',
    },
  },
  components: {
    ttl: {
      type: 'Text',
      label: formatMessage(mapExportMsg.title),
      content: formatMessage.fallback(['app.title']),
      style: {
        fontSize: '14pt',
        fontWeight: 'bold',
      },
      editable: true,
      place: 'center start',
    },
    map: {
      type: 'Map',
      source: undefined,
      dpi: 300,
      mapScale: 'scl',
      scale: null, // reset => prevezme vzdy ze source
    },
    scl: {
      type: 'MapScale',
      map: 'map',
      label: formatMessage(mapExportMsg.mapScale),
      editable: true,
      optional: true,
      scales: [2500, 10000, 50000, 200000],
      place: 'start end',
    },
    sln: {
      type: 'MapScaleLine',
      map: 'map',
    },
    atr: {
      type: 'MapAttribution',
      label: formatMessage(mapExportMsg.copyright),
      editable: true,
      optional: true,
      isHidden: false,
      showOverMap: true,
      content: '',
    },
  },
  defaultHeader: [
    {
      class: 'date',
      style: { marginLeft: 'auto', marginRight: 'auto' },
    },
  ],
  defaultFooter: [
    {
      text: formatMessage(mapExportMsg.footer),
      class: '',
    },
    {
      class: 'pageNumber',
      style: { marginLeft: 'auto' },
    },
    {
      text: '/',
    },
    {
      class: 'totalPages',
    },
  ],
  templates: {
    allInOnePage: {
      label: formatMessage(mapExportMsg.allInOnePage),
      pageSize: {
        A4: {
          portrait: {
            pageTemplates: [
              {
                areas: `
                                "ttl ttl ttl"
                                "map map map"
                                "sln sln scl"
                                "atr atr atr"`,
                columns: 'min-content 1fr min-content',
                rows: 'min-content 1fr max-content min-content',
              },
            ],
          },
          landscape: {
            pageTemplates: [
              {
                areas: `
                                  "ttl ttl ttl"
                                  "map map map"
                                  "sln sln scl"
                                  "atr atr atr"`,
                columns: 'min-content 1fr min-content',
                rows: 'min-content 1fr max-content min-content',
              },
            ],
          },
        },
        A3: {
          portrait: {
            pageTemplates: [
              {
                areas: `
                                "ttl ttl ttl"
                                "map map map"
                                "sln sln scl"
                                "atr atr atr"`,
                columns: 'min-content 1fr min-content',
                rows: 'min-content 1fr max-content min-content',
              },
            ],
          },
          landscape: {
            pageTemplates: [
              {
                areas: `
                                  "ttl ttl ttl"
                                  "map map map"
                                  "sln sln scl"
                                  "atr atr atr"`,
                columns: 'min-content 1fr min-content',
                rows: 'min-content 1fr max-content min-content',
              },
            ],
          },
        },
      },
    },
  },
  locale: {
    orientation: {
      portrait: formatMessage(mapExportMsg.portrait),
      landscape: formatMessage(mapExportMsg.landscape),
    },
    size: {
      a4: 'A4',
      a3: 'A3',
    },
  },
});

type MapExportProps = {
  onClose(e?: React.SyntheticEvent): void;
};

export const MapExport: React.FC<MapExportProps> = ({ onClose }) => {
  const olMap = useOLMap();
  const formatMessage = useMessage();
  const layers = useSelector((state) => state.mapCore.layers);

  const config = useMemo(() => {
    if (!olMap) return;
    const config = getConfig(formatMessage);
    config.components.map.source = olMap as any;
    config.components.atr.content = getAttributions(layers).join(', ');
    return config;
  }, [olMap, formatMessage, layers]);

  return (
    <>
      {olMap && (
        <Dialog width='100%' height='100%' onOverlayClick={onClose}>
          <Repex lg={'cz'} config={config} onCloseBtnClick={onClose} />
        </Dialog>
      )}
    </>
  );
};
