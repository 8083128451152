const mapClassName = 'tw-map';
const mapInnerClassName = 'tw-map--inner';

const getMapElement = (fullMap: boolean) => {
  return document.getElementsByClassName(fullMap ? mapClassName : mapInnerClassName)[0];
};

const getWidthMap = (fullMap: boolean): number => {
  return getMapElement(fullMap)?.clientWidth;
};

const getHeightMap = (fullMap: boolean): number => {
  return getMapElement(fullMap)?.clientHeight;
};

const getWidthDevice = (): number => {
  return window.innerWidth > 0 ? window.innerWidth : window.screen.width;
};

export const isMobileDevice = (): boolean => {
  return getWidthDevice() <= 900;
};

export const getWidthPanel = (): number => {
  const widthMap = getWidthMap(false);
  if (isMobileDevice()) {
    return widthMap;
  }
  return Math.min(400, Math.max(250, Math.round(0.8 * widthMap)));
};

export const getHeightPanel = (): number => {
  const heightMap = getHeightMap(false);
  if (isMobileDevice()) {
    return Math.round(0.6 * heightMap);
  }
  return heightMap;
};

export const getWidthPaddings = (): number => {
  return getWidthMap(true) - getWidthMap(false);
};

export const getHeightPaddings = (): number => {
  return getHeightMap(true) - getHeightMap(false);
};
