import { useSelector } from '@tmapy/redux';
import type { Permission, HasPermission } from '@tmapy/config';

import { evalPermission } from '../evalPermission';

export const createHasPermission =
  (permissions: Permission[]): HasPermission =>
  (type, field) =>
    evalPermission(permissions, type, field);

export const usePermission = (): HasPermission => {
  const permissions = useSelector((state) => state.auth.permissions);
  return createHasPermission(permissions);
};
