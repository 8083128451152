import { FormattedDate, FormattedTime } from 'react-intl';

type DateTimeProps = {
  timestamp: string;
  showSeconds?: boolean;
};

export const DateTime = ({ timestamp, showSeconds }: DateTimeProps) => (
  <time dateTime={timestamp}>
    <span className='sg-a-ws-nw'>
      <FormattedDate year='numeric' month='short' day='numeric' value={timestamp} />
    </span>{' '}
    <FormattedTime
      hour='numeric'
      minute='2-digit'
      second={showSeconds ? '2-digit' : undefined}
      value={timestamp}
    />
  </time>
);
