import type { AuthState } from '@tmapy/auth';
import { auth as defaultReducer, authInitialState as defaultInitialState } from '@tmapy/auth';
import type { Permission } from '@tmapy/config';

import { SET_PERMISSIONS } from '../../src/constants';

declare module '@tmapy/auth' {
  interface AuthState {
    permissions: Permission[];
  }
}

export const authInitialState: AuthState = {
  ...defaultInitialState,
  permissions: [
    /*
    // WILD WILD WEST
    { type: '*', fields: ['*'] },
  
    // ALLOW generic types
    { type: '*Connection', fields: ['*'] },
    { type: '*Edge', fields: ['*'] },
    { type: '*PageInfo', fields: ['*'] },
    { type: '*Payload', fields: ['*'] },

    // ALLOW Issues
    { type: 'Query', fields: ['issues', 'issue'] },
    { type: 'Issue', fields: ['_id', 'id', 'category', 'description'] },
    { type: 'Category', fields: ['*'] },

    // ALLOW ResponsiblePeople
    { type: 'Query', fields: ['responsiblePeople', 'responsiblePerson'] },
    { type: 'ResponsiblePerson', fields: ['_id', 'id', 'name', 'email'] },
    {
      type: 'Mutation',
      fields: ['deleteResponsiblePerson', 'updateResponsiblePerson', 'createResponsiblePerson'],
    },

    // ALLOW Tenants
    { type: 'Query', fields: ['tenants', 'tenant'] },
    { type: 'Tenant', fields: ['*'] },
    // {
    //   type: 'Mutation',
    //   fields: ['updateTenant', 'createTenant'],
    // },

    // ALLOW EmailBlacklist
    { type: 'Query', fields: ['emailBlacklists', 'emailBlacklist'] },
    { type: 'EmailBlacklist', fields: ['*'] },
    {
      type: 'Mutation',
      fields: ['deleteEmailBlacklist', 'updateEmailBlacklist'],
    },
    */
  ],
};

export const auth = (state = authInitialState, action: any) => {
  switch (action.type) {
    case SET_PERMISSIONS:
      return { ...state, permissions: action.payload };
    default: {
      return defaultReducer(state, action);
    }
  }
};
