import type { DocumentNode } from 'graphql';
import { Kind } from 'graphql';

export const getOperationNameFromDocument = (document: DocumentNode) => {
  const firstDefinition = document.definitions[0];
  if (
    firstDefinition.kind !== Kind.SCHEMA_DEFINITION &&
    firstDefinition.kind !== Kind.SCHEMA_EXTENSION
  ) {
    return firstDefinition.name?.value ?? '';
  }
  return '';
};
