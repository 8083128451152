import { actionSetRotation } from '@tmapy/mapcore';

import type { UserLocationProps } from './UserLocation';
import { UserLocation } from './UserLocation';
import { ResetRotation } from './ResetRotation';

export type UserPositionProps = UserLocationProps & { enableUserLocation: boolean };

export const UserPosition: React.FC<UserPositionProps> = ({
  enableUserLocation,
  userPositionMode,
  layerId,
  projection,
  rotation,
  dispatch,
}) => {
  if (enableUserLocation) {
    return (
      <UserLocation
        userPositionMode={userPositionMode}
        layerId={layerId}
        projection={projection}
        rotation={rotation}
        dispatch={dispatch}
      />
    );
  } else {
    const handleResetRorationClick = () => {
      dispatch(actionSetRotation(0));
    };
    return <ResetRotation rotation={rotation} onClick={handleResetRorationClick} />;
  }
};
