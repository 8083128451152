import { defineMessages } from 'react-intl.macro';

import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import type { Dispatch } from '@tmapy/redux';
import { useSelector, useDispatch } from '@tmapy/redux';
import { useMessage } from '@tmapy/intl';
import { BtnBar, Dialog, Checkbox, CloseDialogBtn, CtaBtn } from '@tmapy/style-guide';

import { msg as globalMsg } from '../lib/graphql';

import { useFormattedHtmlMessage } from '../hooks/useFormattedHtmlMessage';

import { actionAppPopupDismissAll, actionAppPopupUpdate } from './actions';
import type { PopupState } from './reducer';

import './AppPopups.scss';

type PopupSectionProps = {
  appId: string;
  popup: PopupState;
  dispatch: Dispatch;
};

const msg = defineMessages({
  notShowAgain: {
    id: 'sys.popup.notShowAgain',
    defaultMessage: 'Příště nezobrazovat',
  },
  ok: {
    id: 'sys.popup.ok',
    defaultMessage: 'Rozumím',
  },
});

const PopupSection: React.VFC<PopupSectionProps> = ({ appId, popup, dispatch }) => {
  const values = useFormattedHtmlMessage();

  const handleNotShowAgainChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(
        actionAppPopupUpdate(appId, {
          id: popup.id,
          notShowAgain: e.target.checked,
        }),
      );
    },
    [appId, dispatch, popup.id],
  );

  return (
    <section className='sg-u-vs-1'>
      <header className='sg-u-type-h2'>
        <FormattedMessage id={`popup.${popup.id}.title`} />
      </header>
      <div className='sg-u-vs-1/2'>
        <FormattedMessage id={`popup.${popup.id}.content.html`} values={values} />
      </div>
      {!popup.rememberNotShow ? null : (
        <Checkbox
          name={popup.id}
          onChange={handleNotShowAgainChange}
          isChecked={popup.notShowAgain}
        >
          <i className='sg-u-type-italic'>
            <FormattedMessage {...msg.notShowAgain} />
          </i>
        </Checkbox>
      )}
    </section>
  );
};

export const AppPopups: React.VFC = () => {
  const formatMessage = useMessage();
  const appId = useSelector((state) => state.app.appId);
  const popups = useSelector((state) => state.app.popups);
  const dispatch = useDispatch();

  const handleDismissAll = useCallback(() => {
    dispatch(actionAppPopupDismissAll());
  }, [dispatch]);

  if (!popups.length) return null;

  return (
    <Dialog width='90ch' onEscKeyDown={handleDismissAll} onOverlayClick={handleDismissAll}>
      <CloseDialogBtn tooltip={formatMessage(globalMsg.close)} onClick={handleDismissAll} />
      <div className='tw-appPopups'>
        {popups.map((popup, index) => (
          <div
            key={popup.id}
            className={classNames({
              'sg-a-mt-2': index > 0,
              'sg-a-pt-2': index > 0,
              'sg-u-bt': index > 0,
            })}
          >
            <PopupSection appId={appId} popup={popup} dispatch={dispatch} />
          </div>
        ))}
        <div className='tw-appPopups--footer'>
          <BtnBar>
            <CtaBtn onClick={handleDismissAll}>
              <FormattedMessage {...msg.ok} />
            </CtaBtn>
          </BtnBar>
        </div>
      </div>
    </Dialog>
  );
};
