import React from 'react';

import { useMessage } from '@tmapy/intl';
import { Legend } from '@tmapy/legend';
import { SvgList } from '@tmapy/style-guide';

import type { PanelProps } from './types';
import { mapMsg } from './messages';
import { PanelHead } from './PanelHead';

export const LegendPanel: React.FC<PanelProps> = React.memo(({ onHideBtnClick }) => {
  const formatMessage = useMessage();

  return (
    <div className='sg-a-d-f sg-a-fd-c sg-a-o-a'>
      <PanelHead
        title={formatMessage(mapMsg.legendTitle)}
        icon={<SvgList />}
        onHideBtnClick={onHideBtnClick}
      />
      <div className='sg-a-mt-1 sg-a-px-2'>
        <Legend />
      </div>
    </div>
  );
});
