import { GraphQLError } from 'graphql';
import { getVariableNameForError } from '../components/QueryError';
import type { GraphQLPath } from '../types';

export const filterErrors = (
  errors: readonly GraphQLError[],
  path: GraphQLPath,
  inputVariableName?: string,
) => {
  return errors.filter(
    (error) =>
      path.every((segment, idx) => error.path?.[idx] === segment) ||
      (inputVariableName && getVariableNameForError(error) === inputVariableName),
  );
};

export const ownErrors = (path: GraphQLPath) => (error: GraphQLError) =>
  error.path?.length === path.length;
