import type { WFSFeature } from '@tmapy/config';
import type { GeoJSONFeature } from '@tmapy/types';
import { isGeoJSONFeature } from '@tmapy/types';

export const createFeatureFromWFSResponse = (
  feature: WFSFeature | GeoJSONFeature,
): GeoJSONFeature => {
  if (isGeoJSONFeature(feature)) {
    return feature;
  }
  return {
    type: 'Feature',
    id: feature.attributes.id,
    properties: feature.attributes,
    geometry: {
      type: 'Point',
      coordinates: [feature.geometry.x, feature.geometry.y],
    },
  };
};
