import React from 'react';

import './DataLayoutSpacing.scss';

export type DataLayoutSpacingProps = {
  children: React.ReactNode;
};

export const DataLayoutSpacing: React.FC<DataLayoutSpacingProps> = ({ children }) => {
  return <div className='tw-dataLayoutSpacing'>{children}</div>;
};
