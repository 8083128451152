import { defineMessages } from 'react-intl.macro';

import { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useMessage } from '@tmapy/intl';
import {
  BtnBar,
  SecondaryBtn,
  SIZE,
  SvgMinusCircle,
  SvgPlusCircle,
  TertiaryBtn,
} from '@tmapy/style-guide';

import { DataComponent } from '../types';
import { DirectiveMap } from '../utils/getDirectives';

import './InputList.scss';

const msg = defineMessages({
  addItem: {
    id: 'sys.input.list.add',
    defaultMessage: 'Přidat položku',
  },
  removeItem: {
    id: 'sys.input.list.remove',
    defaultMessage: 'Odebrat položku',
  },
});

export const createListComponent =
  (Component: DataComponent, directives: DirectiveMap): DataComponent =>
  (props) => {
    const formatMessage = useMessage();
    const { data, onChange } = props;
    const initialValues = useMemo(() => {
      if (directives.list) {
        const listLength = directives.list.length;
        if (Array.isArray(data)) {
          const dataLength = data.length;
          if (dataLength === listLength) {
            return data;
          } else if (dataLength < listLength) {
            return [...data, new Array(listLength - dataLength).fill(null)];
          } else {
            return data.slice(0, listLength);
          }
        } else if (listLength === 1) {
          return [data];
        } else {
          return new Array(listLength).fill(null);
        }
      }

      return Array.isArray(data) ? data : [];
    }, [data]);

    const canAddItem = useMemo(() => !directives.list, []);
    const canRemoveItem = useMemo(() => !directives.list, []);
    const [values, setValues] = useState(initialValues);

    const handleRemoveItemButtonClick = useCallback(
      (e, eventData) => {
        const newValues = [...values];
        newValues.splice(eventData.data, 1);
        setValues(newValues);
        onChange?.(newValues);
      },
      [onChange, values],
    );

    const handleAddItemButtonClick = useCallback(() => {
      const newValues = [...values, null];
      setValues(newValues);
      onChange?.(newValues);
    }, [onChange, values]);

    return (
      <div className='tw-inputList'>
        {values.length > 0 && (
          <div className='tw-inputList--list'>
            {values.map((value, index) => (
              <div key={`${index}-${values.length}`} className='tw-inputList--item'>
                <Component
                  {...props}
                  data={value}
                  onChange={(value: any) => {
                    const newValues = [...values];
                    newValues[index] = value;
                    setValues(newValues);
                    onChange?.(newValues);
                  }}
                />
                {canRemoveItem && (
                  <TertiaryBtn
                    size={SIZE.SML}
                    isDanger
                    icon={{ element: <SvgMinusCircle /> }}
                    data={index}
                    tooltip={formatMessage(msg.removeItem)}
                    onClick={handleRemoveItemButtonClick}
                  />
                )}
              </div>
            ))}
          </div>
        )}
        {canAddItem && (
          <BtnBar align='left'>
            <SecondaryBtn
              icon={{ element: <SvgPlusCircle /> }}
              size={SIZE.SML}
              onClick={handleAddItemButtonClick}
            >
              <FormattedMessage {...msg.addItem} />
            </SecondaryBtn>
          </BtnBar>
        )}
      </div>
    );
  };
