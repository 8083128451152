import { useMemo, useRef, useCallback } from 'react';
import { isEqual } from 'lodash';

import type { RouteStateTWC } from '@tmapy/types';
import { useDispatch } from '@tmapy/redux';
import { useLocation } from '@tmapy/router';
import {
  actionSetCenter,
  actionSetExtent,
  actionSetZoom,
  geoJSONFeatureToGeometryCollection,
  EWKTToGeoJSONObject,
  useOLMap,
} from '@tmapy/mapcore';

import type { DataComponent } from '../../types';
import { DirectiveMap } from '../../utils/getDirectives';

import { GeometryInput } from './GeometryInput';
import { EWKTComponent } from './EWKTComponent';

export function createEWKTInputComponent(directives: DirectiveMap): DataComponent {
  const { layer: layerId, geometryType, projection, pointZoomLevel } = directives.ewkt ?? {};

  const EWKTInputComponent: DataComponent = ({
    data,
    isReadOnly,
    parentContext,
    onChange,
    onInputError,
  }) => {
    const dispatch = useDispatch();

    const prevParentContext = useRef(parentContext);
    // vime ID vrstvy, ID featury a presnou geometrii
    const featureId = parentContext?._id || parentContext?.id;

    const map = useOLMap();
    const mapProjection = map.getView().getProjection().getCode();
    const route = useLocation().route as RouteStateTWC | null;

    // TODO druhá cesta jak zpracováváme feature pro editaci - možný zdroj budoucích bugů
    const feature = useMemo(() => {
      if (!data) {
        return null;
      }
      const feature = EWKTToGeoJSONObject(data, mapProjection);
      feature.id = featureId;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { geom, ...properties } = parentContext ?? {};
      properties._originLayerId = layerId;
      properties._crs = feature.crs;
      feature.properties = { ...feature.properties, ...properties };
      return feature;
    }, [data, featureId, mapProjection, isEqual(prevParentContext.current, parentContext)]);

    const handleZoomBtnClick = useCallback(() => {
      if (feature) {
        if (feature?.geometry.type === 'Point') {
          dispatch(actionSetCenter(feature.geometry.coordinates));
          dispatch(actionSetZoom(pointZoomLevel ?? 16));
        } else {
          dispatch(actionSetExtent(geoJSONFeatureToGeometryCollection([feature]).getExtent()));
        }
      }
    }, [feature, dispatch]);

    const showMap = (route?.showMap && !!feature) || undefined;

    prevParentContext.current = parentContext;

    return (
      <>
        <GeometryInput
          data={data}
          isReadOnly={isReadOnly}
          onZoomBtnClick={showMap && handleZoomBtnClick}
          onChange={onChange}
          onInputError={onInputError}
        />
        {projection && (
          <EWKTComponent
            projection={projection}
            geometryType={geometryType}
            geoJSONFeature={feature}
            layerId={layerId}
            featureId={featureId}
            data={data}
            isReadOnly={isReadOnly}
            onChange={onChange}
          />
        )}
      </>
    );
  };

  return EWKTInputComponent;
}
