import type { MapState } from '@tmapy/config';
import { interactionInitialState } from '@tmapy/config';

export const mapInitialState: MapState = {
  layers: [],
  interaction: interactionInitialState,
  view: {
    maxZoom: 20,
    minZoom: 0,
    zoom: 6,
    rotation: 0,
    projection: 'EPSG:3857',
  },
  zoomLevelShortcuts: null,
};
