import type {
  ASTNode,
  ArgumentNode,
  FieldNode,
  FragmentDefinitionNode,
  GraphQLSchema,
  GraphQLType,
  OperationDefinitionNode,
  SelectionNode,
  VariableNode,
} from 'graphql';
import { visit, visitWithTypeInfo, TypeInfo, isObjectType, getNamedType } from 'graphql';

import { HasPermission } from '@tmapy/config';

const SYSTEM_REQUIRED_FIELDS = ['id', '_id'];

export const scrubDocument = <T extends ASTNode>(
  documentAST: T,
  schema: GraphQLSchema,
  hasPermission: HasPermission,
): T => {
  const typeStack: GraphQLType[] = [];
  let currentNamedType: GraphQLType | null = null;
  let operationSetSelection: SelectionNode[] | null = null;

  let currentFieldName = '';

  const typeInfo = new TypeInfo(schema);
  let inDirective = 0;
  let seenVariables = new Set<string>();

  return visit(
    documentAST,
    visitWithTypeInfo(typeInfo, {
      OperationDefinition: {
        enter: (operationDefinition: OperationDefinitionNode) => {
          seenVariables = new Set<string>();

          operationSetSelection = [...operationDefinition.selectionSet.selections];
          switch (operationDefinition.operation) {
            case 'query': {
              currentNamedType = schema.getQueryType()!;
              break;
            }
            case 'mutation': {
              currentNamedType = schema.getMutationType()!;
              break;
            }
          }
        },
        leave: (operationDefinition: OperationDefinitionNode) => {
          // Pokud je odebrana jedna operace z mutace, odstran vsechny operace
          const originalSelectionSetSelection = operationSetSelection!;

          operationSetSelection = null;
          if (
            operationDefinition.operation === 'mutation' &&
            operationDefinition.selectionSet.selections.length > 0 &&
            originalSelectionSetSelection.length !==
              operationDefinition.selectionSet.selections.length
          ) {
            console.debug(
              `removing all fields from mutation ${
                operationDefinition.name?.value ?? '(anonymous)'
              }`,
            );
            return {
              ...operationDefinition,
              variableDefinitions: undefined,
              selectionSet: {
                ...operationDefinition.selectionSet,
                selections: [],
              },
            };
          }

          const variableDefinitions = operationDefinition.variableDefinitions?.filter(
            (variableDef) => seenVariables.has(variableDef.variable.name.value),
          );

          return {
            ...operationDefinition,
            variableDefinitions,
          };
        },
      },
      ObjectField: {
        enter: (objectFieldNode) => {
          if (inDirective) return;
          const inputType = typeInfo.getInputType();
          if (!inputType) {
            console.debug(
              `WARN - Removing unknown object field '${objectFieldNode.name.value}' with value`,
              objectFieldNode.value,
            );
            return null;
          }
        },
      },
      Field: {
        enter: (field: FieldNode) => {
          currentFieldName = field.name.value;

          if (!currentNamedType) {
            console.warn(`removing field <unknown>.${currentFieldName}`);
            return null;
          }

          if (!hasPermission(currentNamedType.toString(), currentFieldName)) {
            console.debug(
              `[prepareClientDocument] removing unprivileged field ${currentNamedType.toString()}.${currentFieldName}`,
            );
            // remove field node from document
            return null;
          }

          if (isObjectType(currentNamedType)) {
            const fieldName = field.name.value;
            const fieldType = currentNamedType.getFields()[fieldName];
            if (!fieldType) {
              // remove unknown field (not existing in schema)
              if (SYSTEM_REQUIRED_FIELDS.includes(fieldName)) {
                console.warn(
                  `[prepareClientDocument] removing system field ${currentNamedType.toString()}.${fieldName}`,
                );
              } else {
                console.debug(
                  `[prepareClientDocument] removing field ${currentNamedType.toString()}.${fieldName}`,
                );
              }
              return null;
            }
            const currentType = fieldType.type;
            typeStack.push(currentNamedType);
            currentNamedType = getNamedType(currentType);
          }
        },
        leave: (s: FieldNode) => {
          currentNamedType = typeStack.pop()!;
          if (s.selectionSet && s.selectionSet.selections.length === 0) return null;
        },
      },
      InlineFragment: {
        leave: (fragment) => {
          if (fragment.selectionSet && fragment.selectionSet.selections.length === 0) return null;
        },
      },
      Argument: {
        enter: (argument: ArgumentNode) => {
          // do nopt touch AST if we are inside directive
          if (typeInfo.getDirective()) {
            return undefined;
          }

          const fieldDef = typeInfo.getFieldDef();
          const parentType = typeInfo.getParentType();

          const argumentName = argument.name.value;
          const fieldName = fieldDef?.name;
          const parentTypeName = parentType?.toString();

          // argument not in schema
          if (!fieldDef?.args.find((argument) => argument.name === argumentName)) {
            console.warn(
              `[prepareClientDocument] removing not-in-schema arguemnt ${argumentName} on field ${fieldName} at type ${parentTypeName}`,
            );
            return null;
          }

          if (!hasPermission(parentTypeName ?? '', fieldName ?? '', argumentName)) {
            console.debug(
              `[prepareClientDocument] removing unprivileged arguemnt ${argumentName} on field ${fieldName} at type ${currentFieldName}`,
            );
            // remove field node from document
            return null;
          }
        },
      },
      FragmentDefinition: {
        enter: (field: FragmentDefinitionNode) => {
          const typeName = field.typeCondition.name.value;
          currentNamedType = schema.getType(typeName)!;
        },
      },
      Variable: {
        enter: (variableNode: VariableNode) => {
          // if not inside query or mutation, skip
          if (!typeInfo.getFieldDef()) {
            return;
          }
          seenVariables.add(variableNode.name.value);
        },
      },
      Directive: {
        enter: () => {
          inDirective++;
        },
        leave: () => {
          inDirective--;
        },
      },
    }),
  );
};
