import { ReactNode } from 'react';
import classNames from 'classnames';

import './Diff.scss';

type DiffProps = {
  inTableHead?: boolean;
  attrName: ReactNode;
  newValue: ReactNode;
  oldValue: ReactNode;
};

export const Diff: React.FC<DiffProps> = (props) => {
  return (
    <div
      className={classNames('tw-diff', {
        'tw-diff-inTableHead': props.inTableHead,
        'tw-diff-inTableRow': !props.inTableHead,
      })}
    >
      <div className='tw-diff--attrName'>{props.attrName}</div>
      <div className='tw-diff--attrValue'>
        {props.newValue && (
          <div>
            <ins>{props.newValue}</ins>
          </div>
        )}
        {props.oldValue && (
          <div>
            <del>{props.oldValue}</del>
          </div>
        )}
      </div>
    </div>
  );
};
