import { FormattedDate } from 'react-intl';

type DateProps = {
  timestamp: string;
};

export const Date = ({ timestamp }: DateProps) => (
  <time dateTime={timestamp}>
    <FormattedDate year='numeric' month='short' day='numeric' value={timestamp} />
  </time>
);
