import type {
  OperationDefinitionNode,
  VariableDefinitionNode,
  FieldNode,
  SelectionNode,
} from 'graphql';
import { Kind, OperationTypeNode, print } from 'graphql';

import { renameVariables } from '../visitors/renameVariables';

import { getFieldAliasOrName } from './getFieldAliasOrName';

const getSelection = (selection: SelectionNode, suffix: string) => {
  if (selection.kind !== Kind.FIELD) throw new Error('Not supported');
  return {
    ...selection,
    alias: {
      kind: Kind.NAME,
      value: `${getFieldAliasOrName(selection)}${suffix}`,
    },
  };
};

const setResult = (
  selection: SelectionNode,
  suffix: string,
  resultObject: Record<string, any>,
  response: any,
) => {
  if (selection.kind !== Kind.FIELD) throw new Error('Not supported');
  const originalName = getFieldAliasOrName(selection);
  const keyName = `${originalName}${suffix}`;
  resultObject[originalName] = response.data[keyName];
};

export type OperationWithArguments<T = any> = {
  operation: OperationDefinitionNode;
  variables: Record<string, any>;
  processResult?: (response: any) => any;
  result?: T;
};

export type BatchedOperation<T = any> = {
  operation: OperationDefinitionNode;
  variables: Record<string, any>;
  result?: T;
  processResult: (response: any) => void;
};

export const batchOperations = (operations: OperationWithArguments[]): BatchedOperation => {
  let selections: FieldNode[] = [];
  let variableDefinitions: VariableDefinitionNode[] = [];
  const variables: Record<string, any> = {};

  operations.forEach((current, idx) => {
    const suffix = `__${idx}`;

    const modifiedDocument = renameVariables(
      current.operation,
      suffix,
      current.variables,
      variables,
    );

    selections = [
      ...selections,
      ...(modifiedDocument.selectionSet.selections.map((selection) => {
        if (selection.kind === Kind.INLINE_FRAGMENT) {
          return selection.selectionSet.selections.map((selection) => {
            return getSelection(selection, suffix);
          });
        }

        return getSelection(selection, suffix);
      }) as FieldNode[]),
    ];

    current.processResult = (response) => {
      current.result = {};
      modifiedDocument.selectionSet.selections.forEach((selection) => {
        if (selection.kind === Kind.INLINE_FRAGMENT) {
          selection.selectionSet.selections.map((selection) => {
            setResult(selection, suffix, current.result, response);
          });
        } else {
          setResult(selection, suffix, current.result, response);
        }
      });
    };

    variableDefinitions = [...variableDefinitions, ...(modifiedDocument.variableDefinitions ?? [])];
  });

  const operation: OperationDefinitionNode = {
    kind: Kind.OPERATION_DEFINITION,
    operation: OperationTypeNode.MUTATION,
    name: {
      kind: Kind.NAME,
      value: 'batchedEditFeatures',
    },
    variableDefinitions,
    selectionSet: { kind: Kind.SELECTION_SET, selections },
  };

  console.debug('batchOperations', print(operation), variables);

  const processResult = (response: any) => {
    operations.forEach((operation) => operation.processResult!(response));
  };

  const result: BatchedOperation = {
    operation,
    variables,
    processResult,
  };

  return result;
};
