import { defineMessages } from 'react-intl.macro';

import { useRef } from 'react';

import { useMessage } from '@tmapy/intl';
import { usePopup, Popup, TertiaryBtn, SvgMore, Link, LINK_SEVERITY } from '@tmapy/style-guide';
import { OtherLink } from '@tmapy/types';

const msg = defineMessages({
  tooltip: {
    id: 'sys.tool.more',
    defaultMessage: 'Další možnosti',
  },
});

type HeaderToolsProps = {
  links: OtherLink[];
  children?: React.ReactNode[];
};

export const HeaderTools = ({ children, links }: HeaderToolsProps) => {
  const formatMessage = useMessage();

  const popupTriggerRef = useRef<HTMLButtonElement>(null);
  const popupContentRef = useRef<HTMLUListElement>(null);
  const [isPopupShown, setPopupShown] = usePopup(popupTriggerRef, popupContentRef);
  const popupPosition = ['bottom-left' as const];

  const handleClick = () => {
    setPopupShown(!isPopupShown);
  };

  return (
    <div className='sg-a-ml-a sg-a-d-f sg-a-ai-c sg-a-ws-nw'>
      {children}
      {links.length > 0 && (
        <>
          <TertiaryBtn
            ref={popupTriggerRef}
            icon={{ element: <SvgMore /> }}
            isInverse
            tooltip={formatMessage(msg.tooltip)}
            onClick={handleClick}
          />
          {isPopupShown && (
            <Popup triggerRef={popupTriggerRef} position={popupPosition}>
              <ul ref={popupContentRef} className='sg-a-p-2 sg-u-vs-1'>
                {links.map((link) => (
                  <li key={link.url}>
                    <Link
                      href={link.url}
                      severity={LINK_SEVERITY.COLORLESS}
                      target='_blank'
                      isExternal
                    >
                      {link.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </Popup>
          )}
        </>
      )}
    </div>
  );
};
