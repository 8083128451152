import React, { useMemo } from 'react';
import type { DocumentNode } from 'graphql';
import { getNamedType, GraphQLSchema, isEnumType } from 'graphql';
import { FormattedMessage } from 'react-intl';

import { useLink, useLocation } from '@tmapy/router';
import { useMessage } from '@tmapy/intl';

import { Chip } from '../../components/Chip';
import { modifyVariables } from '../../useQueryOrMutationData';
import { msg } from '../../messages';

import { getFilterVariables, VariableDefinition } from './createTableFilterComponent';
import { createEnumComponent } from '../selectComponents/createEnumComponent';
import { createMultiEnumComponent } from '../selectComponents/createMultiEnumComponent';
import { createMultiSelectComponent } from '../selectComponents/createMultiSelectComponent';
import { createSelectComponent } from '../selectComponents/createSelectComponent';
import { InlineViewComponentMap } from '../inputComponents/InlineViewComponentMap';

const createFilterChipsComponent = (
  { name, type, isArray, directives, directiveNodes }: VariableDefinition,
  document: DocumentNode,
  schema: GraphQLSchema,
): React.ComponentType<{
  value: any;
  onChange?: (newValue: any, name: string) => void;
}> => {
  const graphqlTypes = schema.getTypeMap();
  const graphqlType = getNamedType(graphqlTypes[type]);

  let Component = InlineViewComponentMap[type];

  if (!Component) {
    Component = ({ data }) => <>{data}</>;
  }

  if (directives.ewkt) {
    Component = () => <FormattedMessage {...msg.geometryFilterValue} />;
  } else if (isEnumType(graphqlType) && isArray) {
    Component = createMultiEnumComponent(graphqlType);
  } else if (isEnumType(graphqlType)) {
    Component = createEnumComponent(graphqlType);
  } else if (directives.select) {
    const SelectComponent = isArray
      ? createMultiSelectComponent(directiveNodes, document, true)
      : createSelectComponent(directiveNodes, document, schema, true);
    Component = (props) => {
      let value = props.data;
      if (isArray && !Array.isArray(value)) {
        value = value ? [value] : [];
      }
      return <SelectComponent {...props} data={value} />;
    };
  }

  return ({ value }) => {
    const formatMessage = useMessage();
    const location = useLocation();
    const { params } = location;
    const linkRemoveFilter = useLink(location.route?.id, {
      ...params,
      [name]: undefined,
    });

    const label = formatMessage.fallback([`filter.${name}`, name]);
    if (!label) {
      return null;
    }

    return (
      <Chip label={label} onRemoveBtnClick={linkRemoveFilter.onClick}>
        <Component data={value} errors={[]} path={[]} variables={null} loading={false} isReadOnly />
      </Chip>
    );
  };
};

export const createTableFilterChipsComponent = (
  document: DocumentNode,
  schema: GraphQLSchema,
  filterVariablesName: string[],
): React.ComponentType | null => {
  const filterVariables = getFilterVariables(document, filterVariablesName);

  if (!filterVariables || !filterVariables.length) return null;

  const filterComponents = filterVariables.map((variableDefinition) => ({
    ...variableDefinition,
    Component: createFilterChipsComponent(variableDefinition, document, schema),
  }));

  return () => {
    const location = useLocation();
    const { params } = location;

    const modifiedVariables = useMemo(() => {
      return modifyVariables(document, params, filterVariables);
    }, [params]);

    const removeVariables = useMemo(
      () =>
        Object.keys(modifiedVariables ?? {}).reduce(
          (resultVariables, currentKey) => {
            resultVariables[currentKey] = undefined;
            return resultVariables;
          },
          {} as Record<string, undefined>,
        ),
      [modifiedVariables],
    );

    const linkRemoveFilter = useLink(location.route?.id, {
      ...params,
      ...removeVariables,
    });

    const filteredComponents = useMemo(
      () => filterComponents.filter(({ name }) => typeof modifiedVariables![name] !== 'undefined'),
      [modifiedVariables],
    );

    if (!filteredComponents.length) return null;

    return (
      <div className='sg-a-d-f sg-a-fw-w sg-a-ai-c sg-a-g-1/2'>
        {filteredComponents.map(({ Component, name }) => (
          <Component key={name} value={modifiedVariables![name]} />
        ))}
        {filteredComponents.length > 1 && (
          <Chip isDanger showRemoveBtn={false} onRemoveBtnClick={linkRemoveFilter.onClick}>
            <FormattedMessage {...msg.filterChipsRemoveAll} />
          </Chip>
        )}
      </div>
    );
  };
};
