import { useEffect, useRef, useState, useCallback } from 'react';
import classNames from 'classnames';

import type { SplitLayoutDefaultSize } from '@tmapy/types';
import { useSelector } from '@tmapy/redux';
import { useOLMap } from '@tmapy/mapcore';

import './SplitLayout.scss';

import { MapContainer } from 'lib/map';
import { DataLayout } from './DataLayout';
import { useSashPositionLocalStorage } from './useSashPositionLocalStorage';

type SplitLayoutProps = {
  show: 'map' | 'data' | 'both';
  children: React.ReactNode;
  routeId?: string;
  defaultSize: SplitLayoutDefaultSize;
  onLayoutChange(): void;
};

const SPLIT_LAYOUT_DEFAULT_SIZES: Record<SplitLayoutDefaultSize, string> = {
  small: 'clamp(var(--TW_SPLIT_LAYOUT_MIN_SIDE_WIDTH), 38.2%, 600px)',
  medium: 'clamp(var(--TW_SPLIT_LAYOUT_MIN_SIDE_WIDTH), 61.8%, 1200px)',
  large: 'clamp(0px, 75%, 100% - var(--TW_SPLIT_LAYOUT_MIN_SIDE_WIDTH))',
};

export const SplitLayout: React.FC<SplitLayoutProps> = ({
  show,
  children,
  routeId,
  defaultSize,
  onLayoutChange,
}) => {
  const mapRouteId = useSelector((state) => state.app.mapRouteId);
  const defaultRouteId = useSelector((state) => state.app.defaultRouteId);
  const closeRouteId = mapRouteId ?? (defaultRouteId !== routeId ? defaultRouteId : undefined);

  const appId = useSelector((state) => state.app.appId);
  const olMap = useOLMap();

  const dataSideRef = useRef<HTMLDivElement>(null);
  const sashRef = useRef<HTMLDivElement>(null);
  const clientX = useRef(Infinity);
  const startWidth = useRef(Infinity);

  const localStorageRouteKey = `${appId}.${routeId}.Sash.position`;
  const localStorageDefaultKey = `Sash.defaultPosition.${defaultSize}`;

  const [sashPosition, setSashPosition] = useSashPositionLocalStorage(
    localStorageRouteKey,
    localStorageDefaultKey,
  );
  const dataSideWidth = sashPosition ?? SPLIT_LAYOUT_DEFAULT_SIZES[defaultSize];
  const [areDataCollapsed, setDataCollapsed] = useState(false);

  const startDrag = useCallback(
    (e: PointerEvent) => {
      setSashPosition(startWidth.current + e.clientX - clientX.current + 'px');
    },
    [setSashPosition],
  );

  const stopDrag = useCallback(() => {
    document.removeEventListener('pointermove', startDrag, false);
    document.removeEventListener('pointerup', stopDrag, false);
    document.body.style.userSelect = '';
    document.body.style.webkitUserSelect = '';
  }, [startDrag]);

  const initDrag = useCallback(
    (e: PointerEvent) => {
      document.body.style.userSelect = 'none';
      document.body.style.webkitUserSelect = 'none'; // Safari
      clientX.current = e.clientX;
      startWidth.current = parseInt(window.getComputedStyle(dataSideRef.current!).width, 10);
      document.addEventListener('pointermove', startDrag, false);
      document.addEventListener('pointerup', stopDrag, false);
    },
    [startDrag, stopDrag],
  );

  useEffect(() => {
    const sash = sashRef.current;
    sash?.addEventListener('pointerdown', initDrag, false);
    return () => {
      sash?.removeEventListener('pointerdown', initDrag, false);
    };
  }, [initDrag]);

  const handleDataCollapsedToggle = () => {
    setDataCollapsed(!areDataCollapsed);
  };

  useEffect(() => {
    olMap.updateSize();
    onLayoutChange();
  }, [areDataCollapsed, show, dataSideWidth]);

  return (
    <div
      className={classNames({
        'tw-splitLayout': true,
        'tw-splitLayout-dataCollapsed': show === 'both' && areDataCollapsed,
        'tw-splitLayout-mapCollapsed': show === 'both' && !areDataCollapsed,
        'tw-splitLayout-mapShown': show === 'both' || show === 'map',
        'tw-splitLayout-dataShown': show === 'both' || show === 'data',
      })}
    >
      <div className='tw-splitLayout--data' ref={dataSideRef} style={{ width: dataSideWidth }}>
        <DataLayout
          closeRouteId={show === 'both' ? closeRouteId : undefined}
          onExpandBtnClick={areDataCollapsed ? handleDataCollapsedToggle : undefined}
        >
          {children}
        </DataLayout>
      </div>
      <div className='tw-splitLayout--sash' ref={sashRef} />
      <div className='tw-splitLayout--map'>
        <MapContainer />
        {!areDataCollapsed && (
          <button
            className='tw-splitLayout--collapseMapBtn'
            type='button'
            onClick={handleDataCollapsedToggle}
          />
        )}
      </div>
    </div>
  );
};
