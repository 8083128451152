import { defineMessages } from 'react-intl.macro';

export const msg = defineMessages({
  tooltip: {
    id: 'sys.sync.tooltip',
    defaultMessage: 'Synchronizovat data aplikace s externími systémy',
  },
  statusRunning: {
    id: 'sys.sync.status.running',
    defaultMessage: 'Synchronizace spuštěna...',
  },
  statusWaiting: {
    id: 'sys.sync.status.waiting',
    defaultMessage: 'Synchronizace je již zadána a bude spuštěna...',
  },
  statusFailed: {
    id: 'sys.sync.status.failed',
    defaultMessage: 'Poslední synchronizace skončila chybou',
  },
  statusSuccess: {
    id: 'sys.sync.status.success',
    defaultMessage: 'Poslední úspěšná synchronizace proběhla',
  },
  queryStateFail: {
    id: 'sys.sync.queryState.fail',
    defaultMessage: 'Nepodařilo se načíst informace o stavu synchronizace.',
  },
  runBtn: {
    id: 'sys.sync.run',
    defaultMessage: 'Spustit synchronizaci',
  },
  runBtnAgain: {
    id: 'sys.sync.run.again',
    defaultMessage: 'Spustit synchronizaci znovu',
  },
  runQueryStateFail: {
    id: 'sys.sync.run.queryState.fail',
    defaultMessage: 'Synchronizaci se nepodařilo spustit. Zkuste to prosím později.',
  },
});
