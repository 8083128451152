import React, { useMemo } from 'react';
import { useSelector } from '@tmapy/redux';
import { Link } from '@tmapy/router';

export const useFormattedHtmlMessage = () => {
  const routes = useSelector((state) => state.app.routes);

  const values = useMemo(() => {
    const values: Record<string, React.FC> = {};
    for (const route of routes) {
      values[`route-${route.id}`] = (chunks: React.ReactNode) => (
        <Link to={route.id}>{chunks}</Link>
      );
    }
    return values;
  }, [routes]);

  return values;
};
