import { useMessage } from '@tmapy/intl';

import { msg } from 'lib/graphql';
import { ErrorPage } from './ErrorPage';

type AccessDeniedProps = { isLoggedIn: boolean };

export const AccessDenied = ({ isLoggedIn }: AccessDeniedProps) => {
  const formatMessage = useMessage();

  if (isLoggedIn) {
    return <ErrorPage subtitle={formatMessage(msg.accessDenied)} />;
  }
  return <ErrorPage title={' '} subtitle={formatMessage(msg.loginRequired)} />;
};
