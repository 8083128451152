import React from 'react';

export type TabProps = {
  id: string;
  label: string;
  children: React.ReactNode;
};

export function Tab(props: TabProps) {
  return <>{props.children}</>;
}
