import type { GraphQLEnumType, GraphQLEnumValue } from 'graphql';
import { Kind } from 'graphql';

import { useMessage } from '@tmapy/intl';
import { Badge } from '@tmapy/style-guide';

import type { DataComponent } from '../../types';
import { getDirectivesFromDescription } from '../../utils/getDirectivesFromDescription';
import { ReadOnlyFormField } from '../../components/ReadOnlyFormField';

const createBadgeProps = (value: GraphQLEnumValue) => {
  const argumentValue = getDirectivesFromDescription(value.description)
    .find((directive) => directive.name.value === 'badge')
    ?.arguments?.find((argument) => argument.name.value === 'color')?.value;

  let badgeType: 'info' | 'success' | 'danger' | 'warning' | undefined;
  const color = argumentValue?.kind === Kind.STRING && argumentValue.value;
  switch (color) {
    case 'red':
      badgeType = 'danger';
      break;
    case 'green':
      badgeType = 'success';
      break;
    case 'black':
      badgeType = 'info';
      break;
    case 'yellow':
      badgeType = 'warning';
      break;
  }
  return [value.name, badgeType];
};

export function isBadgeType(namedType: GraphQLEnumType) {
  const badges = namedType.getValues().map(createBadgeProps);
  return badges.every(([_, badgeType]) => !!badgeType);
}

export function createBadgeComponent(namedType: GraphQLEnumType): DataComponent {
  const typeName = namedType.name;

  const badges = namedType.getValues().map(createBadgeProps);
  const isBadge = badges.every(([_, badgeType]) => !!badgeType);

  if (isBadge) {
    const badgeTypes = Object.fromEntries(badges);
    return ({ data }) => {
      const formatMessage = useMessage();
      return (
        <div>
          <Badge type={badgeTypes[data]} isStrong>
            {formatMessage.fallback([`${typeName}.${data}`, data]) ?? data}
          </Badge>
        </div>
      );
    };
  } else {
    if (badges.some(([, badgeType]) => !!badgeType)) {
      badges
        .filter(([, badgeType]) => !badgeType)
        .map(([name]) => {
          console.warn(`Unsupported badge color '${name}' in enum '${namedType}'.`);
        });
    }

    return ({ data }) => {
      const formatMessage = useMessage();
      return (
        <ReadOnlyFormField>
          {formatMessage.fallback([`${typeName}.${data}`, data]) ?? data}
        </ReadOnlyFormField>
      );
    };
  }
}
