import { Store } from 'redux';

import type {
  LegendState,
  UIManagerState,
  MapSwitcherState,
  LayerSwitcherState,
} from '@tmapy/config';
import type { AppState } from '@tmapy/redux';
import type { EditToolsState } from '@tmapy/edit-tools';
import { configureStore as configureReduxStore, combineRootReducers } from '@tmapy/redux';
import { mapCore } from '@tmapy/mapcore';
import { notes, NotesState, notesMiddleware } from '@tmapy/notes';
import { router } from '@tmapy/router';
import { mapSwitcher } from '@tmapy/map-switcher';
import { legend } from '@tmapy/legend';
import { layerSwitcher } from '@tmapy/layer-switcher';
import { search } from '@tmapy/search';
import { info } from 'lib/info';
import { intlReducer } from '@tmapy/intl';
import { editTools, editToolsMiddleware } from '@tmapy/edit-tools';
import { uiManager } from '@tmapy/ui-manager';

import { userPosition } from 'lib/user-position';

import { app } from '../app/reducer';
import { auth } from '../auth/reducer';
import { mapInitialState } from '../map/mapInitialState';

import { mapMiddleware } from './mapMiddleware';

declare module '@tmapy/redux' {
  interface AppState {
    mapSwitcher: MapSwitcherState;
    layerSwitcher: LayerSwitcherState;
    legend: LegendState;
    notes: NotesState;
    editTools: EditToolsState;
    uiManager: UIManagerState;
  }
}

export function configureStore(): Store<AppState, any> {
  combineRootReducers({
    auth,
    app,
    router,
    mapCore,
    mapSwitcher,
    info,
    intl: intlReducer,
    legend,
    layerSwitcher,
    search,
    notes,
    editTools,
    userPosition,
    uiManager,
  });

  const initialState = {
    mapCore: mapInitialState,
  };
  const store = configureReduxStore(initialState, [
    editToolsMiddleware,
    notesMiddleware,
    mapMiddleware,
  ]);

  return store;
}
