import { defineMessages } from 'react-intl.macro';

export const msg = defineMessages({
  title: {
    id: 'sys.error.title',
    defaultMessage: 'Jejda!',
  },
  defaultSubtitle: {
    id: 'sys.error.subtitle.default',
    defaultMessage: 'Něco se nepovedlo.',
  },
  notFoundPageSubtitle: {
    id: 'sys.error.subtitle.notFoundPage',
    defaultMessage: 'Požadovaná stránka neexistuje.',
  },
  notFoundAppSubtitle: {
    id: 'sys.error.subtitle.notFoundApp',
    defaultMessage: 'Požadovaná aplikace neexistuje.',
  },
  incompatibilitySubtitle: {
    id: 'sys.error.subtitle.incompatibility',
    defaultMessage: 'Nekompatibilní instalace.',
  },
  failedDeriveState: {
    id: 'sys.error.subtitle.failedDeriveState',
    defaultMessage: 'Nepodařilo se nastavit aplikaci z konfigurace.',
  },
  detailsTitle: {
    id: 'sys.error.details',
    defaultMessage: 'Podrobnosti',
  },
  homePageLink: {
    id: 'sys.error.homePageLink',
    defaultMessage: 'Úvodní stránka',
  },
});
