import classNames from 'classnames';
import { useEffect, useState } from 'react';

import './MapPanel.scss';

type MapPanelProps = {
  isActive: boolean;
  children: React.ReactNode;
};

// https://github.com/imbhargav5/rooks/blob/main/src/hooks/useTimeoutWhen.ts
function useTimeout(callback: () => void, timeoutDelayMs = 0, when = true): void {
  useEffect(() => {
    let timeout = 0;

    if (when) {
      timeout = window.setTimeout(callback, timeoutDelayMs);

      return () => {
        window.clearTimeout(timeout);
      };
    }

    if (!when && timeout !== 0) {
      window.clearTimeout(timeout);
    }
  }, [when]);
}

/* Komponenta resi nekolik samostatne oddelitelnych veci:
 * 1. Komponenta po sobe uklizi, tzn. neaktivni panel nezustava v DOMu
 *    - s tim souvisi stav `isActive` a `isActivePrev`
 *    - na jejich zaklade se urcuje, kdy se ma komponenta namountovat a odmountovat
 *    - odmountovani nastane az po provedeni animace
 * 2. Toto ma dva side efekty
 *    2.1. Kdyz se zmeni routa, tato komponenta se namounti znova a tedy dojde k animaci.
 *         Aby k tomuto nezadoucimu efektu nedochazelo, existuje stav `isFadeInActive`.
 *    2.2. Kdyz dochazi k vymene panelu (jeden se zavre, druhy otevre) a aby doslo ke spravnemu
 *         odanimovani (pmoci CSS), tak je nutne pred odmountovanim chvili pockat.
 *         Toto pomaha resit stav `isTimeoutStarted`. Duvodem je, ze pri vymene panelu dochazi
 *         k fade-in animaci s mirnym zpozdenim, na ktere se pote musi pockat. Pokud k vymene
 *         panelu nedochazi, tj. zadny panel se nezavira, pak naopak zpozdena animace neni zadouci.
 */
export const MapPanel: React.FC<MapPanelProps> = ({ isActive, children }) => {
  const [isActivePrev, setActivePrev] = useState(false);
  const [isTimeoutStarted, setTimeout] = useState(false);
  const [isFadeInActive, activateFadeIn] = useState(!isActivePrev && !isActive);

  useTimeout(
    () => {
      setActivePrev(false);
      setTimeout(false);
    },
    2000,
    isTimeoutStarted,
  );

  const handleAnimationEnd = () => {
    if (!isActive) {
      setTimeout(true);
      activateFadeIn(true);
    }
  };

  useEffect(() => {
    if (isActive) {
      setTimeout(false);
      setActivePrev(true);
    }
  }, [isActive]);

  if (isActive || isActivePrev) {
    return (
      <div
        className={classNames({
          'tw-mapPanel': true,
          'tw-mapPanel-fadeIn': isFadeInActive,
          'tw-mapPanel-fadeOut': isActivePrev && !isActive,
        })}
        onAnimationEnd={handleAnimationEnd}
      >
        {children}
      </div>
    );
  }
  return null;
};
