import type { FieldNode, SelectionNode } from 'graphql';

export const getFieldsFromFragments = (selections: readonly SelectionNode[]): FieldNode[] => {
  const fields: FieldNode[] = [];
  selections.forEach((selection) => {
    switch (selection.kind) {
      case 'InlineFragment': {
        fields.push(...getFieldsFromFragments(selection.selectionSet.selections));
        break;
      }
      case 'Field': {
        fields.push(selection);
        break;
      }
      case 'FragmentSpread': {
        break;
      }
    }
  });
  return fields;
};
