import { FieldNode, Kind } from 'graphql';
import { visit } from 'graphql/language/visitor';

export const getFieldVariableNames = (field: FieldNode, names: string[], notIn = false) => {
  const result: Record<string, string> = {};
  visit(field, {
    Argument: (node) => {
      if (result[node.name.value]) {
        return;
      }
      if (notIn !== names.includes(node.name.value) && node.value.kind === Kind.VARIABLE) {
        result[node.name.value] = node.value.name.value;
      }
    },
    ObjectField: (node) => {
      if (result[node.name.value]) {
        return;
      }
      if (notIn !== names.includes(node.name.value) && node.value.kind === Kind.VARIABLE) {
        result[node.name.value] = node.value.name.value;
      }
    },
  });
  return result;
};
