import React from 'react';

import { useMessage } from '@tmapy/intl';
import { useDispatch, useSelector } from '@tmapy/redux';
import { LAYER_IDS, PANEL } from '@tmapy/config';
import { Search } from '@tmapy/search';
import { SvgSearch } from '@tmapy/style-guide';

import { getMapPadding } from '../utils/getMapPadding';

import type { PanelProps } from './types';
import { mapMsg } from './messages';
import { PanelHead } from './PanelHead';

export const SearchPanel: React.FC<PanelProps> = React.memo(({ onHideBtnClick }) => {
  const formatMessage = useMessage();
  const dispatch = useDispatch();
  const isActive = useSelector((state) => state.uiManager.visiblePanel) === PANEL.SEARCH;
  const token = useSelector((state) => state.auth.access_token);
  const { tabs, activeTabId } = useSelector((state) => state.search);
  const highlightedFeatureIds = useSelector((state) =>
    state.mapCore.interaction.highlightedFeatures
      .filter(
        (highlightedFeature) => highlightedFeature.layerId === `${LAYER_IDS.SEARCH}-${activeTabId}`,
      )
      .map((highlightedFeature) => highlightedFeature.featureId),
  );

  return (
    <div className='sg-a-d-f sg-a-fd-c sg-a-o-a'>
      <PanelHead
        title={formatMessage(mapMsg.searchTitle)}
        icon={<SvgSearch />}
        onHideBtnClick={onHideBtnClick}
      />
      <Search
        token={token}
        isActive={isActive}
        tabs={tabs}
        activeTabId={activeTabId}
        dispatch={dispatch}
        getMapPadding={getMapPadding}
        highlightedFeatureIds={highlightedFeatureIds}
      />
    </div>
  );
});
