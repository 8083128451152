export class DateTimeRangeValue {
  public after?: Date;
  public strictly_before?: Date;
  constructor(config?: { after?: Date; strictly_before?: Date }) {
    this.after = config?.after;
    this.strictly_before = config?.strictly_before;
  }

  public static parse(strValue: string): DateTimeRangeValue | undefined {
    const [afterStr, strictly_beforeStr] = strValue.split('!');
    const after = afterStr ? new Date(afterStr) : undefined;
    const strictly_before = strictly_beforeStr ? new Date(strictly_beforeStr) : undefined;
    if (!after && !strictly_before) return undefined;
    return new DateTimeRangeValue({ after, strictly_before });
  }

  public toString() {
    if (!this.after && !this.strictly_before) return '';
    return `${this.after && !isNaN(this.after.valueOf()) ? this.after.toISOString() : ''}!${
      this.strictly_before && !isNaN(this.strictly_before.valueOf())
        ? this.strictly_before.toISOString()
        : ''
    }`;
  }
}
