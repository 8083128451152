import type {
  BooleanValueNode,
  ConstValueNode,
  EnumValueNode,
  FloatValueNode,
  IntValueNode,
  StringValueNode,
} from 'graphql';
import { Kind } from 'graphql';

export const isScalarValue = (value?: ConstValueNode) => {
  return value && [Kind.INT, Kind.FLOAT, Kind.STRING, Kind.BOOLEAN, Kind.ENUM].includes(value.kind);
};

export const assertScalarValue = (value?: ConstValueNode) => {
  if (!isScalarValue(value)) {
    throw new Error(`Expected ${value} to be a GraphQL scalar value.`);
  }
  return value as
    | IntValueNode
    | FloatValueNode
    | StringValueNode
    | BooleanValueNode
    | EnumValueNode;
};

export const getScalarValue = (value?: ConstValueNode) => {
  const scalarValue = assertScalarValue(value);
  switch (scalarValue.kind) {
    case Kind.INT:
      return parseInt(scalarValue.value, 10);
    case Kind.FLOAT:
      return parseFloat(scalarValue.value);
    default:
      return scalarValue.value;
  }
};
