import type { ActionMoveEnd } from '@tmapy/mapcore';
import { MAP_MOVE_END } from '@tmapy/mapcore';

import type { UserPositionMode, UserPositionState } from '../types';
import type { UserPositionActions } from '../actions';
import { TOOGLE_USER_POSITION_MODE } from '../actions';

const initialState: UserPositionState = {
  userPositionMode: 'LOCATION_BY_USER_WITHOUT_DEVICE_LOCATION',
};

export const userPosition = (
  state = initialState,
  action: UserPositionActions | ActionMoveEnd,
): UserPositionState => {
  switch (action.type) {
    case TOOGLE_USER_POSITION_MODE: {
      let userPositionMode: UserPositionMode = state.userPositionMode;
      switch (state.userPositionMode) {
        case 'LOCATION_AND_ORIENTATION_BY_DEVICE': {
          userPositionMode = action.payload.rotation
            ? 'LOCATION_AND_ORIENTATION_BY_USER_WITHOUT_DEVICE_LOCATION'
            : 'LOCATION_BY_USER_WITHOUT_DEVICE_LOCATION';
          break;
        }
        case 'LOCATION_BY_DEVICE': {
          userPositionMode = 'LOCATION_AND_ORIENTATION_BY_DEVICE';
          break;
        }
        case 'LOCATION_BY_USER': {
          userPositionMode = 'LOCATION_BY_DEVICE';
          break;
        }
        case 'LOCATION_AND_ORIENTATION_BY_USER': {
          userPositionMode = 'LOCATION_BY_USER_WITHOUT_DEVICE_LOCATION';
          break;
        }
        case 'LOCATION_BY_USER_WITHOUT_DEVICE_LOCATION': {
          userPositionMode = 'LOCATION_BY_DEVICE';
          break;
        }
        case 'LOCATION_AND_ORIENTATION_BY_USER_WITHOUT_DEVICE_LOCATION': {
          userPositionMode = 'LOCATION_BY_USER_WITHOUT_DEVICE_LOCATION';
          break;
        }
      }
      return {
        userPositionMode,
      };
    }

    case MAP_MOVE_END: {
      let userPositionMode: UserPositionMode = state.userPositionMode;

      if (action.payload.changedCenter) {
        switch (state.userPositionMode) {
          case 'LOCATION_AND_ORIENTATION_BY_DEVICE':
          case 'LOCATION_BY_DEVICE':
          case 'LOCATION_BY_USER':
          case 'LOCATION_AND_ORIENTATION_BY_USER': {
            userPositionMode = action.payload.rotation
              ? 'LOCATION_AND_ORIENTATION_BY_USER'
              : 'LOCATION_BY_USER';
            break;
          }

          case 'LOCATION_BY_USER_WITHOUT_DEVICE_LOCATION':
          case 'LOCATION_AND_ORIENTATION_BY_USER_WITHOUT_DEVICE_LOCATION': {
            userPositionMode = action.payload.rotation
              ? 'LOCATION_AND_ORIENTATION_BY_USER_WITHOUT_DEVICE_LOCATION'
              : 'LOCATION_BY_USER_WITHOUT_DEVICE_LOCATION';
            break;
          }
        }
      } else if (action.payload.changedRotation) {
        switch (state.userPositionMode) {
          case 'LOCATION_AND_ORIENTATION_BY_DEVICE':
          case 'LOCATION_BY_DEVICE': {
            userPositionMode = action.payload.rotation
              ? 'LOCATION_AND_ORIENTATION_BY_DEVICE'
              : 'LOCATION_BY_DEVICE';
            break;
          }

          case 'LOCATION_BY_USER':
          case 'LOCATION_AND_ORIENTATION_BY_USER': {
            userPositionMode = action.payload.rotation
              ? 'LOCATION_AND_ORIENTATION_BY_USER'
              : 'LOCATION_BY_USER';
            break;
          }

          case 'LOCATION_BY_USER_WITHOUT_DEVICE_LOCATION':
          case 'LOCATION_AND_ORIENTATION_BY_USER_WITHOUT_DEVICE_LOCATION': {
            userPositionMode = action.payload.rotation
              ? 'LOCATION_AND_ORIENTATION_BY_USER_WITHOUT_DEVICE_LOCATION'
              : 'LOCATION_BY_USER_WITHOUT_DEVICE_LOCATION';
            break;
          }
        }
      }

      return {
        userPositionMode,
      };
    }

    default: {
      return state;
    }
  }
};
