import { OperationDefinitionNode } from 'graphql';
import { getVariableNames } from './getVariableNames';

export const getNewVariableName = (
  operation: OperationDefinitionNode,
  suggestedName: string,
): string => {
  const usedNames = getVariableNames(operation);
  if (!usedNames.has(suggestedName)) return suggestedName;
  for (let i = 1; ; i++) {
    const testedName = `${suggestedName}${i}`;
    if (!usedNames.has(testedName)) return testedName;
  }
};
