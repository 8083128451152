import {
  DocumentNode,
  FieldNode,
  GraphQLSchema,
  GraphQLType,
  OperationDefinitionNode,
  FragmentDefinitionNode,
  isUnionType,
} from 'graphql';
import { isObjectType, getNullableType, getNamedType } from 'graphql';
import { visit } from 'graphql/language/visitor';
import { prepareClientDocument } from './prepareClientDocument';
import { removeDirectives } from './removeDirectives';

/**
 * Filter AST from client-only extensions
 */
export function prepareServerDocument(
  documentNode: DocumentNode,
  clientSchema: GraphQLSchema,
): DocumentNode {
  const typeStack: any[] = [];

  const currentPath: string[] = [];
  let currentNamedType: GraphQLType | null = null;

  const clientDocument = removeDirectives(
    prepareClientDocument(documentNode, clientSchema, () => true),
  );

  return visit(clientDocument, {
    OperationDefinition: (operationDefinition: OperationDefinitionNode) => {
      switch (operationDefinition.operation) {
        case 'query': {
          currentNamedType = clientSchema.getQueryType()!;
          break;
        }
        case 'mutation': {
          currentNamedType = clientSchema.getMutationType()!;
          break;
        }
        default: {
          console.error();
        }
      }
    },
    Field: {
      enter: (s: FieldNode) => {
        const aliasOrName = s.alias ? s.alias.value : s.name.value;
        currentPath.push(aliasOrName);

        if (isUnionType(currentNamedType)) {
          throw new Error(`Union field ${aliasOrName}: ${currentNamedType.name} not supported`);
        }

        if (!isObjectType(currentNamedType)) {
          throw new Error(
            `Not object type: ${currentNamedType?.toString() ?? '<unknown>'} at ${currentPath.join(
              '.',
            )}`,
          );
        }

        typeStack.push(currentNamedType);
        const currentType = currentNamedType.getFields()[s.name.value]?.type;
        if (!currentType) {
          console.warn(`[prepareServerDocument] Removing field ${s.name.value} - unknown type`);
          return null;
        }

        currentNamedType = getNamedType(currentType);

        s.type = currentType;
        s.nullableType = getNullableType(currentType);
        s.namedType = currentNamedType;
      },
      leave: () => {
        currentNamedType = typeStack.pop();
        currentPath.pop();
      },
    },
    FragmentDefinition: {
      enter: (field: FragmentDefinitionNode) => {
        const typeName = field.typeCondition.name.value;
        currentNamedType = clientSchema.getType(typeName)!;
      },
    },
  });
}
