import { VERSION } from '../constants';

export const addVersionToUrl = (url: URL) => {
  url.searchParams.append('v', VERSION);
  return url;
};

export const addVersionToUrlString = (url: string) => {
  return addVersionToUrl(new URL(url, window.location.href)).toString();
};
