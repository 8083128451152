import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ApolloError, gql, useMutation } from '@apollo/client';

import { actionUseAccessToken } from '@tmapy/auth';

export const createFile = gql`
  mutation createFile($name: String!, $context: String!) {
    createFile(input: { name: $name, context: $context }) {
      file {
        id
        _id
        key
        uploadUrl
      }
    }
  }
`;

export const useUploadFile = ({ onChange }: { onChange?: (newValue: any) => void }) => {
  const dispatch = useDispatch();
  const [createFileMutation] = useMutation(createFile);
  const context = '/api/';
  const [isUploading, setIsUploading] = useState(false);

  const uploadFile = async (file: File | null): Promise<string | undefined> => {
    if (!file) return;
    const result = await createFileMutation({
      variables: {
        name: file.name,
        context,
      },
    });

    if (result.errors) {
      throw new ApolloError({
        errorMessage: '`CreateFileMutation` has result with errors.',
        graphQLErrors: result.errors,
      });
    } else if (result && onChange) {
      const createFileResponse = result.data.createFile.file;

      const uploadUrl = createFileResponse.uploadUrl;
      await dispatch(
        actionUseAccessToken(async (access_token) => {
          const headers: HeadersInit = {
            Accept: 'application/json',
          };
          if (access_token) {
            headers.Authorization = `Bearer ${access_token}`;
          }

          const formData = new FormData();
          formData.append('file', file);

          await fetch(uploadUrl, {
            method: 'POST',
            body: formData,
            headers,
          });
        }),
      );
      return createFileResponse.key;
    }
  };

  const handleChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    async (event) => {
      const promises: Promise<string | undefined>[] = [];

      const files = event.target.files;

      if (!files?.length) return;
      setIsUploading(true);

      for (let i = 0; i < files.length; i++) {
        const file = files?.item(i);
        promises.push(uploadFile(file));
      }

      const fileKeys = await Promise.all(promises);
      onChange?.(fileKeys.length === 1 ? fileKeys[0] : fileKeys);

      setIsUploading(false);
    },
    [createFileMutation, onChange, dispatch],
  );

  return { isUploading, handleChange };
};
