import React from 'react';
import type {
  DocumentNode,
  FragmentDefinitionNode,
  GraphQLFieldMap,
  GraphQLSchema,
  SelectionNode,
} from 'graphql';

import { useMessage } from '@tmapy/intl';

import type { ComponentFactory, DataComponent } from '../types';
import { nameComponent } from '../utils/nameComponent';
import { getDirectives, hiddenFieldsFilter } from '../utils/getDirectives';
import { getDirectivesFromDescription } from '../utils/getDirectivesFromDescription';
import { getFieldAliasOrName } from '../utils/getFieldAliasOrName';
import { createFilterForDuplicatePasswords } from '../utils/createFilterForDuplicatePasswords';
import { filterErrors } from '../utils/filterErrors';
import { Section } from '../components/Section';

import { createInlineViewComponent } from './inputComponents/createInlineViewComponent';

const createGroupComponent = (
  Component: DataComponent,
  groupMsgId?: string | undefined,
): DataComponent => {
  return (props) => {
    const formatMessage = useMessage();
    const title = formatMessage.fallback([`form.group.${groupMsgId}`]);
    return (
      <Section title={title}>
        <div className='sg-u-box sg-a-p-2 tw-u-vs-3/2'>
          <Component {...props} />
        </div>
      </Section>
    );
  };
};

export function createSelectionSetComponent(
  queryColumns: readonly SelectionNode[],
  columnTypes: GraphQLFieldMap<any, any>,
  document: DocumentNode,
  schema: GraphQLSchema,
  intlPrefix: string | null,
  createComponent: ComponentFactory = createInlineViewComponent,
  renderAfter: React.ReactNode = null,
): DataComponent {
  if (queryColumns.length === 0) {
    console.warn('SelectionSet is empty, possibly access denied');
    return () => null;
  }

  const visibleFields = queryColumns
    .filter(hiddenFieldsFilter)
    .filter(createFilterForDuplicatePasswords(columnTypes));

  const ColumnComponents = visibleFields.map((selection) => {
    switch (selection.kind) {
      case 'Field': {
        const description = columnTypes[selection.name.value].description;
        const directivesFromSchema = getDirectivesFromDescription(description);

        return createComponent(
          columnTypes[selection.name.value],
          directivesFromSchema,
          selection,
          document,
          schema,
          intlPrefix,
        );
      }
      case 'InlineFragment': {
        const directives = getDirectives(selection.directives);
        const selections = selection.selectionSet.selections;

        const Component = createSelectionSetComponent(
          selections,
          columnTypes,
          document,
          schema,
          intlPrefix,
          createComponent,
          renderAfter,
        );

        if (directives.group && selections.length > 0) {
          const groupMsgId = directives.group.id;
          return createGroupComponent(Component, groupMsgId);
        } else {
          return Component;
        }
      }
      case 'FragmentSpread': {
        const fragmentName = selection.name.value;
        const directives = getDirectives(selection.directives);
        const fragmentDefinition = document.definitions.find(
          (def) => def.kind === 'FragmentDefinition' && def.name.value === fragmentName,
        ) as FragmentDefinitionNode | undefined;

        if (!fragmentDefinition) {
          return () => <>Undefined fragment: ${fragmentName}</>;
        }

        const Component = createSelectionSetComponent(
          fragmentDefinition.selectionSet.selections,
          columnTypes,
          document,
          schema,
          intlPrefix,
          createComponent,
          renderAfter,
        );

        if (!Component) {
          return () => <>Cound not create component for fragment: ${fragmentName}</>;
        }

        if (directives.group) {
          const groupMsgId = directives.group.id;
          return createGroupComponent(Component, groupMsgId);
        } else {
          return Component;
        }
      }
      default: {
        console.warn(`COLUMN TYPE not supported`);
        return () => <>UNKNOWN COLUMN TYPE</>;
      }
    }
  });

  return nameComponent(
    'SelectionSet',
    ({ data, errors, path, variables, loading, onChange, isRequired, isReadOnly }: any) => (
      <>
        {visibleFields.map((column, index) => {
          switch (column.kind) {
            case 'Field': {
              const columnId = getFieldAliasOrName(column);
              const Component = ColumnComponents[index];
              const subPath = [...path, columnId];
              return (
                <React.Fragment key={columnId}>
                  <Component
                    data={data ? data[columnId] : null}
                    errors={filterErrors(errors, subPath, columnId)}
                    path={subPath}
                    variables={variables}
                    loading={loading}
                    onChange={onChange}
                    isRequired={isRequired}
                    isReadOnly={isReadOnly}
                    parentContext={data}
                  />
                  {renderAfter}
                </React.Fragment>
              );
            }
            default: {
              const columnId = index;
              const Component = ColumnComponents[index];
              return (
                <React.Fragment key={columnId}>
                  <Component
                    data={data}
                    errors={errors}
                    path={path}
                    variables={variables}
                    loading={loading}
                    onChange={onChange}
                    isRequired={isRequired}
                    isReadOnly={isReadOnly}
                    parentContext={data}
                  />
                  {renderAfter}
                </React.Fragment>
              );
            }
          }
        })}
      </>
    ),
  );
}
