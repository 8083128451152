import { useCallback, useState } from 'react';
import type {
  VariableDefinitionNode,
  DocumentNode,
  GraphQLType,
  DirectiveNode,
  GraphQLSchema,
} from 'graphql';
import { Kind, getNamedType, isEnumType } from 'graphql';

import { useMessage } from '@tmapy/intl';
import { FormItem, FormRow } from '@tmapy/style-guide';

import type { DataComponent, DataProps } from '../../types';
import { getDirectives } from '../../utils/getDirectives';
import { getNamedTypeNode } from '../../utils/getNamedTypeNode';
import { GraphQLQueryError } from '../../components/QueryError';
import { msg } from '../../messages';

import { createEnumComponent } from '../selectComponents/createEnumComponent';
import { createSelectComponent } from '../selectComponents/createSelectComponent';
import { createMultiSelectComponent } from '../selectComponents/createMultiSelectComponent';
import { InputComponentMap } from '../inputComponents/InputComponentMap';
import { createListComponent } from '../createListComponent';
import { createSelectFeatureInputComponent } from '../geometryComponents/createSelectFeatureInputComponent';
import { createDrawEWKTInputComponent } from '../geometryComponents/createDrawEWKTInputComponent';

export const createVariableInputComponent = (
  graphqlType: GraphQLType,
  directivesFromSchema: readonly DirectiveNode[],
  variableDefinition: VariableDefinitionNode,
  document: DocumentNode,
  schema: GraphQLSchema,
  intlPrefix: string | null,
): DataComponent => {
  let unwrapNonNull = variableDefinition.type;
  if (unwrapNonNull.kind === Kind.NON_NULL_TYPE) {
    unwrapNonNull = unwrapNonNull.type;
  }
  const namedType = getNamedType(graphqlType);

  const typeName = getNamedTypeNode(variableDefinition.type).name.value;
  const isRequired = variableDefinition.type.kind === 'NonNullType';
  const variableName = variableDefinition.variable.name.value;
  const label = variableName;

  const directiveNodes = [...directivesFromSchema, ...(variableDefinition.directives ?? [])];
  const directives = getDirectives(directiveNodes);

  let Component = InputComponentMap[typeName];

  if (isEnumType(namedType)) {
    Component = createEnumComponent(namedType);
  }

  if (directives.select && unwrapNonNull.kind === Kind.LIST_TYPE) {
    Component = createMultiSelectComponent(directiveNodes, document);
  } else if (directives.select) {
    Component = createSelectComponent(directiveNodes, document, schema);
  }

  if (!Component) {
    Component = InputComponentMap.String;
  }

  if (directives.ewkt) {
    Component = createDrawEWKTInputComponent(directives.ewkt);
  }
  if (directives.selectFeature) {
    Component = createSelectFeatureInputComponent(variableDefinition, directives.selectFeature);
  }
  if (directives.text) {
    Component = directives.text.multiline
      ? InputComponentMap.MultilineString
      : InputComponentMap.String;
  }

  if (unwrapNonNull.kind === Kind.LIST_TYPE && !directives.select) {
    if (typeName === 'JSON') {
      Component = InputComponentMap.ArrayJSON;
    } else {
      Component = createListComponent(Component, directives);
    }
  }

  if (Component) {
    return ({ data, errors, path, variables, loading, isReadOnly, onChange }: DataProps) => {
      const formatMessage = useMessage();
      const [errorMesssage, setErrorMessage] = useState('');
      const help = formatMessage.fallback([`${intlPrefix}.${label}.help`, `${label}.help`]);
      const hint = formatMessage.fallback([`${intlPrefix}.${label}.hint`, `${label}.hint`]);

      const handleChange = useCallback(
        (value: any) => {
          onChange?.({
            [variableName]: value,
          });
        },
        [onChange],
      );

      const handleInputError = useCallback((errorMessage) => {
        setErrorMessage(errorMessage);
      }, []);

      return (
        <FormRow>
          <FormItem
            id={`aria-${label}`}
            label={formatMessage.fallback([intlPrefix && `${intlPrefix}.${label}`, label]) ?? label}
            isRequired={isRequired}
            errorMessage={
              errors.length || errorMesssage ? (
                <>
                  {errors.map((error, idx) => (
                    <GraphQLQueryError key={idx} error={error} />
                  ))}
                  {errorMesssage}
                </>
              ) : undefined
            }
            hint={hint && !isReadOnly ? hint : undefined}
            help={help && !isReadOnly ? help : undefined}
            helpLabel={formatMessage(msg.formItemHelp)}
            requiredLabel={formatMessage(msg.formItemRequired)}
          >
            <Component
              data={data}
              errors={errors}
              path={path}
              variables={variables}
              loading={loading}
              validate={directives.validate}
              isRequired={isRequired}
              isReadOnly={isReadOnly}
              formType='new'
              onInputError={handleInputError}
              onChange={handleChange}
            />
          </FormItem>
        </FormRow>
      );
    };
  } else {
    console.error(`createVariableInputComponent: unknown Component type: ${typeName}`);
    return () => null;
  }
};
