import React, { useCallback, useMemo } from 'react';
import { GraphQLEnumType } from 'graphql';

import { useMessage } from '@tmapy/intl';
import { CustomSelect, RadioGroup } from '@tmapy/style-guide';

import type { DataComponent } from '../../types';
import { ReadOnlyFormField } from '../../components/ReadOnlyFormField';
import { msg } from '../../messages';

import { renderSelectValue, type Item } from './getTableData';

export const createEnumComponent = (namedType: GraphQLEnumType): DataComponent => {
  const name = namedType.name;
  const namedTypeValues = namedType.getValues();

  return ({ data, isRequired, errors, loading, onChange, isReadOnly }) => {
    const formatMessage = useMessage();
    const getLabel = useCallback(
      (value: string) => formatMessage.fallback([`${name}.${value}`, value], { value }) ?? value,
      [formatMessage],
    );

    const value = {
      value: data,
      label: getLabel(data),
    };

    const items = useMemo(
      () =>
        namedTypeValues.map((namedTypeValue) => {
          const value = namedTypeValue.value;
          const label = getLabel(value);
          return { value, label };
        }),
      [namedTypeValues, getLabel],
    );

    const handleChangeRadioGroup = (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(e.target.value ?? null);
    };

    const handleChangeSelect = (e: React.MouseEvent, eventData: { value: Item | null }) => {
      onChange?.(eventData.value?.value ?? null);
    };

    if (isReadOnly) {
      return (
        <ReadOnlyFormField>{value.label ? value.label : <span data-empty />}</ReadOnlyFormField>
      );
    }

    if ((isRequired && namedTypeValues.length < 5) || name === 'RelatedSelectionStrategy') {
      return (
        <RadioGroup
          value={value.value}
          items={items}
          onChange={handleChangeRadioGroup}
          isInvalid={!!errors.length}
        />
      );
    }

    return (
      <CustomSelect
        value={value}
        items={items}
        hasClearBtn={!isRequired}
        renderItem={renderSelectValue}
        renderValue={renderSelectValue}
        onChange={handleChangeSelect}
        isInvalid={!!errors.length}
        isDisabled={loading}
        noItemsMessage={formatMessage(msg.noItems)}
      />
    );
  };
};
