import React, { useEffect, useMemo } from 'react';

import type { GeoJSONFeature, GeoJSONPoint } from '@tmapy/types';
import type { Dispatch } from '@tmapy/redux';
import { QUERY_STATE } from '@tmapy/utils';
import {
  ArcGISRestInfoState,
  InfoDataState,
  WFSInfoState,
  EWKT,
  WMSInfoState,
} from '@tmapy/config';
import { convertCoordToPixel, EWKTToGeoJSONObject, useOLMap } from '@tmapy/mapcore';

import {
  actionFetchArcGISRestInfoData,
  actionFetchWFSInfoData,
  actionFetchWMSInfoData,
} from '../actions';
import { InfoItemFromHtmlOutput } from './InfoItemFromHtmlOutput';
import { InfoItemFromJsonOutput } from './InfoItemFromJsonOutput';

export type InfoAtPointProps = {
  geom: EWKT;
  distanceInMeters: number;
  config: WFSInfoState | WMSInfoState | ArcGISRestInfoState;
  isVisible: boolean;
  dispatch: Dispatch;
  data: InfoDataState;
};

export const InfoAtPoint: React.FC<InfoAtPointProps> = React.memo(
  ({ geom, distanceInMeters, config, dispatch, isVisible, data }) => {
    const point = useMemo(() => {
      const geoJSON = EWKTToGeoJSONObject(geom) as GeoJSONFeature<GeoJSONPoint>;
      return geoJSON.geometry.coordinates;
    }, [geom]);

    const map = useOLMap();

    useEffect(() => {
      switch (config.type) {
        case 'WFS': {
          dispatch(actionFetchWFSInfoData(config, point, distanceInMeters));
          break;
        }
        case 'WMS': {
          const pixelPoint = convertCoordToPixel(point, map);
          dispatch(actionFetchWMSInfoData(config, pixelPoint, map));
          break;
        }
        case 'ArcGISRest': {
          dispatch(actionFetchArcGISRestInfoData(config, point, distanceInMeters));
          break;
        }
      }
    }, [config, point]);

    const queryState = data?.queryState;

    if (!isVisible || queryState !== QUERY_STATE.SUCCESS) {
      return null;
    }

    switch (config.type) {
      case 'WMS':
        return <InfoItemFromHtmlOutput id={config.id} html={data.html ?? ''} />;
      case 'ArcGISRest':
      case 'WFS':
        return <InfoItemFromJsonOutput config={config} items={data.items} />;
      default:
        return null;
    }
  },
);
