import { defineMessages } from 'react-intl.macro';

import { FormattedMessage } from 'react-intl';

import { useMessage } from '@tmapy/intl';
import { LINK_SEVERITY, Link } from '@tmapy/style-guide';

import './AboutPage.scss';

import { PageTitle, DataLayoutSpacing } from 'lib/graphql';

import { useFormattedHtmlMessage } from '../hooks/useFormattedHtmlMessage';

const msg = defineMessages({
  pageTitle: { id: 'sys.page.about.title', defaultMessage: 'O aplikaci' },
});

type AboutPageProps = {
  appId: string;
};

export const AboutPage = ({ appId }: AboutPageProps) => {
  const values = useFormattedHtmlMessage();
  const formatMessage = useMessage();

  return (
    <DataLayoutSpacing>
      <main className='tw-aboutPage'>
        <PageTitle>
          <FormattedMessage {...msg.pageTitle} />
        </PageTitle>
        <div className='sg-a-mt-2 sg-u-vs-2'>
          {formatMessage.fallback([`${appId}.about.content.html`, 'about.content.html'], {
            ...values,
            P: (children) => <p>{children}</p>,
            LINK: (children) =>
              typeof children[0] === 'string' ? (
                <Link
                  href={children[0]}
                  target='_blank'
                  severity={LINK_SEVERITY.COLORFUL}
                  isExternal={!children.find((child: any) => child?.type === 'img')}
                >
                  {children.length > 1 ? children.slice(1) : children}
                </Link>
              ) : null,
            MAIL: (children) =>
              typeof children[0] === 'string' ? (
                <Link href={`mailto:${children[0]}`} severity={LINK_SEVERITY.COLORFUL}>
                  {children.length > 1 ? children.slice(1) : children}
                </Link>
              ) : null,
            IMG: (children) => (typeof children[0] === 'string' ? <img src={children[0]} /> : null),
            B: (children) => <b className='sg-u-type-bold'>{children}</b>,
            SECTION: (children) => <section className='sg-u-vs-1'>{children}</section>,
            H: (children) => <h2 className='sg-u-type-h2'>{children}</h2>,
            LOGOS: (children) => (
              <section className='tw-aboutPage--logos sg-a-d-f sg-a-fw-w sg-a-jc-se sg-a-ai-c sg-a-g-1 sg-a-py-2'>
                {children}
              </section>
            ),
          })}
        </div>
      </main>
    </DataLayoutSpacing>
  );
};
