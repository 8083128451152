import { ApolloClient, ApolloError, InMemoryCache, createHttpLink } from '@apollo/client';
import type { NormalizedCacheObject, HttpOptions, ServerError } from '@apollo/client';
import { buildClientSchema, extendSchema } from 'graphql';
import type { GraphQLSchema } from 'graphql';

import type { Dispatch } from '@tmapy/redux';
import { isIncompatibilityModules, COMPATIBLE_VERSION } from '@tmapy/config';
import { actionUseAccessToken } from '@tmapy/auth';

import { GRAPHQL_ERROR_CODE } from './constants';
import { prepareServerDocument } from './visitors/prepareServerDocument';

import introspectionQuery from './introspectionQuery.graphql';
import clientSchemaExtensions from './client.schema.graphql';
import installedModulesQuery from './installedModulesQuery.graphql';

export declare class ExtendedApolloClient<
  TCacheShape = NormalizedCacheObject,
> extends ApolloClient<TCacheShape> {
  public serverSchema: GraphQLSchema;
  public clientSchema: GraphQLSchema;
  public typePrefixMappings: Record<string, string>;
  error: ApolloError;
}

/**
 * As API platform have per server global unique id (URL), we do not need __typename
 */
const dataIdFromObject = (obj: any) => obj?.id;

// regular na `@type ( prefix : "…"`
const RE_TYPE_DIRECTIVE = /@type\s*\(\s*prefix\s*:\s*("[^"]*")/;

export const createClient = async (
  dispatch: Dispatch,
  httpOptions: HttpOptions,
  useAccessToken: boolean,
) => {
  const fetchWithAccessToken = async (
    requestInfo: RequestInfo | URL,
    requestInit?: RequestInit,
  ) => {
    return dispatch(
      actionUseAccessToken((access_token) => {
        const init = requestInit ?? ({} as RequestInit);
        if (access_token) {
          const headers = new Headers(init.headers);
          headers.set('Authorization', `Bearer ${access_token}`);
          init.headers = headers;
        }
        return fetch(requestInfo, init);
      }),
    ) as any as Promise<Response>;
  };

  const link = httpOptions.uri
    ? createHttpLink({ ...httpOptions, fetch: useAccessToken ? fetchWithAccessToken : fetch })
    : createHttpLink({
        fetch: async () => {
          return {
            json: async () => {
              return {
                data: null,
              };
            },
          } as any;
        },
      });

  const client = new ApolloClient({
    cache: new InMemoryCache({
      resultCaching: false,
      dataIdFromObject,
    }),
    link,
    connectToDevTools: false,
    defaultOptions: {
      mutate: {
        errorPolicy: 'all',
      },
      query: {
        errorPolicy: 'all',
      },
      watchQuery: {
        errorPolicy: 'all',
      },
    },
  }) as ExtendedApolloClient;

  let data;
  try {
    const response = await client.query({
      query: introspectionQuery,
    });
    data = response.data;
    if (response.errors) {
      throw new ApolloError({ graphQLErrors: response.errors });
    }
  } catch (err) {
    if (err instanceof ApolloError) {
      client.error = err;
      if (err.networkError) {
        const serverError = err.networkError as ServerError;
        const errors = typeof serverError.result !== 'string' && serverError.result?.errors;
        client.error.graphQLErrors = err.graphQLErrors.concat(
          errors ?? [{ code: GRAPHQL_ERROR_CODE.INTERNAL_SERVER_ERROR, message: '' }],
        );
      }
    }
    if (httpOptions.uri) {
      console.error(`Cannot fetch schema from ${httpOptions.uri}`, err);
    }
  }
  if (!data) {
    data = {
      __schema: {
        types: [],
      },
    };
  }

  const assumeValid = false;

  const schemaFromServer = buildClientSchema(data, {
    assumeValid,
  });

  const typePrefixMappings = {} as Record<string, string>;
  for (const [typeName, type] of Object.entries(schemaFromServer.getTypeMap())) {
    const { description } = type;
    if (description) {
      const match = RE_TYPE_DIRECTIVE.exec(description);
      const prefixStr = match?.[1];
      if (prefixStr) {
        const prefix = JSON.parse(prefixStr);
        typePrefixMappings[typeName] = prefix;
      }
    }
  }
  client.typePrefixMappings = typePrefixMappings;

  client.serverSchema = schemaFromServer;

  client.clientSchema = extendSchema(client.serverSchema, clientSchemaExtensions, {
    assumeValid,
    assumeValidSDL: assumeValid,
  });

  return client;
};

export const showInstalledModules = async (
  client: ExtendedApolloClient<NormalizedCacheObject>,
  clientVersion: string,
  packageId?: string,
): Promise<{ incompatibility: boolean; packageVersion?: string }> => {
  let incompatibility = false;
  let packageVersion;
  const query = prepareServerDocument(installedModulesQuery, client.clientSchema);

  const consoleWarnText = '[installedModules] Cannot read installedModules data';
  if (client.clientSchema.getQueryType()?.getFields().installedModules) {
    try {
      const response = await client.query({
        query,
        variables: {
          clientVersion,
        },
      });
      const data = response.data;
      if (response.errors || !data.installedModules) {
        console.warn(consoleWarnText, response.errors);
        return { incompatibility };
      }

      incompatibility = isIncompatibilityModules(data.installedModules.edges, COMPATIBLE_VERSION);
      packageVersion =
        packageId &&
        data.installedModules.edges.find(
          ({ node }: { node: Record<string, any> }) => node.name === packageId,
        )?.node.version;
    } catch (err) {
      console.warn(consoleWarnText, err);
    }
  } else {
    // support not installed
    console.info(consoleWarnText);
  }
  return { incompatibility, packageVersion };
};
