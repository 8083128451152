import { useCallback, useEffect, useState } from 'react';
import { useStore } from 'react-redux';

import { useDispatch, useSelector, batch } from '@tmapy/redux';
import { useMessage } from '@tmapy/intl';
import { LAYER_IDS, PANEL } from '@tmapy/config';
import {
  MapScale,
  MapView,
  Zoom,
  MAPSCALE_TARGET,
  Interactions,
  actionSetExtent,
  actionSetLayerVisibility,
} from '@tmapy/mapcore';
import { Attributions } from '@tmapy/attributions';
import { actionSetPanel } from '@tmapy/ui-manager';
import { actionActivateMeasure } from '@tmapy/measure';
import { actionActivateSearch } from '@tmapy/search';
import { actionActivateNotes } from '@tmapy/notes';
import {
  SvgPrinter,
  SvgLayers,
  SvgList,
  SvgRuler,
  SvgSearch,
  SvgPin,
  SvgFit,
  Statusbar,
  TertiaryBtn,
  SecondaryBtn,
} from '@tmapy/style-guide';

import { UserPosition } from 'lib/user-position';

import './MapContainer.scss';

import { actionDeactivatePanel } from '../actions';

import { mapMsg } from './messages';
import { MapSwitcherWithStore } from './MapSwitcherWithStore';
import { MapPanel } from './MapPanel';
import { LayerSwitcherPanel } from './LayerSwitcherPanel';
import { LegendPanel } from './LegendPanel';
import { MeasurePanel } from './MeasurePanel';
import { SearchPanel } from './SearchPanel';
import { MapExport } from './MapExport';
import { NotesPanel } from './NotesPanel';
import { EditTools } from './EditTools';
import { ToolBar } from './ToolBar';
import { MapTool } from './MapTool';

export const MapContainer: React.FC = () => {
  const dispatch = useDispatch();
  const formatMessage = useMessage();

  const currentRouteVisibleLayers = useSelector((state) => state.router.route?.visibleLayers);
  const store = useStore();

  useEffect(() => {
    if (!currentRouteVisibleLayers) return;

    const layers = store.getState().mapCore.layers;
    const isLayerVisible = (layerId: string) =>
      layers.find((layer) => layer.id === layerId)?.layerOptions.visible;
    const visibledLayers: string[] = [];
    for (const activeLayerId of currentRouteVisibleLayers) {
      if (!isLayerVisible(activeLayerId)) {
        visibledLayers.push(activeLayerId);
      }
    }
    if (!visibledLayers.length) return;

    dispatch(
      actionSetLayerVisibility(
        Object.fromEntries(visibledLayers.map((layerId) => [layerId, true])),
      ),
    );

    return () => {
      dispatch(
        actionSetLayerVisibility(
          Object.fromEntries(visibledLayers.map((layerId) => [layerId, false])),
        ),
      );
    };
  }, [currentRouteVisibleLayers, dispatch, store]);

  const view = useSelector((state) => state.mapCore.view);
  const zoomLevelShortcuts = useSelector((state) => state.mapCore.zoomLevelShortcuts);
  const isLayerSwitcherVisible = useSelector((state) => state.layerSwitcher).layers.length > 0;
  const isLegendVisible = useSelector((state) => state.legend).length > 0;
  const isMeasureEnabled = useSelector((state) => state.app.isMeasureEnabled);
  const isNotesVisible = useSelector((state) => state.app.isNotesEnabled);
  const isSearchVisible = useSelector((state) => state.search).tabs.length > 0;
  const isExportMapVisible = useSelector((state) => state.app.isExportMapEnabled);
  const isEditToolsVisible = useSelector((state) => state.app.isEditToolsEnabled);
  const isUserLocationVisible = useSelector((state) => state.app.isUserLocationEnabled);
  const interaction = useSelector((state) => state.mapCore.interaction);
  const visiblePanel = useSelector((state) => state.uiManager.visiblePanel);
  const isEditor = useSelector((state) => state.app.isEditor);
  const userPositionMode = useSelector((state) => state.userPosition.userPositionMode);

  const [isExportMapActive, setExportMapActive] = useState(false);

  const handleExportMapToggle = useCallback(() => {
    setExportMapActive(!isExportMapActive);
  }, [setExportMapActive, isExportMapActive]);

  const handleShowLegendPanelBtnClick = useCallback(() => {
    if (visiblePanel === PANEL.LEGEND) {
      dispatch(actionDeactivatePanel());
    } else {
      dispatch(actionSetPanel(PANEL.LEGEND));
    }
  }, [dispatch, visiblePanel]);

  const handleShowLayerSwitcherPanelBtnClick = useCallback(() => {
    if (visiblePanel === PANEL.LAYER_SWITCHER) {
      dispatch(actionDeactivatePanel());
    } else {
      dispatch(actionSetPanel(PANEL.LAYER_SWITCHER));
    }
  }, [dispatch, visiblePanel]);

  const handleShowMeasurePanelBtnClick = useCallback(() => {
    batch('handleShowMeasurePanelBtnClick', () => {
      dispatch(actionDeactivatePanel());
      if (visiblePanel !== PANEL.MEASURE) {
        dispatch(actionActivateMeasure());
      }
    });
  }, [dispatch, visiblePanel]);

  const handleShowSearchPanelBtnClick = useCallback(() => {
    batch('handleShowMeasurePanelBtnClick', () => {
      dispatch(actionDeactivatePanel());
      if (visiblePanel !== PANEL.SEARCH) {
        dispatch(actionActivateSearch());
      }
    });
  }, [dispatch, visiblePanel]);

  const handleShowNotesPanelBtnClick = useCallback(() => {
    batch('handleShowNotesPanelBtnClick', () => {
      dispatch(actionDeactivatePanel());
      if (visiblePanel !== PANEL.NOTES) {
        dispatch(actionActivateNotes());
      }
    });
  }, [dispatch, visiblePanel]);

  const handleHidePanelBtnClick = useCallback(() => {
    dispatch(actionDeactivatePanel());
  }, [dispatch]);

  const handleDefaultExtentBtnClick = useCallback(() => {
    const extent = view.defaultExtent;
    extent && dispatch(actionSetExtent(extent));
  }, [dispatch, view]);

  const highlight = isEditor ? 'default' : 'mapbox';

  return (
    <div className='tw-map'>
      <MapView />
      <Interactions interaction={interaction} dispatch={dispatch} highlight={highlight} />
      <div className='tw-map--inner'>
        <div className='tw-map--toolBar'>
          <ToolBar>
            {isLayerSwitcherVisible && (
              <TertiaryBtn
                isInverse
                icon={{ element: <SvgLayers /> }}
                isActive={visiblePanel === PANEL.LAYER_SWITCHER}
                onClick={handleShowLayerSwitcherPanelBtnClick}
                tooltip={formatMessage(mapMsg.tooltipTitle)}
              />
            )}
            {isSearchVisible && (
              <TertiaryBtn
                isInverse
                icon={{ element: <SvgSearch /> }}
                isActive={visiblePanel === PANEL.SEARCH}
                onClick={handleShowSearchPanelBtnClick}
                tooltip={formatMessage(mapMsg.searchTitle)}
              />
            )}
            {isLegendVisible && (
              <TertiaryBtn
                isInverse
                icon={{ element: <SvgList /> }}
                isActive={visiblePanel === PANEL.LEGEND}
                onClick={handleShowLegendPanelBtnClick}
                tooltip={formatMessage(mapMsg.legendTitle)}
              />
            )}
            {isMeasureEnabled && (
              <TertiaryBtn
                isInverse
                icon={{ element: <SvgRuler /> }}
                isActive={visiblePanel === PANEL.MEASURE}
                onClick={handleShowMeasurePanelBtnClick}
                tooltip={formatMessage(mapMsg.measureTitle)}
              />
            )}
            {isNotesVisible && (
              <TertiaryBtn
                isInverse
                icon={{ element: <SvgPin /> }}
                isActive={visiblePanel === PANEL.NOTES}
                onClick={handleShowNotesPanelBtnClick}
                tooltip={formatMessage(mapMsg.notesTitle)}
              />
            )}
            {isExportMapVisible && (
              <TertiaryBtn
                isInverse
                icon={{ element: <SvgPrinter /> }}
                isActive={isExportMapActive}
                onClick={handleExportMapToggle}
                tooltip={formatMessage(mapMsg.exportMapTitle)}
              />
            )}
          </ToolBar>
          {isEditToolsVisible && <EditTools />}
        </div>
        <div className='tw-map--rightBar'>
          {view.defaultExtent && (
            <MapTool>
              <SecondaryBtn
                icon={{ element: <SvgFit /> }}
                onClick={handleDefaultExtentBtnClick}
                tooltip={formatMessage(mapMsg.defaultExtent)}
              />
            </MapTool>
          )}
          <MapTool>
            <Zoom
              inTooltip={formatMessage(mapMsg.inTooltip)}
              outTooltip={formatMessage(mapMsg.outTooltip)}
              zoomLevelShortcuts={zoomLevelShortcuts}
              dispatch={dispatch}
              view={view}
            />
          </MapTool>
          {isUserLocationVisible || view.rotation ? (
            <MapTool>
              <UserPosition
                enableUserLocation={isUserLocationVisible}
                userPositionMode={userPositionMode}
                layerId={LAYER_IDS.USER_LOCATION}
                projection={view.projection}
                rotation={view.rotation}
                dispatch={dispatch}
              />
            </MapTool>
          ) : null}
          <div className='tw-map--mapSwitcher'>
            <MapSwitcherWithStore />
          </div>
        </div>
        <div className='tw-map--statusBar'>
          <Statusbar mapScaleId={MAPSCALE_TARGET}>
            <MapScale />
            <Attributions />
          </Statusbar>
        </div>
        <div className='tw-map--panel'>
          {/* Necessary container for proper animation */}
          <MapPanel isActive={visiblePanel === PANEL.LEGEND}>
            <LegendPanel onHideBtnClick={handleHidePanelBtnClick} />
          </MapPanel>
          <MapPanel isActive={visiblePanel === PANEL.LAYER_SWITCHER}>
            <LayerSwitcherPanel onHideBtnClick={handleHidePanelBtnClick} />
          </MapPanel>
          <MapPanel isActive={visiblePanel === PANEL.MEASURE}>
            <MeasurePanel onHideBtnClick={handleHidePanelBtnClick} />
          </MapPanel>
          <MapPanel isActive={visiblePanel === PANEL.NOTES}>
            <NotesPanel onHideBtnClick={handleHidePanelBtnClick} />
          </MapPanel>
          <MapPanel isActive={visiblePanel === PANEL.SEARCH}>
            <SearchPanel onHideBtnClick={handleHidePanelBtnClick} />
          </MapPanel>
        </div>
      </div>
      {isExportMapActive && <MapExport onClose={handleExportMapToggle} />}
    </div>
  );
};
