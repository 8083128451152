import { useLink, useLocation } from '@tmapy/router';
import {
  SvgCaretEdgeLeft,
  SvgCaretLeft,
  SvgCaretRight,
  SvgCaretEdgeRight,
  TertiaryBtn,
} from '@tmapy/style-guide';

import type { PageInfo } from '../types';

import './Pagination.scss';

export type PaginationVariableNames = {
  firstVariableName: string;
  lastVariableName: string;
  beforeVariableName: string;
  afterVariableName: string;
};

export type TablePaginationProps = {
  pageInfo: PageInfo;
  paginationVariables: PaginationVariableNames;
  defaultPageSize?: number;
};

const fixNullValue = (params: Record<string, any>) => {
  Object.keys(params).forEach((param) => {
    if (params[param] === 'null') {
      params[param] = null;
    }
  });
  return params;
};

export const TablePagination: React.FC<TablePaginationProps> = ({
  pageInfo,
  paginationVariables,
  defaultPageSize = 20,
}) => {
  const currentLocation = useLocation();
  const params = fixNullValue(currentLocation.params);

  const { firstVariableName, lastVariableName, beforeVariableName, afterVariableName } =
    paginationVariables;

  const onBtnNextClick = useLink(currentLocation.route?.id, {
    ...params,
    [beforeVariableName]: undefined,
    [afterVariableName]: pageInfo?.endCursor,
    [firstVariableName]: params[firstVariableName] ?? params[lastVariableName] ?? defaultPageSize,
    [lastVariableName]: null,
  });

  const onBtnPrevClick = useLink(currentLocation.route?.id, {
    ...params,
    [afterVariableName]: undefined,
    [beforeVariableName]: pageInfo?.startCursor,
    [firstVariableName]: null,
    [lastVariableName]: params[lastVariableName] ?? params[firstVariableName] ?? defaultPageSize,
  });

  const onBtnLastClick = useLink(currentLocation.route?.id, {
    ...params,
    [beforeVariableName]: undefined,
    [afterVariableName]: undefined,
    [firstVariableName]: null,
    [lastVariableName]: params[lastVariableName] ?? params[firstVariableName] ?? defaultPageSize,
  });

  const onBtnFirstClick = useLink(currentLocation.route?.id, {
    ...params,
    [beforeVariableName]: undefined,
    [afterVariableName]: undefined,
    [firstVariableName]: params[firstVariableName] ?? params[lastVariableName] ?? defaultPageSize,
    [lastVariableName]: null,
  });

  const hasMorePages = pageInfo?.hasPreviousPage || pageInfo?.hasNextPage;
  if (!hasMorePages) return null;

  return (
    <div className='tw-pagination'>
      <TertiaryBtn
        isDisabled={!pageInfo.hasPreviousPage}
        {...onBtnFirstClick}
        icon={{ element: <SvgCaretEdgeLeft /> }}
      />
      <TertiaryBtn
        isDisabled={!pageInfo.hasPreviousPage}
        {...onBtnPrevClick}
        icon={{ element: <SvgCaretLeft /> }}
      />
      <TertiaryBtn
        isDisabled={!pageInfo.hasNextPage}
        {...onBtnNextClick}
        icon={{ element: <SvgCaretRight /> }}
      />
      <TertiaryBtn
        isDisabled={!pageInfo.hasNextPage}
        {...onBtnLastClick}
        icon={{ element: <SvgCaretEdgeRight /> }}
      />
    </div>
  );
};
