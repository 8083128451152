import type { RouteStateTWC } from '@tmapy/types';

const getRouteMap = (routes: RouteStateTWC[]) =>
  routes.reduce(
    (acc, route) => {
      acc[route.id] = route;
      return acc;
    },
    {} as Record<string, RouteStateTWC>,
  );

const removingNonExistentChildrenRouteIds = (routes: RouteStateTWC[]): RouteStateTWC[] => {
  const routeMap = getRouteMap(routes);
  return routes.map((route) => ({
    ...route,
    childrenRouteIds: route.childrenRouteIds?.filter((routeIds) => !!routeMap[routeIds]),
  }));
};

const addAllChildrenRouteIds = (routes: RouteStateTWC[]): RouteStateTWC[] => {
  const routeMap = getRouteMap(routes);

  const getAllChildrenRouteIds = (route: RouteStateTWC, parentRouteIds: string[]): string[] => {
    return (route.childrenRouteIds ?? [])
      .filter((childrenRouteId) => !parentRouteIds.includes(childrenRouteId))
      .flatMap((childrenRouteId) => {
        const route = routeMap[childrenRouteId];
        const childrenRouteIds = getAllChildrenRouteIds(route, [...parentRouteIds, route.id]);
        return [childrenRouteId, ...childrenRouteIds];
      });
  };

  return routes.map((route) => {
    const childrenRouteIds = getAllChildrenRouteIds(route, [route.id]);
    return { ...route, childrenRouteIds };
  });
};

const addShowMap = (routes: RouteStateTWC[]): RouteStateTWC[] => {
  const routeMap = getRouteMap(routes);

  return routes.map((route) => {
    return {
      ...route,
      showMap:
        route.showMap ||
        !!(
          route.childrenRouteIds &&
          route.childrenRouteIds.some((childrenRoute) => routeMap[childrenRoute].showMap)
        ),
    };
  });
};

export const applyInheritanceOfRoutes = (routes: RouteStateTWC[]): RouteStateTWC[] => {
  return addShowMap(addAllChildrenRouteIds(removingNonExistentChildrenRouteIds(routes)));
};
