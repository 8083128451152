import { PANEL } from '@tmapy/config';

import { version } from '../package.json';

export const VERSION = process.env.REACT_APP_VERSION ?? version;

export const SET_APP_STATE = 'SET_APP_STATE';
export const SET_PERMISSIONS = '@twc/SET_PERMISSIONS';

export const APP_POPUP_ADD = '@twc/APP_POPUP_ADD';
export const APP_POPUP_DISMISS_ALL = '@twc/APP_POPUP_DISMISS_ALL';
export const APP_POPUP_UPDATE = '@twc/APP_POPUP_UPDATE';
export const APP_SET_FILTER_GROUP_VISIBILITY = '@twc/APP_SET_FILTER_GROUP_VISIBILITY';
export const APP_SET_PACKAGE_VERSION = '@twc/APP_SET_PACKAGE_VERSION';

// `#l=v1u*-v21u~57`
export const LAYER_HASH_FRAGMENT_DELIMITER = '*';

/**
 * - visible = volitelne minus
 * - id vrstvy
 * - !opacity
 */
export const RE_HASH_PARAM_LAYERS_FRAGMENT = /(-?)([^~]*)(?:~(\d+))?/;

export const HASH_PARAM_PANEL: Partial<Record<PANEL, string>> = {
  [PANEL.LAYER_SWITCHER]: 'ls',
  [PANEL.LEGEND]: 'l',
  [PANEL.MEASURE]: 'm',
  [PANEL.SEARCH]: 's',
};
