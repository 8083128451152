export const deriveEnvironmentConfig = (
  config: Record<string, any>,
): string | Record<string, any> => {
  for (const [key, val] of Object.entries(config)) {
    if (typeof val === 'string') {
      continue;
    } else if (key === 'environment') {
      const envConfig = val?.[globalThis.CONFIG?.environment] ?? val.default;
      if (typeof envConfig === 'string') {
        return envConfig;
      } else {
        config = { ...config, ...val.default, ...envConfig };
        delete config.environment;
      }
    } else if (typeof val === 'object' && val !== null) {
      config[key] = deriveEnvironmentConfig(val);
    }
  }
  return config;
};
