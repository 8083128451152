import React, { useState } from 'react';

import './Gallery.scss';

import { Slideshow } from './Slideshow';

export type GalleryProps = {
  images: {
    src: string;
    tooltip?: string;
  }[];
};

export const Gallery: React.FC<GalleryProps> = React.memo(({ images }) => {
  const [isSlideshowVisible, setSlideshowVisibility] = useState(false);
  const [imageIndex, setImageIndex] = useState(-1);

  const handleClick = (e: React.MouseEvent, index: number) => {
    e.preventDefault();
    setSlideshowVisibility(true);
    setImageIndex(index);
  };

  const handleSlideshowClose = () => {
    setSlideshowVisibility(false);
  };

  return (
    <>
      <div className='inf-gallery'>
        {images.map((image, index) => (
          <figure key={image.src}>
            <a href={image.src} rel='noopener noreferrer' onClick={(e) => handleClick(e, index)}>
              <img src={image.src} title={image.tooltip} loading='lazy' alt='' draggable='false' />
            </a>
          </figure>
        ))}
      </div>
      {isSlideshowVisible && (
        <Slideshow imageIndex={imageIndex} images={images} onClose={handleSlideshowClose} />
      )}
    </>
  );
});
