import React, { ErrorInfo } from 'react';
import { FormattedMessage } from 'react-intl';

import { DangerAlert, ErrorPageProps } from '@tmapy/style-guide';
import { DataLayoutSpacing } from 'lib/graphql';
import { msg } from './messages';

const DefaultErrorComponent = ({ title, subtitle, details }: ErrorPageProps) => (
  <DataLayoutSpacing>
    <div>
      <DangerAlert>
        <b className='sg-u-type-bold'>
          {title} {subtitle}
        </b>
        {details && <span className='sg-a-d-b sg-a-mt-1/2'>{details}</span>}
      </DangerAlert>
    </div>
  </DataLayoutSpacing>
);

interface IErrorBoundaryProps {
  fallback?: React.ReactNode;
  fallbackComponent?: React.ComponentType<ErrorPageProps>;
}

interface IErrorBoundaryState {
  error: Error | null;
}

export class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
  readonly state: IErrorBoundaryState = {
    error: null,
  };

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('[ErrorBoundary]', error, errorInfo);
  }

  render() {
    const { error } = this.state;
    if (error) {
      const { fallback, fallbackComponent: Component = DefaultErrorComponent } = this.props;
      return (
        fallback ?? (
          <Component
            title={<FormattedMessage {...msg.title} />}
            subtitle={<FormattedMessage {...msg.defaultSubtitle} />}
            details={error.toString()}
          />
        )
      );
    }

    return this.props.children;
  }
}
