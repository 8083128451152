import type { ArgumentNode, ObjectFieldNode, VariableDefinitionNode } from 'graphql';
import { Kind } from 'graphql';

import { createNameNode } from './createNameNode';

export const createVariableDefinitionNode = (name: string): VariableDefinitionNode => ({
  kind: Kind.VARIABLE_DEFINITION,
  variable: {
    kind: Kind.VARIABLE,
    name: createNameNode(name),
  },
  type: {
    kind: Kind.NAMED_TYPE,
    name: createNameNode('auto'),
  },
});

export const createVariableDefinitionNodes = (
  agr: VariableDefinitionNode[],
  arg: ArgumentNode | ObjectFieldNode,
) => {
  if (arg.value.kind === Kind.OBJECT) {
    arg.value.fields.reduce(createVariableDefinitionNodes, agr);
  } else {
    const variableDefinition = createVariableDefinitionNode(arg.name.value);
    agr.push(variableDefinition);
  }
  return agr;
};
