import { RE_HASH_PARAM_LAYERS_FRAGMENT } from '../constants';

export type HashParamLayerFragment = {
  layerId: string;
  visible: boolean;
  opacity?: number;
};

/**
 * @example `-v21u~57`
 */
export const parseHashParamLayerFragment = (
  hashParamLayerFragment: string,
): HashParamLayerFragment | null => {
  const match = hashParamLayerFragment.match(RE_HASH_PARAM_LAYERS_FRAGMENT);
  if (match) {
    const [, visible, layerId, opacity] = match;
    return {
      layerId,
      visible: visible !== '-',
      opacity: opacity ? parseInt(opacity) / 100 : 1,
    };
  }
  return null;
};

export const serializeHashParamLayerFragment = (layer: HashParamLayerFragment): string => {
  return `${layer.visible ? '' : '-'}${layer.layerId}${
    layer.opacity && layer.opacity !== 1 ? `~${(layer.opacity * 100).toFixed(0)}` : ''
  }`;
};
