import type { GraphQLFieldMap, GraphQLInputFieldMap } from 'graphql';
import { getNamedType, ASTNode, isNonNullType } from 'graphql';

export const createFilterForDuplicatePasswords = (
  fieldMap: GraphQLFieldMap<any, any> | GraphQLInputFieldMap,
) => {
  const isPassword = (field: ASTNode) => {
    if (field.kind !== 'Field' && field.kind !== 'VariableDefinition') return false;
    const variable = field.kind === 'VariableDefinition' ? field.variable : field;
    return getNamedType(fieldMap[variable.name.value]?.type)?.name === 'Password';
  };

  const isNonNull = (field: ASTNode) => {
    if (field.kind !== 'Field' && field.kind !== 'VariableDefinition') return false;
    const variable = field.kind === 'VariableDefinition' ? field.variable : field;
    return isNonNullType(fieldMap[variable.name.value]?.type);
  };

  return (field: ASTNode, index: number, fields: readonly ASTNode[]) => {
    const isDuplicatePassword =
      isPassword(field) && fields.slice(0, index).some((field) => isPassword(field));

    if (isDuplicatePassword && isNonNull(field)) {
      throw new Error('Password is required a second time.');
    }

    return !isDuplicatePassword;
  };
};
