import type { TWC, extendedTWC } from '@tmapy/config';
import { SymbolError, fetchJSON, loadConfigFromTinker } from '@tmapy/config';
import { getAbsoluteUrl } from '@tmapy/utils';

import { deriveEnvironmentConfig, getBeaBasePath, resolveImports } from './';

import { addVersionToUrlString } from '../utils/addVersionToUrl';

export const loadConfig = async (appBaseName: string): Promise<extendedTWC | undefined> => {
  const tinkerConfig: Partial<extendedTWC> = await loadConfigFromTinker(
    appBaseName,
    globalThis.CONFIG?.graphql?.tinker,
  );

  const appId = tinkerConfig.appId ?? appBaseName;

  const localConfigBasePath = new URL(
    `${process.env.PUBLIC_URL}config/`,
    window.location.href,
  ).toString();

  let localConfig: Partial<TWC> = {};
  localConfig = await fetchJSON(addVersionToUrlString(`${localConfigBasePath}${appId}.json`), {
    ignoreErrors: true,
  });
  localConfig = deriveEnvironmentConfig(localConfig) as Partial<TWC>;
  if (SymbolError in localConfig) {
    console.debug(`Cannot load local config from ${localConfigBasePath}${appId}.`);
  }

  const loadBeaConfig = tinkerConfig.loadBeaConfig && !localConfig?.appId;
  const beaBasePath = getBeaBasePath(appId);

  let beaConfig: Partial<TWC> = {};
  if (loadBeaConfig) {
    beaConfig = await fetchJSON(`${beaBasePath}config.json`, {
      ignoreErrors: true,
    });
    if (SymbolError in beaConfig) {
      console.info(`Cannot load BEA config from ${beaBasePath}config.`);
    }
  }

  const basePath = beaConfig.routes ? beaBasePath : localConfigBasePath;

  try {
    if (!localConfig?.appId && !beaConfig?.appId && !tinkerConfig?.appId) {
      throw new Error('No configuration loaded. Maybe add appId or baseName to the config.');
    }
    if (localConfig?.appId && localConfig.appId !== appId) {
      throw new Error(`Application identifier mismatch: '${appId}' != '${localConfig.appId}'.`);
    }
    if (loadBeaConfig && beaConfig.appId !== appId) {
      const errorMessage = `Application identifier mismatch: '${appId}' != '${beaConfig.appId}'. Set the application identification correctly.`;
      if (tinkerConfig.origin === 'System') {
        throw new Error(errorMessage);
      } else {
        /** @deprecate - Replace throw new Error! */
        console.error(errorMessage);
      }
    }

    const routes = beaConfig.routes ?? (localConfig.routes || []).concat(tinkerConfig.routes || []);

    const graphql =
      globalThis.CONFIG?.graphql[localConfig?.graphql ?? 'default'] ??
      localConfig?.graphql ??
      getAbsoluteUrl('/graphql')?.href;

    const mergedConfig = {
      ...localConfig,
      ...tinkerConfig,
      map: (tinkerConfig.map || localConfig.map) && {
        ...localConfig.map,
        ...tinkerConfig.map,
        view: localConfig?.map?.view ?? tinkerConfig.map?.view,
      },
      info: (tinkerConfig.info || localConfig.info) && {
        coordsInfo: tinkerConfig.info?.coordsInfo || localConfig.info?.coordsInfo,
        services: [...(tinkerConfig.info?.services ?? []), ...(localConfig.info?.services ?? [])],
      },
      layerSwitcher: {
        groups: tinkerConfig.layerSwitcher?.groups ?? localConfig.layerSwitcher?.groups,
        enableFilter:
          tinkerConfig.layerSwitcher?.enableFilter ?? localConfig.layerSwitcher?.enableFilter,
        enableCustomLayers:
          tinkerConfig.layerSwitcher?.enableCustomLayers ??
          localConfig.layerSwitcher?.enableCustomLayers,
      },
      loadBeaConfig,
      auth:
        (localConfig?.auth || beaConfig?.auth || tinkerConfig?.auth) &&
        (globalThis.CONFIG?.auth ?? localConfig?.auth),
      appId: beaConfig.appId ?? localConfig?.appId ?? appId,
      packageId: tinkerConfig.packageId ?? beaConfig.packageId,
      graphql,
      isEditor: localConfig?.graphql === 'dmx' || !appId.indexOf('dtm'),
      basename: beaConfig.basename ?? localConfig?.basename ?? `/${appBaseName}`,
      routes,
      permissions: localConfig?.permissions,
      popups: beaConfig.popups ?? localConfig?.popups,
      sync: beaConfig.sync ?? localConfig?.sync,
    };

    return await resolveImports(mergedConfig, basePath);
  } catch (err) {
    const message = err instanceof Error ? err.message : String(err);
    console.error(`Cannot load configs from ${basePath}${appId}: ${message}`);
  }
};
