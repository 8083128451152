import { captureConsoleIntegration } from '@sentry/browser';
import * as Sentry from '@sentry/react';
import { VERSION } from './constants';

const environment = process.env.NODE_ENV;
const isDevelopment = environment === 'development';

export const sentryInit = () => {
  if (isDevelopment) return;

  /**
   * @See [Configuration](https://docs.sentry.io/platforms/javascript/guides/react/configuration/)
   */
  Sentry.init({
    // https://myra.tmapy.cz/organizations/tmapy/issues/?project=2
    dsn: 'https://aca489366cc14200ab19bb98364cb025@myra.tmapy.cz/2',
    release: VERSION,
    environment,
    integrations: [captureConsoleIntegration({ levels: ['warn', 'error', 'assert'] })],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
};
