import React, { useCallback, useEffect, useMemo } from 'react';
import classNames from 'classnames';

import type { GeoJSONFeature, GeoJSONPoint } from '@tmapy/types';
import type { EWKT, InfoDataState, InfoFeatureItem, InfoServiceState } from '@tmapy/config';
import { LAYER_IDS } from '@tmapy/config';
import { FormItem, FormRow } from '@tmapy/style-guide';
import { Dispatch, useDispatch } from '@tmapy/redux';
import { QUERY_STATE } from '@tmapy/utils';
import { actionSetLayerFeatures, EWKTToGeoJSONObject } from '@tmapy/mapcore';

import { actionFetchArcGISRestInfoData } from '../actions';
import { Section } from '../../graphql/components/Section';
import { Gallery } from './Gallery';

export type InfoBombyProps = {
  geom: EWKT;
  distanceInMeters: number;
  config: InfoServiceState;
  isVisible: boolean;
  dispatch: Dispatch;
  data: InfoDataState;
};

const InfoBombyFeature: React.FC<{
  config: InfoServiceState;
  feature: GeoJSONFeature;
  rank: number;
  data: any;
}> = ({ feature, rank, data, config }) => {
  const dispatch = useDispatch();
  const relationshipData = data.relationshipData;
  const serviceURL = useMemo<URL>(
    () => new URL((config as any)?.url ?? '', window.location.href),
    [config],
  );

  const handleMouseEnter = useCallback(() => {
    dispatch(actionSetLayerFeatures(LAYER_IDS.INFO_RESULTS, [feature]));
  }, [dispatch, feature]);

  const handleMouseLeave = useCallback(() => {
    dispatch(actionSetLayerFeatures(LAYER_IDS.INFO_RESULTS, []));
  }, [dispatch]);

  return (
    <li
      className={classNames({
        'sg-a-p-2 sg-u-vs-1': true,
        'sg-u-bt': rank > 0,
      })}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <FormRow>
        <FormItem label='Místo'>{feature.properties?.address}</FormItem>
      </FormRow>
      <FormRow>
        <FormItem label='Letectvo'>{feature?.properties?.raid}</FormItem>
      </FormRow>
      {relationshipData?.[+feature?.properties?.objectid]?.data?.map(
        (dataItem: any[], idx: number) => {
          const photos = dataItem.map((photo) => ({
            src: `${serviceURL.origin}${photo.attributes.file}`,
          }));
          return (
            photos?.length > 0 && (
              <div key={idx} className='sg-a-mt-2'>
                <Gallery images={photos} />
              </div>
            )
          );
        },
      )}
    </li>
  );
};

export const InfoBomby: React.FC<InfoBombyProps> = React.memo(
  ({ geom, distanceInMeters, config, dispatch, isVisible, data }) => {
    const point = useMemo(() => {
      const geoJSON = EWKTToGeoJSONObject(geom) as GeoJSONFeature<GeoJSONPoint>;
      return geoJSON.geometry.coordinates;
    }, [geom]);

    useEffect(() => {
      if (config.type === 'ArcGISRest') {
        dispatch(actionFetchArcGISRestInfoData(config, point, distanceInMeters));
      }
    }, [config, point]);

    const queryState = data?.queryState;

    if (
      !isVisible ||
      queryState !== QUERY_STATE.SUCCESS ||
      data?.items?.length === 0 ||
      (data?.items?.length === 1 && data.items[0].features?.length === 0)
    ) {
      return null;
    }

    return (
      <Section title='Bombardování Brna'>
        <ul className='sg-u-box'>
          {data?.items?.map((item: InfoFeatureItem, idx) =>
            item.features.map((feature, idy: number) => (
              <InfoBombyFeature
                key={`${idx}_${config.id}_${idy}`}
                config={config}
                feature={feature}
                rank={idx + idy}
                data={data}
              />
            )),
          )}
        </ul>
      </Section>
    );
  },
);
