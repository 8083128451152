import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import type { GeoJSONFeature } from '@tmapy/types';
import { FormItem, FormRow } from '@tmapy/style-guide';
import { useMessage } from '@tmapy/intl';
import { useDispatch } from '@tmapy/redux';
import { actionSetLayerFeatures } from '@tmapy/mapcore';
import type { ArcGISRestInfoPropertiesState, WFSInfoState } from '@tmapy/config';
import { useLink } from '@tmapy/router';
import { LAYER_IDS } from '@tmapy/config';

import { getProperty } from '../utils/getProperty';

import './InfoFeature.scss';

type InfoFeatureProps = {
  id: string;
  properties: WFSInfoState['properties'] | ArcGISRestInfoPropertiesState;
  feature: GeoJSONFeature;
  geoJSONFeature: GeoJSONFeature;
};

export const InfoFeature: React.FC<InfoFeatureProps> = ({
  properties,
  id,
  feature,
  geoJSONFeature,
}) => {
  const dispatch = useDispatch();
  const formatMessage = useMessage();

  const handleMouseEnter = useCallback(() => {
    dispatch(actionSetLayerFeatures(LAYER_IDS.INFO_RESULTS, [geoJSONFeature]));
  }, [dispatch, geoJSONFeature]);

  const handleMouseLeave = useCallback(() => {
    dispatch(actionSetLayerFeatures(LAYER_IDS.INFO_RESULTS, []));
  }, [dispatch]);

  const htmlContentId = `info.${id}.${properties.id ? properties.id + '.' : ''}item.content.html`;

  const panorama = useMemo(() => {
    const panoServiceConfig = properties.fields?.find?.((field) => field.type === 'panorama');
    if (!panoServiceConfig) return null;

    const imgId = getProperty(feature, panoServiceConfig.nameId);
    const src = panoServiceConfig.template?.replace('%FIELD%', imgId);
    return { src, imgId };
  }, [feature, properties.fields]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isSameRoute, isSameExactly, ...panoramaLink } = useLink('panorama', {
    imgId: panorama?.imgId,
    serviceId: id,
  });

  const content = panorama ? (
    <figure>
      <a {...panoramaLink} rel='noopener noreferrer'>
        <img className='tw-u-br' src={panorama.src} loading='lazy' alt='' draggable='false' />
      </a>
    </figure>
  ) : properties.fields ? (
    properties.fields.map((item) => {
      const itemValue =
        getProperty(feature.properties, item.nameId) ?? getProperty(feature, item.nameId);

      const label =
        formatMessage.fallback([
          item.labelId,
          `info.${id}.${item.nameId}`,
          `info.${item.nameId}`,
        ]) ??
        item.labelId ??
        item.nameId;

      return (
        <FormRow key={item.nameId}>
          <FormItem label={label}>{itemValue}</FormItem>
        </FormRow>
      );
    })
  ) : (
    <FormattedMessage
      id={htmlContentId}
      values={{
        ...feature,
        ...geoJSONFeature.properties,
        ITEM: (children) => {
          const length = children.length;
          const label = length === 2 && typeof children[0] === 'string' ? children[0] : undefined;
          if (length === 1 || label) {
            return (
              <FormRow>
                <FormItem label={label}>{children[length - 1]}</FormItem>
              </FormRow>
            );
          }
          return null;
        },
        LABEL: (children) => (typeof children[0] === 'string' ? children[0] : null),
        VALUE: (children) => (typeof children[0] === 'string' ? <>{children[0]}</> : null),
      }}
    />
  );

  return (
    <li
      className={classNames({
        'inf-infoFeature sg-a-p-2 sg-u-vs-1': true,
        'inf-infoFeature-isHoverable': !!geoJSONFeature.geometry,
      })}
      onMouseEnter={geoJSONFeature.geometry ? handleMouseEnter : undefined}
      onMouseLeave={geoJSONFeature.geometry ? handleMouseLeave : undefined}
    >
      {content}
    </li>
  );
};
