import type { ThunkAction } from '@tmapy/redux';
import type { RouteParams } from '@tmapy/router';
import type {
  ArcGISRestInfoState,
  InfoFeatureItem,
  InfoState,
  InfoDataState,
  WFSInfoState,
  WMSInfoState,
} from '@tmapy/config';
import { assertAGSResponse, DEFAULT_DISTANCE_IN_PIXELS } from '@tmapy/config';
import { convertPointToExtent, MapCoreActions } from '@tmapy/mapcore';
import { removeFalsey, ensureArray, QUERY_STATE } from '@tmapy/utils';

import { getProperty } from './utils/getProperty';
import { createFeatureFromWFSResponse } from './utils/createFeatureFromWFSResponse';
import { createFeatureFromAGSResponse } from './utils/createFeatureFromAGSResponse';

import { SET_INFO_SERVICE_DATA, SET_INFO_STATE } from './constants';

export type ActionSetInfoState = ReturnType<typeof actionSetInfoState>;
export type SetInfoServiceData = ReturnType<typeof setInfoServiceData>;

export type InfoActions = ActionSetInfoState | MapCoreActions | SetInfoServiceData;

const getAuthorizationHeaders = (
  token: string | null,
  isAuth: boolean | undefined,
): Record<string, string> | undefined => {
  let headers: Record<string, string> | undefined = undefined;
  if (token && isAuth) {
    headers = {
      Authorization: `Bearer ${token}`,
    };
  }

  return headers;
};

export const actionSetInfoState = (payload: InfoState) =>
  ({
    type: SET_INFO_STATE,
    payload,
  }) as const;

export const setInfoServiceData = (serviceId: string, data: InfoDataState) =>
  ({
    type: SET_INFO_SERVICE_DATA,
    payload: { serviceId, data },
  }) as const;

const metadataCache = new Map<string, any>();

export const actionFetchArcGISRestInfoData =
  (config: ArcGISRestInfoState, point: number[], distanceInMetersParam: number): ThunkAction =>
  async (dispatch, getState) => {
    const state = getState();

    dispatch(
      setInfoServiceData(config.id, {
        queryState: QUERY_STATE.WAITING,
        items: null,
        relationshipData: null,
      }),
    );

    let data: any;

    const metadataURL = new URL(`${config.url}?f=pjson`, window.location.href).href;
    let metadata = metadataCache.get(metadataURL);
    let metadataPromise: Promise<Response> | null = null;

    try {
      const resolution = state.mapCore.view.resolution;

      const distanceInPixels = config.params?.distance ?? DEFAULT_DISTANCE_IN_PIXELS;
      const currentDistanceInMeters = resolution ? resolution * +distanceInPixels : 0;
      const distanceInMeters = distanceInMetersParam
        ? distanceInMetersParam
        : currentDistanceInMeters;

      const agsUrl = new URL(`${config.url}/query`, window.location.href);
      const searchParams = agsUrl.searchParams;

      const params = {
        ...(config.params && typeof config.params === 'object' ? config.params : null),
        geometryType: 'esriGeometryPoint',
        geometry: point.join(','),
        units: 'esriSRUnit_Meter',
        distance: distanceInMeters,
        f: 'json',
      };

      // set AGS required params if not exists
      if (params.outFields === undefined) {
        params.outFields = '*';
      }

      for (const [key, value] of Object.entries(params)) {
        if (value !== null && value !== undefined) {
          searchParams.set(key, String(value));
        }
      }

      const responsePromise = fetch(agsUrl.href);

      if (!metadata) {
        metadataPromise = fetch(metadataURL);
      }

      const response = await responsePromise;
      data = await response.json();
      if (data.error) {
        throw new Error(data.error.message ?? data.error);
      }

      const AGSResponse = assertAGSResponse(data);

      const features = removeFalsey(AGSResponse.features.map(createFeatureFromAGSResponse));
      const fields = config.properties?.fields?.map((field) => {
        const AGSfield = AGSResponse.fields.find((AGSfield) => AGSfield.name === field.nameId);
        return { ...field, labelId: field.labelId ?? AGSfield?.alias };
      });
      const items: InfoFeatureItem[] = [];
      items.push({ features, properties: { ...config.properties, fields } });

      dispatch(setInfoServiceData(config.id, { queryState: QUERY_STATE.SUCCESS, items }));
    } catch (err) {
      const message = err instanceof Error ? err.message : String(err);
      console.error('[actionFetchArcGISRestInfoData] failed:', message);
      dispatch(setInfoServiceData(config.id, { queryState: QUERY_STATE.FAIL }));
      return;
    }

    if (metadataPromise) {
      const metadataResponse = await metadataPromise;
      metadata = await metadataResponse.json();
      metadataCache.set(metadataURL, metadata);
    }

    if (!metadata || metadata.error) {
      console.error(
        '[actionFetchArcGISRestInfoData] failed: no metadata available.',
        metadata.error,
      );
      dispatch(setInfoServiceData(config.id, { queryState: QUERY_STATE.FAIL }));
    }

    const objectIdParamField = metadata.fields?.find(
      (field: Record<string, string | number>) => field.type === 'esriFieldTypeOID',
    );

    const relationships = metadata.relationships;
    const relationshipsData = new Map();

    const serviceURL = new URL(config.url, window.location.href);

    await Promise.all(
      data.features.map(async (feature: Record<string, any>) => {
        const attributes = feature.attributes;
        const objectId =
          attributes[objectIdParamField.name] || attributes[objectIdParamField.alias];

        if (relationships.length > 0) {
          const queryRelationshipsUrls: string[] = [];
          for (const relationship of relationships) {
            queryRelationshipsUrls.push(
              `${serviceURL.href}/queryRelatedRecords?objectIds=${objectId}&relationshipId=${relationship.id}&outFields=*&f=pjson`,
            );
          }

          const relationshipsDataResponse = await Promise.all(
            queryRelationshipsUrls.map(async (url) => {
              const response = await fetch(url).then((data) => data.json());
              return response;
            }),
          );
          const data = relationshipsDataResponse.map((relationshipData) => {
            return relationshipData.relatedRecordGroups?.[0]?.relatedRecords || [];
          });

          relationshipsData.set(objectId, { objectId, data });
        }
      }),
    );

    dispatch(
      setInfoServiceData(config.id, {
        queryState: QUERY_STATE.SUCCESS,
        relationshipData: Object.fromEntries(relationshipsData.entries()),
      }),
    );
  };

export const actionFetchWFSInfoData =
  (config: WFSInfoState, point: number[], distanceInMetersParam: number): ThunkAction =>
  async (dispatch, getState) => {
    const state = getState();

    dispatch(
      setInfoServiceData(config.id, {
        queryState: QUERY_STATE.WAITING,
        items: null,
        relationshipData: null,
      }),
    );
    try {
      const resolution = state.mapCore.view.resolution;
      const token = state.auth.access_token;

      const currentDistanceInMeters = resolution ? resolution * DEFAULT_DISTANCE_IN_PIXELS : 0;
      const distanceInMeters = distanceInMetersParam
        ? distanceInMetersParam
        : currentDistanceInMeters;

      const geometryField = config.properties.geometryField ?? 'geom';
      const isOGC = !config.url.includes('/gservice');
      const url = isOGC
        ? `${config.url}&BBOX=${convertPointToExtent(point, distanceInMeters).join(',')}`
        : `${config.url}&cql_filter=DWITHIN(${geometryField},POINT(${point.join(
            ' ',
          )}),${distanceInMeters},meters)`;
      const wfsUrl = new URL(url, window.location.href);

      const headers = getAuthorizationHeaders(token, config.isAuth);
      const response = await fetch(wfsUrl.href, { headers });
      const data = await response.json();

      const items: InfoFeatureItem[] = [];

      ensureArray(config.properties).forEach((item) => {
        const features = ensureArray(getProperty(data, item.fieldsPath)).map(
          createFeatureFromWFSResponse,
        );
        const properties = item;

        if (features.length) items.push({ features, properties });
      });

      dispatch(setInfoServiceData(config.id, { queryState: QUERY_STATE.SUCCESS, items }));
    } catch (err) {
      console.error('[actionFetchWFSInfoData] failed:', err);
      dispatch(setInfoServiceData(config.id, { queryState: QUERY_STATE.FAIL }));
    }
  };

export const actionFetchWMSInfoData =
  (config: WMSInfoState, point: number[], map: any): ThunkAction =>
  async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.access_token;
    const params = state.router.params;
    dispatch(
      setInfoServiceData(config.id, {
        queryState: QUERY_STATE.WAITING,
        items: null,
        relationshipData: null,
      }),
    );

    try {
      const projection = state.mapCore.view.projection;
      const size = map.getSize();
      const bbox = map.getView().calculateExtent(size).toString();
      const url = `${config.url}&WIDTH=${size[0]}&HEIGHT=${
        size[1]
      }&SRS=${projection}&X=${Math.trunc(point[0])}&Y=${Math.trunc(point[1])}&BBOX=${bbox}`;

      const featureInfoParams = `&REQUEST=GetFeatureInfo&SERVICE=WMS&VERSION=1.1.1&INFO_FORMAT=text/html`;
      const configParams = getUrlConfigParams(config);
      const routerParams = getUrlRouterParams(config, params);

      const wmsUrl = new URL(
        `${url}${featureInfoParams}${configParams}${routerParams}`,
        window.location.href,
      );

      const headers = getAuthorizationHeaders(token, config.isAuth);
      const response = await fetch(wmsUrl.href, { headers });
      const html = await response.text();
      dispatch(setInfoServiceData(config.id, { queryState: QUERY_STATE.SUCCESS, html }));
    } catch (err) {
      console.error('[actionFetchWMSInfoData] failed:', err);
      dispatch(setInfoServiceData(config.id, { queryState: QUERY_STATE.FAIL }));
    }
  };

const getUrlRouterParams = (config: WMSInfoState, params: RouteParams) => {
  let urlParams = '';
  if (config.routerParams) {
    for (const param in config.routerParams) {
      if (Object.prototype.hasOwnProperty.call(config.routerParams, param)) {
        const nameParam = config.routerParams[param] as string;
        if (nameParam && params[param] !== undefined) {
          urlParams += `&${nameParam}=${params[param]}`;
        }
      }
    }
  }
  return urlParams;
};

const getUrlConfigParams = (config: WMSInfoState) => {
  let urlParams = '';
  if (config.params) {
    for (const [key, value] of Object.entries(config.params)) {
      if (value !== null && value !== undefined) {
        urlParams += `&${key}=${String(value)}`;
      }
    }
  }
  return urlParams;
};
