import type { ASTNode } from 'graphql';
import { visit } from 'graphql';

import { getDirectives } from './getDirectives';
import { getFieldAliasOrName } from './getFieldAliasOrName';

export const processExports = (node: ASTNode, result: any) => {
  const variables: Record<string, any> = {};

  let currentValue = result;
  const stack: any[] = [];

  visit(node, {
    Field: {
      enter: (node) => {
        const dataFieldName = getFieldAliasOrName(node);

        stack.push(currentValue);
        currentValue = currentValue?.[dataFieldName];

        if (Array.isArray(currentValue)) {
          currentValue = currentValue[0];
        }

        if (['string', 'number'].includes(typeof currentValue)) {
          const directives = getDirectives(node.directives);
          const exportName = directives?.export?.as ?? dataFieldName;
          variables[exportName] = currentValue;
        }
      },
      leave: () => {
        currentValue = stack.pop();
      },
    },
  });

  return variables;
};
