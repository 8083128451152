import React from 'react';
import { ApolloProvider } from '@apollo/client';

import { ReduxProvider } from '@tmapy/redux';
import { IntlProvider } from '@tmapy/intl';
import { DevToolsContainer } from '@tmapy/devtools';
import { ErrorPage } from '@tmapy/style-guide';

import type { ExtendedApolloClient } from '../lib/graphql';

import { MapWithStore } from '../map/MapWithStore';

import { ErrorBoundary } from './errors/ErrorBoundary';
import { AppPopups } from './AppPopups';

export const BaseApp: React.FC = ({ children }) => (
  <ReduxProvider>
    <IntlProvider>
      <DevToolsContainer options={{ position: { left: 3, top: 3 } }} />
      <ErrorBoundary fallbackComponent={ErrorPage}>{children}</ErrorBoundary>
    </IntlProvider>
  </ReduxProvider>
);

export const App: React.FC<{ client: ExtendedApolloClient }> = ({ client, children }) => {
  return (
    <BaseApp>
      <ApolloProvider client={client}>
        <AppPopups />
        <MapWithStore>{children}</MapWithStore>
      </ApolloProvider>
    </BaseApp>
  );
};

export default App;
