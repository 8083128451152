import type { GraphQLType, GraphQLObjectType, GraphQLNamedType, GraphQLOutputType } from 'graphql';
import { isObjectType, isListType, getNamedType, getNullableType } from 'graphql';

export type ConnectionInfo = {
  nodeType: GraphQLNamedType;
  /** Named Type of "edges" field */
  edgeType: GraphQLObjectType;
  /** Named Type of "pageInfo" field */
  pageInfoType: GraphQLOutputType;
};

const warnNotConnection = (graphqlType: GraphQLType, text: string) =>
  console.warn(
    `GraphQL type ${graphqlType.toString()} looks like "Connection Type" but it is not.\n${text}`,
  );

/**
 * Get info about [GraphQL Cursor Connection](https://relay.dev/graphql/connections.htm)
 */
export const getConnectionInfo = (
  graphqlConnectionType: GraphQLObjectType,
): null | ConnectionInfo => {
  // Connection types must have fields named edges and pageInfo.
  // They may have additional fields related to the connection, as the schema designer sees fit.
  const { edges, pageInfo } = graphqlConnectionType.getFields();
  if (!edges || !pageInfo) return null;
  console.debug('graphqlConnectionType, edges, pageInfo', graphqlConnectionType, edges, pageInfo);

  // A "Connection Type" must contain a field called pageInfo.
  // This field must return a non‐null PageInfo object, as defined in the "PageInfo" section below.
  const pageInfoType = getNullableType(pageInfo.type);
  if (!pageInfoType) return null;

  // A "Connection Type" must contain a field called edges.
  // This field must return a list type that wraps an edge type.
  const edgeListType = getNullableType(edges.type);
  if (!isListType(edgeListType)) {
    warnNotConnection(
      graphqlConnectionType,
      `"edges" must be a List Type (not ${edgeListType?.toString()})`,
    );
    return null;
  }
  const edgeType = getNamedType(edgeListType);
  if (!isObjectType(edgeType)) {
    warnNotConnection(
      graphqlConnectionType,
      `"edges" must be a List Type of Object Type (not ${edgeListType?.toString()})`,
    );
    return null;
  }

  const { node } = edgeType.getFields();
  if (!node) return null;

  const nodeType = getNamedType(node.type);

  return {
    nodeType,
    edgeType,
    pageInfoType,
  };
};

/**
 * Determines if type matches to
 * [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm)
 */
export const isConnectionType = (graphqlType: GraphQLType): boolean => {
  const namedType = getNamedType(graphqlType);
  if (!isObjectType(namedType)) {
    return false;
  }
  return !!getConnectionInfo(namedType);
};
