import { useEffect, useMemo, useReducer } from 'react';
import { DocumentNode, GraphQLError, Kind } from 'graphql';
import { visit } from 'graphql/language/visitor';

import type { ApolloError } from '@apollo/client';
import { useApolloClient, NetworkStatus } from '@apollo/client';

import { EWKTToGeoJSONObject, GeoJSONToEWKT } from '@tmapy/mapcore';

import { prepareServerDocument } from './visitors/prepareServerDocument';
import {
  getVariablesFromDocument,
  VariableDefinition,
} from './astFactories/tableComponents/createTableFilterComponent';
import type { ExtendedApolloClient } from './createClient';
import { DateTimeRangeValue } from './utils/DateTimeRangeValue';
import { DateRangeValue } from './utils/DateRangeValue';

export type ResultTypeQueryOrMutation = {
  client: ExtendedApolloClient;
  data?: any;
  error?: ApolloError;
  errors?: readonly GraphQLError[];
  loading: boolean;
  networkStatus: NetworkStatus;
  called: boolean;
};

type Variables = Record<string, any>;

export const modifyVariables = (
  document: DocumentNode,
  stringVariables: Variables,
  filterVariables?: VariableDefinition[],
): Variables | undefined => {
  if (!filterVariables || !stringVariables) return;

  const orderVariables = new Set<string>();
  visit(document.definitions[0], {
    Argument: (node) => {
      if (node.name.value === 'order') {
        orderVariables.add((node.value as any).name.value);
      }
    },
  });

  let isArraySort = false;
  visit(document.definitions[0], {
    VariableDefinition: (node) => {
      if (orderVariables.has(node.variable.name.value)) {
        isArraySort = node.type.kind === 'ListType';
      }
    },
  });

  const variables = {} as Record<string, any>;
  for (const { name, type, directives } of filterVariables) {
    if (!(name in stringVariables)) continue;
    const stringValue = stringVariables[name];
    if (directives.ewkt) {
      if (!stringValue) {
        variables[name] = null;
      } else {
        if (directives.ewkt.projection) {
          const geoJSON = EWKTToGeoJSONObject(stringValue, directives.ewkt.projection);
          const reprojected = GeoJSONToEWKT(
            geoJSON,
            directives.ewkt.projection,
            directives.ewkt.projection,
          );
          variables[name] = reprojected;
        }
      }
    }
    // vyjimka na razeni
    else if (orderVariables.has(name)) {
      const field = stringValue;
      const isDesc = field.charAt(0) === '-';
      const sort = isDesc ? 'DESC' : 'ASC';
      const fieldName = (isDesc && field.slice(1)) || field;
      const value = JSON.parse(`{"${fieldName}": "${sort}"}`);
      variables[name] = isArraySort ? [value] : value;
    } else {
      switch (type) {
        case 'ID':
        case 'GlobalID': // BEG neumi 'ID'
        case 'L10nString':
        case 'String': {
          variables[name] = stringValue;
          break;
        }
        case 'Int': {
          variables[name] = parseInt(stringValue, 10);
          break;
        }
        case 'Boolean': {
          variables[name] = stringValue === 'true';
          break;
        }
        case 'DateRange': {
          variables[name] = DateRangeValue.parse(stringValue);
          break;
        }
        case 'DateTimeRange': {
          variables[name] = DateTimeRangeValue.parse(stringValue);
          break;
        }
        case 'EWKT': {
          console.warn(
            `[modifyVariables] EWKT type without @ewkt directive for input variable $${name}`,
          );
          variables[name] = stringValue ? stringValue : null;
          break;
        }
        default: {
          console.error(`[modifyVariables] Cannot convert value for type ${type}`);
          variables[name] = stringValue;
        }
      }
    }
  }
  return variables;
};

export function useQueryOrMutationData(
  document: DocumentNode,
  variables: Record<string, any>,
): ResultTypeQueryOrMutation {
  const firstDefinition = document.definitions[0];

  const fragments = useMemo(
    () => document.definitions.filter((def) => def.kind === 'FragmentDefinition'),
    [document],
  );

  const loading = !(
    firstDefinition.kind === 'OperationDefinition' && firstDefinition.operation === 'mutation'
  );

  const modifiedVariables = useMemo(() => {
    const filterVariables = getVariablesFromDocument(document);
    return modifyVariables(document, variables, filterVariables);
  }, [variables, document]);

  const client = useApolloClient() as ExtendedApolloClient;
  const [results, setResults] = useReducer(
    (state: ResultTypeQueryOrMutation, action: Partial<ResultTypeQueryOrMutation>) => {
      return {
        ...state,
        ...action,
      };
    },
    {
      client,
      data: null,
      loading,
      networkStatus: loading ? NetworkStatus.loading : NetworkStatus.ready,
      called: loading,
    },
  );

  useEffect(() => {
    const serverDocument = prepareServerDocument(document, client.clientSchema);
    const firstDefinition = serverDocument.definitions[0];
    if (firstDefinition.kind === 'OperationDefinition') {
      if (firstDefinition.operation === 'query') {
        setResults({ loading: true, networkStatus: NetworkStatus.loading, called: true });

        const observableQuery = client.watchQuery({
          variables: modifiedVariables,
          query: {
            kind: Kind.DOCUMENT,
            definitions: [firstDefinition, ...fragments],
          },
          fetchPolicy: 'cache-and-network',
        });

        const subscription = observableQuery.subscribe(
          (result) => {
            setResults({
              ...result,
              client,
              called: true,
            });
          },
          (error) => {
            setResults({
              error,
              loading: false,
              networkStatus: NetworkStatus.error,
            });
          },
        );
        return () => {
          subscription.unsubscribe();
        };
      }
    }
  }, [document, fragments, modifiedVariables, setResults, client]);

  return results;
}
