import React from 'react';
import classNames from 'classnames';

import { SvgArrowUp, SvgArrowDown, SvgArrowUpDown, Icon } from '@tmapy/style-guide';

import './TableHeadCell.scss';

import { SORTING_METHOD } from '../constants';

type TableHeadCellProps = {
  sortingMethod?: SORTING_METHOD;
  onClick?(e: React.MouseEvent): void;
  columnId?: string;
  isBadge?: boolean;
};
const iconMap: Record<SORTING_METHOD, JSX.Element> = {
  [SORTING_METHOD.ASC]: <SvgArrowDown />, // eslint-disable-line no-useless-computed-key
  [SORTING_METHOD.DESC]: <SvgArrowUp />, // eslint-disable-line no-useless-computed-key
  [SORTING_METHOD.UNSORTED]: <SvgArrowUpDown />, // eslint-disable-line no-useless-computed-key
};

export const TableHeadCell: React.FC<TableHeadCellProps> = ({
  sortingMethod,
  onClick,
  columnId,
  isBadge,
  children,
}) => {
  let icon: JSX.Element | undefined;
  if (sortingMethod) {
    icon = iconMap[sortingMethod];
  }

  return (
    <th
      className={classNames('tw-tableHeadCell sg-u-hsl-1/2', {
        'tw-tableHeadCell-isSortable': !!sortingMethod,
        'tw-tableHeadCell-isNotSorted': sortingMethod === SORTING_METHOD.UNSORTED,
        'sg-table--narrowCell sg-a-p-1 sg-a-ta-c': isBadge,
      })}
      data-test-id={columnId && `tw-tableHeadCell--${columnId}`}
      onClick={onClick}
    >
      <span className='tw-tableHeadCell--label'>{children}</span>
      {icon && (
        <span className='tw-tableHeadCell--icon'>
          <Icon element={icon} />
        </span>
      )}
    </th>
  );
};
